import React from 'react';
import './SubmitButton.scss';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubmitButton = ({
  label,
  rightIconId,
  leftIconId,
  isDisabled,
  isTransparent,
  isLarge,
  onClick,
  tooltip,
  onDisabledTooltip,
  isLightTheme,
  isToggleMode,
  isSelectedToggleMode,
  width,
  children,
  className,
}) => (
  <div
    style={{ width: width }}
    className={classNames(
      className,
      'submit-button-component',
      { disabled: isDisabled },
      { transparent: isTransparent },
      { 'is-large': isLarge },
      { 'light-mode': isLightTheme },
      { 'toggle-mode': isToggleMode },
      { 'toggle-light-mode': isToggleMode && isLightTheme }, //rename this when removing modal-white.scss
      { 'selected-toggle': isToggleMode && isSelectedToggleMode }
    )}
    onClick={() => !isDisabled && onClick()}
    role="button"
    tabIndex={0}
  >
    <Tooltip content={isDisabled ? onDisabledTooltip : tooltip}>
      <div className="submit-button-content">
        {leftIconId && <Icon iconId={leftIconId} className="icon left-icon"></Icon>}
        <span>{label}</span>
        {rightIconId && <Icon iconId={rightIconId} className="icon right-icon"></Icon>}
      </div>
    </Tooltip>

    {children}
  </div>
);

SubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  rightIconId: PropTypes.string,
  leftIconId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  onDisabledTooltip: PropTypes.string,
  isLightTheme: PropTypes.bool,
  isToggleMode: PropTypes.bool,
  isSelectedToggleMode: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.object,
  className: PropTypes.string,
};

SubmitButton.defaultProps = {
  isDisabled: false,
  isTransparent: false,
  isLarge: false,
  isLightTheme: false,
  isToggleMode: false,
  isSelectedToggleMode: false,
};

export default SubmitButton;
