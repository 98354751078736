import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

var Template = require("./top-association-widget.html"),
    d3 = require("d3"),
    c = require("infra/utils/common"),
    ex = require("infra/utils/export"),
    BaseWidget = require("../base_widget"),
    TopAssociationService = require("data/insights/association-trend-service"),
    TopChart = require("common/charts/top-chart"),
    TopBar = require("common/charts/top-bar"),
    keywords = require("data/keywords.srv"),
    examplesDataModel = require("data/insights/examples-data-model"),
    consumptionTrendService = require("data/insights/consumption-trend-service"),
    TrendChart = require('common/charts/trend-chart');

var topChartConfiguration = {
    topBarGroup: {
        graph: {},
        examples: {}
    }
};

var isDefined = angular.isObject,
    isArray = angular.isArray;

var isNumber = function (n) {
    return angular.isNumber(n) && !isNaN(n);
};

const DEFAULT_VALUE_MEASURE = 'absolute';
const DEFAULT_VIEW_TYPE = 'normal';
const TREND_LINE_ERROR_MESSAGE = "The Graph is currently unavailable";
const associationExportName = 'Associations';
const consumptionExportTabName = 'Association Trend';
const consumptionChangeFromAvgExportTabName = '% Change From Average';
const strengthTemplate = _.template("Strength <%= parseInt(consumption).toLocaleString('en') %>, <%= consumptionAvg > 0 ? '+' : ''%><%= consumptionAvg %>% from Avg");
const consumptionTemplate = _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg");
const skewTemplate = _.template("Skew <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg");
const ASSOC_LIMIT = 60;
const associationLimitPassedMessage = "Only " + ASSOC_LIMIT +" associations can be selected. Please narrow your search.";
const ADD_ASSOCIATION_DELAY = 1500;

function TopAssociationController($scope, $rootScope, $timeout, $state, associationTrendService, util,
                                  examplesDataModel, consumptionTrendService, insightsExportService, permissions,
                                  baseInsightsService, TIMES, filtersPartition, notificator, $location) {

    var self = this;
    this.service = associationTrendService;
    this.consumptionService = consumptionTrendService;
    this.insightsExportService = insightsExportService;
    this.parameters = {};
    this.util = util;
    this.permissions = permissions;
    this.baseInsightsService = baseInsightsService;
    this.$location = $location;

    $scope.context = $rootScope.context;
    $scope.noData = true;
    $scope.trendLineError = false;
    $scope.termsByUser = false;
    $scope.inProcess = false;
    $scope.showGraph = false;
    $scope.examplesData = examplesDataModel;
    $scope.spinner = false;
    $scope.legendTrends = [];
    $scope.suggestedAssociations = [];
    $scope.allSuggestedAssociations = [];
    $scope.assocLimit = ASSOC_LIMIT;
    $scope.associationTimers = [];
    $scope.filterTypes = [
      {value: 'normal', label: 'Normal'},
      {value: 'gender', label: 'By Gender'}
    ];
    $scope.filterType = $scope.filterTypes[0];
    $scope.hideSkewMeasure = () => $scope.filterType.value !== 'normal';
    $scope.hideSovMeasure = () => $scope.filterType.value !== 'normal' || !_.isArray($scope.trends) || $scope.trends.length <= 1;

    const VALUE_MEASURES = [
      {
        label: 'Consumption',
        value: 'absolute',
        tooltip: 'The Consumption score represents how often a phrase is read together with the seed, compared to the other displayed phrases. The displayed phrase with the highest co-occurrence with the seed is given a score of 100.'
      },
      {
        label: 'Skew',
        value: 'skew',
        tooltip: 'The extent to which the seed interest is over-indexed within the association compared to within other associations.',
        hideFn: $scope.hideSkewMeasure
      },
      {
        label: 'SOV',
        value: 'sov',
        tooltip: 'SOV (share of voice) measures how often a tested association is consumed together with the seed, out of the total consumption of the association with any of the input seeds.',
        hideFn: $scope.hideSovMeasure
      },
      {
        label: 'Strength',
        value: 'relative',
        tooltip: 'Association strength reflects the tendency of the seed and the phrase being read together as opposed to separately. 100 means they are always read together, 0 means they are never read together.'
      }
    ];

    this.timeframe = null;
    this.terms = null;
    this.topChart = null;
    this.userChart = null;
    this.chart = new TrendChart(this, "association-consumption-chart", {
        type: 'daily',
        force_max: true,
        agg_examples_mode: true,
        templates: {relative: strengthTemplate,
                    absolute: consumptionTemplate,
                    sov: consumptionTemplate,
                    skew: skewTemplate
        },
        marginTop: 85
    });
    this.consumptionChartData = {
        chart: [],
        range: [],
        max: 100
    };
    this.selectedAssociation = {id: null};
    this.$scope = $scope;
    this.TIMES = TIMES;
    this.filtersPartition = filtersPartition;
    this.notificator = notificator;
    this.backupUserTerms = [];
    this.apiError = false;
    this.clearedAll = false;
    MixpanelInsights.trackPageView('associations');
    function trackAssociations() {
        var snapshot = angular.copy($rootScope.context.current);
        snapshot.measure = $scope.valueMeasure.value;
        var associations = $scope.userTerms;
        $rootScope.context.current.user_associations = associations;
        MixpanelInsights.trackAssociations(associations);
    }

    $scope.setType = function (type) {
        self.unselectAssociation();
        if (type != $scope.filterType.value) {
            $scope.filterType = _.find($scope.filterTypes, {value: type}) || $scope.filterTypes[0];
            setAvailableValueMeasures();
            $scope.inProcess = true;
            self.performUpdate({suggestions: true, references: true});
        }
    };

    var getMeasureMax = (data, measure) => _.isEmpty(data) ? null :
                                                            (measure === "skew" ? _.maxBy(data, "maxSkew").maxSkew : 100);

    function setAvailableValueMeasures() {
        $scope.valueMeasures = _.filter(VALUE_MEASURES, (measure) => typeof measure.hideFn !== 'function' || !measure.hideFn());
        if(!_.find($scope.valueMeasures, {value: ($scope.valueMeasure || {}).value})){
            $scope.valueMeasure = _.find($scope.valueMeasures, {value: DEFAULT_VALUE_MEASURE});
        }
    }

    setAvailableValueMeasures();

    $scope.setValueMeasure = function (measure) {
        if (measure != $scope.valueMeasure.value) {
            $scope.valueMeasure = _.find($scope.valueMeasures, {value: measure}) ||
                                  _.find($scope.valueMeasures, {value: DEFAULT_VALUE_MEASURE});
            self.measure = measure;
            topChartConfiguration.topBarGroup.graph.showGraph = self.showGraph;
            topChartConfiguration.topBarGroup.examples.showExamples = self.showExamples;
            if (self.userChart) self.userChart.destroy();
            self.userChart = new TopChart(self.type, self.removeUserAssociation,
                '#user-split-bar-chart', self.$scope.showGender ? {} : topChartConfiguration);
            if ( c.isArray($scope.userTerms) && $scope.userTerms.length > 0 ) {
                c.sortByNumeric(self.userData, self.service.getSortKey(self.measure));
                self.showUserAssociations(true);
                setRecordDescription(self.userData, self.measure);
                self.measureMax = getMeasureMax(self.userData, self.measure);
                self.userChart.draw(self.userData, self.type, self.measure, self.measureMax);
            }
            self.onResizeGraph();
            trackAssociations();
        }
    };

    $scope.addAllSuggestedAssociations = function () {
        $scope.addAllSelected = true;
        var visibleItems = [];
        var parent = document.getElementsByClassName("suggested-associations-tags")[0];
        _.each(parent.getElementsByTagName("tag"), function (tag){
            if (tag.offsetTop < parent.offsetTop + parent.offsetHeight){
                  visibleItems.push(tag.getElementsByClassName("box text")[0].innerHTML);
              }
        });
        _.each($scope.suggestedAssociations, (term) => term.text = term.label);

        var terms = _.uniqBy(_.union($scope.userTerms,
                                     _.filter($scope.suggestedAssociations,
                                             (o) => _.includes(visibleItems, o.text))),
                             'id');
        if (terms.length <= ASSOC_LIMIT){
            angular.copy($scope.userTerms, self.backupUserTerms);
            $scope.userTerms = terms.slice(0, ASSOC_LIMIT);
            trackAssociations();
        } else {
            $scope.addAllSelected = false;
            self.notificator.notify({body: associationLimitPassedMessage});
        }
    };

    $scope.addSuggestedAssociation = function(term) {
        if (!term.selected && $scope.userTerms.length + _.filter($scope.suggestedAssociations, 'selected').length >= ASSOC_LIMIT){
            self.notificator.notify({body: associationLimitPassedMessage});
            return;
        }
        _.each($scope.associationTimers, (timer) => $timeout.cancel(timer));
        $scope.associationTimers = [];
        term.selected = !term.selected;
        $scope.associationTimers.push($timeout(function() {
            let waitingAssociations = _.filter($scope.suggestedAssociations, 'selected');
            if (_.isEmpty(waitingAssociations)){
              return;
            }
            angular.copy($scope.userTerms, self.backupUserTerms);
            _.each(waitingAssociations, (assoc) => assoc.text = assoc.label);
            $scope.userTerms = _.uniqBy($scope.userTerms.concat(waitingAssociations), 'id').slice(0, ASSOC_LIMIT);
            _.each($scope.suggestedAssociations, (assoc) => assoc.selected = false);
            trackAssociations();
            $scope.associationTimers = [];
        }, ADD_ASSOCIATION_DELAY));
    };

    $scope.shouldDisplayTypeSelection = function() {
        return $scope.insightsChannel.value == 'articles' && !_.includes(['sov','skew'], $scope.valueMeasure.value) &&
            $scope.context.current._language_mold.isResolveKeywords($state, $scope.context);
    };

    function addAssociation() {
        if ($scope.currentUserTerms.length <= 0 && !self.clearedAll) {
            return;
        }

        self.clearedAll = false;
        var inputBarController = angular.element("input-bar.test-association").controller('inputBar');
        inputBarController.removeInvalidTerms();
        if ($scope.currentUserTerms.length == 0) return;
        if ($scope.userTerms.length >= ASSOC_LIMIT){
          self.notificator.notify({body: associationLimitPassedMessage});
          return;
        }

        _.each($scope.currentUserTerms, function (term) {
            term.id = c.getTermId(term);
        });

        angular.copy($scope.userTerms, self.backupUserTerms);
        $scope.userTerms = _.uniqBy(_.union($scope.userTerms, $scope.currentUserTerms), 'id').slice(0, ASSOC_LIMIT);
        $scope.userTerms = $scope.context.current._language_mold.formatUserTerms($state, $scope.context, $scope.userTerms);
        trackAssociations();
        $scope.currentUserTerms = [];
    }

    $scope.currentUserTerms = [];
    $scope.userTerms = $scope.userTerms || [];
    $scope.$watch('currentUserTerms', addAssociation);

    this.onResize = function () {
        if (!_.isEmpty(this.userData)) {
            this.userChart.draw(this.userData, this.type, this.measure, this.measureMax);
        }
        this.onResizeGraph();
    };

    this.performUpdate = function (functionCalls = {}) {
        self.insightsExportService.addReportFunction(self.getExportReport);
        self.insightsExportService.setExampleFunction(self.getExportExamples);
        let callGetSuggestedAssoc = functionCalls.suggestions;
        let callGetAssocByRef = functionCalls.references;
        if (!callGetSuggestedAssoc && !callGetAssocByRef) return;
        $scope.isSuggestionsOpen = false;
        $scope.noData = true;
        self.type = c.isString($scope.filterType && $scope.filterType.value) && self.channel == 'articles' ? $scope.filterType.value : DEFAULT_VIEW_TYPE;
        self.measure = ($scope.valueMeasure && $scope.valueMeasure.value) || DEFAULT_VALUE_MEASURE;
        self.user_first_party_audience = self.channel == 'articles' && permissions.hasPermission('first party segments') ?
                                         _.map($scope.userFirstPartyAudience, "value") : null;

        self.startLoader();
        $scope.inProcess = true;
        let params = _.clone(self.parameters);
        params.audience = c.getAudience($scope, self.channel);
        self.assocByRefDone = !callGetAssocByRef;
        self.suggestedAssocDone = !callGetSuggestedAssoc;
        if (callGetSuggestedAssoc) {
          return self.service.getAssociations(params, ASSOC_LIMIT).then(function(response) {
            if(callGetAssocByRef) {
              self.getAssociationsByReference(params);
            }
            self.postProcessSuggestions(response);
          }, this.handleServerError);
        }
        return self.getAssociationsByReference(params);
    };

    this.getAssociationsByReference = function (params) {
      return self.service.getUserAssociationsByReference(self.type, self.measure, $scope.userTerms, params, ASSOC_LIMIT).then(self.postProcessReferences, self.handleServerError);
    };

    this.postProcessReferences = function (response){
      self.userData = response.userData;
      topChartConfiguration.topBarGroup.graph.showGraph = self.showGraph;
      topChartConfiguration.topBarGroup.examples.showExamples = self.showExamples;
      self.userChart = new TopChart(self.type, self.removeUserAssociation,
        '#user-split-bar-chart', self.$scope.showGender ? {} : topChartConfiguration);
      c.sortByNumeric(response.userData, self.service.getSortKey(self.measure));
      $scope.termsByUser = true;
      $timeout(function () {
        $scope.noData = false;
        self.showUserAssociations(!_.isEmpty(response.userData));
        setRecordDescription(response.userData, self.measure);
        self.measureMax = getMeasureMax(response.userData, self.measure);
        self.userChart.draw(response.userData, self.type, self.measure, self.measureMax);
        var ids = _.map(response.userData, 'id');
        self.showSuggestedAssociations(_.reject($scope.suggestedAssociations, (o) => _.includes(ids, o.id)));
        self.assocByRefDone = true;
        if (self.suggestedAssocDone) {
          $scope.inProcess = false;
          self.stopLoader();
        }
      }, 0);
    };

    this.postProcessSuggestions = function (response) {
      $scope.showGender = ($scope.filterType.value == 'gender' && self.channel == 'articles');
      self.topData = response;
      $timeout(function () {
        $scope.allSuggestedAssociations = response.suggestions;
        var ids = _.map($scope.userTerms, 'id');
        self.showSuggestedAssociations(_.reject($scope.allSuggestedAssociations, (o) => _.includes(ids, o.id)));
        self.onResizeGraph();
        self.suggestedAssocDone = true;
        if(self.assocByRefDone) {
          $scope.inProcess = false;
          self.stopLoader();
        }
      }, 0);
    };

    this.handleServerError = function (error) {
      if (!self.skipLoad){
        self.stopLoader();
      }
      self.clear();
      if(error.status === -1) {
        self.apiError = true;
        $scope.userTerms = self.backupUserTerms;
        trackAssociations();
      }
      $scope.$emit('insightsError', error);
    };

    this.clear = function () {
        $scope.noData = true;
        $scope.inProcess = false;
        $scope.termsByUser = false;
        this.timeframe = null;
        this.terms = null;
        this.geo = null;
        this.topics = null;
        $scope.userData = [];
        $scope.examplesData.examples.association = [];
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.filters = [];
        $scope.examplesData.filter_type = '';
        $scope.suggestedAssocations = [];
        this.unselectAssociation();
        var type = ($scope.filterType && $scope.filterType.value) || DEFAULT_VIEW_TYPE;
        if (c.is(this.userChart)) {
            this.userChart.setData([], type, $scope.valueMeasure.value);
        }
    };

    this.removeAssociation = function (association) {
        self.unselectAssociation();
        if (c.is(self.userChart)) {
            if (c.is(self.userData) && c.is(association)) {
                var data = []; // TODO Use index instead
                _.each(self.userData, function (entry) {
                    if (entry.id != association.id && entry.label != association.label) {
                        data.push(entry);
                    }
                });
                $timeout(function() {
                    let results = {userData: data};
                    self.service.recalculate(results, $scope.filterType.value);
                    self.userData = results.userData;
                    self.userChart.draw(results.userData, $scope.filterType.value, $scope.valueMeasure.value,
                                        getMeasureMax(data, $scope.valueMeasure.value));
                    self.showUserAssociations(true);
                    let user_ids = _.map(data, 'id');
                    _.remove($scope.userTerms, (term) => term.id == association.id && term.label == association.label);
                    self.showSuggestedAssociations(_.reject($scope.allSuggestedAssociations, (s) => _.includes(user_ids, s.id)));
                }, 0)
            } else {
                self.userChart.setData([], $scope.filterType.value, $scope.valueMeasure.value);
            }
        }
    };

    this.showGraph = function (selectedSeed) {
        $scope.AssociationTitle = "Association trend of \"" + selectedSeed.label + "\" with";
        $scope.showGraph = true;
        $scope.examplesData.alphabetized = true;
        self.getAssociationData(selectedSeed);

        self.insightsExportService.addReportFunction(self.getExportReport, true);
        _.each([false, true], (avgMode) => {
            self.insightsExportService.addReportFunction((format) => self.getConsumptionExportReport(format, avgMode), false);
        });
    };

    this.showExamples = function (selectedSeed) {
        if (selectedSeed.id != self.selectedAssociation.id) {
            self.hideGraph();
        }
        $scope.examplesData.open = true;
        $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        self.getAssociationData(selectedSeed);

        // Force svg content to rerender due to possible container size change
        this.onResizeGraph();
    };

    this.onResizeGraph = function () {
        if ($scope.showGraph) {
            var tickMax = Math.ceil(self.consumptionChartData.max / 10) * 10;
            self.chart.draw(self.consumptionChartData, tickMax, $scope.valueMeasure.value);
            self.chart.addCircles($scope.examplesData.visible_examples);
        }
    };

    this.getAssociationData = function (selectedSeed) {
        $scope.trendLineError = false;
        var associated;
        if (isFinite(selectedSeed.id) || ['@', '#'].indexOf(selectedSeed.id[0]) != -1 || selectedSeed.id.toLowerCase() == selectedSeed.label.toLowerCase() + '_id') {
            associated = {text: selectedSeed.label, id: selectedSeed.id};
        } else {
            let idStr = $scope.context.current._language_mold.booleanLogicFormat($state, $scope.context);
            associated = _.find($scope.userTerms, {id: selectedSeed.id + idStr});
        }

        if (selectedSeed.id != self.selectedAssociation.id && isDefined(self.timeframe) && isArray($scope.trends)) {
            $scope.spinner = true;
            self.resetContentDriversPaneFilters();
            self.resetHighlightedLetter();
            $scope.examplesData.examples.association = [];
            $scope.examplesData.visible_examples = [];

            if ($scope.trends.length < 1) {
                // TODO Clean data
            } else {
                self.selectedAssociation = associated;
                self.chart.resetHighlightedCircle();
                this.parameters.measure = $scope.valueMeasure.value == 'sov' ? 'absolute' : $scope.valueMeasure.value;
                this.parameters.associated = associated;
                $scope.examplesData.promise = self.consumptionService.getAssociationTrendLine(self.parameters, associated);
                return $scope.examplesData.promise.then(function (data) {
                    if (isDefined(data) && isArray(data.averages) && isNumber(data.max)) {
                        $scope.examplesData.examples.association = data.examples;
                        _.each($scope.examplesData.examples.association, function (ex) {
                            ex.class = $scope.termClasses[ex.keyword];
                        });
                        $scope.examplesData.examples.association = _.filter($scope.examplesData.examples.association, function (example) {
                            return $scope.termClasses[example.keyword]
                        });
                        $scope.examplesData.visible_examples = $scope.examplesData.examples.association;
                    }
                    $timeout(function () {
                        self.consumptionChartData = data;
                        self.onResizeGraph();
                        $scope.spinner = false;
                    }, 0);
                }, function (err) {
                    $scope.trendLineError = true;
                    $scope.spinner = false;
                });
            }
        } else {
            $timeout(function () {
                self.onResizeGraph();
                $scope.spinner = false;
            }, 0);
        }
    };

    $scope.hasTrendLineError = function () {
        $scope.trendLineErrorMsg = TREND_LINE_ERROR_MESSAGE;
        return $scope.trendLineError;
    };

    this.showSuggestedAssociations = function(suggested) {
        angular.copy(suggested, $scope.suggestedAssociations);
    };

    var setRecordDescription = function(data, measure) {
      var recordDescriptionFn = (record) => undefined;
      if (measure === 'skew') {
        recordDescriptionFn = function({seed, assoc}) {
          let skew = TopBar.prototype.formatLabel(assoc.skew);
          switch (true) {
            case (skew == 0):
              return `${assoc.display} is unlikely to be consumed with ${seed.label}.`;
            case (skew < 1):
              let reciprocal_skew = TopBar.prototype.formatLabel(1 / assoc.skew);
              return `${assoc.display} is x${reciprocal_skew} times less likely to be consumed with ${seed.label} than with other associations.`;
            case (skew == 1):
              return `${assoc.display} is as likely to be consumed with ${seed.label} as it is with other associations.`;
            default:
              return `${assoc.display} is x${skew} times more likely to be consumed with ${seed.label} than with other associations.`;
          }
        }
      }
      _.each(data, function(record) {
        _.each(record.values, function(assoc) {
          assoc.title = recordDescriptionFn({seed: record, assoc: assoc});
        });
      });
    };

    this.showUserAssociations = function (show) {
        if (!(c.isArray(self.terms) || c.isArray(self.boolean_logics))) return;
        $scope.termsByUser = show;
        var numberOfTerms = self.measure == 'sov' ? 1 : self.terms.length + self.boolean_logics.length;
        var containerHeight = (numberOfTerms * 18) + 38;
        containerHeight = c.isArray(self.userData) ? self.userData.length * containerHeight : 0;
        containerHeight = (containerHeight > 0 && DEFAULT_VIEW_TYPE != $scope.filterType.value) ?
                            (containerHeight + 51) :
                            (containerHeight > 0) ? containerHeight + 23 : containerHeight;
        containerHeight += 60;
        c.setElementHeight('user-associations-container', containerHeight, containerHeight);
    };

    this.removeUserAssociation = function (association) {
        //non EN languages, each $scope.userTerms id is NOT a number, but "text_id" EX: "Trump_id"
        association = $scope.context.current._language_mold.formatUserTerms($state, $scope.context, [association])[0];

        if (c.is(self) && c.is(association)) {
            var userTerms = [];
            _.each($scope.userTerms, function (entry) {
                if (entry.id != association.id) {
                    userTerms.push(entry);
                }
            });
            $scope.userTerms = userTerms;
            trackAssociations();
            self.removeAssociation(association);
            self.showUserAssociations(userTerms.length > 0);
        }
        d3.event.stopPropagation();
    };

    $scope.clearUserAssociations = function () {
        $timeout(function () {
            $scope.userTerms = [];
            $scope.userData = [];
            $rootScope.context.current.user_associations = [];
            trackAssociations();
            self.clearedAll = true;
            self.showUserAssociations(false);
            self.showSuggestedAssociations($scope.allSuggestedAssociations);
        });
    };

    $scope.closeGraph = this.hideGraph = function () {
        $scope.showGraph = false;
        $scope.examplesData.alphabetized = false;
    };

    this.unselectAssociation = function () {
        let association_unselected = !!self.selectedAssociation.id;
        document.getElementsByClassName("selected-row")[0] &&
            document.getElementsByClassName("selected-row")[0].classList.remove("selected-row");
        self.selectedAssociation = {id: null};
        self.hideGraph();
        if(association_unselected) {
            self.setConsumptionContentExamples(false);
        }
        self.insightsExportService.addReportFunction(self.getExportReport, true)
    };

    this.getExportReport = function (format) {
        var titles = [format('Association', 'bold'), format('Seed', 'bold')];
        var columns = [{width: 45}, {width: 35}];
        if ($scope.filterType.value == 'gender') {
            titles.push(format('Strength - Male', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption - Male', 'bold'));
            columns.push({width: 18});
            titles.push(format('Strength - Female', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption - Female', 'bold'));
            columns.push({width: 18});
        } else {
            titles.push(format('Strength', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption', 'bold'));
            columns.push({width: 18});
            titles.push(format('Skew', 'bold'));
            columns.push({width: 18});

            if(!$scope.hideSovMeasure()){
                titles.push(format('SOV', 'bold'));
                columns.push({width: 18});
            }
        }
        var table = [titles];
        var type = ($scope.filterType && $scope.filterType.value) || DEFAULT_VIEW_TYPE;
        var result = self.service.getValuesByAssociationTable(self.parameters, type, self.measure, format);
        table = table.concat(result);
        return {name: associationExportName, columns: columns, table: table};
    };

    this.getConsumptionExportReport = function (format, avgMode) {
        var report = {
          name: avgMode ? consumptionChangeFromAvgExportTabName : consumptionExportTabName,
          columns: [],
          table: []
        };
        var terms = self.util.getValidTerms($scope, self.parameters);
        if (c.isArray(terms)) {
            report.columns = [{width: 19}];
            var info = [format('Selected Association:', 'bold'), self.selectedAssociation.text];
            var blank = [];
            var titles = [format('Date', 'bold')];
            _.each(terms, function (term) {
                var text = self.selectedAssociation ? term.text + " with " + self.selectedAssociation.text : term.text;
                titles.push(format(text, 'bold'));
                report.columns.push({width: 25});
            });
            report.table = [info, blank, titles];
            const cellType = self.parameters.to_normalize ? 'numeric' : 'integer';
            var result = self.consumptionService.getValuesByDateTable(terms, format, cellType, avgMode);
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.getExportExamples = function (format) {
        var report = {name: 'Content Drivers', columns: [], table: []};
        var selectedAssociation = self.selectedAssociation.text || 'None';
        var info = [format('Selected Association:', 'bold'), selectedAssociation];
        var blank = [];
        var seedTitle = self.selectedAssociation ? 'Seed and Association' : 'Seed';
        var titles = [format('Date', 'bold'), format(seedTitle, 'bold'), format('Content Drivers', 'bold')];
        report.table = [info, blank, titles];
        report.columns = [{width: 19}, {width: 25}, {width: 100}];
        var result = self.consumptionService.getExamples($scope.trends, format, $scope.examplesData.visible_examples, self.selectedAssociation);
        report.table = report.table.concat(result);
        return report;
    };

    this.setConsumptionContentExamples = function (doUpdate) {
        if ($scope.examplesData.examples.articles_consumption.length > 0 && !doUpdate) {
            angular.copy($scope.examplesData.examples.articles_consumption, $scope.examplesData.visible_examples);
        } else {
            let params = _.clone(self.parameters);
            params.examples_only = true;
            $scope.examplesData.promise = self.consumptionService.get(params, {});
            return $scope.examplesData.promise.then(function (data) {
                if (isDefined(data)) {
                    $scope.examplesData.icon = data.icon;
                    $scope.examplesData.examples.articles_consumption = data.examples;
                    _.each($scope.examplesData.examples.articles_consumption, function (ex) {
                        ex.class = $scope.termClasses[ex.keyword];
                    });
                    $scope.examplesData.examples.articles_consumption = _.filter($scope.examplesData.examples.articles_consumption, function (example) {
                        return $scope.termClasses[example.keyword]
                    });
                    if ($state.includes('*.association')) {
                        angular.copy($scope.examplesData.examples.articles_consumption, $scope.examplesData.visible_examples);
                    }
                }
            }, function (err) {
                $scope.trendLineError = true;
                $scope.spinner = false;
            });
        }
    };

    this.resetContentDriversPaneFilters = function () {
        _.each($scope.examplesData.filters, function (trend) {
            trend.show = true;
        });
    };

    this.resetHighlightedLetter = function () {
        self.chart.resetHighlightedCircle();
        $scope.$emit('resetLetterClicked');
    };

    this.validateTimeframeSocial = function (changedVals, customMessage) {
        var msgBody = customMessage || 'Maximum date span is three months. Timeframe was adjusted.';
        if (this.channel != 'tweets') {
            return;
        }
        var format = "DD_MM_YY_HH_mm";
        if (($scope.timeframe[1] === 'month' && parseInt($scope.timeframe[0]) > 3) || ($scope.timeframe[1] === 'year')) {
            $rootScope.$broadcast('timeframe-update', [3, 'month'], false, msgBody);
        } else if ($scope.timeframe[1] != 'month') {
            if (moment($scope.timeframe[0], format) <
                  moment($scope.timeframe[1], format).subtract({'month': 3, 'day': c.calcOffsetDays($scope.timeframe, 3)})) {
                if (changedVals.timeframe === undefined || changedVals.timeframe[1] === 'month' ||
                    $scope.timeframe[1].substring(0, 8) === changedVals.timeframe[1].substring(0, 8)) {
                    $scope.timeframe[1] = moment($scope.timeframe[0], format).add({'month': 3}).format(format);
                } else {
                    $scope.timeframe[0] = moment($scope.timeframe[1], format).subtract({'month': 3}).format(format);
                }
                $rootScope.$broadcast('timeframe-update', $scope.timeframe, false, msgBody);
            }
        }
    };

    $scope.unselectAssociation = this.unselectAssociation;

    $scope.$on('contentDriverMouseover', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriverMouseout', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, false);
    });

    $scope.$on('contentDriverClick', function (event, letter) {
        self.chart.resetHighlightedCircle();
        self.chart.letterClicked = letter;
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('widgetPanelClick', function () {
        self.resetContentDriversPaneFilters();
        self.chart.resetHighlightedCircle();
    });

    $scope.$on('contentDriversPaneOpen', function (event) {
        if (!self.selectedAssociation.id) {
            self.setConsumptionContentExamples(false);
        }
    });

    $scope.$watch('trends', setAvailableValueMeasures);

    $rootScope.$on('switch-language', function () {
        $scope.clearUserAssociations();
    });


    $scope.resetHighlightedCircle = function () {
        self.chart.resetHighlightedCircle();
    };

    this.emitEventForChartCircle = function (eventType, letter) {
        $scope.$emit(eventType, letter);
    };

    setAvailableValueMeasures();
    insightsExportService.setTitle('Associations');

    $scope.$on('$destroy', function() {
      if (self.userChart) self.userChart.destroy();
    });
}

TopAssociationController.prototype._doUpdate = function (values,changedVals) {
    var $scope = this.$scope;
    var $location = this.$location;
    var self = this;
    this.trends = $scope.terms;
    this.hideGraph();
    if (this.selectedAssociation.id) {
        this.unselectAssociation();
    }
    $scope.examplesData.visible = true;
    $scope.examplesData.visible_examples = [];
    $scope.examplesData.examples.association = [];
    $scope.examplesData.alphabetized = false;
    $scope.showGraph = false;
    $scope.addAllSelected = false;

    if (this.apiError) {
        this.apiError = false;
        return;
    }

    if (!$scope.insightsChannel.hasOwnProperty('value')) {
        $scope.context.current.insightsChannels = $scope.insightsChannel =c.getAvailableContext($scope.$root.insightsAssociationsChannelsFilter,this.permissions);
    }else{
        if ($scope.insightsChannel.permission != null && !this.permissions.hasPermission($scope.insightsChannel.permission)){
            $scope.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsAssociationsChannelsFilter, this.permissions);
        }
    }

    if(c.isNotBelongToPermissionGroup($scope.$root.insightsAssociationsChannelsFilter, $scope.insightsChannel.value)){
        $scope.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsAssociationsChannelsFilter, this.permissions);
    }

    this.channel = this.parameters.channel = $scope.insightsChannel.value;
    var nonPhrases = false;
    if (this.channel == 'tweets') {
        c.validateNonPosts($scope.terms, this.notificator);
    } else {
        nonPhrases = c.validateNonPhrases($scope.terms, this.channel, this.notificator);
    }
    if ($location.path() == "/insights/association") { //Don't set timeframe vars if we're stepping out of widget
        let times = this.channel == "tweets" ? ["1M", "3M"] : ["1M", "3M", "6M", "1Y"];
        $scope.$root.$broadcast('timeframe-vars-update', times, this.TIMES.INSIGHTS_OFFSET, false, true, $scope.insightsChannel.value == 'tweets');
    }
    this.validateTimeframeSocial(changedVals);

    this.parameters = this.util.buildInsightsParameters(_.pick($scope, ['insightsChannel', 'addTerm', 'topics', 'terms',
                                                                        'active', 'geo', 'sub_geos', 'timeframe',
                                                                        'audience', 'sgTelcoAudience','userFirstPartyAudience']), true);
    c.logUpdate('Associations', this.parameters);
    this.timeframe = this.parameters.timeframe;
    this.terms = this.parameters.terms;
    this.geo = this.parameters.geo;
    this.topics = this.parameters.topics;
    this.boolean_logics = this.parameters.boolean_logics;
    this.audience = this.parameters.audience;
    this.kwd_ids = this.parameters.kwd_ids;
    $scope.trends = this.parameters.trends;
    $scope.termClasses = {};
    _.each($scope.trends, function (trend) {
        $scope.termClasses[trend.text] = $scope.termClasses[trend.id] = trend.class;
    });
    $scope.examplesData.filter_type = 'trend';
    $scope.examplesData.terms = $scope.trends;
    angular.copy($scope.trends, $scope.examplesData.filters);
    $scope.examplesData.filters = _.filter($scope.examplesData.filters, function (t) {
        return nonPhrases ? ['mention', 'hashTag', 'post'].indexOf(t.type) === -1 && !self.util.isSocialBooleanLogic(t) : t.type != 'post';
    });
    $scope.legendTrends = $scope.examplesData.filters;
    if($scope.valueMeasure.value == 'sov' && $scope.trends.length < 2){
        $scope.valueMeasure = _.find($scope.valueMeasures, {value: DEFAULT_VALUE_MEASURE});
    }
    this.resetContentDriversPaneFilters();
    this.resetHighlightedLetter();
    this.insightsExportService.clear();
    this.insightsExportService.addSummaryFields({
        label: 'Selected Association:', value_f: function (entry, field) {
            return self.selectedAssociation.text || "None";
        }
    });
    var export_parameters = [];
    if(this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        export_parameters =  _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        export_parameters = _.clone(this.parameters);
    }
    if(this.parameters.channel == 'articles' && this.permissions.hasPermission('first party segments') && !_.isEmpty($scope.userFirstPartyAudience)) {
        this.insightsExportService.addSummaryFields(ex.FIRST_PARTY_SEGMENTS_SUMMARY_FIELD);
        export_parameters.first_party_audience = _.map($scope.userFirstPartyAudience, 'summary');
    } else {
        delete this.parameters.user_first_party_audience;
    }

    this.insightsExportService.setParams(export_parameters);

    if (this.terms.length < 1 && this.boolean_logics.length < 1) {
        this.clear();
    } else {
        let callSuggestedAssoc, callAssocByRef;
        let onlyUserTermsChanged = !_.isEmpty(changedVals) && _.every(changedVals, (v,k) => k == "userTerms");
        callSuggestedAssoc = !onlyUserTermsChanged;
        callAssocByRef = onlyUserTermsChanged ? !(values.userTerms.length < changedVals.userTerms.length) :
                                                !(_.isEmpty(values.userTerms) &&
                                                  _.isEmpty(changedVals.userTerms) &&
                                                  _.isEmpty($scope.userTerms));
        self.setConsumptionContentExamples(callSuggestedAssoc);
        return this.performUpdate({suggestions: callSuggestedAssoc, references: callAssocByRef});
    }
};

TopAssociationController.$inject = ["$scope", "$rootScope", "$timeout", "$state", "associationTrendService",
        "util", "examplesDataModel", "consumptionTrendService", "insightsExportService",
        "abiPermissions", "baseInsightsService", "TIMES", "filtersPartition", "notificator", "$location"];

module.exports = angular.module(__filename, [
    TopAssociationService.name,
    examplesDataModel.name,
    consumptionTrendService.name,
    require('common/tags-viewer.drv/tags-viewer.drv').name
]).directive("topAssociationWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: Template,
        scope: {
            timeframe: "=",
            active: "=",
            terms: "=",
            geo: "=",
            sub_geos: "=subGeos",
            topics: "=",
            insightsChannel: '=',
            audience: '=',
            sgTelcoAudience: '=',
            userFirstPartyAudience: '=',
            addTerm: "=",
            userTerms: '=',
            cacheBaster: '='
        },
        controller: TopAssociationController
    });
}]);
