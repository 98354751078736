#= require d3
#= require pack-rectangle
#= require wordwrap
#= require heat

WordWrapper = require("./wordwrap")
Heat = require("./heat")
d3 = require("d3")
pack_rectangle = require("./pack-rectangle")

module.exports = class @Balls

	MIN_BUBBLE_RADIUS: 		 13

	constructor: (@svg, @nodes, @width, @height) ->
		@allow_pruning  = true
		@ww             = new WordWrapper()
		@minimal_radius = this.MIN_BUBBLE_RADIUS
		@fontsize = Math.min( Math.max( 14, 2 * @minimal_radius / 3 ), 22 )   # min 15px, max 22px
		@padding = 3
		that = this
		@wrap_word = (d,i) ->
			that.word_wrap(d,i,$(this)[0]) # this is so coffeescript will leave the this alone and pass it without replacing it

		@on_node_click = (d,i) ->
			that.on_click(d,i,$(this)[0])
			d3.event.stopPropagation()
			return false
		@redraw()

	set_pruning:(@allow_pruning) =>

	translate:(d) -> "translate(#{d.x},#{d.y})"

	radius:(d) => Math.max(0, d.r)
	back_radius:(d) => @radius(d)

	#node_text:(d) => d.word

	node_id:(d) -> d.id

	transition_started: () =>
		@transition_started_flag = true
		setTimeout ()=>
			@transition_started_flag = false;
			if @on_circle_transition_ended then @on_circle_transition_ended()
			@on_circle_transition_ended = null;
		, 1050

	do_on_transition_end: (func) =>
		if @transition_started_flag
			@on_circle_transition_ended = func
		else
			func()

	ball_color: (ball_color_func, border_color_func) =>
		selection = @svg.selectAll(".node").select("circle")
		if (@ball_color_func)
			#only one transition can happen at a time, so if a transition is already happening
			#we need to schedule this to when the transition finishes
			@do_on_transition_end () =>
				selection.transition().duration(1000).attr("r",(d)->d.r).attr("class", ball_color_func )
		else
			#first time, set color immediately since we want the color to exist from the start
			#and because of pruning we don't have the actual function at start time
			selection.attr("class", ball_color_func )

		#@svg.selectAll(".background").style("stroke", ball_color_func)
		@ball_color_func = ball_color_func
		return @

	text_color: (func) =>
		@svg.selectAll(".node").select("text").attr("class", func )
		return @

	on_click: (d,i,element) ->

		matrix = element.getScreenCTM()
		x = matrix.e
		y = matrix.f  - $("#balls").offset().top
		$(element).trigger($.Event("orbis.clicked_node",{"node": d,"position":{x:x,y:y}}))
		return false

	word_wrap: (d, i, element) =>
		#console.log(d,i,element)
		@ww.word_wrap( element, @preprocess_text(d.word), d.r - @padding, @fontsize )

	preprocess_text:(raw) -> if raw.split(" ").length == 1 && raw.length == 3 then raw.toUpperCase() else raw

	redraw: () =>
		# console.log("BUBBLES Redraw")
		data = @calculate_data_with_layout(@nodes,@width, @height)
		#@draw_backgrounds(data)
		@draw_nodes(data)

		setTimeout( @prune_mute_nodes, 100)

	calculate_data_with_layout: (raw_data,w,h) =>
		# console.log(raw_data)
		laid_out_data 			= pack_rectangle( raw_data, w, h  )
		$.grep( laid_out_data, (x) => x.r > @minimal_radius or x.word.toLowerCase() == x.seed.toLowerCase()  )

	draw_backgrounds:(data) =>
		backgrounds = @svg.selectAll(".background"	).data( data, @node_id )

		incoming_backs = @setup_basic_circle(backgrounds,@back_radius,".node", "blue")
		incoming_backs.attr("class", "background")

	draw_nodes:(data) =>
		nodes 		= @svg.selectAll(".node").data( data, @node_id )
			.on("click", @on_node_click )
		nodes.classed("pruned",false)
		incoming_nodes = @setup_basic_circle(nodes,(d)->d.r)
		incoming_nodes.on("click", @on_node_click )
		incoming_nodes.attr("class","node").attr("title",(d)->d.word)
		incoming_nodes.append("text").each( @wrap_word )

		nodes.select("text").style("visibility","visible").each( @wrap_word )
		nodes.exit().select("text").style("visibility","hidden")

		@transition_started()

	#inserts a g and a circle under it. handles transitions and removal
	#before is used to make sure the circles are added at a certain place, since svg
	#doesn't have z-index - layout is determined by order of appearence
	setup_basic_circle: (selection,radius_func,before, color) =>

		incoming = selection.enter()
			.insert("g",before)
			.attr("data-id", (d)-> d.id )
			.attr("transform", @translate )
		incoming.append("circle")
			.attr("r", 0)
			#.style("stroke", ()-> color)
			.transition().duration(1000)
			.attr("r", radius_func)

		#On update, updates radius to new size, and moves location incase the layout change
		#caused existing circles to move
		selection.select("circle").transition().duration(1000).attr("r", radius_func )
		selection.transition().duration(1000).attr("transform", @translate)

		#upon exit
		outgoing = selection.exit()
		outgoing.select("circle").transition().duration(1000).attr("r", 0)
		outgoing.transition().duration(1000).remove()

		return incoming;


	prune_mute_nodes: () =>
		return unless @allow_pruning
		word_wrapped = $("tspan[x=-5000]").closest("g")  # any bubble with at least one word hidden
		$.each( word_wrapped, (i,node ) =>
			id = $(node).data("id")
			data = d3.select(node).data()[0]
#			if !data.force_keep
#				$(node).trigger($.Event("orbis.pruned_node",{"node": data}))
#				$(node).remove()
#				$("g.background[data-id=#{id}]").remove()
#			else
			$(node).find("tspan").remove()
			d3.select(node).classed("pruned",true)
			@ww.word_wrap( $(node).find("text")[0], "...", data.r - @padding, @fontsize )
			#hack to make tooltip work on bubbles with no text
			# if we only remove tspan, the text element is stil starts at x 5000, we need to move the text back to 0
			$(node).find("tspan[x=-5000]").css("visibility","hidden").attr("x",0)
		)

	abort: () =>
		@request.abort() if (@request && @request.readyState != 4)
