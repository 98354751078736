import React from 'react';
import PropTypes from 'prop-types';
import './ModalWithConfirmationButtons.scss';
import BaseModal from '../BaseModal/BaseModal';
import ConfirmationButtons from '../../ConfirmationButtons/ConfirmationButtons';
import Separator from '../../Separator/Separator';

const ModalWithConfirmationButtons = ({
  isOpen,
  modalTitle,
  width,
  height,
  minHeight,
  marginTop,
  onCancel,
  onSubmit,
  onEscKeyPress,
  onEnterKeyPress,
  isSubmitDisabled,
  isCancelDisabled,
  isCancelTransparent,
  isXDisabled,
  isDisabledEnterKeyPress,
  isYAutoScrollEnabled,
  submitLabel,
  cancelLabel,
  showSpinner,
  children,
  hasScrollContainer,
  maxHeightBeforeScroll,
  submitButtonTooltip,
  onXClick,
  titleMargin,
  isLightTheme,
  showSeparator = isLightTheme,
  confirmationButtonsWidth,
}) => (
  <div className="modal-with-confirmation-buttons-component">
    <BaseModal
      isOpen={isOpen}
      modalTitle={modalTitle}
      onEscKeyPress={onEscKeyPress || onCancel}
      onEnterKeyPress={onEnterKeyPress || onSubmit}
      onXClick={onXClick}
      isXDisabled={isXDisabled === undefined ? isCancelDisabled : isXDisabled}
      marginTop={marginTop}
      width={width}
      height={height}
      minHeight={minHeight}
      isDisabledEnterKeyPress={isDisabledEnterKeyPress || isSubmitDisabled}
      showSpinner={showSpinner}
      titleMargin={titleMargin}
      isLightTheme={isLightTheme}
      containerPadding="24px 40px"
    >
      <div className="modal-container" style={{ maxHeight: maxHeightBeforeScroll }}>
        {hasScrollContainer ? (
          <div className="modal-scroll-container" style={{ overflowY: isYAutoScrollEnabled && 'auto' }}>
            {children}
          </div>
        ) : (
          children
        )}
        {showSeparator && <Separator className="modal-bottom-separator" />}
        <div className="confirmation-buttons">
          <ConfirmationButtons
            submitLabel={submitLabel}
            isSubmitDisabled={isSubmitDisabled}
            isCancelDisabled={isCancelDisabled}
            onSubmitClick={onSubmit}
            cancelLabel={cancelLabel}
            onCancelClick={onCancel}
            submitButtonTooltip={submitButtonTooltip}
            isLightTheme={isLightTheme}
            isCancelTransparent={isCancelTransparent}
            buttonsWidth={confirmationButtonsWidth}
          />
        </div>
      </div>
    </BaseModal>
  </div>
);

ModalWithConfirmationButtons.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEscKeyPress: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  isXDisabled: PropTypes.bool,
  isDisabledEnterKeyPress: PropTypes.bool,
  isYAutoScrollEnabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  hasScrollContainer: PropTypes.bool,
  showSpinner: PropTypes.bool,
  children: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  maxHeightBeforeScroll: PropTypes.string,
  submitButtonTooltip: PropTypes.string,
  onXClick: PropTypes.func,
  titleMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLightTheme: PropTypes.bool,
  showSeparator: PropTypes.bool,
  isCancelTransparent: PropTypes.bool,
  confirmationButtonsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ModalWithConfirmationButtons.defaultProps = {
  submitLabel: 'Done',
  cancelLabel: 'Cancel',
  isSubmitDisabled: false,
  hasScrollContainer: true,
  isCancelDisabled: false,
  isYAutoScrollEnabled: true,
  isLightTheme: false,
  isCancelTransparent: true,
  confirmationButtonsWidth: '80px',
  showSeparator: true,
};

export default ModalWithConfirmationButtons;
