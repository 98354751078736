//show forcaste of next month impressions
//for this target 
import _ from 'lodash';
import $ from 'jquery';
import config from 'infra/config';

module.exports = angular.module(__filename, []).directive("targetForecast", ['$http', 'context', 'TargetsCommon', ($http , context, TargetsCommon) => {
    return {
        restrict: 'E',
        template: require('./target-forecast.drv.html'),
        link: function (scope, elem, attrs, ngModel) {

            scope.target = {
                topics: [],
                results: {
                    phrases: []  
                }
            }

            scope.firstLoading = true;

            scope.$watch('target', (newValue, oldValue)=> {
                scope.setImps(); 
            }, true);

            scope.$on("current-target-changed", (event, target)=>{
                scope.target = $.extend(true, {}, target);
            });

            scope.$on("change-topics", (event, topics)=>{
                scope.target.topics = topics;   
            });

            scope.$on("remove-phrase", (event, params)=>{
                scope.target.results[params.tab].splice(
                    $.inArray(params.phrase, scope.target.results[params.tab])
                , 1);
            });

            scope.$on("add-phrases", (event, params)=>{
                scope.target.results[params.tab] = 
                _.uniqBy(scope.target.results[params.tab].concat(params.phrases), (p)=> p.id);
            });

            scope.$on("toggle-exclude", (event, value)=>{
                scope.target.incOrExc = value;
            });

            scope.$on("toggle-dynamic", (event, params)=>{
                if(params.on){
                    $http.get(params.url).then((res)=>{
                        scope.combineDynamicAndNormal({phrases: res.data.results[params.tab], tab: params.tab});
                    });
                }else{
                    let arr = scope.target.results[params.tab];
                    arr = arr.filter((phrase)=>!phrase.dynamic);
                    scope.target.results[params.tab] = arr;
                }    
            }); 

            scope.$on("dynamic-trends-window", (event, params)=>{
                scope.combineDynamicAndNormal({phrases: params.phrases, tab: params.tab});
            }); 

            scope.combineDynamicAndNormal =(params)=>{
                let normal = scope.target.results[params.tab].filter((p)=>!p.dynamic);
                let combined = normal.concat(params.phrases);
                scope.target.results[params.tab] = _.uniqBy(combined, (p)=> p.id);
                
                TargetsCommon.TargetData.targetToUI({
                  target: scope.target, context: context, action: 'edit-target'
                });
            };
                             

            scope.setImps =()=>{
                if("exclude" == scope.target.incOrExc){
                    scope.imps = 'N/A';
                    return;
                }

                if((!scope.target.topics || 
                    !scope.target.topics.length) &&
                   (!scope.target.results.phrases || !scope.target.results.phrases.length)){
                    scope.imps = '';
                    return;
                }

                const kwd_ids = scope.target.results.phrases.map((p)=>parseInt(p.id))
                const DAYS = 30;
                scope.loading = true;
                $http({
                    method: 'POST',
                    url: config.USER_MGMT_API+'/targets/consumption',
                    data: {
                        "days": DAYS,
                        "kw": kwd_ids,
                        topics: scope.target.topics
                    }
                }).then((res)=> {
                    scope.imps = DAYS * res.data.value;
                    scope.loading = false;
                    scope.firstLoading = false;
                });
            };

        }
    }
    
}]);
