import 'angular-ui-router';
import { react2angular } from 'react2angular';
import BITopBar from 'react/components/common/AppNavigation/BITopBar/BITopBar';
import { openHelpPage } from 'react/middleware/AngularExportedMiddleware';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';

const topbarTmpl = require("./topbar.html"),
    contextModule = require("infra/context/context"),
    PlatformAuthUtils = require("react/infra/PlatformAuthUtils.js");

const topBarModule = angular.module(__filename, [
    contextModule.name,
    'ui.router',
    require("data/discovery-service").name
]);

topBarModule.component('biTopBar', react2angular(BITopBar, ['userEmail', 'hideHelpCenter', 'helpPageName', 'isHamburgerVisible', 'onHamburgerClick', 'isPlatformMenuOpen']));

topBarModule.directive("topbar", ["context", "$state", '$rootScope', 'abiPermissions', 'TIMES',
    function (context, $state, $rootScope, abiPermissions, TIMES) {
        return {
            restrict: "E",
            template: topbarTmpl,
            compile: function () {
                return {
                    pre: function (scope) {
                        scope.context = context;
                        scope.$state = $state;

                        scope.times = [];
                        scope.minDate = null;
                        scope.subtractDay = false;

                        scope.isHamburgerVisible = PlatformAuthUtils.isUserHasDSPPermission();
                        scope.onHamburgerClick = () => {
                            scope.isPlatformMenuOpen = !scope.isPlatformMenuOpen;
                            scope.$apply();
                        }
                        scope.isPlatformMenuOpen = false;

                        scope.checkChangeProgram = function (e) {
                            //block update when warroom loads
                            // TODO: commented code below as isWarroomRealtime method moved from this component
                            // if (scope.isWarroomRealtime() && $scope.time.loading) {
                            //     e.stopPropagation();
                            //     e.preventDefault();
                            //     e.stopImmediatePropagation();
                            // }
                        };

                        scope.trackHelpCenter = function () {
                            const currentChannel = context.current.gridChannels.label;
                            MixpanelCommon.trackHelpCenter($rootScope.helpPage, currentChannel);
                        };

                        scope.hasPermission = abiPermissions.hasPermission;

                        scope.hideHelp = function () {
                            const state = $state.current;
                            return !scope.hasPermission(['help center'])
                                || state.name.startsWith('audience-')
                                || (state.name.startsWith('settings.') && !$state.is('settings.programs'))
                                || $state.is('insights.landscape')
                                || $state.is('insights.channels');
                        };
                    }
                }}
        }
    }
]);

module.exports = angular.module(__filename);
