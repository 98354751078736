"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
 ]).factory("LanguageMold", ['filtersPartition', 'filterMoldService', 'abiPermissions' , '$timeout', '$rootScope', 'notificator',
    function (filtersPartition, filterMoldService , abiPermissions , $timeout , $rootScope, notificator) {
    return function LanguageMold() {
        this._value = [];
        
        this.default = filtersPartition.language[0];
        this.prevValue = null;
        this.prevPage = null;
        this._selectRefreshed = false;
        this._selectRefreshedOnce = false;
        this.pagesToResetArr = ["discovery", "insights", "dashboard"];

        this.getLanguage = function($state, context){
            /*
            on each tab/channel, we check if selected language is supported.
            if not, we clear seeds

            to use
            ======
            each tab needs context.current._language_mold.getLanguage($state, context);

            apology
            =======
            clearning seeds inside a "get" function
            is NOT great design patterns but 
            other (numerous) solutions failed because:

            1. many components like input bar dont have ordered set+get 
            2. many events are triggered on every tab/channel change
            3. there were too many edge states to handle
            */

            if((!context.current.language) || (!this.shouldCheckLanguageSupport($state))){
                return this.default
            }
          

            this.prevValue = this.prevValue || $.extend(true, {}, this._value);
            var newValue = this.isSupportLanguage($state, context) ? this._value: this.default;
            this.prevPage = $state.current.name;

            if(!this._value || this.prevValue.value != newValue.value){

                if(!this._value){
                    newValue = this.default;
                    this._value = this.default;
                }

                var hasSeeds = context.current.terms.length;
                this.prevValue = $.extend(true, {}, newValue);
                $rootScope.$broadcast('switch-language');

                if(hasSeeds){
                    notificator.notify({body: "Seeds reset because language setup has changed"});
                }
               
            }
       
            return newValue;
        };

        this.getLanguages = function (langArr, languages) {
            return languages.filter((lan)=> $.inArray(lan.value, langArr) !== -1);    
        };

        this.getUserLanguages = function () {
            return $rootScope.user.languages? Array.from($rootScope.user.languages) : [];
        };

        this.resetRefreshSelect = function () {
            this._selectRefreshedOnce = true;
            this._selectRefreshed = false;
        };

        this.refreshSelect = function () {
            this._selectRefreshed = true;
        };


        this.languageSelectRefreshHandler = function ($state, context) {
            //for some reson the language select does not mark the language due to timing isues + context incorrect value.
            //this function will enforce that the context will be correct when called (currently when unified is selected) (SEE: DISCOVERY.JS languageSelectRefreshHandler FUNCTION)
           if(!this._selectRefreshed){
                this.setContextLanguage($state, context);
                this.refreshSelect();
           }
        };

        this.supportedLanguages = function ($state, context) {

            let page = $state.current.name,
                channels = c.getChannels($state, context), 
                languages = this.getUserLanguages();

            if(page.includes("discovery")) {

                if(page.includes("streams")){
                    if ((c.isAllChannelsSelected($state, context, $rootScope))){
                        //unified --> none --> means default english !
                        languages = [];
                    }else if (channels.includes("tweets") && channels.length == 1) {
                        //en/es
                        languages = this.getLanguages(['es', 'en'], languages);
                    }else if(!channels.includes("articles")){
                        languages = [];
                    }else{
                        //web, no need to change, we support ALL languages.
                        //BUG FIX: for some reson the language select does not mark the language due to timing isues + context incorrect value.
                        if(!this._selectRefreshedOnce){
                            this.resetRefreshSelect();
                        }
                    }
                }else if (
                    channels.includes("tweets") || 
                    channels.includes("articles") && abiPermissions.hasPermission("web language targeting") || 
                    channels.length == 0) { //grid
                    //en/es
                    languages = this.getLanguages(['es', 'en'], languages);
                }else{
                    //none
                    languages = [];
                }
            }

            return languages;
        };

        this.isSupportLanguage = function ($state, context) {

            //1st, see if user has permission to 2+ language
            if(this.getUserLanguages().length < 2){ 
                return false;
            }

            var page = $state.current.name, 
                channels = c.getChannels($state, context);

                if(!this.prevPage){
                    this.prevPage = page;
                }


            if(page.includes("discovery")){
               //guyB:
                //TODO: maybe turn this to a stand alone function to check the supported languages per page,
                //and then ask supported = [context.current.language].filter for ANY page and not just for discovery.

                if(context.current.language){
        
                    if(this.isStreamsTwitter($state, context)){

                        let supportLanguage = this.isInLanguageArr($state, context);

                        if(supportLanguage ===-1){
                            context.current.language = this.default;
                        }
                        return supportLanguage !==-1;
                        
                    }else{
                         return this.isInLanguageArr($state, context)!== -1 && (channels.length === 0 || channels.includes('tweets') || channels.includes('articles'));
                    }
                }else{
                     //ORIG CODE
                     //mainly after reset, the context is not set
                     this.setContextLanguage($state, context);
                     return channels.length === 0 || 
                            channels.includes('tweets') ||
                            channels.includes('articles') && abiPermissions.hasPermission("web language targeting");
                }
            
            }else if(page.includes("insights.consumption")){
                return channels.includes('all');
            
            }else if(page.includes("insights.association")){
                return channels.includes('all');
            }

            return false;
        };

        this.setContextLanguage = function($state, context){
            context.current.language = this.getLanguage($state, context);
        };

        this.isCompleteKeywords = function($state, context){
            var lan = this.getLanguage($state, context).value;
            return lan =='en';
        };

        this.isResolveKeywords = function($state, context){
            var lan = this.getLanguage($state, context).value,
                page = $state.current.name;

            return lan =='en' || 
                (page.includes("discovery") && lan=='es') ||
                (page.includes("insights") && lan=='es');
        };

        this.shouldShowTopics = function($state, context){
            let page = $state.current.name;

            if(page.includes("insights")){
                return this.isResolveKeywords($state, context);
            }else{
                return this.getLanguage($state, context).value == 'en' ||
                this.isSpanishWeb($state, context);
            }
        };

        this.isShowAudience = function($state, context){
            return this.getLanguage($state, context).value == 'en' || 
            this.isSpanishWeb($state, context);
        }


        //only these pages has some language support, so when leaving them to other pages, DONT reset
        this.shouldCheckLanguageSupport = function ($state) {
            return $.inArray($state.current.name.split(".")[0], this.pagesToResetArr) !== -1
        };

        this.getDefaultLanguage = function(){
            return this.default;
        };

        this.isInLanguageArr = function ($state, context) {
            let supportedlagArr = this.supportedLanguages($state, context).map(function (obj) {return obj.value;}); 
            return $.inArray([context.current.language][0].value, supportedlagArr);
        };

        this.isStreamsTwitter = function ($state, context) {
            let page = $state.current.name, 
                channels = c.getChannels($state, context);
            return page.includes("streams") && channels.includes('tweets') && channels.length === 1;
        };

        this.isSpanishWeb = function($state, context) {

          var page = $state.current.name,
              channels = c.getChannels($state, context),
              language = this.getLanguage($state, context).value;

          return language == 'es' &&
                 channels.includes('articles') &&
                 page.includes('discovery') &&
                 abiPermissions.hasPermission("web language targeting");
        }





        //for non EN languages, each $scope.userTerms id is NOT a number, but "text_id" EX: "Trump_id" 
        //gets an array of scope.userTerms OR one [association] to delete
        this.formatUserTerms = function($state, context, userTerms){

            let formatedUserTerms = [],
                idStr = this.booleanLogicFormat($state, context);

            if(!this.shouldNotFormatData($state, context)){
                _.each(userTerms, function (entry) {
                        entry.id = entry.id.includes(idStr)? entry.id : entry.id + idStr;
                        formatedUserTerms.push(entry);
                });
                userTerms = formatedUserTerms;
            }

            return userTerms;
        };
       

        //for non EN languages, each $scope.userTerms id is NOT a number, but "text_id" EX: "Trump_id" 
        //just concat the returnd value to any BLobj proccessed. 
        //EX:  let idStr = $scope.context.current._language_mold.booleanLogicFormat($state, $scope.context);
        //id: selectedSeed.id + idStr
        this.booleanLogicFormat = function($state, context){
            return !this.shouldNotFormatData($state, context)? '_id' : '';
        };

        this.shouldNotFormatData = function($state, context){
            return (this.getLanguage($state, context).value == 'en' || this.getLanguage($state, context).value == 'es');
        };

        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.language);
     }
 }]);
