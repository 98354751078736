module.exports = angular.module(__filename, [
    require('../connect-source/connect-source').name
]).controller('programSourcesController', ['$scope', '$location', 'ModalService', 'programSourcesService', 'streamsService',
    function ($scope, $location, modalService, programSourcesService, streamsService) {
        $scope.editSource = sourceModal;
        $scope.deleteSource = deleteSource;

        $scope.$on('add-new-source', () => sourceModal());

        $scope.query = {
            dataArray: [],
            columns: [
                {
                    id: 'name',
                    title: 'sources'
                },
                {
                    id: 'sources',
                    title: 'number of connections',
                    template: 'partials/source-items.partial.html',
                    sort: false
                },
                {
                    id: 'created_at',
                    title: 'date added',
                    template: 'partials/source-date.partial.html'
                },
                {
                    id: 'actions',
                    title: '',
                    template: 'partials/source-actions.partial.html',
                    sort: false
                }
            ],
            fixedSize: true,
            order: 'created_at',
            reverse: true
        };

        function populateSources() {
            programSourcesService.list($scope.program.id).then(function (data) {
                $scope.query.show(data);
                $scope.$parent.program_sources = data;
                if (!$location.$$path.includes("bubbles")) {$scope.context.program.program_sources = data}
            });
        }

        $scope.$watch('selectedView', function (newView) {
            if (newView == 'connect sources') populateSources();
        });

        $scope.formatDate = function (date) {
            return moment(date).format('MMM Do YYYY');
        };

        function sourceModal(source) {
            $scope.$parent.editSourceOpened = true;
            modalService.showModal({
                template: require('../connect-source/connect-source.html'),
                inputs: {source: angular.copy(source), program_id: $scope.program.id},
                controller: 'connectSourceController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    $scope.$parent.editSourceOpened = false;
                    if (result && result.reload) populateSources();
                });
            });
        }

        function deleteSource(source) {
            var confirmationMsg = "Are you sure you want to remove source '" + source.name + "'\nfrom program '" + $scope.program.name + "'?";
            $scope.confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) executeDelete(source);
                });
            });
        }

        function executeDelete(source) {
            programSourcesService.delete($scope.program.id, source.id).then(function () {
                $scope.notificator.success({body: 'Source ' + source.name + ' was removed from program ' + $scope.program.name});
                populateSources();
            });
        }
    }
]);