import config from 'infra/config';
import 'angular-cookies';
const HttpClient = require('../../react/infra/HttpClient');
import { setSilentReAuthenticationService, setLogoutAuthenticationService } from '../../react/middleware/AngularExportedMiddleware';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';

export default angular.module(__filename, [
    require("common/sso/sso-service").name,
    'ngCookies'

]).service("authentication", ['$rootScope', 'abiPermissions', '$injector', '$http', '$q', '$auth', '$state', '$window', '$location', '$cookies', 'userMgmt', 'authenticationConfig', 'ssoService',
    function ($rootScope, abiPermissions, $injector, $http, $q, $auth, $state, $window, $location, $cookies, userMgmt, authenticationConfig, ssoService) {
        this.login = login;
        this.logout = logout;
        setLogoutAuthenticationService(logout);
        this.authenticate = autoAuthenticate;
        this.authenticatePage = authenticatePage;
        this.setRedirectUrl = setRedirectUrl;
        this.silentReAuthentication = silentReAuthentication;
        setSilentReAuthenticationService(silentReAuthentication);

        var APP_TO_PAGE_MAP = {
            "trending interests": "discovery.bubbles",
            "content streams": "discovery.streams",
            "channel insights": "discovery.grid.phrases.web",
            "consumption trends": "insights.consumption",
            topics: "insights.topic",
            sentiment: "insights.sentiment",
            associations: "insights.association",
            channels: "insights.channels",
            timing: "insights.timing",
            landscape: "insights.landscape",
            geos: "insights.geo",
            "war room": "warroom.groups",
            audience: "audience-builder",
            alerts: "alerts"
        };

        var reAuthTs = 0;
        var MAX_MILLSEC_BETWEEN_AUTHS = 5000;
        var reAuthPromise = null;
        var reAuthURLs = {};
        var reauthListener = angular.noop;
        var PAGE_REDIRECTIONS = {"audience-explorer": "audience-builder"};
        var hash   = $window.location.hash;
        var search = $location.search();

        function autoAuthenticate() {
            if ($window.location.pathname.indexOf('login') !== -1) return $q.reject('Already in login');
            if (localStorage.rememberMe == 'false' && !$cookies.get('abi-session')) {
                ssoService.redirectToLogin(hash);
            }

            if ($auth.isAuthenticated()) {
                return userMgmt.user($auth.getToken()).then(function (response) {
                    return onLoginSuccess({user: response, token: $auth.getToken()});
                });
            } else {
                return refreshAccessToken().then(function (tokens) {
                    return $q.all([userMgmt.user($auth.getToken()), tokens.data])
                }).then(function (data) {
                    return onLoginSuccess(angular.extend({}, {user: data[0]}, data[1]));
                }).catch(function (err) {
                    //deleting the refresh_token in order for the SSO flow to continue to next step in the /login
                    localStorage.removeItem('refresh_token');
                    ssoService.redirectToLogin(hash);
                });
            }
        }

        function authenticatePage(state) {
            let perms = state.data.permissions;
            return 0==perms.length || perms.every((perm)=>{
              return abiPermissions.hasPermission(perm);
            });
        }

        function silentReAuthentication(url) {
            if (!reAuthPromise || new Date().getTime() - reAuthTs > MAX_MILLSEC_BETWEEN_AUTHS) {
                reAuthPromise = refreshAccessToken();
                reAuthURLs = {};
                reAuthURLs[url] = true;
            } else {
                if (reAuthURLs[url]) {
                    return Promise.reject('url ' + url + ' already asked for reAuthentication!');
                }

                reAuthURLs[url] = true;
            }

            reAuthTs = new Date().getTime();
            return reAuthPromise;
        }

        function setRedirectUrl(redirectUrl){
            let urlMinusDomain = redirectUrl.replace(/^.*\/\/[^\/]+/, '');
            var exp = new Date();
            exp.setTime(exp.getTime() + (60 * 1000));
            $cookies.put('last_page', urlMinusDomain, {expires: exp});
        }

        function login(username, password, rememberMe) {
            // In case user doesn't want to be remembered
            localStorage.setItem('rememberMe', rememberMe);
            $cookies.put('abi-session', true);

            var userCredentials = {username: username, password: password, rememberMe: rememberMe};
            return $auth.login(userCredentials).then(function (response) {
                return onLoginSuccess(response.data)
            }).catch(function (err) {
                return $q.reject(err);
            });
        }

        function isUsingSsoWrapper() {
          return $q(function(resolve, reject) {
            resolve(ssoService.isUsingSso());
          });
        }

        function authenticationLogout(req_config, isSso, isHardLogout) {
            return $http.post(config.AUTHENTICATION_API + "/logout", {token: localStorage.refresh_token}, req_config)
            .then(function (response) {})
            .catch(function (data) {
                MixpanelCommon.sendToMixpanel("Logout", { hard: isHardLogout, sso: isSso, status: data.status, error: data.statusText});
            });
        }

        function logoutSso(isHardLogout, isSso) {
          return ssoService.getAccessToken().then(function(accessToken) {
            let req_config = {};
            if (accessToken && isHardLogout) {
              req_config = { 'headers' : { 'SSO-Access-Token' : accessToken.accessToken } };
            }
            return authenticationLogout(req_config, isSso, isHardLogout);
          });
        }

        //isHardLogout: true means Hard logout from all SSO apps
        function logout(isHardLogout) {
          isUsingSsoWrapper().then(function(isSso) {
            MixpanelCommon.sendToMixpanel("Logout", { hard: isHardLogout, sso: isSso });
            if (isSso) {
              return logoutSso(isHardLogout, isSso);
            } else {
              let req_config = {}
              return authenticationLogout(req_config, isSso, isHardLogout); //logout Legacy
            }}).finally(function (response) {
              HttpClient.setCommonHeader('Authorization', null);
              $auth.logout();
            }).then(function() {
               // Waiting for the state transition to end,
               // and after that prevent state changes to other states in the application
               reauthListener = $rootScope.$on('$stateChangeStart', function (event, nextState) {
                 event.preventDefault();
               });
                ssoService.redirectToLogin(hash, isHardLogout);
             });
          }

        function refreshAccessToken() {
            let login_params = {};
            if (localStorage.refreshToken) {
              login_params['refresh_token'] = localStorage.refreshToken;
            }
            return $auth.login(login_params).then(function (response) {
                MixpanelCommon.sendToMixpanel("Refresh Access Token");
                if (response.data.refresh_token) {
                  localStorage.setItem("refresh_token", response.data.refresh_token);
                }
                return response;
            });
        }

        function onLoginSuccess(data) {
            HttpClient.setCommonHeader('Authorization', data.token);
            $cookies.put('Authorization', data.token, { expires: moment().add(1, 'days').toDate()});
            // Will destroy the reauth listener if exists.
            reauthListener();

            if (data.refresh_token) {
                localStorage.setItem("refresh_token", data.refresh_token);
            }

            $cookies.put('Authorization_help', data.token, { expires: moment().add(1, 'days').toDate()});

            if ($window.location.pathname.indexOf('login') !== -1) return $window.location.replace('/');
            return postAuthentication(userMgmt.buildUser(data.user));
        }

        function postAuthentication(user) {
            return $q.all(startListeners(user)).then(function () {
                if ($state.params.help) {
                    return $window.location.href = 'http://help.intelligence.amobee.com';
                }
                const permissions = user.permissions.map(function (permission) {
                    return permission.name;
                });

                // If no dashboard for user navigate to the first application in map
                const allowedApps = _.intersection(Object.keys(APP_TO_PAGE_MAP), permissions);
                const appStates = allowedApps.map(function (app) {
                    return APP_TO_PAGE_MAP[app].split('.')[0];
                }).concat('settings');

                let nextState = permissions.indexOf('dashboard') == -1 ? APP_TO_PAGE_MAP[allowedApps[0]] : 'dashboard';
                let wantedState = hash.substring(2).replace(/\//g, '.').replace(/#.*/g, '');

                if(hash && hash != "#/" &&
                    false==$window.location.href.includes('login')){ //user entered a specific url
                    nextState = wantedState;
                }else{ //user entered default url
                    if (PAGE_REDIRECTIONS[wantedState]) wantedState = PAGE_REDIRECTIONS[wantedState];
                    nextState = appStates.includes(wantedState.split('.')[0].split('?')[0]) ? wantedState : nextState;
                }
                nextState = nextState.split('?')[0];

                $state.go(nextState, {reload: true, ...search}).then(function () {
                }).catch(function (err) {
                    console.log(err)
                });

                //add the iframe other apps use to login
                if($("#loginFrames").length){
                    $("body").remove("#loginFrames");
                    setTimeout(()=>loginIframe());
                }else{
                    loginIframe();
                }

                function loginIframe(){
                    if(ssoService.isProduction){       
                        ssoService.getAccessToken().then(function(data) {
                            let url = "https://ssomgr-api.amobee.com/web/login?accessToken=" + data.accessToken + "&app=APP_BRAND_INTELLIGENCE";
                            $("body").append('<iframe id="loginFrames" src="'+url+'" style="display: none;"></iframe>');
                        });
                    }
                }

                return user;
            });
        }

        function startListeners(authInfo) {
            return authenticationConfig.doAfterAuthentication.map(function (cb) {
                return $injector.invoke(cb, null, {authInfo: authInfo});
            });
        }
    }
]);
