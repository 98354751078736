"use strict";

module.exports = angular.module(__filename, []).constant('deckConfig', {
    'title-class': 'deck-title',
    'message-class': 'toast-message',
    'icon-classes': {
        error: 'toast-error',
        notify: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning'
    },
    'icon': {
        error: 'icon-status-alert',
        warning: 'icon-status-warning',
        notify: 'icon-status-info',
        success: 'icon-Box_Roll_On'
    },
    'default-icon-class': 'toast-info',
    'timeout': 10000,
    'tap-to-close': true,
    'close-button': true,
    'body-output-type': 'trustedHtml',
    'position-class': 'toast-top-right'
}).service('notificator', ['$rootScope', 'deckConfig', function ($rootScope, config) {
    this.pop = function (type, body, title, timeout, bodyOutputType, clickHandler, priority) {
        if (angular.isObject(type)) {
            var params = type;
            this.notice = {
                type: params.type,
                body: params.body,
                title: params.title,
                timeout: params.timeout,
                bodyOutputType: params.bodyOutputType,
                clickHandler: params.clickHandler,
                priority: params.priority
            };
        } else {
            this.notice = {
                type: type,
                body: body,
                title: title,
                timeout: timeout,
                bodyOutputType: bodyOutputType,
                clickHandler: clickHandler,
                priority: priority
            };
        }
        $rootScope.$emit('notification-newNotice');
    };

    this.clear = function () {
        $rootScope.$emit('notification-clearNotices');
    };

    for (var type in config['icon-classes']) {
        this[type] = (function (noticeType) {
            return function (title, body, timeout, bodyOutputType, clickHandler, priority) {
                if (angular.isString(title)) {
                    this.pop(noticeType, title, body, timeout, bodyOutputType, clickHandler, priority);
                } else {
                    this.pop(angular.extend(title, {type: noticeType}));
                }
            }
        })(type);
    }
}]).factory('notificationEvents', function () {
    var notificationFactory = {
        _NewNotificationEvent: false,
        _ClearAllNotificationsEvent: false,
        registerNewNotificationEvent: function () {
            this._NewNotificationEvent = true;
        },
        registerClearAllNotificationsEvent: function () {
            this._ClearAllNotificationsEvent = true;
        },
        isNewNotificationEvent: function () {
            return this._NewNotificationEvent;
        },
        isClearAllNotificationsEvent: function () {
            return this._ClearAllNotificationsEvent;
        }
    };
    return {
        registerNewNotificationEvent: notificationFactory.registerNewNotificationEvent,
        registerClearNotificationsEvent: notificationFactory.registerClearAllNotificationsEvent,
        isNewNotificationEvent: notificationFactory.isNewNotificationEvent,
        isClearNotificationsEvent: notificationFactory.isClearAllNotificationsEvent
    }
}).directive('amDeck', ['$parse', '$rootScope', '$interval', '$sce', 'deckConfig', 'notificator', 'notificationEvents',
    function ($parse, $rootScope, $interval, $sce, config, notificator, notificationEvents) {
        return {
            replace: true,
            restrict: 'EA',
            scope: true, // creates an internal scope for this directive
            link: function (scope, elm, attrs) {
                var id = 0, mergedConfig;
                mergedConfig = angular.extend({}, config, scope.$eval.notificationOptions);
                scope.config = {
                    position: mergedConfig['position-class'],
                    title: mergedConfig['title-class'],
                    message: mergedConfig['msg-class'],
                    tap: mergedConfig['tap-to-close'],
                    animation: mergedConfig['animation-class'],
                    mouseoverTimer: mergedConfig['mouseover-timer-stop'],
                    closeButton: mergedConfig['close-button']
                };

                scope.deregClearNotifications = null;
                scope.deregNewNotification = null;
                scope.$on("$destroy", function () {
                    if (scope.deregClearNotifications) scope.deregClearNotifications();
                    if (scope.deregNewNotification) scope.deregNewNotification();
                    scope.deregClearNotifications = null;
                    scope.deregNewNotification = null;
                });

                scope.configureTimer = function (notification) {
                    var timeout = typeof(notification.timeout) == 'number' ? notification.timeout : mergedConfig['timeout'];
                    if (timeout > 0) setTimeout(notification, timeout);
                };

                function removeDangerousHTML(message){
                    if (!message) return message;
                    return message.replace(/<script/ig, '');
                }

                function addNotification(notification) {
                    var isErrorNotice = function (notice) {
                        return notice.type == mergedConfig['icon-classes']['error'];
                    };
                    if (notification.type == 'error' && scope.notifications.filter(isErrorNotice).length >= 1) return;
                    var bodies = scope.notifications.map(function (x) {
                        return x.body;
                    });
                    if (bodies.indexOf(notification.body) != -1) return;

                    notification.icon = mergedConfig['icon'][notification.type];
                    notification.type = mergedConfig['icon-classes'][notification.type];
                    if (!notification.type) notification.type = mergedConfig['default-icon-class'];

                    id++;
                    angular.extend(notification, {id: id});

                    notification.bodyOutputType = notification.bodyOutputType || mergedConfig['body-output-type'];
                    switch (notification.bodyOutputType) {
                        case 'trustedHtml':
                            notification.html = $sce.trustAsHtml(removeDangerousHTML(notification.body));
                            break;
                        case 'template':
                            notification.bodyTemplate = notification.body || mergedConfig['body-template'];
                            break;
                        case 'templateWithData':
                            var fcGet = $parse(notification.body || mergedConfig['body-template']);
                            var templateWithData = fcGet(scope);
                            notification.bodyTemplate = templateWithData.template;
                            notification.data = templateWithData.data;
                            break;
                    }

                    scope.configureTimer(notification);
                    scope.notifications.unshift(notification);

                    notification.mouseover = false;
                }

                scope.notifications = [];

                function setTimeout(notification, time) {
                    notification.timeout = $interval(function () {
                        if (!notification.mouseover) scope.removeNotification(notification.id);
                        //TODO: FIX MEMORY LEAK
                    }, time);
                }

                // registration of events ...
                if (!notificationEvents.isNewNotificationEvent()) {
                    notificationEvents.registerNewNotificationEvent();
                    scope.deregNewNotification = $rootScope.$on('notification-newNotice', function () {
                        addNotification(notificator.notice);
                    });
                }
                if (!notificationEvents.isClearNotificationsEvent()) {
                    notificationEvents.registerClearNotificationsEvent();
                    scope.deregClearNotifications = $rootScope.$on('notification-clearNotices', function () {
                        scope.notifications.splice(0, scope.notifications.length);
                    });
                }
            },
            controller: ['$scope', '$element', '$attrs', '$state', function ($scope, $element, $attrs, $state) {
                $scope.stopTimer = function (notification) {
                    notification.mouseover = true;
                    if ($scope.config.mouseoverTimer == true) {
                        if (notification.timeout) {
                            $interval.cancel(notification.timeout);
                            notification.timeout = null;
                        }
                    }
                };

                $scope.restartTimer = function (notification) {
                    notification.mouseover = false;
                    if ($scope.config.mouseoverTimer == true) {
                        if (!notification.timeout) $scope.configureTimer(notification);
                    } else if (notification.timeout === null) {
                        $scope.removeNotification(notification.id);
                    }
                };

                $scope.removeNotification = function (id) {
                    var i = 0;
                    for (i; i < $scope.notifications.length; i++) {
                        if ($scope.notifications[i].id == id) break;
                    }
                    $scope.notifications.splice(i, 1);
                };

                $scope.click = function (notification, isCloseButton) {
                    if ($scope.config.tap === true || isCloseButton == true) {
                        var removeNotification = true;
                        if (notification.clickHandler) {
                            if (angular.isFunction(notification.clickHandler)) {
                                removeNotification = notification.clickHandler(notification, isCloseButton);
                            } else if (angular.isFunction($scope.$parent.$eval(notification.clickHandler))) {
                                removeNotification = $scope.$parent.$eval(notification.clickHandler)(notification, isCloseButton);
                            } else {
                                //console.log('Notification: your click handler is not inside the parent scope of notifications-container')
                            }
                        }
                        if (removeNotification) {
                            $scope.removeNotification(notification.id);
                        }
                    }
                };
            }],
            template: require("./notification_deck.tmpl.html")
        }
    }
]);
