//ws & pstats group data

import config from 'infra/config';
var warVars = require("pages/warroom/warroom-vars");

module.exports = {

    minutes: null, realtimeFreq: 15000,
    orders: {},

    getGroups: function(time, ids) {
        var  that = this, i , params, len = ids.length, startBuckets = 240; 
        that.deferred = that.$q.defer();
        that.minutes = time.minutes
        that.orders = {};

        that.stopGroups();

        if(time.realtime==false){
                
            for(i=0;i<len;i++){ //send groups 1 by 1

                var params = {};
                params.phrase_group_id = ids[i];
                if (warVars.geo != "all") {
                    params.country = warVars.geo;
                }

                if (time.mode == 'custom') {
                    params.end_time = time.to;
                    params.start_time = time.from;
                } else {
                    params.end_time = 'now';
                    params.start_time = '-' + time.minutes + 'm';
                }

                this.$http({method: 'POST', url: config.REALTIME_API + '/phrases_trendline', data: $.param(params), headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                    .success(function(data){
                    data = [data];
                    that.addUIData(data);
                    that.deferred.notify(data);
                }).error(function(){
                    that.deferred.notify({error:true});
                });
            }

        }else{
            that = this;

            function update_realtime() {
                var promises = [];
                for(i=0;i<len;i++) { //send groups 1 by 1
                    var params = {};
                    params.phrase_group_id = ids[i];
                    if (warVars.geo != "all") {
                        params.country = warVars.geo;
                    }

                    if (time.mode == 'custom') {
                        params.end_time = time.to;
                        params.start_time = time.from;
                    } else {
                        params.end_time = 'now';
                        params.start_time = '-' + time.minutes + 'm';
                    }
                    promises.push(that.$http({method: 'POST', url: config.REALTIME_API + '/phrases_trendline', data: $.param(params), headers: {'Content-Type': 'application/x-www-form-urlencoded'}}));
                }

                that.$q.when(that.$q.all(promises)).then(function(data){

                    var total_data = [];
                    for(i=0;i<data.length;i++) {
                        total_data.push(data[i].data);
                    }
                    that.addUIData(total_data);
                    that.deferred.notify(total_data);
                });
            }

            that.stopGroups();
            that.realtime_interval = setInterval(update_realtime, that.realtimeFreq);
            update_realtime();
        }

        return that.deferred.promise;
    },
    

    stopGroups: function() {
        if (this.realtime_interval) {
            clearInterval(this.realtime_interval);
        }
    },

    saveOrder: function(groups){
        var that = this;
         _.map(groups, function(group) {
            group.order = group.velocity;
            that.orders[group.id]=group.order;

            _.map(group.phrases, function(phrase) {
                phrase.order = phrase.velocity;
                that.orders[phrase.id]=phrase.order;
            });
        });
    },

    addUIData: function(groups){
        //data used by ui like order, factor

        var that = this;
        if(0 == Object.keys(that.orders).length){
           that.saveOrder(groups);
        }else{
            _.map(groups, function(group) {
                group.order = that.orders[group.id];

                _.map(group.phrases, function(phrase) {
                    phrase.order = that.orders[phrase.id];
                });
            });
        }
   
        $.each(groups, function(i, group) {

            var factor = warVars.groupFactor(group);

            factorToEntity(group, factor);

            $.each(group.stats, function(j, stat) {
                factorToEntity(stat, factor);
            });

            $.each(group.phrases, function(j, phrase) {

                factorToEntity(phrase, factor);

                $.each(phrase.stats, function(j, stat) {
                    factorToEntity(stat, factor);
                });

            });
        }); 

        function factorToEntity(entity, factor){
            entity.pos =   Math.round(entity.pos*factor);
            entity.neu =   Math.round(entity.neu*factor);
            entity.neg =   Math.round(entity.neg*factor);
            entity.total = Math.round(entity.total*factor);
        }
    }

}