import config from 'infra/config';
var warVars   = require("pages/warroom/warroom-vars");

module.exports = {

  get: function(entity, scope){
      
    if(null === entity){
      query.show([]);
      return;
    }

    var that = this,
        query = scope.queries.associations;
    query.loading = true;
    query.title = (entity.display || entity.name || entity.text);

    $("#associations .column-title").eq(0).html(
      '<span class="associations-title">associations</span> <i class="icon-Triangle_Right"></i> '+query.title);

    var params = {};
    params.phrase_group_id = scope.selected.group.id;

    if (scope.selected.date && scope.selected.date_end) {
      params.end_time = scope.selected.date_end.substring(0, scope.selected.date_end.lastIndexOf(':'));
      params.start_time = scope.selected.date.substring(0, scope.selected.date_end.lastIndexOf(':'));
    } else if (scope.time.mode == 'custom') {
      params.end_time = scope.time.to;
      params.start_time = scope.time.from;
    } else {
      params.end_time = 'now';
      params.start_time = '-' + scope.time.minutes + 'm';
    }

    if (warVars.geo != "all") {
      params.country = warVars.geo;
    }

    if(entity.type === "phrase"){
      var id = entity.id;
      params.phrase_id = id.indexOf("@") !=-1 ? id : encodeURIComponent(id);
    }

    if(entity.total===0 && scope.time.mode==='realtime'){
      query.show([]);
    }
    else{
      this.$http({method: 'POST', url: config.REALTIME_API + '/phrases_associations', data: $.param(params), headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
          .success(function(data) {
          query.loading = false;
          query.dataArray = data.map(function(cell){
            if (cell.type === 'hashtag') {
              cell.isHashtag=true;
            }
            cell.type="association";
            cell.count = that.stepFactor(cell.count, warVars.groupFactor(scope.selected.group));
            return cell;
          });

          query.show(query.dataArray);

          setTimeout(function(){scope.resizeAssosications();},0);

      }).error(function(e){
        console.log(e);
      });
    }
  },

  stepFactor: function(base, factor) {

    if(factor===1){
      return base;
    }

    if(base < 3) {
      return Math.round( base * 3.4 );
    }
    else if (base < 6) {
      return Math.round( base * 5.7 );
    }
    else {
      return Math.round( base * 8.8 );
    }
  },

  add: function(ass, scope, context){
    if(scope.queries.associations){
      scope.queries.associations.loading = true;
    }

    var text = (ass.isHashtag && -1 === ass.text.indexOf("#")) ? "#"+ass.text : ass.text;

    var phrase = {
      'id':scope.phraseId(ass), 
      'type': ass.isHashtag ? 'hashTag' : 'term', 
      'text':text, 
      'display':text, 
      'invalid':false, 
      'origin': text};
      
    this.$http({
      url: config.USER_MGMT_API+"/add_ass/"+ scope.selected.group.id,
      method: "put",
      contentType: "application/json",
      data: angular.toJson({phrase:phrase})
  
    }).success(function() {
      scope.msg("'"+text+"' added to "+scope.selected.group.name);
      
      try{  
        scope.getGroups({keep:true, clicked:true});    
      }catch(er){}

    }).error(function(e){
          console.log(e);
    });

  }
}
