var c = require("infra/utils/common");

module.exports = angular.module(__filename, [])
    .directive("amTimeframeFilter", ['$rootScope', '$window', 'notificator', 'TIMES',
      function ($rootScope, $window, notificator, TIMES) {
        return {
            restrict: "E",
            template: require("./timeframe.html"),
            scope: {
                mold: "=",
                showDatePicker: '&',
                showTimePicker: '=',
                times: '=',
                minDate: '=',
                subtractDay: '=',
                fullWeeksDetection: '=',
                noMaxTfNotif: '=',
                maxSpan: '=',
            },
            link: function (scope, elem, attr) {
                const format = scope.getFormat = TIMES.FORMAT;

                function setFromMinDate(){
                    const DEFAULT_MIN_DATE_OFFSET = TIMES.INSIGHTS_OFFSET;
                    if(typeof scope.minDate == 'string') {
                        let fromMinDate = moment(scope.minDate, format).startOf('day');
                        let systemMinDate = moment().startOf('day').subtract(...DEFAULT_MIN_DATE_OFFSET);
                        scope.fromMinDate = _.max([fromMinDate, systemMinDate]);
                        return;
                    }
                    scope.fromMinDate = moment().startOf('day');
                    var offset = _.isArray(scope.minDate) ? scope.minDate : DEFAULT_MIN_DATE_OFFSET;
                    scope.fromMinDate.subtract(...offset);
                    if(_.isEqual(offset, DEFAULT_MIN_DATE_OFFSET)) {
                        if(scope.fromMinDate.isoWeekday() != 1) {
                            scope.fromMinDate = scope.fromMinDate.isoWeekday(1).add(1, 'weeks');
                        }
                    }
                }

                function setFullWeeks(notify){
                    if (!c.isTimeframeForWeekly(scope.fromDate, scope.toDate)) return;

                    var adjusted_timeframe = c.getFullWeeksTimeframe(scope.fromDate, scope.toDate);
                    if(!adjusted_timeframe[0].startOf('day').isSame(scope.fromDate, 'day') ||
                       !adjusted_timeframe[1].startOf('day').isSame(scope.toDate, 'day')) {
                        scope.fromDate = adjusted_timeframe[0];
                        scope.toDate = adjusted_timeframe[1];
                        if (scope.mold && scope.mold._value) {
                            if(c.isNumber(scope.mold._value[0])) {
                                notify = false;
                            } else {
                                scope.mold.replace([adjusted_timeframe[0].format(format), adjusted_timeframe[1].format(format)]);
                            }
                        }

                        if (notify) {
                            notificator.notify({body: "Timeframe adjusted to full weeks to be aligned with the weekly breakdown"});
                        }
                    }
                }

                const onChange = function(event, newVal, subtractDay, event_notification_message) {
                    if (!scope.mold) return;

                    let { mold: { _value: mold } } = scope;
                    const event_received = event && event.name === 'timeframe-update' && newVal && newVal[0] && newVal[1];
                    if (scope.times.some((time) => typeof time != 'object')) {
                        scope.times = TIMES.getTimes(scope.times);
                    }

                    scope.noTimeframe = _.isEmpty(scope.times);
                    scope.toMaxDate = scope.subtractDay ? moment().subtract(1, 'days') : roundDownHalfHour(moment().add(30, 'minutes'));
                    setFromMinDate();
                    if (event_received) {
                        mold = newVal;
                        if (event_notification_message) {
                            notificator.notify({ body: event_notification_message });
                        }
                    }

                    if (c.isNumber(mold[0])) {
                        const index = _.findIndex(scope.times, { amount: mold[0], unit: mold[1] });
                        if (index !== -1) scope.currentTime = scope.times[index];

                        scope.toDate = moment();
                        scope.fromDate = moment().subtract(...mold);
                        if (scope.subtractDay) {
                            scope.toDate = moment().subtract(1, 'days');
                            if (mold[1] === 'month') {
                                scope.fromDate.subtract(1, 'days');
                            } else if (mold[0] === 7) {
                                scope.fromDate = moment().clone().subtract(7, 'days');
                            }
                        }

                        if (!_.isEmpty(scope.times) && !IsCurrentSelectionAllowed(mold[1])) {
                            setCustomTimeframe();
                        } else if (scope.fromDate < scope.fromMinDate) {
                            scope.fromDate = scope.fromMinDate.clone();
                            scope.mold.replace([
                                scope.fromDate.format(format),
                                scope.toDate.format(format),
                            ]);
                            notificator.notify({ body: "No data exists for the selected timeframe. The timeframe's start date has been updated." });
                        } else if (event_received) {
                            scope.mold.replace(newVal);
                        }
                    } else {
                        if (scope.fromDate && scope.fromDate < scope.fromMinDate) {
                            const diffMonths = scope.toDate.diff(scope.fromDate, 'months');
                            const diffDays = scope.toDate.diff(scope.fromDate.add(diffMonths, 'month'), 'days');
                            let notificationMessage = "No data exists for the selected timeframe. The timeframe's start date has been updated.";
                            scope.fromDate = scope.fromMinDate.clone();
                            if (scope.toDate < scope.fromDate) {
                                scope.toDate = scope.fromDate.clone().add({ month: diffMonths, day: diffDays });
                                if (scope.toDate > scope.toMaxDate) {
                                    scope.toDate = scope.toMaxDate.clone();
                                    scope.toMinDate = scope.toDate.clone();
                                }

                                notificationMessage = "No data exists for the selected timeframe. The timeframe's start date and end date have been updated.";
                            }

                            notificator.notify({ body: notificationMessage });
                        } else {
                            scope.fromDate = roundDownHalfHour(moment(mold[0], format));
                            if (scope.fromDate < scope.fromMinDate) {
                                scope.fromDate = scope.fromMinDate.clone();
                            }

                            scope.toDate = roundDownHalfHour(moment(mold[1], format));
                            if (scope.toDate < scope.toMinDate) {
                                if ((scope.subtractDay || subtractDay) && scope.toMinDate > moment().subtract(1, 'days')) {
                                    scope.toMinDate = scope.fromDate.clone();
                                    scope.toMaxDate = moment().subtract(1, 'days');
                                } else {
                                    scope.toDate = roundDownHalfHour(scope.toMinDate);
                                }
                            } else if (scope.toDate > scope.toMaxDate) {
                                notificator.notify({ body: "The time you've selected is in the future. The timeframe has been adjusted accordingly." });
                                scope.toDate = roundDownHalfHour(scope.toMaxDate);
                                setCustomTimeframe();
                            }
                        }

                        if (scope.maxSpan && scope.toDate.clone().subtract(...scope.maxSpan) > scope.fromDate) {
                            const { source }  = event;
                            if (source === 'fromDate') {
                                scope.toDate = scope.fromDate.clone().add(...scope.maxSpan);
                            } else {
                                scope.fromDate = scope.toDate.clone().subtract(...scope.maxSpan);
                            }

                            const maxSpanString = moment.duration(...scope.maxSpan).humanize({d: 46});
                            notificator.notify({ body: `Maximum date span is ${maxSpanString}. Timeframe was adjusted accordingly.` });
                        }

                        let endOfWeek = scope.toDate.clone().isoWeekday(7);
                        if (endOfWeek > moment()) endOfWeek = endOfWeek.subtract(1, 'weeks');

                        if (!scope.noMaxTfNotif && endOfWeek > scope.fromDate.clone().isoWeekday(1).add(57, 'weeks')) {
                            scope.fromDate.isoWeekday(1);
                            scope.toDate = scope.fromDate.clone().add(57, 'weeks').subtract(1, 'days');
                            scope.toMaxDate = scope.toDate.clone();
                            notificator.notify({ body: 'Maximum date span is 13 months. Timeframe was adjusted to complete weeks accordingly.' });
                        }

                        scope.mold.replace([
                            roundDownHalfHour(scope.fromDate).format(format),
                            roundDownHalfHour(scope.toDate).format(format),
                        ]);
                    }

                    scope.fromMaxDate = scope.toDate.clone();
                    scope.toMinDate = scope.fromDate.clone().add(30, 'minutes');
                    if (!(event && event.name === '$stateChangeSuccess' && !c.isNumber(mold[0])) && scope.fullWeeksDetection) {
                        setFullWeeks(event && event.name === 'timeframe-update');
                    }
                };

                function roundDownHalfHour(time) {
                    let minutes = time.get('minute');
                    if (minutes >= 30) {
                        minutes -= 30;
                    }

                    return time.clone().subtract(minutes, 'minutes');
                }

                function setCustomTimeframe() {
                    scope.mold.replace([
                        roundDownHalfHour(moment(scope.fromDate)).format(format),
                        roundDownHalfHour(moment(scope.toMaxDate)).format(format),
                    ]);
                }

                function IsCurrentSelectionAllowed(unit){
                    return scope.times === undefined || _.find(scope.times, (time) => time.unit === unit);
                }

                scope.setTime = function (chosenTime) {
                    if (!chosenTime) return;

                    let newTimeVal = [chosenTime.amount, chosenTime.unit];
                    onChange({name: 'timeframe-update'}, newTimeVal);
                };

                scope.isSelected = () => !scope.mold || !scope.mold._value || !c.isNumber(scope.mold._value[0]);

                scope.isNarrow = () => $window.innerWidth < 1350;

                scope.isChosenTime = (time) => time && scope.mold && scope.mold._value &&
                                               scope.mold._value[0] === time.amount &&
                                               scope.mold._value[1] === time.unit;

                scope.setDate = function(source){
                    let newTimeVal = [scope.fromDate.format(format), scope.toDate.format(format)];
                    onChange({name: 'timeframe-update', source}, newTimeVal);
                };

                onChange({name: 'timeframe-update'});
                $rootScope.$on("timeframe-update", onChange);
                $rootScope.$on("$stateChangeSuccess", onChange);
                scope.$watch('mold', () => onChange({name: 'timeframe-update'}));
                scope.$watch('fullWeeksDetection', () => onChange({name: 'timeframe-update'}));
                scope.$watch('times', onChange);
                scope.$watch('minDate', onChange);
                scope.narrowModeOpen = false;
                scope.narrowModeChanged = () => scope.narrowModeOpen = !scope.narrowModeOpen;
                scope.closeNarrowModeOption = () => scope.narrowModeOpen = false;
            }
        }
    }]
);
