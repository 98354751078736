"use strict";
/**
 How to add a new attribute to the context.
 only Pages can define new attributes on the context
 to do so, you need to add a "context" attribute to pageConfig (see discovery)
 each attribute must define a "mold" - I.E. TermMold, BooleanMold (see BooleanMold for a simple Mold)

 Creating a new Mold:
 See BooleanMold in infra/context/filters/boolean-mold for a simple example
 Each Mold is an angular service that returns a constructor for an Object that contains:
 _value - the actual value (for boolean true/false, for integers a number)
 replace: a function that given an attribute, replaces the _value. must return a promise (note that you can perform validations)
 serialize: a "toString" method, providing the text representation of value for use in url, serialization etc...
 Each Mold also needs to define a "routerType" - a constant named "BooleanMoldRouterType" that defines the "ui-router"
 type. (see http://angular-ui.github.io/ui-router/site/#/api/ui.router.util.type:Type)
 TODO: we might want to change this so that it will work immediately from serialize/deserialize

 Using the attribute in the context
 To read, you just need to pass the name provided in the page.
 For instance if you named your attribute 'terms' you can access it using context.current.terms
 to update the value, you need to access the `hidden` mold value in context.
 use context.current._terms_mold to access the mold's replace and serialize functions.
 */

var common = require("infra/utils/common");
import 'angular-ui-router';

module.exports = angular.module(__filename, [
    require("../snapshot").name,
    require("../user").name,
    'ui.router'
]).config(["authenticationConfig", function (authenticationConfig) {
    authenticationConfig.doAfterAuthentication.push(["user", "context", function (user, context) {
        return user.$load.then(function () {
            return context.changeContext();
        });
    }]);
}]).service("context", ["$rootScope", "snapshot", "topicsTree", "user", "$injector", "stateHelper", "$q", "geoService", "filtersPartition", "abiPermissions",
    function ($rootScope, snapshot, topicsTree, user, $injector, stateHelper, $q, geoService, filtersPartition, abiPermissions) {
        var listeners = [];
        var changeContextReady = null;

        function setSnapshotTerms(currentProgram) {
            return snapshot.get(user, currentProgram).then(function (snapshot) {
                return {snapshot: snapshot, program: snapshot.program, currentProgram: currentProgram, user: user};
            }).catch(function () {
                return {snapshot: {}, program: user.programs[0], currentProgram: currentProgram, user: user};
            }).then(function (contextObject) {
                contextObject = setProgram(contextObject);

                contextObject.snapshot.terms = contextObject.snapshot.terms || [];
                contextObject.snapshot.terms.filter(function (term) {
                    return term.type === 'programBL';
                }).forEach(function (term) {
                    var index = _.findIndex(contextObject.snapshot.terms, {id: term.id});
                    var pbl = _.find(contextObject.program.boolean_logics, {id: term.id});
                    if (pbl) {
                        term = angular.copy(term);
                        term.text = pbl.text;
                        term.required = pbl.required;
                        term.included = pbl.included;
                        term.excluded = pbl.excluded;
                    } else {
                        contextObject.snapshot.terms.splice(index, 1);
                    }
                });

                contextObject.snapshot.gridChannels = contextObject.snapshot.gridChannels || contextObject.snapshot.bubblesChannels;
                return deleteUnnecessaryProperties(contextObject);
            });
        }

        // cleanup all properties in context that should not be here
        function deleteUnnecessaryProperties(contextObject) {
            delete contextObject.snapshot.molds;
            delete contextObject.snapshot.program;
            delete contextObject.snapshot.searchType;
            delete contextObject.snapshot.audience_searchType;
            delete contextObject.snapshot.levelOfIntent;
            delete contextObject.snapshot.behavioralSegment;
            delete contextObject.snapshot.discoveryView;
            delete contextObject.snapshot.query_params;
            delete contextObject.snapshot.domain;
            delete contextObject.snapshot.phrase;

            return mapTargets(contextObject);
        }

        function mapTargets(contextObject) {
            var snapshot = contextObject.snapshot;
            if (typeof snapshot.target_web == 'object') {
                snapshot.target_web = snapshot.target_web._id;
            }

            if (typeof snapshot.target_social == 'object') {
                snapshot.target_social = snapshot.target_social._id;
            }

            if (typeof snapshot.target_facebook == 'object') {
                snapshot.target_facebook = snapshot.target_facebook._id;
            }
            if (typeof snapshot.target_sg_telco == 'object') {
                snapshot.target_sg_telco = snapshot.target_sg_telco._id;
            }
            return contextObject;
        }

        function setProgram(contextObject) {
            var currentProgram = contextObject.currentProgram;
            if (!currentProgram) {
                currentProgram = contextObject.program;
                var u_program = contextObject.user.programs.filter(function (p) {
                    return p.id === currentProgram.id;
                })[0];

                if (u_program) {
                  if (!currentProgram.boolean_logics) {
                    currentProgram.boolean_logics = u_program.boolean_logics;
                  }
                  if (!currentProgram.trending_topics) {
                    currentProgram.trending_topics = u_program.trending_topics;
                  }
                  if (!currentProgram.program_audience_mappings) {
                    currentProgram.program_audience_mappings = u_program.program_audience_mappings;
                  }
                  if (!currentProgram.program_sources) {
                    currentProgram.program_sources = u_program.program_sources;
                  }
                }
            }

            return _.extend(contextObject, {program: currentProgram});
        }

        function populateCustomFilters(filterName) {
            var filter = $rootScope[`${filterName}Filter`];
            const filterFunc = (filter) => {
                const filterPermission = filter.permission && !abiPermissions.hasPermission(filter.permission);
                const filterAnyPermission = filter.anyPermission && !abiPermissions.hasSomePermissions(filter.anyPermission);
                return filterPermission || filterAnyPermission;
            }

            // Iterate over the filter object (filters are in filters-partition.srv.js)
            // If the object contains 'permission' and the user doesn't have that permission
            // the object will be removed from the collection
            _.remove(filter, filterFunc);
            _.each(filter, (filterObject) => {
              _.remove(filterObject.children, filterFunc)
            });
        }

        function populateSources(contextObject) {
            if (!_.find(user.permissions, {name: 'program sources'})) return;

            _.remove($rootScope.streamsChannelsFilter, 'custom');
            _.remove($rootScope.bubblesChannelsFilter, 'custom');

            _.each(contextObject.program.program_sources,
                (s) => $rootScope.streamsChannelsFilter.push({id: s.id, value: s.id, label: s.name, custom: true, type: s.sources[0].channel})
            );
            var facebookSources = _.filter(contextObject.program.program_sources, (source) => source.sources[0].type == 'facebook');
            _.each(facebookSources,(s) => $rootScope.bubblesChannelsFilter.push({id: s.id, value: s.id, label: s.name, custom: true, type: s.sources[0].channel}) );

        }

        function setFilters(contextObject) {
            var topicsPromise = contextObject.program.sensitive_content ? topicsTree.getTopicsWithSensitive() : topicsTree.get();
            var geosPromise = geoService.getGeos(contextObject.user.id);
            return Promise.all([topicsPromise, geosPromise]).then(function (response) {
                $rootScope.topicsFilter = response[0];
                populateCustomFilters('streamsChannels');
                populateCustomFilters('bubblesChannels');
                populateCustomFilters('insightsChannels');
                populateCustomFilters('insightsAssociationsChannels');
                populateCustomFilters('geoInsightsChannels');
                populateCustomFilters('insightsTimingChannels');
                populateCustomFilters('insightsReferralsChannels');
                populateCustomFilters('audienceChannels');
                /*
                 There is a chance that the snapshot will contain topics that are currently not loaded in the topics filter.
                 For example, snapshot saved with sensitive topics and then the sensitive content was removed from that program.
                 That's why we need the intersection.
                 */
                var topicsId = _.intersection(_.map(contextObject.snapshot.topics, 'id'), _.map(topicsTree.topicsFlatten, 'id'));
                contextObject.snapshot.topics = _.filter(contextObject.snapshot.topics, function (t) {
                    return _.includes(topicsId, t.id);
                });

                var geoIds = _.intersection(_.map(contextObject.snapshot.geo, 'id'), _.map(response[1], 'id'));
                contextObject.snapshot.geo = _.filter(contextObject.snapshot.geo, function (g) {
                    return _.includes(geoIds, g.id);
                });

                geoService.setWhiteListGeos(contextObject.program.whitelist_geos || []);
                return contextObject;
            });
        }

        function handleProgramName(contextObject) {
            /* convert personal program name to 'my interests' */
            contextObject.program.name = common.getProgramName(contextObject.program.name);

            if (contextObject.program.name.match(/coca/ig)) {
                filtersPartition.age[0].label = "13-17";
                filtersPartition.age[0].summary = "13-17";
            }

            return contextObject;
        }

        function setMolds(contextObject) {
            var context = {};
            /* todo: should DIE */
            var promises = [];
            _.forEach(stateHelper.contextDefinition, function (definition, key) {
                var Mold = $injector.get(definition.mold);
                var mold = new Mold(context);

                Object.defineProperty(context, key, {
                    get: function () {
                        return mold._value
                    },
                    set: function (value) {
                        return mold.replace(value);
                    },
                    enumerable: true
                });

                Object.defineProperty(context, "_" + key + "_mold", {
                    value: mold,
                    enumerable: false
                });

                promises.push(mold.replace(contextObject.snapshot[key]));
            });

            _.defaults(context, contextObject.snapshot);
            return $q.all(promises).then(function () {
                serviceInstance.current = context;
                serviceInstance.program = contextObject.program;
                return context;
            });
        }

        $rootScope.$watch(function () {
            return serviceInstance.current;
        }, function (newVal, oldVal) {
            if (newVal == null && oldVal == null) return false;
            if (angular.toJson(oldVal) == angular.toJson(newVal)) return false;
            try {
                changeContextReady.then(function () {
                    $rootScope.$broadcast('context-updated', newVal);

                    /* deprecated style, because user must remember to remove his listener when $scope is dead */
                    listeners.forEach(function (l) {
                        l(newVal, oldVal)
                    });
                });
            } catch (e) {}
        }, true);

        $rootScope.$watch(function () {
            return serviceInstance.program;
        }, function () {
            populateSources(serviceInstance);
        }, true);

        var serviceInstance = {
            program: null,
            current: null,
            onChange: function (fn) {
                listeners.push(fn);
                return function unbind() {
                    listeners.splice(listeners.indexOf(fn), 1)
                };
            },
            get done() {
                return changeContextReady
            },
            changeContext: function (currentProgram) {
                changeContextReady = null;
                return setSnapshotTerms(currentProgram)
                    .then(setFilters)
                    .then(handleProgramName)
                    .then(setMolds).then(function () {
                        changeContextReady = $q.when();
                    });
            }
        };

        return serviceInstance;
    }
]).run(["$rootScope", "context", "snapshot", "user",
    function ($rootScope, context, snapshot, user) {
        var saveContext = _.debounce(function () {
            var savedContext = angular.copy(context.current);
            savedContext.u_id = user.id;
            savedContext.p_id = context.program.id;
            savedContext.program = context.program;
            snapshot.set(user, context.current);
        }, 3000);

        // sync url and context to each other
        context.onChange(function syncState() {
            // Save context ...
            if (context.program) {
                saveContext();
            }
        });

        $rootScope.$on("$stateChangeStart", function syncContext() {
            $rootScope.$emit('notification-clearNotices');
        });
    }
]);
