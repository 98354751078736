import config from 'infra/config';
const customFilter = require("infra/context/filters/custom-filter"),
    c = require('infra/utils/common'),
    exportHelper = require('infra/utils/export'),
    version = 'v3';


module.exports = angular.module(__filename, [])
    .service('Grid', ['$http', '$state', '$rootScope', 'conceptsService', '$q', 'geoService', 'notificator', 'util', 'CHANNEL', 'Excel', 'GridDataLayer', 'context', 'topicsTree', 'abiPermissions',
        function ($http, $state, $rootScope, conceptsService, $q, geoService, notificator, util, CHANNEL, Excel, GridDataLayer, context, topicsTree, permissions) {

            var serviceConfig = {
                timeframe: null,
                terms: null,
                topics: null,
                program: null,
                audience: null,
                examples: 20,
                channels: null,
                gridAdvanced: null,
                language: null,
                sub_geos: null, 
                app: 'abi'
            },
            supportSubGeos = false,
            subGeosExportTitle = '';

            var query_params;

            var wmTermsPromisesCache = new Map;

            /*******API ****************/
            return Object.defineProperties({
                list: get,
                generateSummarySheet: addSummaryToWorkbook,
                clearCache: clearCache
            }, {
                'config': {
                    get: function () {
                        return serviceConfig
                    },
                    set: function (newConfig) {
                        return _.extend(serviceConfig, newConfig);
                    }
                },
                query_params: {
                    get: function () {
                        return query_params
                    }
                }
            });

            /******* END API ***********/
            function get(ts) {
                var config = _.extend({kwd_ids: kwd_ids}, serviceConfig);
                var kwd_ids = util.getPhrasesToIdMap(config.terms || []);

                var query = GridDataLayer.queries[config.channels.value][config.grid_tab],
                    queryId = config.channels.value + config.grid_tab;

                setGridAdvanced(GridDataLayer, queryId);

                /*if query is undefined, no request*/
                if (!query) return $q.when([]);

                supportSubGeos = query.supportSubGeos;
                subGeosExportTitle = config.subGeosExportTitle;

                var params = query['paramsDefaults'](config);
                params = $.extend(params, customFilter.objWithoutDef(context.current.adv));
                params = $.extend(params, {source: config.channels.value, kwd_ids: kwd_ids});

                query_params = _.cloneDeep(params);
                if(config.channels.value != 'articles' || !permissions.hasPermission('first party segments')){
                  delete params.user_first_party_audience;
                } else if(params.user_first_party_audience) {
                  params.user_first_party_audience = _.map(params.user_first_party_audience, "value");
                }
                if (params.sub_geos && context.current.discoveryAllSubGeosSelected){
                  params.sub_geos = [];
                }

                /*split terms and send them separately or use cache*/
                return splitAndSend(params, config).then(function (termsData) {
                    return postProcess(termsData, config, ts);
                });
            }

            function setGridAdvanced(data, queryId) {
                var queries = data.queries,
                    version = data.version;

                if ('undefined' == typeof context.current.gridAdvanced || context.current.gridAdvanced.version != version) {
                    //new grid version = reset defaults
                    var def = {customFilter: true, version: data.version};
                    for (var i in queries) {
                        for (var j in queries[i]) {
                            def[i + j] = queries[i][j].gridAdvanced;
                        }
                    }

                    context.current.gridAdvanced = $.extend(true, {}, def);
                }

                context.current.adv = context.current.gridAdvanced[queryId]; //shortcut
            }

            function cacheOrSend(params, config, concept) {
                var cache = wmTermsPromisesCache.get(concept);

                if (!supportSubGeos) {
                    params.sub_geos = []; //too long ...
                }

                if (cache && angular.equals(cache.params, params)) {
                    return cache;
                } else {
                    // save it
                    var httpPromise = send(params, config);
                    wmTermsPromisesCache.set(concept, httpPromise);
                    httpPromise.params = _.extend({}, params);
                    // release it
                    return httpPromise;
                }
            }

            function setKeyTrends(params, concept) {
                
                if (concept == "trending") {
                    params["key_trends"] = 1;
                    switch (params["source"]) {
                        case "articles":
                            params["phrases"] = [];
                            break;
                        case "tweets":
                            params["concepts[]"] = [];
                            break;
                        case "facebook":
                            params["phrases[]"] = [];
                            break;
                    }
                    return;
                }
                params["key_trends"] = 0;
            }

            function splitAndSend(params, config) {
                var query = GridDataLayer.queries[config.channels.value][config.grid_tab];
                var termsPromises = {};
                var terms = config.terms;
                var concept, i, result;

                var concepts = util.getTerms(terms, false);
                if (config.isKeyTrends) {
                    concepts.push("trending");
                }
                for (i = 0; concept = concepts[i]; i++) {
                    query['concepts'](params, concept);

                    setKeyTrends(params, concept);
                    result = cacheOrSend(params, config, concept + config.grid_tab);
                    termsPromises[concept] = result;
                }
                concepts = util.getTerms(terms, true);
                for (i = 0; concept = concepts[i]; i++) {
                    query['blConcepts'](params, concept);

                    setKeyTrends(params, concept);
                    result = cacheOrSend(params, config, concept + config.grid_tab);
                    termsPromises[concept.text] = result;
                }

                return $q.all(termsPromises).then(
                    function (termsResolved) {
                        return query['resolveData'](termsResolved, query['metric']);
                    },
                    function (termsRejected) {
                        return query['resolveData']({}, query['metric']);
                    }
                );
            }

            function send(params, config) {
                var query = GridDataLayer.queries[config.channels.value][config.grid_tab];
                var method = query['method'] || 'GET';
                var dataToSend =
                {
                    method: method,
                    url: (query['host'] + query['endpoint']).replace('VERSION', version)
                };

                if(method == 'GET') {
                    dataToSend.params = _.extend({}, params);
                } else {
                    dataToSend.data = _.extend({}, params);
                }

                return $http(dataToSend).then(function (server_data) {

                    if (window.gridKdebugData && undefined != window.gridKdebugData.docsUsed1) {
                        var docs_used = server_data.data.docs_used || "0/0";
                        window.gridKdebugData.docsUsed1 += 1 * (docs_used.substring(0, docs_used.indexOf("/")));
                        window.gridKdebugData.docsUsed2 += 1 * (docs_used.substring(docs_used.indexOf("/") + 1));
                        window.gridKdebugData.elapsed += server_data.data.elapsed || 0;
                    }

                    var query = GridDataLayer.queries[config.channels.value][config.grid_tab];
                    _.forEach(server_data.data[query.metric], function (item) {
                        query.addQueryData(item);
                    });
                    return server_data;
                });
            }

            function postProcess(data, config, ts) {
                var trendsTerm = {class: 'term-trending', text: 'trending', type: 'term'};
                for (var keyName in data['data']) {
                    var term = _.find(config.terms, {text: keyName});
                    _.forEach(data['data'][keyName], function (item) {
                        item['seed'] = keyName;
                        item['term'] = keyName == "trending" ? trendsTerm : term;
                        item['type'] = config.channels.value;
                        item['selected'] = false; // because the Cache
                        switch (config.channels.value) {
                            case 'articles':
                                item['icon'] = 'icon-Article';
                                break;
                            case 'tweets':
                                item['icon'] = 'icon-Twitter';
                                break;
                            case 'facebook':
                                item['icon'] = 'icon-facebook';
                                break;
                        }
                    });
                }

                var res = _(data['data']).values().flatten().compact().value().sort(function (item1, item2) {
                    return item2['score'] - item1['score'];
                });
                return {data: res, topicsDistribution: util.getTopicsDistribution(data.topics), ts: ts}
            }

            function clearCache() {
                wmTermsPromisesCache.clear();
            }

            function addSummaryToWorkbook(workbook) {
                var query = serviceConfig;
                var t = Excel.formater(workbook);
                var booleans = c.selectBooleanLogics(query.terms);
                var grid = [
                    [t('Generated At:', 'bold'), moment().format('YYYY-MM-DD HH:mm')],
                    [t('Content From:', 'bold'), c.getTimeframeLabel(query.timeframe, 'YYYY-MM-DD')],
                    [t('Program:', 'bold'), query.program.name],
                    [t('Seeds:', 'bold'), t(_.or(c.rejectBooleanLogics(query.terms), 'None'), 'display')],
                    [t('Refine interests:', 'bold'), t(_.or(booleans, 'None'), 'display')],
                    [t('Selected Topics:', 'bold'), t(_.or(query.topics, 'all'), 'name')],
                    [t('Language:', 'bold'), t(_.or(query.language, 'all'), 'label')]
                ];

                var sgTelcoAudiencePermission = permissions.hasPermission('sg telco audience filters');
                if (query.channels.value == 'articles' ||
                    (query.channels.value == 'sg_telco' && sgTelcoAudiencePermission)) {
                    grid.push([t('Selected Audience:', 'bold'), _.or(c.titleize(c.titleize(_.compact(_.map(query.audience, 'summary')).join(', '))), 'all')]);
                }

                if (query.channels.value == 'articles' && permissions.hasPermission('first party segments') && !_.isEmpty(context.current.firstPartyAudience)) {
                    grid.push([t('1st Party Segments: ', 'bold'), t(_.map(context.current.firstPartyAudience, 'summary').join(', '), 'summary')]);
                }

                grid.push([t('Geo:', 'bold'), t(_.or(query.geos, 'all'), 'label')]);

                if(supportSubGeos){
                    let geo = geoService.geosForChannel(query.geos, $state, context)[0];
                    let exportGeos = context.current.discoveryAllSubGeosSelected ? 'all' :
                                     _.or(geoService.indexedSubGeosTreeHelper($rootScope.subGeosTree, (geo || {}).id)
                                                    .contextSubGeosHelper(query.sub_geos)
                                                    .getOnlyTopCheckedSubGeos(), 'all');
                    grid.push([t(subGeosExportTitle + ':', 'bold'), t(exportGeos, 'name')]);
                }

                grid.push([t('Channel:', 'bold'), query.channels.label]);

                var advSummary = customFilter.summary('gridAdvanced', context.current.adv);

                $.each(advSummary, function (i, sum) {
                    grid.push([t(sum.title + ':', 'bold'), t(_.or(sum.value, 'all'), 'value')])
                });


                var bls = util.getTerms(booleans, true);
                if (!_.isEmpty(bls)) {
                    var boolSummary = exportHelper.getBooleansLoginSummary(bls, {});
                    grid.push([]); // new line
                    grid.push([t('Refine interests:', 'bold')].concat(boolSummary.title));
                    grid = grid.concat(boolSummary.data);
                }

                var summarySheet = workbook.createWorksheet({name: 'Summary'});
                summarySheet.setColumns([{width: 20}, {width: 20}]);
                summarySheet.setData(grid);
                workbook.addWorksheet(summarySheet);
            }
        }
    ]
);
