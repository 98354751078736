var $ = require("jquery");

function createAbstractDirective(childConf, from, provided) {
    var childController = childConf.controller;

    function AbstractDirCtrl($injector) {
        var locals = provided || {};
        for (var i = 0; i < AbstractDirCtrl.$inject.length; i++) {
            locals[AbstractDirCtrl.$inject[i]] = arguments[i];
        }
        $injector.invoke(from, this, locals);
        $injector.invoke(childController, this, locals);
    }

    AbstractDirCtrl.$inject = ["$injector"].concat(childController.$inject, from.$inject).filter(function (i) {
        return !provided[i] && provided[i] != 0;
    });
    angular.extend(AbstractDirCtrl.prototype, from.prototype, childController.prototype);
    childConf.controller = AbstractDirCtrl;
    return childConf;
}

function BaseWidgetController($window, $scope, $rootScope, $element, $timeout, bindsToWatch, notificator, debounce) {
    this.$element = $element;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.notificator = notificator;
    this.loaderDiv = angular.element("<div class='loader-overlay'></div><div class='loader'>&nbsp;</div>");
    this.loaderId = 0;
    this.bind(bindsToWatch);
    this.executeWithSpinnerDebounced = debounce ? _.debounce(this.executeWithSpinner.bind(this), debounce) : angular.noop;
    this.executeWithSpinner({}, {}, this._doUpdate);
    if (debounce) this.executeWithSpinner({}, {}, this._doUpdateDebounced);
}

BaseWidgetController.$inject = ["$window", "$scope", "$rootScope", "$element", "$timeout", "bindsToWatch", "notificator", "debounce"];

BaseWidgetController.prototype.bind = function (bindsToWatch) {
    var self = this;
    this._boundedResizeFunc = function () {
        self.onResize && self.onResize();
    };
    var changedVals = {};
    bindsToWatch.forEach(function (b) {
        self.$scope.$watchCollection(b, function (newVal, oldVal) {
            if (self._hasChanges(oldVal, newVal)) return false;
            changedVals[b] = oldVal;
            self.timeout = self.timeout || self.$timeout(function () {
                    var valMap = {};
                    var forSwitch = changedVals;
                    self.timeout = undefined;
                    changedVals = {};
                    for (var i = 0; i < bindsToWatch.length; i++) {
                        var bind = bindsToWatch[i];
                        valMap[bind] = self.$scope[bind];
                    }
                    self.update(valMap, forSwitch);
                }, 0);
        })
    });

    $(this.$window).on("resize", this._boundedResizeFunc);
    this.$scope.$on("$destroy", this.unbind.bind(this));
};

BaseWidgetController.prototype.unbind = function () {
    $(this.$window).off("resize", this._boundedResizeFunc);
};

BaseWidgetController.prototype.update = function (valMap, changedVals) {
    this.executeWithSpinner(valMap, changedVals, this._doUpdate);
    this.executeWithSpinnerDebounced(valMap, changedVals, this._doUpdateDebounced)
};

BaseWidgetController.prototype.executeWithSpinner = function (valMap, changedVals, callback) {
    var self = this;
    self.startLoader();
    try {
        this.$timeout(function () {
            var promise = callback.bind(self)(valMap, changedVals);
            if (promise && promise.finally) {
                promise.finally(function () {
                    self.stopLoader();
                });
            } else {
                self.stopLoader();
            }
        }, 0);
    } catch (e) {
        self.stopLoader();
        throw e;
    }
};

BaseWidgetController.prototype._doUpdate = function (newValues) {};

BaseWidgetController.prototype._doUpdateDebounced = function (newValues) {
    throw new Error("children of BaseWidget must implement _doUpdateDebounced");
};

BaseWidgetController.prototype.startLoader = function () {
    this.$element.append(this.loaderDiv);
    ++this.loaderId;
};

BaseWidgetController.prototype.stopLoader = function () {
    this.loaderId = _.max([this.loaderId - 1, 0]);
    if (this.loaderId == 0) this.loaderDiv.remove();
};

BaseWidgetController.prototype._hasChanges = function(oldVal, newVal) {
  // Permission are not suppose to be cause for _doUpdate to be called again.
  // for example see filters-partition.js insightsChannels and geoInsightsChannels
  let hasChanges;
  if (_.isObject(oldVal) && !_.isArray(oldVal)) {
    hasChanges = JSON.stringify(_.omit(oldVal, 'permission')) == JSON.stringify(_.omit(newVal, 'permission'))
  } else {
    hasChanges = JSON.stringify(oldVal) == JSON.stringify(newVal)
  }

  return hasChanges
};

module.exports = function (childConf) {
    var bindsToWatch = Object.keys(childConf.scope);
    return createAbstractDirective(childConf, BaseWidgetController, {
        bindsToWatch: bindsToWatch,
        debounce: childConf.debounce || 0
    });
};
