module.exports = angular.module(__filename, [
  require('../alerts-settings/alerts-settings').name,
  require('common/modals/confirmation/confirm-action.modal.service.js').name,
  require('data/alerts-v2-service').name,
  require('data/insights/alerts-insights-service').name,
  require('./alert-dsp/alert-dsp-component').name
]).controller('alertV2DialogController', ['$scope', '$rootScope', 'close', 'util', 'alertsV2Service', 'ModalService', 'confirmAction', '$state', 'notificator',
  'alert', 'currentAlertsNames', 'settingsMissing', "filtersPartition", 'geoService', 'alertsInsightsService', '$window', '$timeout', 'abiPermissions', 'topicsTree', 'TIMES',
  function ($scope, $rootScope, close, utils, alertsInterface, ModalService, confirmAction, $state, notificator, alert, currentAlertsNames,
            settingsMissing, filter, geoService, alertsInsightsService, $window, $timeout, abiPermissions, topicsTree, TIMES) {
    $scope.alertTypes = alertsInterface.consts.ALERT_TYPES;
    $scope.sentimentTypes = alertsInterface.consts.SENTIMENT_TYPES;
    $scope.alertTrendDirectionsBool = alertsInterface.consts.TREND_DIRECTIONS_BOOL;
    $scope.geos = $rootScope.Geos.geos;
    $scope.TIMES = TIMES;
    
    $scope.netSentimentExplain = "pts. = points-change in net sentiment index. For example, going from 20 to -10 is a 30 points decrease.";
    
    var defaultAlert = {
      interests: [],
      signal_timeframe: '1D',
      benchmark_timeframe: '1M',
      channel: filter.alertsV2Channels[0].value,
      geos: geoService.serverValue(),
      sentiment_type: $scope.sentimentTypes[0],
      is_increase: $scope.alertTrendDirectionsBool[0],
      predefined: false,
      dsp: {},
      triggers: {
        compare_to_average: {
          is_active: true,
          value_type: 'stdev',
          stdev: 2
        },
        compare_to_previous: {is_active: false}
      },
      time_limit: {start: null, end: null}
    };
    
    const timeframesByValue = _.keyBy([
      {text: '1H', value: '1H'},
      {text: '3H', value: '3H'},
      {text: '6H', value: '6H'},
      {text: '12H', value: '12H'},
      {text: '1D', value: '1D'},
      {text: '1W', value: '1W'},
      {text: '1M', value: '1M'},
      {text: '3M', value: '3M'},
      {text: '6M', value: '6M'},
      {text: '1Y', value: '1Y'}
    ], 'value');
    
    $scope.signalTimeframes = ['1H', '3H', '6H', '12H', '1D', '1W'].map((timeframeValue) => timeframesByValue[timeframeValue]);
    $scope.benchmarkTimeframsByValue = _.mapValues({
      '1H': ['1D'], '3H': ['1D'], '6H': ['1W'], '12H': ['1W'], '1D': ['1M', '3M', '6M', '1Y'], '1W': ['1M', '3M', '6M', '1Y']
    }, (timeframeValues) => timeframeValues.map((timeframeValue) => timeframesByValue[timeframeValue]));
    
    function isPredefined(alert) {
      return alert && alert.predefined;
    }
    
    $scope.alert = defaultAlert;
    var allowedAlertsTypes = _.reject($scope.alertTypes, 'disabled');
    $scope.alert.type = allowedAlertsTypes.length === 1 ? allowedAlertsTypes[0].value : undefined;
    if (alert && !isPredefined(alert)) {
      $scope.alert = alert;
      $scope.alert.geos = alert.geos || defaultAlert.geos;
      
      // a UI(single selection geos dropdown) fix for all geos save
      
      $scope.alert.channel = alert.channel || defaultAlert.channel;
      $scope.alert.type = alert.type;
      $scope.alert.sentiment_type = _.find($scope.sentimentTypes, {id: alert.sentiment_type});
      $scope.alert.is_increase = _.find($scope.alertTrendDirectionsBool, {value: $scope.alert.is_increase}) || defaultAlert.is_increase;
      
      $scope.title = 'Edit alert';
    } else {
      $scope.alert.lineItems = '';
      $scope.title = 'Create a new alert';
    }
  
    // for time limit fields
    $scope.today = moment();
    $scope.theFarFarFuture = moment().add(5, 'years');
    $scope.timeLimitIncludeStart = !!($scope.alert.time_limit && $scope.alert.time_limit.start);
    $scope.timeLimitIncludeEnd = !!($scope.alert.time_limit && $scope.alert.time_limit.end);
    
    if ($scope.alert.geos.length > 1) $scope.alert.geos = [];
    if (isPredefined(alert)) {
      $scope.alert.predefined = true;
      $scope.alert.interests = alert.interests;
    }
    
    var originalChannel = $scope.alert.channel;
    $scope.originalTimeframe = $scope.alert.benchmark_timeframe;
    $scope.close = close;
    $scope.save = save;
    
    $scope.channels = filter.alertsV2Channels;
    
    // var uniqueId = 0;
    // $scope.activationPercentage = function () {
    //   if (!$scope.alert.type) return;
    //   uniqueId++;
    //   var promises = [];
    //   var alert = angular.copy($scope.alert);
    //   var defaultSettings = {
    //     topics: [],
    //     geos: [],
    //     insightsChannel: {value: 'articles'},
    //     toNormalize: true
    //   };
    //   alert.uniqueId = uniqueId;
    //
    //   if (($scope.alertDialogForm && !$scope.alertDialogForm.$valid) || alert.interests.length === 0) return;
    //
    //   $scope.showEstimation = false;
    //   $scope.isBusy = true;
    //   if (!validateAlert(alert)) {
    //     updateView(false);
    //     return;
    //   }
    //
    //   alert.interests.forEach(function (term) {
    //     var parameters = utils.buildInsightsParameters(angular.extend(
    //       {terms: [term], timeframe: getTimeframe(alert.benchmark_timeframe)}, defaultSettings, extractSettings(alert)
    //     ));
    //
    //     if (alert.type === 'sentiment') {
    //       parameters.channel = 'tweets';
    //       parameters.sentiment_type = alert.sentiment_type;
    //     }
    //     parameters.is_increase = alert.is_increase;
    //     parameters.triggers = alert.triggers;
    //     if (parameters.channel === 'tweets') parameters.to_normalize = false;
    //
    //     var promise = alertsInsightsService.getInsights(parameters).then(function (res) {
    //       return res.examples.filter(function (obj) {
    //         return obj.letter !== 'A';
    //       }).map(function (obj) {
    //         return obj.date;
    //       });
    //     });
    //     promises.push(promise);
    //   });
    //
    //   return Promise.all(promises).then(success, failed).then(check).then(updateView).catch(_.noop);
    //
    //   // functions ....
    //   function failed() {
    //     return 'Calculation failed..';
    //   }
    //
    //   function success(activeDatesArray) {
    //     var totalDays = $scope.timeframes.find((obj) => obj.value === alert.benchmark_timeframe).days;
    //     var activeDatesFlat = [].concat.apply([], activeDatesArray);
    //     var activeDatesUnique = activeDatesFlat.filter(function (item, pos) {
    //       return activeDatesFlat.indexOf(item) == pos;
    //     });
    //
    //     return Math.floor(activeDatesUnique.length / totalDays * 100) + '%';
    //   }
    //
    //   function check(value) {
    //     if (alert.uniqueId !== uniqueId) throw 'Do nothing...';
    //     return validateAlert(alert) ? value : false
    //   }
    //
    //   function updateView(value) {
    //     $scope.estimatedActivation = value ? value : 'Cannot calculate..';
    //     $scope.showEstimation = true;
    //     $scope.isBusy = false;
    //     $scope.$digest();
    //   }
    //
    //   function extractSettings(alert) {
    //     var channel = (alert.channel === "") ? null : {value: alert.channel};
    //     var geos = alert.geos && alert.geos[0] ? alert.geos : null;
    //     return {
    //       geos: geos || [],
    //       sensitive_content: alert.sensitive_content,
    //       insightsChannel: channel || {value: 'articles'}
    //     };
    //   }
    //
    //   function getTimeframe(timeframe) {
    //     var endTimeframe = moment();
    //     var startTimeframe = endTimeframe.clone();
    //     var totalDays = $scope.timeframes.find((obj) => obj.value === timeframe).days;
    //     startTimeframe.subtract(totalDays - 1, 'days');
    //     return [startTimeframe.format('DD/MM/YY'), endTimeframe.format('DD/MM/YY')];
    //   }
    // };
    
    // // debounce activation calculation
    // var debouncedAC = _.debounce($scope.activationPercentage, 2000, {'maxWait': 5000});
    // $scope.$watch('alert', debouncedAC, true);
    
    function editSettings(alert) {
      angular.element(document.querySelector('#alert-dialog-overlay')).hide();
      
      ModalService.showModal({
        template: require('../alerts-settings/alerts-settings.html'),
        inputs: {settings: angular.copy($scope.settings['alerts_notification_settings'][0]), firstTime: true},
        controller: 'alertsV2SettingsController'
      }).then(function (modal) {
        modal.close.then(function () {
          saveAlert(alert);
        });
      });
    }
    
    $scope.getButtonCss = (option, currentVal) => utils.getButtonCss(option, currentVal, $scope);
    
    $scope.filterTimeframe = (option) => $scope.alert.channel !== 'tweets' || option.value === '1M';
    
    $scope.onAlertTypeSelected = function (type) {
      $timeout(handleDialogScroll, 400);
      setDefaults($scope.alert, type);
    };
    
    $scope.onSignalTimeframeSelect = (timeframe) =>  {
      $scope.alert.signal_timeframe = timeframe.value;
      $scope.alert.benchmark_timeframe = $scope.benchmarkTimeframsByValue[timeframe.value][0].value;
    };
    $scope.onBenchmarkTimeframeSelect = (timeframe) => {
      $scope.alert.benchmark_timeframe = timeframe.value;
    };
    
    var MARGIN_TOP = 5;
    $scope.isAlertShouldScroll = false;
    $scope.$watch(() => angular.element(document.querySelector('#dialog-form-container')).height(), handleDialogScroll);
    $scope.$watch('settings_open', handleDialogScroll);
    $scope.$watch('turn_dsp_open', handleDialogScroll);
    
    function handleDialogScroll() {
      var dialogHeight = (angular.element(document.querySelector('#alert-dialog-modal'))[0] || {}).offsetHeight;
      var dialogMargin = (($window.innerHeight / 100) * MARGIN_TOP) * 2;
      $timeout(() => $scope.isAlertShouldScroll = $window.innerHeight - dialogMargin <= dialogHeight);
      $timeout(() => {
        $timeout(() => angular.element(document.querySelector('#alert-dialog-modal')).css('overflow', 'inherit'), 800);
      }, 400);
      
      var dropdownSelectors = angular.element(document.querySelectorAll('am-select .scroll-container'));
      _.each(dropdownSelectors, selector => {
        var selectorMaxHeight = selector.getBoundingClientRect().top ? $window.innerHeight - selector.getBoundingClientRect().top : 1000;
        selectorMaxHeight -= 20;
        selector.style["max-height"] = `${selectorMaxHeight}px`;
      })
    }
    
    $scope.channelChanged = function (channel) {
      originalChannel = channel;
    };
    
    $scope.advanceSettingsLabel = () => {
      return !$scope.alert.is_increase.value && $scope.alert.type === "sentiment" ? "below" : "above";
    };
    
    $scope.isNetSentimentAlert = function () {
      return $scope.alert.type === 'sentiment' && $scope.alert.sentiment_type && $scope.alert.sentiment_type.id === 'neutral';
    };
    
    function save(alert) {
      if (!validateAlert(alert)) return;
      
      if (settingsMissing) {
        editSettings(alert);
      } else {
        saveAlert(alert);
      }
    }
    
    function saveAlert(alert) {
      $scope.saving = true;
      const allowedInterestProps = ['id', 'text', 'display', 'origin', 'required', 'included','excluded'];
      console.log(alert);
      const alertAttrsToSave = {
        name: alert.name,
        type: alert.type,
        channel: alert.channel,
        geos: geoService.handleSupportedCountries(alert.geos),
        interests: alert.interests.map((interest) => _.pick(interest, allowedInterestProps)),
        dsp: handleTurnFields(alert),
        triggers: alert.triggers,
        is_increase: alert.is_increase && alert.is_increase.value,
        sentiment_type: alert.sentiment_type && alert.sentiment_type.id,
        time_limit: _.mapValues(alert.time_limit || [], (v) => (v)instanceof(moment) ? v.toDate() : v),
        signal_timeframe: alert.signal_timeframe,
        benchmark_timeframe: alert.benchmark_timeframe,
        is_active: true
      };
      alert.id ? update(alert.id, alertAttrsToSave) : create(alertAttrsToSave);
    }
    
    function handleTurnFields(alert) {
      const dspValues = {
        market: _.isEmpty(alert.dsp_market) ? null : _.omit(alert.dsp_market, 'market'),
        advertiser: _.isEmpty(alert.dsp_advertiser) ? null : _.omit(alert.dsp_advertiser, 'advertiser'),
        insertion_order: _.isEmpty(alert.dsp_insertion_order) ? null : _.omit(alert.dsp_insertion_order, 'insertion'),
        package: _.isEmpty(alert.dsp_package) ? null : _.omit(alert.dsp_package, 'package'),
        line_items: _.isEmpty(alert.lineItems) ? null : _.compact(alert.lineItems.split(',').map((li) => li.trim))
      };
      return _.some(dspValues, _.isNil) ? null : dspValues
    }
    
    function setDefaults(alert, type) {
      if (type === "sentiment") {
        alert.channel = 'tweets';
        alert.sentiment_type = alert.sentiment_type || defaultAlert.sentiment_type;
      } else {
        alert.channel = originalChannel;
      }
    }
    
    // function getKwdIds(interests) {
    //   var kwd_ids = utils.getPhrasesToIdMap(interests);
    //   var result = {};
    //   Object.keys(kwd_ids).forEach(function (kw) {
    //     // mongo key with dot
    //     result[kw.replace(new RegExp(/\./, 'g'), "\uff0e")] = kwd_ids[kw];
    //   });
    //
    //   return result;
    // }
    
    function validateAlert(alert) {
      let nonPhrases = false; // false -> valid
      let customMsg;
      $scope.nameNotUniqueError = alert.name && currentAlertsNames.indexOf(alert.name.toLowerCase()) !== -1;
      $scope.noInterestsError = alert.interests.length === 0;
      if (alert.benchmark_timeframe !== '1M') {
        customMsg = 'Sorry, only 1M benchmark timeframe can be used for @mentions, @posts and #hashtags monitoring';
        nonPhrases = utils.common.validateNonPhrases(alert.interests, alert.channel, notificator, customMsg);
      } else {
        customMsg = 'Sorry, @mentions, @posts and #hashtags are supported only for social channel';
        nonPhrases = utils.common.validateNonPhrases(alert.interests, alert.channel, notificator, customMsg);
      }
      return validateTriggers(alert.triggers) && !$scope.noInterestsError && !$scope.invalidEmailsError && !$scope.nameNotUniqueError && !nonPhrases;
    }
    
    function validateTriggers(triggers) {
      $scope.noTriggersSelected = !triggers.compare_to_average.is_active && !triggers.compare_to_previous.is_active;
      if ($scope.noTriggersSelected) return false;
      
      if (triggers.compare_to_average.is_active) {
        $scope.compare_to_average_missingValueError = !triggers.compare_to_average.percentage && !triggers.compare_to_average.stdev;
      } else {
        $scope.compare_to_average_missingValueError = false;
      }
      
      if (triggers.compare_to_previous.is_active) {
        $scope.compare_to_previous_missingValueError = !triggers.compare_to_previous.percentage && !triggers.compare_to_previous.stdev;
      } else {
        $scope.compare_to_previous_missingValueError = false;
      }
      
      if ($scope.alert.type === 'sentiment') {
        $scope.previous_positive_values = (triggers.compare_to_previous.sentiment_points < 0 || triggers.compare_to_previous.stdev < 0);
        $scope.avg_positive_values = (triggers.compare_to_average.sentiment_points < 0 || triggers.compare_to_average.stdev < 0);
      } else {
        $scope.previous_positive_values = false;
        $scope.avg_positive_values = false;
      }
      
      $scope.avg_positive_values &= triggers.compare_to_average.is_active;
      $scope.previous_positive_values &= triggers.compare_to_previous.is_active;
      
      return !$scope.compare_to_average_missingValueError && !$scope.compare_to_previous_missingValueError
             && !$scope.previous_positive_values && !$scope.avg_positive_values;
    }
    
    function openSuccessMessage(alert) {
      if (isPredefined(alert)) {
        var confirmationMsg = alert.name + " Alert was added successfully!";
        var txtConfirm = "View Alerts";
        var txtCancel = "Back to discovery";
        confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, "Alert Notification").then(function (modal) {
          modal.close.then(function (confirmation) {
            if (confirmation) $state.go('alerts');
          });
        });
      }
    }
    
    function create(alert) {
      alertsInterface.alerts.create(alert).then(() => {
        notificator.success({body: alert.name + ' was saved successfully'});
        openSuccessMessage(alert);
        close({reload: true});
      }, handleErrors);
    }
    
    function update(id, alert) {
      alertsInterface.alerts.update(id, alert).then(() => {
        notificator.success({body: alert.name + ' was updated successfully'});
        close({reload: true});
      }, handleErrors);
    }
    
    function handleErrors(res) {
      $scope.saving = false;
      $scope.dspErrors = res.data
    }
  }
]);
