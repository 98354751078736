"use strict";
import config from 'infra/config';

class DspService {
  constructor($http, ssoService, CacheFactory, user) {
    this.$http = $http;
    this.user = user;
    this.ssoService = ssoService;
    this.cache = CacheFactory('dspService', {
      storageMode: 'memory',
      maxAge: 60 * 60 * 1000 // 1 hour
    });
    this.marketCacheKey = 'markets';
    this.marketContextCacheKey = 'market-context';
    this.amplificationMarketContextCacheKey = 'amplification-market-context';
    this.advertiserContextCachePrefix= 'advertiser-context';
    this.amplificationAdvertiserContextCachePrefix= 'amplification-advertiser-context';
    this.advertisersCachePrefix = 'advertisers';
    this.insertionOrderCachePrefix = 'insertion'
    this.packageCachePrefix = 'package';
    this.baseURL = config.USER_MGMT_API + "/dsp";
  }

  getMarkets(nameKeyInResult = 'market', idKey = "id", isAllMarketsSupported = false) {
    return this._getResourceFromCacheOrHTTP(this.marketCacheKey, '/markets').then(markets => {
        const marketsResult = this._parseResults(markets, 'name', 'marketId', nameKeyInResult, idKey);
        const allMarkets = { [nameKeyInResult]: 'Activate to all markets', [idKey]: '0', name: 'Activate to all markets'}
        return (isAllMarketsSupported && !_.isEmpty(marketsResult)) ? [ allMarkets, ...marketsResult ] : marketsResult;
      }
    );
  }

  listAdvertisers(marketId) {
    return this.getAdvertisers(marketId, null, {limit: -1})
  }

  getAdvertisers(marketId, prefix, params) {
      const cacheKey = `${this.advertisersCachePrefix}:${marketId}:${prefix}`;
      const newParams = Object.assign({limit: 10, q: prefix}, params);
      return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/advertisers/${this.getSessionId()}`, newParams).then(advertisers =>
        this._parseResults(advertisers, 'name', 'advertiserId', 'advertiser')
      );
  }

  getMarketContext(idKey = 'id') {
    const cacheKey = this.marketContextCacheKey;
    return this.ssoService.getAccessToken().then(accessToken => {
      let headers = { 'SSO-Access-Token' : accessToken.accessToken };
      return this._getResourceFromCacheOrHTTP(cacheKey, '/markets/user', null, headers).then(marketContext =>
        ({ advertisers : this._parseResults(marketContext['advertisers'], 'name', 'value', 'label', idKey),
           markets     : this._parseResults(marketContext['markets'], 'name', 'value', 'label', idKey),
           market_id   : marketContext['market_id'],
           isCS        : marketContext['isCS']})
      )});
  }

  getAmplificationMarketContext(idKey = 'id') {
    const cacheKey = this.amplificationMarketContextCacheKey;
    return this.ssoService.getAccessToken().then(accessToken => {
      let headers = { 'SSO-Access-Token' : accessToken.accessToken };
      return this._getResourceFromCacheOrHTTP(cacheKey, '/amplification-markets/user', null, headers).then(marketContext =>
        ({ advertisers : this._parseResults(marketContext['advertisers'], 'name', 'value', 'label', idKey),
           markets     : this._parseResults(marketContext['markets'], 'name', 'value', 'label', idKey),
           market_id   : marketContext['market_id'],
           isCS        : marketContext['isCS']})
      )});
  }

  getAdvertiserContext(marketId, idKey = 'id') {
    const cacheKey = `${this.advertiserContextCachePrefix}:${marketId}`;
    let params = { market_id: marketId };
    return this.ssoService.getAccessToken().then(accessToken => {
      let headers = { 'SSO-Access-Token' : accessToken.accessToken };
      return this._getResourceFromCacheOrHTTP(cacheKey, '/advertisers/user', params, headers).then(advertiserContext =>
        ({ advertisers : this._parseResults(advertiserContext['advertisers'], 'name', 'value', 'label', idKey),
           market_id   : advertiserContext['market_id'] })
      )});
  }

  getAmplificationAdvertiserContext(marketId, idKey = 'id') {
    const cacheKey = `${this.amplificationAdvertiserContextCachePrefix}:${marketId}`;
    let params = { market_id: marketId };
    return this.ssoService.getAccessToken().then(accessToken => {
      let headers = { 'SSO-Access-Token' : accessToken.accessToken };
      return this._getResourceFromCacheOrHTTP(cacheKey, '/amplification-advertisers/user', params, headers).then(advertiserContext =>
        ({ advertisers : this._parseResults(advertiserContext['advertisers'], 'name', 'value', 'label', idKey),
           market_id   : advertiserContext['market_id'] })
      )});
  }
  
  listInsertionOrders(marketId, advertiserID) {
      return this.getInsertionOrders(marketId, advertiserID, null, {limit: -1});
  }

  getInsertionOrders(marketId, advertiserID, prefix, params) {
    const cacheKey = `${this.insertionOrderCachePrefix}:${advertiserID}:${prefix}`;
    const newParams = Object.assign({limit: 10, q: prefix}, params);
    return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/advertisers/${advertiserID}/insertion/${this.getSessionId()}`, newParams).then(insertions =>
      this._parseResults(insertions, 'name', 'insertionOrderId', 'insertion')
    );
  }

  listPackages(marketId, insertionId) {
    return this.getPackages(marketId, insertionId, null, {limit: -1})
  }

  getPackages(marketId, insertionId, prefix, params) {
    const cacheKey = `${this.packageCachePrefix}:${insertionId}:${prefix}`;
    const newParams = Object.assign({limit: 10, q: prefix}, params);
    return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/insertion_order/${insertionId}/packages/${this.getSessionId()}`, newParams).then(packages =>
      this._parseResults(packages, 'packageName', 'packageId', 'package')
    );
  }

  getMarketFromId(id, {nameKeyInResult = 'market', idKey = "id", isAllMarketsSupported = false} = {}) {
    return this.getMarkets(nameKeyInResult, idKey, isAllMarketsSupported).then(markets => _.find(markets, { id: id.toString() }));
  }

  getAdvertiserFromId(id, marketId) {
    return this.getAdvertisers(marketId, id).then(advertisers => _.find(advertisers, { id }));
  }

  getInsertionOrderById(id, advertiserId, marketId) {
    return this.getInsertionOrders(marketId, advertiserId, id).then(insertions => _.find(insertions, { id }));
  }

  getSessionId() {
    return this.user.id;
  }

  _setMarket(marketId) {
    const url = this.baseURL + "/markets"
    marketId == this.currentMarket
      ? Promise.resolve()
      : this.$http.post(url, {market_id: marketId, session_id: this.getSessionId()}).then(() => this.currentMarket = marketId)
  }

  _getResourceFromCacheOrHTTP(cacheKey, path, params = {}, headers = {}) {
    const URL = this.baseURL + path;
    const cacheResults = this.cache.get(cacheKey);

    return cacheResults ? Promise.resolve(cacheResults) : this.$http.get(URL, {params, headers}).then(res => {
      this.cache.put(cacheKey, res.data);
      return res.data
    });
  }

  _parseResults(results, nameField, idField, nameKeyInResult, idKey = 'id') {
    const sortedList = _.chain(results).pickBy(_.identity).sortBy(o => parseInt(o[idField])).value();
    return _.map(sortedList, item => ({
      [nameKeyInResult]: `${item[idField]} - ${item[nameField]}`,
      [idKey]: item[idField],
      name: item[nameField]
    }));
  }
}

module.exports = require("angular").module(__filename, []) .service("dspService", ['$http', 'ssoService', 'CacheFactory', 'user', DspService]);
