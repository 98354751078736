import { cloneDeep, isArray, isEmpty } from 'lodash';
import compliance from 'react/services/ComplianceService';

function removeSensitiveData(segments, sensitivePhrases) {
  const isNonSensitive = ({ label, text = label }) => !sensitivePhrases.has(text);
  return cloneDeep(segments).filter((segment) => {
    let hadArrays = false;
    for (const [key, val] of Object.entries(segment)) {
      if (isArray(val)) {
        hadArrays = true;
        segment[key] = val.filter(isNonSensitive);
        if (isEmpty(segment[key])) {
          delete segment[key];
        }
      }
    }

    // demographics on smartTv has the geo field as non-array, for some reason
    const hasData =
      Object.values(segment).some(isArray) || (/demographics/i.test(segment.type) && !isEmpty(segment.geo));

    // KEEP segment if it currently has data (arrays OR demographics.geo)
    // OR didn't have any arrays, and the text/label field is not sensitive
    return hasData || (!hadArrays && isNonSensitive(segment));
  });
}

export async function checkNameSensitivity(name) {
  const { passed, text } = await compliance.checkPhrases({
    phrases: [name],
    feature: 'audience',
    isTitle: true,
    checkGDPR: true,
    checkNAI: true,
  });
  return { passed, text };
}

export async function checkSensitivity(audience, reviewSensitivityHandler) {
  const sensitivityData = await compliance.getSensitivityDataForAudience(audience);
  return {
    reviewSensitivityHandler({ gdpr: removeGdpr, nai: removeNai }) {
      const phrasesToRemove = [];
      if (removeGdpr) {
        const { phrases } = sensitivityData.find(({ value }) => value === 'gdpr');
        phrasesToRemove.push(...phrases);
      }

      if (removeNai) {
        const { phrases } = sensitivityData.find(({ value }) => value === 'nai');
        phrasesToRemove.push(...phrases);
      }

      if (removeGdpr || removeNai) {
        const segments = removeSensitiveData(audience.segments, new Set(phrasesToRemove));
        reviewSensitivityHandler(segments);
      } else {
        reviewSensitivityHandler();
      }
    },
    sensitivityData,
  };
}
