import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateRangePickerWithSingleSelectToggle from '../../../../common/DateRangePickerWithSingleSelectToggle/DateRangePickerWithSingleSelectToggle';
import moment from 'moment';

const MIN_START_DATE = new Date('2019-08-01');
const DEFAULT_TIMEFRAME = '3 months';
const TIMEFRAMES = [
  //value=moment unit of time
  { label: '1W', value: '1 week' },
  { label: '1M', value: '1 months' },
  { label: '3M', value: '3 months' },
  { label: '6M', value: '6 months' },
];

const TvCustomTimeframe = ({
  isLightTheme,
  startDateString,
  endDateString,
  selectedStartDateCB,
  selectedEndDateCB,
}) => {
  const startDate = convertToDate(startDateString);
  const endDate = convertToDate(endDateString);
  const [selectedTimeframe, setSelectedTimeframe] = useState(startDate && endDate ? null : DEFAULT_TIMEFRAME);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate || getStartDateByTimeframe(selectedTimeframe));
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDate || getEndDateByTimeframe(selectedStartDate, selectedTimeframe)
  );

  const onSelectTimeframe = useCallback((timeframe) => {
    const startDate = getStartDateByTimeframe(timeframe);
    const endDate = getEndDateByTimeframe(startDate, timeframe);
    setSelectedTimeframe(timeframe);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }, []);

  const onStartDateSelect = useCallback((selectedDate) => {
    setSelectedTimeframe(null);
    setSelectedStartDate(selectedDate);
  }, []);

  const onEndDateSelect = useCallback((selectedDate) => {
    setSelectedTimeframe(null);
    setSelectedEndDate(selectedDate);
  }, []);

  useEffect(() => {
    selectedStartDateCB(selectedStartDate);
  }, [selectedStartDate]);

  useEffect(() => {
    selectedEndDateCB(selectedEndDate);
  }, [selectedEndDate]);

  function convertTimeframeToUnitValue(timeframe) {
    if (!timeframe) return [null, null];
    const [unit, value] = timeframe.split(' ');
    return unit && value ? [unit, value] : [null, null];
  }

  function getStartDateByTimeframe(timeframe) {
    if (!timeframe) return null;
    const [unit, value] = convertTimeframeToUnitValue(timeframe);
    const selectedStartDate = moment().subtract(unit, value).toDate();
    return selectedStartDate < MIN_START_DATE ? MIN_START_DATE : selectedStartDate;
  }

  function getEndDateByTimeframe(startDate, timeframe) {
    if (!timeframe) return null;
    const [unit, value] = convertTimeframeToUnitValue(timeframe);
    return moment(startDate).add(unit, value).toDate();
  }

  function convertToDate(str) {
    if (!str) return null;
    const date = new Date(str);
    return date instanceof Date && !isNaN(date) ? date : null;
  }

  return (
    <div className="tv-custom-timeframe-component">
      <DateRangePickerWithSingleSelectToggle
        isLightTheme={isLightTheme}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        minDate={MIN_START_DATE}
        onStartDaySelected={onStartDateSelect}
        onEndDaySelected={onEndDateSelect}
        selectToggleValues={TIMEFRAMES}
        selectedToggleValue={selectedTimeframe}
        onToggleSelect={onSelectTimeframe}
      ></DateRangePickerWithSingleSelectToggle>
    </div>
  );
};

TvCustomTimeframe.propTypes = {
  isLightTheme: PropTypes.bool,
  startDateString: PropTypes.string,
  endDateString: PropTypes.string,
  selectedStartDateCB: PropTypes.func.isRequired,
  selectedEndDateCB: PropTypes.func.isRequired,
};

TvCustomTimeframe.defaultProps = {
  isLightTheme: true,
};

export default TvCustomTimeframe;
