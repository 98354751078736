import 'angular-modal-service';
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    'angularModalService',
    require('common/modals/confirmation/confirm-action.modal.service.js').name
]).service('defineInterestModal', ['programBooleanLogicService', 'ModalService', 'confirmAction', 'util', 'notificator', function (pblService, ModalService, confirmAction, util, notificator) {
    const thisService = this;
    this.showModal = showModal;

    this.deleteBooleanLogic = (interest, programName, close = null) => {
      const interestName = interest.display || interest.text;
      const deleteMessage = "Are you sure you want to remove\nseed refinement '" + interestName + "' from current program?";
      return confirmAction.getConfirmation(deleteMessage).then(deleteModal =>
        deleteModal.close.then(deleteConfirm => {
          if (deleteConfirm) {
            return pblService.delete(interest.id, interest.p_id).then(res => {
              notificator.success({body: interestName + ' was removed from program ' + programName});
              close && close({deleted: true})
            }).catch(err => {
              if (!err.cancelDelete) notificator.success({body: 'Failed to delete ' + interestName});
              close && close(interest)
            });

          }
        })
      )
    }
    function showModal(current_terms, interest, title, set_dirty, program) {
        return ModalService.showModal({
            template: require('./define-interest.html'),
            inputs: {interest: interest || {}, current_terms: current_terms, title: title || 'Define an interest', set_dirty: set_dirty, program: program},
            controller: ['$scope', 'close', 'context', 'interest', 'current_terms', 'title', 'set_dirty', 'program', '$state', 'SIGNS', 'abiPermissions', DefineInterestModalController]
        });
    }

    function DefineInterestModalController($scope, close, context, interest, current_terms, title, set_dirty, program, $state, SIGNS, abiPermissions) {
        if (set_dirty) {
            $scope.editForm.$setDirty();
        }

        const allowedDiscoveryAppsForExclusionsLimit = $state.is('discovery.bubbles') || $state.is('discovery.streams');
        const allowedInsightsAppsForExclusionsLimit = $state.is('insights.consumption') || $state.is('insights.topic') || $state.is('insights.channels') || $state.is('insights.geo');
        $scope.exclusionsLimit = abiPermissions.hasPermission('exclusion refinement extension') &&
            (allowedDiscoveryAppsForExclusionsLimit || allowedInsightsAppsForExclusionsLimit) ? 70 : 20;
        $scope.EDITED_SIGN = SIGNS.EDITED;
        $scope.POST_SIGN = SIGNS.POST;

        $scope.title = title;
        $scope.current_terms_names = _.map(current_terms, 'text').map(function (t) {
            return t.toLowerCase()
        });

        $scope.arePostsDisabled = function () {
            return $state.includes('*.association') || $state.includes('*.landscape');
        };


        $scope.program = program || context.program;
        $scope.current_program_bl = _.map($scope.program.boolean_logics, 'text').map(function (bl) {
            return bl.toLowerCase()
        });

        $scope.interest = angular.copy(interest);
        if ($scope.interest && $scope.interest.p_id) {
            $scope.interest.saveToProgram = true;
            $scope.current_program_bl.splice($scope.current_program_bl.indexOf($scope.interest.text.toLocaleLowerCase()), 1);
        }

        $scope.clear = function () {
            $scope.interest.required = null;
            $scope.interest.included = null;
            $scope.interest.excluded = null;

            var editForm = $scope.editForm;
            editForm.includedPhrases.$setValidity("noInterests", true);
            editForm.requiredPhrases.$setValidity("noInterests", true);
            editForm.$setValidity("uniqueInterests", true);
            editForm.text.$setValidity("uniqSeedName", true);
            editForm.text.$setValidity("required", true);
        };

        $scope.isValid = function () {
            var editForm = $scope.editForm;
            self.interestLength = ($scope.interest.required || []).length + ($scope.interest.included || []).length;
            var hasOneInterest = self.interestLength > 0;
            editForm.includedPhrases.$setValidity("noInterests", hasOneInterest);
            editForm.requiredPhrases.$setValidity("noInterests", hasOneInterest);
            var all_terms = $scope.interest ? _.map(($scope.interest.required || []).concat($scope.interest.included || []).concat($scope.interest.excluded || []), "text") : [];
            var uniqInterests = all_terms.length == _.uniq(all_terms).length;
            editForm.$setValidity("uniqueInterests", uniqInterests);
            if ($scope.interest && $scope.interest.text) $scope.interest.text = $scope.interest.text.trim();
            var isValid = !$scope.interest || !$scope.interest.text || $scope.current_terms_names.indexOf($scope.interest.text.toLocaleLowerCase()) == -1;
            editForm.text.$setValidity("uniqSeedName", isValid && isUniqueProgramBooleanLogicName());
            editForm.text.$setValidity("required", !!$scope.interest && !!$scope.interest.text && $scope.interest.text.length > 0);
            var isBooleanUnique = isUnique();
            editForm.text.$setValidity("uniqueBoolean", isBooleanUnique);
            return editForm.$valid;
        };

        function isUniqueProgramBooleanLogicName() {
            if (!$scope.interest.saveToProgram) return true;
            return !$scope.interest || !$scope.interest.text || $scope.current_program_bl.indexOf($scope.interest.text.toLocaleLowerCase()) == -1;
        }

        $scope.createInterest = function (interest) {
            if(!interest.display){
                notificator.error({body: "Please fill 'Seed name'."});
                return;
            }

            interest.text = util.getTermTextByDisplay(interest.display);

            if ($scope.isValid()) {
                let promise = Promise.resolve(interest);
                if ($scope.interest.saveToProgram) {
                    interest.p_id = $scope.program.id;
                    interest.type = 'programBL';
                    let isUpdate = false;
                    if (_.isUndefined(interest.id) || interest.id == -1 || interest.id.indexOf('bl.') == -1) {
                      interest.id = -1;
                      promise = pblService.create(interest)
                    } else {
                      isUpdate = true;
                      promise = pblService.update(interest.id, interest, interest.p_id);
                    }

                    promise.then(savedInterest => {
                      notificator.success({body: (savedInterest.display || savedInterest.text) + ' was saved successfully to current program'});
                      interest.id = savedInterest.id;
                      return interest
                    }).catch(err => {
                      if (err && !err.cancelDelete) {
                        notificator.error({body: err.error});
                      }
                      if (!isUpdate) {
                        interest.type = 'booleanLogic';
                        interest.p_id = null;
                      }

                      return Promise.reject(interest);
                    });
                } else {
                    interest.id = c.generateUUID();
                }

                return promise.then(() => close(interest))
            }
        };

        $scope.deleteFromProgram = function () {
            thisService.deleteBooleanLogic($scope.interest, $scope.program.name, close)
        };

        $scope.isProgramBL = function () {
            return $scope.interest && $scope.interest.p_id;
        };

        $scope.close = function () {
            close(false);
        };

        function isUniqueBooleans(booleans, interestRequired, interestIncluded, interestExcluded) {
            return !booleans.some((term) => {
                const thisRequired = _.map(_.map((term.required || []), 'text'), toLowerCase);
                const thisIncluded = _.map(_.map((term.included || []), 'text'), toLowerCase);
                const thisExcluded = _.map(_.map((term.excluded || []), 'text'), toLowerCase);

                return _.xor(thisRequired, interestRequired).length === 0
                    && _.xor(thisIncluded, interestIncluded).length === 0
                    && _.xor(thisExcluded, interestExcluded).length === 0;
            });
        }

        function isUniqueTerms(terms, interestRequired, interestIncluded, interestExcluded) {
            const allInterestTypesEmpty = _.isEmpty(interestRequired) && _.isEmpty(interestIncluded) && _.isEmpty(interestExcluded);
            const hasInterestExcluded = !_.isEmpty(interestExcluded);
            if (allInterestTypesEmpty || hasInterestExcluded) return true;

            return terms.every((term) => {
                const interestTerm = interestRequired.concat(interestIncluded);
                return _.difference(interestTerm, [term]).length !== 0;
            });
        }

        function isUnique() {
            const booleans = _.filter(current_terms, (o) => ['booleanLogic', 'programBL'].includes(o.type));
            const terms = _.map(_.map(_.filter(current_terms, (o) => o.type === 'term'), 'text'), toLowerCase);
            const interestRequired = _.map(_.map(($scope.interest.required || []), 'text'), toLowerCase);
            const interestIncluded = _.map(_.map(($scope.interest.included || []), 'text'), toLowerCase);
            const interestExcluded = _.map(_.map(($scope.interest.excluded || []), 'text'), toLowerCase);

            return isUniqueBooleans(booleans, interestRequired, interestIncluded, interestExcluded)
                && isUniqueTerms(terms, interestRequired, interestIncluded, interestExcluded);
        }

        function toLowerCase(s) {
            return s.toLowerCase();
        }
    }
}]).directive('ensureAtLeastOneInterest', [function () {
    return {
        require: 'ngModel',
        link: function (scope, ele, attrs) {
            scope.$watch(attrs.ngModel, function () {
                var hasOneInterest = scope.interest && ((scope.interest.required || []).length > 0 || (scope.interest.included || []).length > 0);
                scope.editForm.includedPhrases.$setValidity("noInterests", hasOneInterest);
                scope.editForm.requiredPhrases.$setValidity("noInterests", hasOneInterest);
            });
        }
    };
}]).directive('ensureUniqInterests', [function () {
    return {
        require: 'ngModel',
        link: function (scope, ele, attrs) {
            scope.$watch(attrs.ngModel, function () {
                var all_terms = scope.interest ? _.map((scope.interest.required || []).concat(scope.interest.included || []).concat(scope.interest.excluded || []), "text") : [];
                var uniqInterests = all_terms.length == _.uniq(all_terms).length;
                scope.editForm.$setValidity("uniqueInterests", uniqInterests);
            });
        }
    };
}]).directive('ensureUniqSeedName', [function () {
    return {
        require: 'ngModel',
        link: function (scope, ele, attrs) {
            scope.$watch(attrs.ngModel, function () {
                var isValid = !scope.interest || !scope.interest.text || scope.current_terms.indexOf(scope.interest.text.toLocaleLowerCase()) == -1;
                scope.editForm.text.$setValidity("uniqSeedName", isValid);
            });
        }
    };
}]);
