module.exports = angular.module(__filename, [
    require("../../am-switch-button/am-switch-button").name,
    require("../../../data/audience-skew-service").name
]).directive("bubblesFilterBar", ['$window', 'filtersPartition', 'audienceSkewService', function ($window, filtersPartition, audienceSkewService) {
    return {
        restrict: "E",
        scope: {
            bubblesChannels: "=",
            getCustomAudienceSegments: "=",
            program: "="
        },
        template: require("./bubbles-filter-bar.html"),
        compile: function () {
            return {
                pre: function ($scope) {
                    $scope.lifestyle = {};
                    const lifestyles = audienceSkewService.getAudienceLifeStyle(filtersPartition);

                    $scope.openExclusions = () => {
                        $scope.$root.$broadcast('bubbles-open-exclusions');
                    };

                    $scope.checkWidth = () => ($window.innerWidth >= 1280);

                    $scope.isLifestyleVisible = function () {
                        return !_.isEmpty($scope.customSegments) && ['articles', 'sg_telco', 'au_telco'].includes(this.bubblesChannels.value);
                    };

                    $scope.$watch('program', function (program) {
                        if (!program) return;
                
                        $scope.getCustomAudienceSegments(program, lifestyles, $scope.bubblesChannels.value).then((segments) => {
                            $scope.customSegments = segments;
                        });
                    });

                    $scope.$watch('bubblesChannels', function (newChannel) {
                        if (!$scope.isLifestyleVisible() && $scope.lifestyle && $scope.lifestyle[0]) {
                          $scope.lifestyle = null;
                        } else if (['articles', 'sg_telco', 'au_telco'].includes($scope.bubblesChannels.value) && !$scope.lifestyle) {
                          $scope.lifestyle = {};
                        }

                        $scope.getCustomAudienceSegments($scope.program, lifestyles, newChannel.value).then((segments) => {
                            $scope.customSegments = segments;
                        });
                    });

                    const unregisterBubblesShowVelocityToggle = $scope.$root.$on('bubbles-show-velocity-toggle', function(event, isShowVelocity) {
                        $scope.$apply();
                        $scope.onLifestyleChange(null);
                    });

                    $scope.onLifestyleChange = (lifestyle) => {
                        $scope.lifestyle = lifestyle;
                        $scope.$root.$broadcast('bubbles-on-lifestyle-change', lifestyle);
                    };

                    $scope.lifestyleSummaryTextBuilder = (selectedValues) => {
                        if (_.isEmpty(selectedValues[0])) return 'Select Audience';
                        return selectedValues[0]['label'];
                    };

                    $scope.$on('$destroy', function() {
                        unregisterBubblesShowVelocityToggle();
                    });
                }
            }
        }


    }
}]);
