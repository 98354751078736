import React from 'react';
import PropTypes from 'prop-types';
import './BaseModal.scss';
import classNames from 'classnames';
import ClickableIcon from '../../ClickableIcon/ClickableIcon';
import WindowEventListener from '../../WindowEventListener/WindowEventListener';
import Spinner from '../../Spinner/Spinner';

class BaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalOnKeyDown = this.handleModalOnKeyDown.bind(this);
  }

  handleModalOnKeyDown(e) {
    const { onEscKeyPress, onEnterKeyPress, isDisabledEnterKeyPress } = this.props;
    if (onEscKeyPress && (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27)) onEscKeyPress(e);
    if (!isDisabledEnterKeyPress && onEnterKeyPress && (e.key === 'Enter' || e.keyCode === 13)) onEnterKeyPress(e);
  }

  render() {
    const {
      isOpen,
      modalTitle,
      width,
      height,
      minHeight,
      marginTop,
      titleMargin,
      onXClick,
      isXDisabled,
      showSpinner,
      children,
      isLightTheme,
      containerPadding,
    } = this.props;
    if (!isOpen) return null;
    const maxHeightCalc = marginTop ? `calc(80% - ${marginTop})` : '80%';

    return (
      <div className="base-modal-component" style={{ backgroundColor: !showSpinner && 'rgba(0, 0, 0, 0.65)' }}>
        <WindowEventListener events="keydown" eventHandlerFunction={this.handleModalOnKeyDown} />
        <Spinner show={showSpinner}></Spinner>
        <div
          className={classNames('base-modal-container', { white: isLightTheme })}
          style={{
            width: width,
            height: height,
            padding: containerPadding,
            marginTop: marginTop,
            maxHeight: maxHeightCalc,
            minHeight: minHeight,
          }}
        >
          <div
            className={classNames('base-modal-header', { 'has-title': modalTitle })}
            style={{ margin: modalTitle && titleMargin }}
          >
            {modalTitle && (
              <div className="title" style={{ paddingRight: onXClick ? '25px' : 0 }}>
                {modalTitle}
              </div>
            )}
            {onXClick && (
              <ClickableIcon
                iconId="x"
                width="12px"
                height="12px"
                className="close-icon"
                onClick={onXClick}
                isDisabled={isXDisabled}
              />
            )}
          </div>
          {children}
        </div>
      </div>
    );
  }
}

BaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.string,
  titleMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEscKeyPress: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  isDisabledEnterKeyPress: PropTypes.bool,
  onXClick: PropTypes.func,
  isXDisabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  children: PropTypes.element.isRequired,
  isLightTheme: PropTypes.bool,
};

BaseModal.defaultProps = {
  minHeight: '220px',
  titleMargin: '0 0 30px 0',
  containerPadding: '30px',
  isDisabledEnterKeyPress: false,
  isXDisabled: false,
  showSpinner: false,
  isLightTheme: false,
};

export default BaseModal;
