import config from 'infra/config';
import common from 'infra/utils/common';
var date = require("infra/utils/date");

var STREAMS_API = config.SEARCH_API + "/streams";
var STREAMS_HAS_MORE_API = config.SEARCH_API + "/streams?disable_notification=1";
var STREAMS_NEXT_PAGE_API = config.SEARCH_API + "/streams_next_page";
var STREAMS_DELETE_SCROLL_API = config.SEARCH_API + "/streams_delete_scroll?disable_notification=1";
var STREAMS_DOCS_COUNT = config.SEARCH_API + "/streams_docs_count";
var SCORE_FUNCTIONS_MAX_RESULTS = {
    published_date_boost: 2.6999999999999997,
    content_type_boost: 10.1,
    relevancy: 7.93680489,
    volume_boost: 16,
    fb_likes_boost: 18.686610575761343,
    date_volumes_boost: 1,
    view_count_boost: 21.59160179142042,
    'user.verified_boost': 1.1,
    optional_keywords_boost: 16,
    rt_count_boost: 17.32546558587249,
    video_score: 314.758158394,
    tweet_score: 70,
    article_score: 44019.891702774
};

module.exports = angular.module(__filename, [])
    .service("streamsService", ["$http", "$q", '$state', 'context', 'util', 'abiPermissions', 'geoService', '$rootScope', function ($http, $q, $state, context, util, permissions, geoService, $rootScope) {
        var NUM_OF_ELEM_IN_PAGE = 50;
        var lastSortBy = '';

        const parseTimeframe = (timeframe) => {
          let start_tf, end_tf;
          if (date.isCustomTimeframe(timeframe)) {
              timeframe = date.getTimeframe(timeframe, true);
              start_tf = timeframe.start.format();
              end_tf = timeframe.end.format()
          } else {
              start_tf = (timeframe[1] != 'hour') ? moment().subtract(timeframe[0], timeframe[1]) : moment().subtract(timeframe[0] * 3, timeframe[1]);
              if (timeframe[1] != 'hour') start_tf = start_tf.startOf('day');
              start_tf = start_tf.format();
          }

          return {start_tf, end_tf};
        }

        var getStreams = function (scroll_ids, params, size, ids_only, no_scroll, additional_fields) {
            params.sort_by = params.sort_by || lastSortBy;
            lastSortBy = params.sort_by;
            // var start_tf, end_tf, timeframe = params.timeframe;
            // if (date.isCustomTimeframe(timeframe)) {
            //     timeframe = date.getTimeframe(timeframe, true);
            //     start_tf = timeframe.start.format();
            //     end_tf = timeframe.end.format()
            // } else {
            //     start_tf = (timeframe[1] != 'hour') ? moment().subtract(timeframe[0], timeframe[1]) : moment().subtract(timeframe[0] * 3, timeframe[1]);
            //     if (timeframe[1] != 'hour') start_tf = start_tf.startOf('day');
            //     start_tf = start_tf.format();
            // }

            const { start_tf, end_tf } = parseTimeframe(params.timeframe);

            var canceller = $q.defer();
            var cancel = function () {
                canceller.resolve('AMOBEE_CANCEL_OK')
            };

            var streamsApi = ids_only ? STREAMS_HAS_MORE_API : STREAMS_API;

            var promise = $http.post(streamsApi,
                {
                    timeframe_start: start_tf,
                    timeframe_end: end_tf,
                    "phrases": params.phrases,
                    "boolean_logics": params.boolean_logic_phrases,
                    kwd_ids: params.terms_ids,
                    "excluded_phrases": params.excluded_phrases,
                    "types": params.channels,
                    "article_types": params.article_types,
                    "topics": params.topics,
                    "geos": geoService.geosForChannel(params.geos, $state, context, "id"),
                    "sub_geos": context.current.discoveryAllSubGeosSelected ? [] : geoService.createRequestSubGeoParam(geoService.subGeosForChannel(params.sub_geos, $state, context)),
                    language: context.current._language_mold.getLanguage($state, context).value,
                    "audience": params.audience,
                    sensitive_content: params.sensitive_content,
                    blend: params.blend,
                    sort_by: params.sort_by,
                    test_mode: params.test_mode,
                    "trending_topics": params.trending_topics,
                    "audience_mappings": params.audience_mappings,
                    program_sources: params.program_sources,
                    sg_telco_data: permissions.hasPermission('sg telco channel'),
                    au_telco_data: permissions.hasPermission('au telco channel'),
                    size: size || NUM_OF_ELEM_IN_PAGE,
                    ids_only: ids_only,
                    no_scroll: no_scroll,
                    "additional_fields": additional_fields || [],
                    scroll_ids: scroll_ids,
                    user_first_party_audience: params.user_first_party_audience
                },

                {timeout: canceller.promise}

            ).then(function (res) {
                if (params.test_mode) {
                    handleScoreBreakdown(res.data);
                }
                res.data.topicsDistribution = util.getTopicsDistribution(res.data.topics);

                return res.data;

            }, function (err) {
                console.error('Error when getting streams', err);
                return false;
            });

            return {
                promise: promise,
                cancel: cancel
            }
        };

        var getStreamsNextPage = function (scroll_ids, page, params) {
            var canceller = $q.defer();
            var cancel = function () {
                canceller.resolve('AMOBEE_CANCEL_OK');
            };

            const {start_tf, end_tf} = parseTimeframe(params.timeframe);
            var promise = $http.post(STREAMS_NEXT_PAGE_API,
                {
                    scroll_ids: scroll_ids,
                    page: page,
                    "phrases": params.phrases, // for kwd matching
                    timeframe_start: start_tf,
                    timeframe_end: end_tf,
                    program_sources: params.program_sources, // for kwd matching
                    "boolean_logics": params.boolean_logic_phrases, // for kwd matching
                    kwd_ids: params.terms_ids,
                    blend: params.blend,
                    sort_by: params.sort_by,
                    test_mode: params.test_mode,
                    language: context.current._language_mold.getLanguage($state, context).value,
                    size: NUM_OF_ELEM_IN_PAGE
                },

                {timeout: canceller.promise}

            ).then(function (res) {
                if (params.test_mode) {
                    handleScoreBreakdown(res.data);
                }

                return res.data;

            }, function (err) {
                console.error('Error when getting streams', err);
                return false;
            });

            return {
                promise: promise,
                cancel: cancel
            }
        };

        var getStreamsHasMoreItems = function (scroll_ids, params, current_ids, is_last_page) {
            var defer = $q.defer();
            var promise = getStreams(scroll_ids, params, current_ids.length * 2, true, true);

            promise.promise.then(function (res) {
                if (!res) return false; // in case of error - return false (=new items are not available)
                if (res.has_newer_items == undefined) {
                    var newDocs = is_last_page ? res.docs : res.docs.slice(0, res.docs.indexOf(_.last(current_ids)));
                    var hasMoreItems = _.difference(newDocs, current_ids).length > 0;
                    defer.resolve(hasMoreItems)
                } else {
                    defer.resolve(res.has_newer_items)
                }
            }, function (err) {
                console.error('Error when getting streams items for has more items', err);
                return false;
            });
            return {
                promise: defer.promise,
                cancel: promise.cancel
            }
        };

        var deleteScroll = function (scroll_ids, language) {
            if (!scroll_ids || !Object.keys(scroll_ids).length) return;

            $http.get(STREAMS_DELETE_SCROLL_API, {
                params: {
                    "scroll_ids": scroll_ids,
                    "language": language || "en"
                }
            });
        };

        var handleScoreBreakdown = function (data) {
            data.docs.forEach(function (doc) {

                if (doc.type == "article" || doc.type == "tweet") {
                    var boostKeys = Object.keys(doc).filter(function (key) {
                        return key.endsWith('_boost') && doc[key] != null
                    });
                    boostKeys.unshift("score");

                    if (doc.type == 'tweet') {
                        var optionalKeywordsBoost = "script_0_boost";
                        doc["optional_keywords_boost"] = doc[optionalKeywordsBoost];
                        boostKeys.push("optional_keywords_boost");
                        boostKeys = _.without(boostKeys, optionalKeywordsBoost);
                    }

                    doc.boostKeys = boostKeys;

                    //Normalize to 100% each boost field.
                    boostKeys.forEach(function (key) {
                        var keyToNormalize = key == 'score' ? "score_max" : key;
                        if (doc[key + "_max"] != null) {
                            doc[key + "_normal"] = doc[key] / doc[key + "_max"] * 100;
                        }
                    });
                } else {
                    var boostKeys = Object.keys(doc).filter(function (key) {
                        return key.includes('_boost') && doc[key] != null
                    });
                    boostKeys.unshift("score");

                    doc.relevancy = boostKeys.reduce(function (prev, next, index, element) {
                        prev = isNaN(prev) ? doc[prev] : prev;
                        return prev / doc[next];
                    });
                    boostKeys.splice(1, 0, "relevancy");

                    if (doc.type == 'tweet') {
                        var optionalKeywordsBoost = "script_0_boost";
                        doc["optional_keywords_boost"] = doc[optionalKeywordsBoost];
                        boostKeys.push("optional_keywords_boost");
                        boostKeys = _.without(boostKeys, optionalKeywordsBoost);
                    }

                    doc.boostKeys = boostKeys;

                    //Normalize to 100% each boost field.
                    boostKeys.forEach(function (key) {
                        var keyToNormalize = key == 'score' ? doc.type + "_score" : key;
                        if (SCORE_FUNCTIONS_MAX_RESULTS[keyToNormalize] != null) {
                            doc[key + "_normal"] = doc[key] / SCORE_FUNCTIONS_MAX_RESULTS[keyToNormalize] * 100;
                        }
                    });
                }
            });
        };

        function getDocsCount(params) {
            var canceller = $q.defer();
            var cancel = function () {
                canceller.resolve('AMOBEE_CANCEL_OK')
            };

            var promise = $http.get(STREAMS_DOCS_COUNT, {
                timeout: canceller.promise,
                params: {
                    program_sources: _(params.programSources).map('sources').flatten().groupBy('channel').mapValues(v => _.map(v, 'id')).value()
                }
            }).then(function (res) {
                return res.data;
            }, function (err) {
                console.error('Error when getting streams docs count', err);
                return false;
            });

            return {
                promise: promise,
                cancel: cancel
            }
        }

        function getChannels(channels, sources = []) {
            var streamsChannels = _.isEmpty(channels) && _.isEmpty(sources) ? _.chain($rootScope.streamsChannelsFilter).reduce((res, channel) => {
                return res.concat(channel.children ? channel.children : channel);
            }, []) : _.chain(channels);
            return streamsChannels.reject('custom').reject(['value', 'dummy_parent']).map('value').value();
        }

        return {
            streams: getStreams,
            streamsNextPage: getStreamsNextPage,
            streamsDeleteScroll: deleteScroll,
            hasMoreItems: getStreamsHasMoreItems,
            streamsDocCount: getDocsCount,
            getChannels: getChannels
        }
    }]
);
