module.exports = angular.module(__filename, [
    require('common/am-range-slider.drv/am-range-slider.drv').name
]).service('demographicsModal', ['ModalService', 'abiPermissions', function (ModalService, abiPermissions) {
    this.showModal = showModal;

    function showModal (demographics, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./demographics-modal.html'),
            inputs: {demographics: demographics, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params},
            controller: ['$scope', 'close', 'filtersPartition', 'demographics', 'isNewSegment', 'disableGeo', 'channel', 'params', DemographicsModalCtrl]
        });
    }

    function DemographicsModalCtrl ($scope, close, filtersPartition, demographics, isNewSegment, disableGeo, channel, params) {
        const SG_CHANNELS = ['snbb', 'data_spark'];
        const US = $scope.Geos.geos.find(geo => geo && geo.id === '840');
        const SG = $scope.Geos.geos.find(geo => geo && geo.id === '702');
        const AU = $scope.Geos.geos.find(geo => geo && geo.id === '036');

        const ALL_GEOS = {label: 'All Geos', value: 'all', id: -1};

        $scope.isSgChannel = () => SG_CHANNELS.includes(channel);
        $scope.isNewSegment = isNewSegment;
        $scope.demographics = _.extend({gender: null, age: null, income: null, children: null, geo: ALL_GEOS}, demographics);
        $scope.demographics.geo = $scope.demographics.geo && $scope.demographics.geo.length ? $scope.demographics.geo[0] : ALL_GEOS;
        $scope.demographics.ethnicity = $scope.demographics.ethnicity || [];
        if (channel === 'au_telco') setGeo(AU);
        if ($scope.isSgChannel()) {
            setGeo(SG);
            $scope.demographics.ethnicitySG = $scope.demographics.ethnicity;
        } else $scope.demographics.ethnicityUS = $scope.demographics.ethnicity;
        $scope.disableGeo = $scope.isSgChannel() || channel === 'au_telco' || disableGeo;
        $scope.showEthnicity = ($scope.isSgChannel() && params.abiPermissions.hasPermission("sg telco ethnicity")) ||
                               (params.abiPermissions.hasPermission("geos.US") && !_.includes(['au_telco', 'data_spark'], channel));
        $scope.showIncome = !$scope.isSgChannel() && channel !== 'au_telco' && params.abiPermissions.hasPermission("geos.US");
        $scope.close = close;
        $scope.all_geos = [ALL_GEOS].concat($scope.Geos.geos.map(geo => ({...geo, value: geo["id"]})));
        $scope.shouldResetIncome = false;
        $scope.onGeoChange = (newGeo) => setGeo(newGeo);
        $scope.onSGEthnicityChange = (newEthnicities) => { $scope.demographics.ethnicitySG = newEthnicities};
        $scope.onUSEthnicityChange = (newEthnicities) => { $scope.demographics.ethnicityUS = newEthnicities};

        $scope.all_incomes = [{value: 0, label: "$0K"}, {value: 25, label: "$25K"}, {value: 50, label: "$50K"},
            {value: 75, label: "$75K"}, {value: 100, label: "$100K"}, {value: 150, label: "$150K"},
            {value: 200, label: "$200K"}, {value: 250, label: "Over $200K"}];
        let income_from_index = ($scope.demographics.income || []).length ? convert_income_to_index($scope.demographics.income[0]) : 0;
        $scope.income_from = $scope.all_incomes[income_from_index];
        let income_to_index = ($scope.demographics.income || []).length ? convert_income_to_index($scope.demographics.income[$scope.demographics.income.length - 1]) + 1 : $scope.all_incomes.length - 1;
        $scope.income_to = $scope.all_incomes[income_to_index];
        $scope.demographics.income = filtersPartition.newIncome.slice(income_from_index, income_to_index);

        $scope.onIncomeSelected = function (from, to) {
            let income_from_index = _.findIndex($scope.all_incomes, {value: from.value}) || 0;
            let income_to_index = _.findIndex($scope.all_incomes, {value: to.value}) || $scope.all_incomes.length - 1;
            $scope.demographics.income = filtersPartition.newIncome.slice(income_from_index, income_to_index);
            $scope.income_from = $scope.all_incomes[income_from_index];
            $scope.income_to = $scope.all_incomes[income_to_index];
            $scope.$apply();
        };

        $scope.$watch("demographics.ethnicityUS", function () {
            if (!allEthnicitiesSelected($scope.demographics.ethnicityUS) && $scope.demographics.geo && $scope.demographics.geo.id === ALL_GEOS.id) setGeo(US);
        });

        $scope.$watch("demographics.income", function () {
            if ($scope.demographics.income.length !== filtersPartition.newIncome.length && $scope.demographics.geo && ALL_GEOS && $scope.demographics.geo.id === ALL_GEOS.id) setGeo(US);
        });

        $scope.$watch("demographics.geo", function () {
            if ($scope.demographics.geo && US && $scope.demographics.geo.id !== US.id) {
                $scope.demographics.ethnicityUS = [];
                $scope.shouldResetIncome = !$scope.shouldResetIncome;
                $scope.demographics.income = filtersPartition.newIncome.slice(0, filtersPartition.newIncome.length);
            }
        });

        function convert_income_to_index (income) {
            return _.map(filtersPartition.newIncome, 'value').findIndex((value) => _.isEqual(value, income.value));
        }

        $scope.iconToLabelMap = {};
        filtersPartition.genders.forEach(g => { $scope.iconToLabelMap[g["icon"]] = _.capitalize(g["audienceLabel"]) });

        $scope.isUsSelected = function () {
            return this.demographics.geo && (this.demographics.geo.id === US.id || this.demographics.geo.id === ALL_GEOS.id);
        };

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.demographics))
        };

        function setGeo (geo) {
            $scope.demographics.geo = geo && geo.id === ALL_GEOS.id ? geo : { ...geo, value: geo.id };
        }

        function allEthnicitiesSelected (ethnicity) {
            if(ethnicity) return ethnicity.length === 0 || ethnicity.length === ($scope.isSgChannel ? filtersPartition.sgEthnicity : filtersPartition.races).length;
        }

        function removeEmptyFilters (demographics) {
            let results = {};
            if ((demographics.gender || []).length > 0) results.gender = demographics.gender;
            if ((demographics.age || []).length > 0 && (demographics.age || []).length < filtersPartition.age.length) results.age = demographics.age;
            if ((demographics.income || []).length < $scope.all_incomes.length - 1) results.income = demographics.income;
            if ((demographics.children || []).length > 0) results.children = demographics.children;
            if (demographics.geo && demographics.geo.id !== ALL_GEOS.id) results.geo = [demographics.geo];
            if (demographics.ethnicity && !allEthnicitiesSelected(demographics.ethnicity)) results.ethnicity = demographics.ethnicity;
            return results;
        }

        $scope.save = function () {
            $scope.demographics.ethnicity = $scope.isSgChannel() ? $scope.demographics.ethnicitySG : $scope.demographics.ethnicityUS;
            close(_.extend(removeEmptyFilters($scope.demographics), {type: "demographics", operand: this.demographics.operand}));
        }
    }
}]);
