import React from 'react';
import './EstimatedReachWidget.scss';
import PropTypes from 'prop-types';
import Widget from '../../../../common/Widget/Widget';
import { formatNumberToUnits } from '../../../../../utils/NumberUtils';

const EstimatedReachWidget = ({ getReachNumber, params, reachType, title }) => (
  <div className="estimated-reach-component">
    <span className="estimated-reach-label">{title}: </span>
    <div className="reach">
      <Widget
        name="EstimatedReachWidget"
        dataService={getReachNumber}
        dataServiceParams={params}
        renderOnValueChange={params}
        onRender={(reachNumber) => {
          const reach = `${formatNumberToUnits(reachNumber, 3)} ${reachType}`;
          return <span>{reach}</span>;
        }}
        isSmallSpinner={true}
        style={{ width: '155px' }}
      />
    </div>
  </div>
);

EstimatedReachWidget.propTypes = {
  getReachNumber: PropTypes.func.isRequired,
  params: PropTypes.array.isRequired,
  reachType: PropTypes.string,
  title: PropTypes.string,
};

EstimatedReachWidget.defaultProps = {
  title: 'Estimated reach',
};

export default EstimatedReachWidget;
