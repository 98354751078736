"use strict";
import ComplianceModal from 'react/components/apps/channelInsights/ComplianceModal/ComplianceModal';
import { react2angular } from 'react2angular';
import { getFirstPartyDataByProgram } from '../../react/services/AudienceInsightsService';
import ToggleSwitch from 'react/components/common/ToggleSwitch/ToggleSwitch';
import * as MixpanelDiscovery from '../../react/infra/mixpanel/MixpanelDiscovery';

var customFilter = require("infra/context/filters/custom-filter");
var c = require("infra/utils/common");


var discoveryModule = angular.module(__filename, [
    require("widgets/bubbles-widget/bubbles-widget").name,
    require("widgets/streams-widget/streams-widget").name,
    require("data/discovery-export-service").name,
    require("data/stream-export-service").name,
    require("infra/context/filters/channels-mold").name,
    require("infra/context/filters/bubblesChannels-mold").name,
    require("infra/context/filters/articleType-mold").name,
    require("infra/context/filters/language-mold").name,
    require('./grid.discovery.prediction').name,
    require('pages/discovery/grid.queries').name,
    require("pages/programs/program_settings/program-settings").name,
    require("infra/user_access/abi-permissions").name,
    require("common/bubbles/bubbles-filter-bar/bubbles-filter-bar").name,
    require("common/bubbles/bubbles-actions-bar/bubbles-actions-bar").name,
    require('pages/discovery/target-forecast.drv.js').name
]);


discoveryModule.component('toggleSwitch', react2angular(ToggleSwitch));
discoveryModule.component('complianceModal', react2angular(ComplianceModal, ['phrasesByTab', 'topics', 'text', 'isOpen' ,'saveWithNonCompliant', 'saveWithoutNonCompliant', 'onCloseCompliance']));

DiscoveryController.$inject = ["$scope", "$rootScope", "$http", "$state", "$window", "$timeout", "programService", "ModalService",
                               "context", "filtersPartition", "streamsService", "abiPermissions", "audienceSkewService", "audienceMgmt"];
function DiscoveryController(scope, $rootScope, $http, $state, $window, $timeout, programService, ModalService,
                             context, filtersPartition, streamsService, permissions, audienceSkewService, audienceMgmt) {

    scope.$state = $state;
    scope.context = context;
    scope.refreshAudienceSummary = 0;
    scope.tFires = false;

    $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    const listener = $rootScope.$on('$stateChangeSuccess', ()=> {
        $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    });

    //events
    scope.keyDown = ($event) => scope.checkKey($event, true);
    scope.keyUp = ($event) => scope.checkKey($event, false);
    scope.checkKey = ($event, down)=>{
        //shift = if combined with click, un/mark bubbles

        let shiftPressed = $event.keyCode == 16;
        if(shiftPressed){
            scope.shiftDown = down;
        }
        //esc = unmark all bubbles
        let escPressed = $event.keyCode == 27;
        if(escPressed && down){
            $.each($("#balls g, .brick"), (ind, item)=>{

                item = $(item);
                let cls = item.attr("class");
                if(cls){ //no $ as svg doesnt support $.removeClass
                    item.attr("class", cls.replace("shift-marked", '').trim());
                }
            });
        }
    };

    $window.addEventListener('keydown', scope.keyDown);
    $window.addEventListener('keyup', scope.keyUp);
    scope.$on('$destroy', ()=>{
        $window.removeEventListener("keydown", scope.keyDown);
        $window.removeEventListener("keyup", scope.keyUp);
        listener();
    });

    scope.isSupportLanguage = function () {
        context.current._language_mold.languageSelectRefreshHandler($state, context);
        return context.current._language_mold.isSupportLanguage($state, context);
    };

    scope.shouldShowTopics = function () {
        return !scope.isFacebookDemoUser() && context.current._language_mold.shouldShowTopics($state, context);
    };

    scope.supportedLanguages = function(){
        return context.current._language_mold.supportedLanguages($state, context);
    };

    scope.editSources = function () {
        programService.program(context.program.id).then(function (program) {
            ModalService.showModal({
                template: require('pages/programs/program_settings/program-settings.html'),
                inputs: {program: program, initialView: 'connect sources'},
                controller: 'programSettingsController'
            });
        }, function () {
            console.log("failed Edit");
            notificator.error({body: "Edit canceled! Could not load program, please check internet connection"});
        });
    };

    var closeListener = context.onChange(MixpanelDiscovery.trackDiscoverySearch);
    scope.$on('$destroy', closeListener);
    scope.audienceSummaryOrder = _.compact(_.map(filtersPartition.genders.concat(filtersPartition.children).concat(filtersPartition.age)
        .concat(filtersPartition.income).concat(filtersPartition.racesWithAll), "summary"));

    scope.trackFilterChange = function () {
        MixpanelDiscovery.trackDiscoverySearchChange();
    };

    scope.gridAdvancedSummary = function () {
        return customFilter.summary('gridAdvanced', context.current.adv);
    };

    scope.gridAdvancedTitles = function (text) {
        return customFilter.gridAdvanced.titles[text];
    };

    scope.gridAdvancedShow = function () {
        return $state.includes('discovery.grid') && context.current.adv && Object.keys(context.current.adv).length;
    };

    scope.discoveryGeos = function () {
        var geos = $rootScope.Geos.serverValue();
        return $rootScope.Geos.geosForChannel(geos, $state, context);
    };

    scope.showAudienceFilter = function () {
        if(false == context.current._language_mold.isShowAudience($state, context)){
            return false;
        }

        if (scope.isDemoUser()) return false;
        var channels = c.getChannels($state, context);
        if ($state.is('discovery.streams') && (context.current.showCustomSources || (channels.includes('sg_telco') && (channels.length > 1)))) return false;

        var channel_sources = _.groupBy(channels, c.getSource);
        var shouldDisplayAudience = _.size(channel_sources) == 1 && _(['articles', 'sg_telco']).includes(_(channel_sources).keys().first());
        var hasSgTelcoAudiencePermission = permissions.hasPermission('sg telco audience filters');
        if (channel_sources['sg_telco']) shouldDisplayAudience = shouldDisplayAudience && hasSgTelcoAudiencePermission;
        return shouldDisplayAudience;
    };

    scope.hideGeoFilter = function () {
        if (scope.isDemoUser()) return true;
        var showCustomSources = ($state.is('discovery.streams') && context.current.showCustomSources) || ($state.is('discovery.bubbles') && context.current.bubblesShowCustomSources);
        return showCustomSources || scope.showSubGeos();
    };

    scope.showSubGeos = function () {
        var channels = c.getChannels($state, context);
        return $rootScope.Geos.showSubGeos(channels) &&
          !(_.includes(channels, 'sg_telco') && !permissions.hasPermission('SG Telco Electoral Filter'));
    };

    scope.showMediaTypeFilter = function () {
        return !scope.isFacebookDemoUser() && $state.is('discovery.streams') && (!context.current.showCustomSources && !scope.hideMediaFilter);
    };

    scope.unsocialState = function () {
        if ($state.is('discovery.bubbles') || $state.includes('discovery.grid')) {
            var channels = c.getChannels($state, context);
            var noTweets = channels.indexOf('tweets') == -1;
            var noFacebook = channels.indexOf('facebook') == -1;
            return noTweets && noFacebook;
        }

        if ($state.is('discovery.streams')) {
            var mappingField = context.current.showCustomSources ? 'type' : 'value';
            return context.current.channels.length > 0 ? _.intersection(_.map(context.current.channels, mappingField), ['tweets','facebook']).length == 0 : false;
        }
    };

    scope.getCustomAudienceSegments = async function(program, lifestyles, channel) {
        if (!program || !program.id) return;

        const audienceChannel = channel === 'sg_telco' ? 'data_spark' : channel;
        const audienceByChannel = await audienceMgmt.list(audienceChannel);
        const lifestylesSegments = (channel === 'articles') ? lifestyles : [];
        const audienceCustomSegments = audienceSkewService.getAudienceCustomSegments(audienceByChannel);
        let firstPartyAudienceSegments = [];

        if (permissions.hasPermission('first party segments') && channel === 'articles') {
            const firstPartyDataByProgram = await getFirstPartyDataByProgram(program);
            firstPartyAudienceSegments = firstPartyDataByProgram.map(s => ({
                label: s.label,
                value: s.value,
                segment: [{type: '1st party', value: s.value, operand: {value: 'and'}}]
            }));
        }

        return _.sortBy(audienceCustomSegments.concat(firstPartyAudienceSegments).concat(lifestylesSegments), s => s.label.toLowerCase());
    };

    scope.getChannelAllSelectedText = function() {
        if((scope.user || {}).id === 1029) return 'video';
        if((scope.isFacebookDemoUser())) return 'facebook';
        return 'all channels';
    };

    scope.isDemoUser = function() {
        return (scope.user || {}).id === 1029 || scope.isFacebookDemoUser();
    };

    scope.isFacebookDemoUser = function () {
        return (scope.user || {}).email === "test.abi.fb@gmail.com";
    };

    scope.$watch('context.current._gridChannels_mold._value', channelChanged);
    scope.$watch('context.current._channels_mold._value', channelChanged);
    scope.$watch('context.current._bubblesChannels_mold._value', channelChanged);
    scope.$watch("context.current._discoverySubGeos_mold._value", (newValue, oldValue) => {
      if(!_.isEqual(newValue, oldValue)) {
        context.current.discoveryAllSubGeosSelected = scope.dataTrees.subGeos.isAllChecked();
        scope.trackFilterChange();
      }
    });

    function channelChanged() {
            //media types
            var streamsChannels = streamsService.getChannels(context.current.channels);
            var mediaTypes = _.flatten(Object.values(_.pick(filtersPartition.mediaTypes, streamsChannels)));
            var intersectionFilters = _.uniqBy(mediaTypes, 'value');

            context.current._articleType_mold.replace(_.intersectionBy(context.current.articleType, intersectionFilters, 'value'));

            // Show the filters only when there is more then 1 type for each channel
            scope.hideMediaFilter = streamsChannels.length >= mediaTypes.length;
            scope.mediaTypes = intersectionFilters;
            scope.channels = c.getChannels($state, scope.context);

            $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    }
}

var bubbles = require('./bubbles.discovery.js').stateConfig;
var streams = require('./streams.discovery.js').stateConfig;
var grid = require('./grid.discovery.js').stateConfig;

discoveryModule.stateConfig = {
    name: "discovery",
    url: "/discovery",
    template: require("./discovery.html"),
    display: "Discovery",
    abstract: true,
    reload: false,
    context: {},
    data: {
        permissions: ["discovery"]
    },
    children: [bubbles, streams, grid],
    controller: DiscoveryController,
    controllerAs: "discoveryCtrl"
};

export default discoveryModule;
