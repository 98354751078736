export const isTimeframeHidden = (stateName, stateDisplay) => {
    return stateDisplay.toLowerCase() === 'dashboard'
        || stateName.startsWith('audience-')
        || stateName.startsWith('settings.')
        || stateName.startsWith('alerts.')
        || (stateName.toLowerCase() === 'warroom.realtime' && localStorage["warroom_mode"] != 'custom')
        || stateName.includes('warroom.groups')
        || stateName.includes('warroom.trending');
} 

export const showDatePicker = (stateName, stateDisplay, hasPermission)  => {
    return (stateDisplay.toLowerCase() === 'discovery' && hasPermission(['discovery custom timeframe']))
    || stateDisplay.toLowerCase() === 'insights'
    || stateDisplay.toLowerCase() === 'real time'
    || stateName.toLowerCase() === 'warroom.realtime';
}