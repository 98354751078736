import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getGenres: () => ({
    url: `${HOST}/tv/genres`,
  }),
  getNetworks: (channel) => ({
    url: `${HOST}/tv/networks/${channel}`,
  }),
  getShows: (channel) => ({
    url: `${HOST}/tv/shows/${channel}`,
  }),
  getCommercials: (channel) => ({
    url: `${HOST}/tv/commercials/${channel}`,
  }),
  getNetworkIdToNameMap: (channel) => ({
    url: `${HOST}/tv/network_id_to_name_map/${channel}`,
  }),
  getShowsMetadataByids: (ids, channel) => ({
    url: `${HOST}/tv/shows_by_ids/${channel}`,
    payload: { ids },
  }),
  getNielsenNetworksMap: () => ({
    url: `${HOST}/tv/nielsen_networks_map`,
  }),
  getNetworksShows: (networkIds, channel) => ({
    url: `${HOST}/tv/networks_shows/${channel}`,
    payload: { network_ids: networkIds },
  }),
};
