module.exports = angular.module(__filename, []).service('linkedinIndustriesModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(industries, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./linkedin-industries-modal.html'),
            inputs: { industries: industries, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params },
            controller: ['$scope', 'close', 'industries', 'isNewSegment', 'disableGeo', 'channel', 'params', LinkedinIndustriesModalCtrl]
        });
    }

    function LinkedinIndustriesModalCtrl($scope, close, industries, isNewSegment, disableGeo, channel, params) {
        $scope.isNewSegment = industries == null;
        $scope.industries = _.extend({industries: null}, industries);
        $scope.close = close;
        $scope.linkedInPromise = params.linkedinMetaDataPromise.then(data => {
            $scope.industriesData = data.industries;
        });

        function removeEmptyFilters(industries) {
            let results = {};
            if (!_.isEmpty(industries.industries)) {
                results.industries = industries.industries;
            }
            
            return results;
        }

        function industryChange(industry) {
            if (_.isEmpty(industry)) {
                $scope.industries.industries = [];
            }
        }

        $scope.onIndustryError = function(msg) {
            console.log(msg);
        };

        $scope.onIndustrySelect = (industry) => {
            $scope.industries.industries = [_.pick(industry, ['label', 'value'])];
        };

        $scope.onIndustryChange = (industry) => {
            industryChange(industry);
        };

        $scope.isValid = () => {
            return !_.isEmpty(removeEmptyFilters($scope.industries));
        };

        $scope.save = () => {
            close(_.extend(removeEmptyFilters($scope.industries), {type: 'linkedinIndustries', operand: $scope.industries.operand}));
        };
    }
}]);
