module.exports = angular.module(__filename, [
    require('common/radiogroup-slider/radiogroup-slider').name
]).service('interestsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(interests, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./interests-modal.html'),
            inputs: {interests: interests, isNewSegment: isNewSegment, channel: channel, params: params},
            controller: ['$scope', 'close', 'interests', 'TermsMold', 'isNewSegment', 'channel',  'params', InterestsModalCtrl]
        });
    }

    function InterestsModalCtrl($scope, close, interests, TermsMold, isNewSegment, channel, params) {
        $scope.isNewSegment = interests == null;
        $scope.interests = _.extend({required: null, included: null, excluded: null}, interests);
        $scope.close = close;
        $scope.termsMold = new TermsMold();
        $scope.debugLimit = params.debugUser && channel === 'au_telco';

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.interests));
        };

        function removeEmptyFilters(interests) {
            var results = {};
            if ((interests.required || []).length > 0) results.required = interests.required;
            if ((interests.included || []).length > 0) results.included = interests.included;
            if ((interests.excluded || []).length > 0) results.excluded = interests.excluded;
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters(this.interests), {
                type: "interests",
                levelOfIntent: this.interests.levelOfIntent,
                operand: this.interests.operand
            }));
        }
    }
}]);
