/* multi level table

to use:
=======

1. scope.dataTrees = {
    some-tree: {
        checkedArray: [{id: id1}, {id: id2}, {id: id3}]

        children: [
          {id:..., name:...},
          {id:..., name:...},
          {id:..., name:..., children:{...}}
        ]
    }
}

if page has several trees, please place them all in
scope.dataTrees{
 some-tree:{...},
 another-tree:{...}
}

if the dataTree is part of the side filters menu, add customFilter so the filter can be reset
some-tree:{customFilter: true}

then do <quick-tree tree-name="some-tree"></quick-tree> can add class="white"

2. then, scope.dataTrees.some-tree.show()

3. page should have scope.dataTrees.some-tree.saveChecked(checkedArray)
   tree will call it with debounce

4. optional scope.dataTrees params:
   * allSelectedLabel
   * showSearch - default=false
   
5. optional node params:
  * type
  * selectable - default=true
  *


*/


var tmpl = require("./quick-tree.html"),
    default_debounce = 1000;

module.exports = angular.module(__filename, []).directive("quickTree", ['$timeout', '$sce', function ($timeout, $sce) {
    return {
        restrict: 'E',
        template: tmpl,
        scope: true,
        
        link: function (scope, elem, attrs, ngModel) {

            if (attrs.treeName && !_.isEmpty(scope.dataTrees)) {
                scope.dataTree = scope.dataTrees[attrs.treeName];
                scope.dataTree.id = attrs.treeName;
            }

            scope.initLeafs = function() {
              var checkedHash = {};
              (scope.dataTree.checkedArray||[]).forEach((checked) => checkedHash[checked.id] = checked);

              var initLeaf = function(leaf, parent) {
                parent = parent || {id: null};
                leaf.parentID = parent.id;
                leaf.shown = true;

                var checkedVal = checkedHash[leaf.id];

                leaf.checked = (parent.checked  === "checked" || checkedVal) ? "checked" : "";
                
                if (checkedVal) {
                  _.extend(checkedVal, leaf);
                }

                if (leaf.children) {
                  leaf.children.forEach((child) => initLeaf(child, leaf));
                } else {
                  scope.checkParentState(leaf);
                }
              };

              initLeaf(scope.dataTree, null);
            };

            scope.clickLeaf = function(leaf, checked){
                leaf.checked = checked;
                (leaf.children || []).forEach((child) => scope.clickLeaf(child, checked));
                scope.checkParentState(leaf);
            };

            const clickLeafParentSingleSelect = function(leaf, checked) {
              if (leaf.parentID === scope.dataTree.id) {
                //top level of sub tree clicked, select sub tree that was clicked and unselect other sub trees
                scope.dataTree.children.forEach((child) => {
                  let checked_val = child.id === leaf.id ? checked : '';
                  scope.clickLeaf(child, checked_val);
                });
              } else if (leaf.hasOwnProperty('treeKey')) {
                  scope.dataTree.children.forEach((child) => {
                    //uncheck children from other sub trees
                    if (child.id !== leaf.treeKey) scope.clickLeaf(child, '');
                  });
                  scope.clickLeaf(leaf, checked);
                  if (scope.dataTree.numOfChecked === 0) {
                    scope.dataTree.children.forEach((child) => {
                      //check all children from this sub tree
                      if (child.id === leaf.treeKey) scope.clickLeaf(child, 'checked');
                    });
                  }
              } else if (!leaf.parentID && leaf.id === scope.dataTree.id) {
                //reset to first sub tree
                scope.dataTree.children.forEach((child, i) => {
                  if (i === 0) return scope.clickLeaf(child, 'checked');
                  scope.clickLeaf(child, '');
                });
              }
            };

            scope.clickLeafs = function(leaf, checked) {
                scope.dataTree.isParentSingleSelect ? clickLeafParentSingleSelect(leaf, checked) : scope.clickLeaf(leaf, checked);
                scope.genCheckedArray();
                scope.genSummaryStr();

                //clear search
                var prevSearch = scope.searchStr;
                scope.searchStr = '';
                if(prevSearch){
                  scope.search();
                }
                
                //save
                $timeout.cancel(scope.dataTree.clickTimer);
                scope.dataTree.clickTimer = $timeout(function() {
                  scope.dataTree.saveChecked(scope.dataTree.checkedArray.slice(0));
                }, scope.dataTree.debounce || default_debounce);
                
            };

            scope.openCloseLeafs = function(leaf, opened){  
                leaf.opened = opened;
                leaf.shown = true;
                (leaf.children || []).forEach((child) => scope.openCloseLeafs(child, opened));
            };

            scope.search = function(){
              scope.searchStr = scope.searchStr.toLowerCase();

              var leaf = scope.dataTree.children.length > 1 ? 
                  scope.dataTree : scope.dataTree.children[0];

              if(scope.searchStr.length >= 2){
                scope.searchLeafs(leaf);
              } else {
                leaf.shown = true;
                (leaf.children || []).forEach((child) => scope.openCloseLeafs(child, false));
              }
            };



            scope.searchLeafs = function(leaf){
                var leafHasString = leaf.name.toLowerCase().includes(scope.searchStr);
                leaf.shown = leafHasString;
                leaf.opened = leaf.shown;

                (leaf.children || []).forEach((child) => scope.searchLeafs(child));

                if(leafHasString){
                  scope.showParent(leaf);
                }
            };


            scope.showParent = function(leaf){
              var parent = scope.getParent(leaf);

              if(parent && parent.name){
                parent.shown = true;
                parent.opened = true;
                scope.showParent(parent);
              }
            };

            scope.checkParentState = function(leaf){

              //if only some children are checked, checkbox class = "semi"
              //if none are checked, uncheck the parent too

              if (!leaf.parentID){
                return;
              }

              var parent = scope.getParent(leaf);
              if (!parent.children){
                  scope.checkParentState(parent);
                  return;
              }

              parent.numOfChecked = 0;

              parent.children.forEach(function(child){
                if(child.checked == 'checked'){
                  parent.numOfChecked++;
                }
                else if(child.checked == 'semi'){
                  parent.numOfChecked += 0.5;
                }
              });

              parent.checked =
                parent.numOfChecked == parent.children.length ? 'checked' :
                  parent.numOfChecked == 0 ? '' : 'semi';

              scope.checkParentState(parent);
            };


            scope.getParent = (leaf) => scope.getLeafParent(scope.dataTree, leaf.parentID);

            scope.getLeafParent = function(leaf, parentID){
               if(!parentID){
                 return null;
               } else if(leaf.id == parentID){
                 return leaf;
               } else if (leaf.children){
                 var i, parent = null, num_children = leaf.children.length;
                 for (i=0; i < num_children; i++) {
                   parent = scope.getLeafParent(leaf.children[i], parentID);
                   if (parent){
                     break;
                   }
                 }
                 return parent;
               }
               return null;
            };

            scope.markSearch = (text) => $sce.trustAsHtml(scope.searchStr ?
                text.replace(new RegExp(scope.searchStr, 'gi'), '<span class="checked">$&</span>') : text);

            function isLeafChecked(leaf){
                return leaf.checked !== undefined && leaf.checked != '';
            }
            
            scope.genCheckedArray = function(){
              var checkedArray = [];
              genCheckedLeaf(scope.dataTree);

              function genCheckedLeaf(leaf){
                if(leaf.checked === 'checked' && leaf.name){
                  checkedArray.push({id: leaf.id, type: leaf.type, name: leaf.name});
                }
                (leaf.children||[]).forEach(genCheckedLeaf);
              }

              scope.dataTree.checkedArray = checkedArray;
            };

            scope.genSummaryStr = function(){

                //check if all are selected / not selected
                var all = true, checkedClass = null;
                
                //check which chlidren to search
                var kids = scope.dataTree.children  || [];
                let children, allSelectedLabel;
                const checkedSubTree = _.find(kids, (o) => _.isMatch(o, { checked: 'checked' }));

                if (scope.dataTree.isParentSingleSelect && checkedSubTree) {
                  children = checkedSubTree.children;
                  allSelectedLabel = checkedSubTree.name;
                } else {
                  children = kids.length == 1 && kids[0].children.length ? kids[0].children : kids;
                  allSelectedLabel = scope.dataTree.allSelectedLabel || 'All';
                }

                children.forEach(function(child){

                  if(!all) return;

                  if(null==checkedClass){
                    checkedClass = child.checked;
                  }

                  if(child.checked != checkedClass && (isLeafChecked(child) || isLeafChecked({checked: checkedClass}))){
                    all = false;
                  }
                });

                if(all){
                  scope.summaryStr = allSelectedLabel;
                  scope.dataTree.summaryStr = scope.summaryStr;
                  return;
                }

                var str = '', leaf= '', len = 0, MAX_SHOWN = 4;
                var max_items = scope.dataTree.maxSummaryItems || MAX_SHOWN;

                scope.dataTree.checkedArray.forEach(function(leaf){
                    if(leaf && leaf.name){
                        if(len < max_items){
                            str += leaf.name+', ';
                        }
                        len++;
                    }
                });
                str = str.substring(0, str.length-2) + ( len > max_items ? " and " + (len - max_items) + " other" : "");

                scope.summaryStr = str;
                scope.dataTree.summaryStr = str;
            };



            // public methods

            scope.dataTree.clickAll = function(checkedAll){
              if(undefined == checkedAll){

                checkedAll = 'checked';

                if(scope.dataTree.checkedAll=='checked' ||
                  scope.dataTree.children[0].checked){
                  checkedAll = '';
                }
              }

              scope.clickLeafs(scope.dataTree, checkedAll);
            };

            scope.dataTree.isAllChecked = function(){
              
              var children = scope.dataTree.children || [];
              if(scope.dataTree.children && scope.dataTree.children.length == 1){
                children = scope.dataTree.children[0].children;
              }

              var checked = null, all = true;
              children.forEach(function(child){
                if(checked == null){
                  checked = child.checked;
                }else{
                  if(child.checked != checked){
                    all = false;
                  }
                }
              });

              return all;
            };

            scope.dataTree.show = function(){

              //init & show tree
              scope.searchStr = '';
              scope.summaryStr = '';
              scope.dataTree.name = scope.dataTree.name || '';
              scope.dataTree.checkedArray = scope.dataTree.checkedArray || [];
              scope.dataTree.clickTimer = $timeout(function() {});

              if(scope.dataTree.children.length === 1){
                scope.dataTree.children[0].opened = true;
              }
              
              scope.initLeafs();
              scope.genSummaryStr();
            }
        }          
    }
}]);
