let silentReAuthenticationService;
let logoutAuthenticationService;
let showContactUsService;
let stateService;
let ssoService;
let openNewVersionModalService;
let displayErrorBannerIfNeededService;
let displayCustomErrorService;
let channelService;
let dspService;
let context;
let firstPartyService;
let abiPermissionsService;
let geoService;
let abiKeywordsService;
let audienceSkewService;
let utilService;
let uploadService;

export const getDspService = () => dspService;
export const getSilentReAuthenticationService = () => silentReAuthenticationService;
export const logout = (isHardLogout) => logoutAuthenticationService(isHardLogout);
export const showContactUs = () => showContactUsService();
export const openNewVersionModal = () => openNewVersionModalService();
export const redirectToLoginPage = () => window.location.replace('/login');
export const openHelpPage = (page) =>
  window.open(`http://help.intelligence.amobee.com/The_Dashboard.html#t=${page}.htm`, '_blank');
export const goToState = (state, params) => stateService.go(state, params);
// TODO: add test for this added function
export const setContext = (contextData) => (context = contextData);
export const setChannelService = (channel) => (channelService = channel);
export const setDspService = (dsp) => (dspService = dsp);
export const getContext = () => context;
export const getChannelName = (channel) => channelService && channelService.name(channel);
export const getStateName = () => stateService && (stateService.current.displayTab || stateService.current.display);
export const getStateCurrent = () => stateService && stateService.current;
export const getSsoAccessToken = () => ssoService && ssoService.getAccessToken();
export const getSsoLoginDataObj = () => (ssoService && ssoService.getLoginDataObj()) || {};
export const setSsoLoginDataObj = (loginData) => ssoService && ssoService.setLoginDataObj(loginData);
export const setSilentReAuthenticationService = (angularSilentReAuthenticationService) =>
  (silentReAuthenticationService = angularSilentReAuthenticationService);
export const setLogoutAuthenticationService = (angularLogoutAuthenticationService) =>
  (logoutAuthenticationService = angularLogoutAuthenticationService);
export const setShowContactUsService = (angularShowContactUsService) =>
  (showContactUsService = angularShowContactUsService);
export const setStateService = (angularStateService) => (stateService = angularStateService);
export const setSsoServiceService = (angularSsoServiceService) => (ssoService = angularSsoServiceService);
export const setOpenNewVersionModalService = (angularOpenNewVersionModalService) =>
  (openNewVersionModalService = angularOpenNewVersionModalService);
export const setDisplayErrorBannerIfNeededService = (angularDisplayErrorBannerIfNeededService) =>
  (displayErrorBannerIfNeededService = angularDisplayErrorBannerIfNeededService);
export const displayErrorBannerIfNeeded = (error) => displayErrorBannerIfNeededService(error);
export const setDisplayCustomErrorService = (angularDisplayCustomErrorService) =>
  (displayCustomErrorService = angularDisplayCustomErrorService);
export const displayCustomError = (error) => displayCustomErrorService(error);
export const setFirstPartyService = (angularMgmtFirstPartyService) =>
  (firstPartyService = angularMgmtFirstPartyService);
export const getFirstPartyService = () => firstPartyService;
export const setAbiPermissionsService = (angularAbiPermissionsService) =>
  (abiPermissionsService = angularAbiPermissionsService);
export const getAbiPermissionsService = () => abiPermissionsService;
export const setGeoService = (angularGeoService) => (geoService = angularGeoService);
export const getGeoService = () => geoService;
export const setKeywordsService = (angularKeywordsService) => (abiKeywordsService = angularKeywordsService);
export const getKeywordsService = () => abiKeywordsService;
export const setAudienceSkewService = (angularAudienceSkewService) =>
  (audienceSkewService = angularAudienceSkewService);
export const getAudienceSkewService = () => audienceSkewService;
export const setUtilService = (angularUtilService) => (utilService = angularUtilService);
export const getIsMySegmentsProgram = (program) => utilService.isMySegmentsProgram(program);
export const setUploadService = (angularUploadService) => (uploadService = angularUploadService);
export const getUploadService = () => uploadService;
