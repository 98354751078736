import config from 'infra/config';

var SEARCH_API = config.SEARCH_API + '/new_related_articles?disable_notification=1';
var SEARCH_TWEETS_API = config.SEARCH_API + '/related_tweets?disable_notification=1';

module.exports = angular.module(__filename, [])
    .service('newsService', ['$http', 'geoService', 'notificator', 'errorMgmt', '$q', 'topicsTree', 'abiPermissions',
        function ($http, geoService, notificator, errorMgmt, $q, topicsTree, permissions) {
            var errorCounter = 1;

            // API //
            this.get = getRelatedArticles;
            this.getTweets = getRelatedTweets;
            ////////

            function getRelatedArticles({ terms, results_per_term, geos, kwd_ids, timeframe, brand_name }) {
                return $http({
                    url: SEARCH_API,
                    method: 'GET',
                    cache: true,
                    params: Object.assign({
                        "phrases[]": terms, // array of phrases
                        "cc_filter[]": _.map(geoService.serverValue(geos), 'id'),
                        sensitive_content: topicsTree.isSensitive,
                        results_per_term,
                        kwd_ids,
                        brand_name
                    }, {timeframe_start: timeframe.start, timeframe_end: timeframe.end})
                }).then(responseSucess, articlesResponseFailed);
            }

            function getRelatedTweets(terms, num_results) {
                var defer = $q.defer();
                var cancel = function () {
                    defer.resolve('AMOBEE_CANCEL_OK');
                };
                var promise = $http({
                    url: SEARCH_TWEETS_API,
                    method: 'GET',
                    timeout: defer.promise,
                    //cache: true,
                    params: {
                        "terms[]": terms, // array of phrases
                        results_per_term: num_results,
                        sensitive_content: topicsTree.isSensitive
                    }
                }).then(tweetsResponseSuccess, tweetsResponseFailed);
                return {
                    promise: promise,
                    cancel: cancel
                };
            }

            function responseSucess(res) {
                return res.data
            }

            function tweetsResponseSuccess(res) {
                errorCounter = 0;
                return res.data
            }

            function articlesResponseFailed(err) {
                errorMgmt.widgetServiceError('Recent Articles', err);
                return []
            }

            function tweetsResponseFailed(err) {
                var errorReason = "canceled";
                if (err.status > 0) {
                    console.log(" *** tweetsResponseFailed error counter", errorCounter);
                    errorCounter++;
                    errorMgmt.widgetServiceError('Recent Articles Live', err);
                    console.log("tweetsResponseFailed error counter", errorCounter);
                    errorReason = errorCounter;
                }

                return $q.reject(errorReason);
            }
        }
    ]
);
