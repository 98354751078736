import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import tippy from 'tippy.js';
import { omit, extend } from 'lodash';
import 'tippy.js/dist/tippy.css';
import './Tooltip.scss';

const DEFAULT_TIPPY_OPTIONS = {
  delay: [90, 200],
  duration: 90,
  a11y: false,
  animation: 'fade',
  arrow: true,
  placement: 'top',
  theme: 'default-tooltip',
  updateDuration: 0,
  maxWidth: '280px',
};

tippy.setDefaults({
  onShow: () => {
    //hide poppers with duration of 0 so they don't leave a trail
    [...document.querySelectorAll('.tippy-popper')].forEach((popper) => {
      popper._tippy.hide(0);
    });
  },
});

const Tooltip = ({ options, content, children }) => {
  const tippyProps = omit(extend({}, DEFAULT_TIPPY_OPTIONS, options), ['content', 'children']);

  return !content ? (
    children
  ) : (
    <Tippy content={content} {...tippyProps}>
      {children}
    </Tippy>
  );
};

Tooltip.propTypes = {
  options: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element.isRequired,
};

Tooltip.defaultProps = {
  options: {},
};

export default Tooltip;
