import config from 'infra/config';
import 'satellizer';
let openNewVersionModal;
import { setOpenNewVersionModalService } from '../../react/middleware/AngularExportedMiddleware';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon';

module.exports = angular.module(__filename, [
    'satellizer',
    require('./authentication').name
]).service("openNewVersionModal", ['ModalService','$window', function(ModalService,$window){
    var isModalOpen = false;

    openNewVersionModal = () => {
        if (isModalOpen) return false;
        isModalOpen = true;
        ModalService.showModal({
            template: require('./new-version.html'),
            controller: ['$scope', function ($scope) {
                $scope.reload = function () {
                $window.location.reload();
                }
            }]
        });
    }

    setOpenNewVersionModalService(openNewVersionModal);
}])
.config(["$authProvider", function ($authProvider) {
    $authProvider.loginUrl = config.AUTHENTICATION_API + '/login';
    $authProvider.signupUrl = config.AUTHENTICATION_API + '/signup';
    $authProvider.tokenPrefix = 'access';
    $authProvider.tokenType = '';
}]).constant("authenticationConfig", {
    doAfterAuthentication: []
}).service("reauthenticateInterceptor", ["$injector", function ($injector) {
    let isModalAlreadyOpen = false;
    return {
        'response': function (response) {
          return $injector.invoke(['$q', function ($q) {
            var returnValue = response;
            if (response.status === 205 && !isModalAlreadyOpen) {
                isModalAlreadyOpen = true;
                openNewVersionModal()
                returnValue = $q.reject(response);
            }

            return returnValue;
          }]);
        },
        'responseError': function (response) {
            var isRequestCancelled = function isRequestCancelled(error){
                return error.config.timeout &&
                        error.config.timeout.$$state &&
                        error.config.timeout.$$state.value == "AMOBEE_CANCEL_OK";
            }
            return $injector.invoke(["$q", '$state', "$http", "$window", "authentication", 'ssoService', function ($q, $state, $http, $window, authentication, ssoService) {
                if (!isRequestCancelled(response)) {
                  console.log('Response Error. status ' + response.status + ' for url: ' + response.config.url, response);
                }

                // Do not try to login if:
                // 1. response is not 401
                // 2. request sent without authentication header
                // 3. current state is login
                // 4. request is to reAuthentication (/login)
                if (response.status !== 401 || response.config.skipAuthorization || $window.location.pathname.indexOf('login') !== -1) return $q.reject(response);
                if (response.config.url.split('/').reverse()[0] === 'login') return $q.reject(response);

                MixpanelCommon.sendToMixpanel("401 Unauthorized", { 'requested': response.config.url });
                return authentication.silentReAuthentication(response.config.url).then(function (data) {
                    return $http(response.config);
                }).catch(function (err) {
                    // Couldn't re-auth
                    console.log(err);
                    ssoService.redirectToLogin($window.location.hash, false);
                    return $q.reject(response);
                });
            }]);
        }
    };
}]).config(["$httpProvider", function ($httpProvider) {
    $httpProvider.interceptors.push('reauthenticateInterceptor');
}]);
