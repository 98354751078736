module.exports = angular.module(__filename, [
    require('common/am-range-slider.drv/am-range-slider.drv').name
]).service('linearTvDemographicsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(demographics, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./linear-tv-demographics-modal.html'),
            inputs: { demographics: demographics, isNewSegment: isNewSegment, disableGeo: disableGeo, channel: channel, params: params },
            controller: ['$timeout', '$scope', 'close', 'filtersPartition', 'demographics', 'isNewSegment', 'disableGeo', 'channel', 'params', linearTvDemographicsModalCtrl]
        });
    }

    function linearTvDemographicsModalCtrl($timeout, $scope, close, filtersPartition, demographics, isNewSegment, disableGeo, channel, params) {
        const ALL_GEOS = {label: 'All Geos', value: 'all', id: -1};
        const ALL_ETHNICITIES = { label: 'All Ethnicities', value: 'all' };
        const US = $scope.Geos.geos.find(geo => geo.id === '840');
        US.value = 840;

        $scope.isNewSegment = isNewSegment;
        $scope.close = close;
        $scope.demographics = _.extend({ gender: null, age: null, income: null, geo: US, ethnicity: [] }, demographics);  
        $scope.geos = [US];
        $scope.ethnicities = [ALL_ETHNICITIES, {label: 'Hispanic', value: 'hispanic'}];

        $scope.onEthnicityChange = newEthnicy => { $scope.demographics.ethnicity = newEthnicy};
        $scope.onGeoChange = () => {};

        $scope.allIncomes = [{value: 0, label: "$0K"}, {value: 25, label: "$25K"}, {value: 50, label: "$50K"},
            {value: 75, label: "$75K"}, {value: 100, label: "$100K"}, {value: 150, label: "$150K"},
            {value: 200, label: "$200K"}, {value: 250, label: "Over $200K"}];
        let income_from_index = ($scope.demographics.income || []).length ? convertIncomeToIndex($scope.demographics.income[0]) : 0;
        $scope.incomeFrom = $scope.allIncomes[income_from_index];
        let income_to_index = ($scope.demographics.income || []).length ? convertIncomeToIndex($scope.demographics.income[$scope.demographics.income.length - 1]) + 1 : $scope.allIncomes.length - 1;
        $scope.incomeTo = $scope.allIncomes[income_to_index];
        $scope.demographics.income = filtersPartition.newIncome.slice(income_from_index, income_to_index);

        $scope.onIncomeSelected = function (from, to) {
            let income_from_index = _.findIndex($scope.allIncomes, {value: from.value}) || 0;
            let income_to_index = _.findIndex($scope.allIncomes, {value: to.value}) || $scope.allIncomes.length - 1;
            $scope.demographics.income = filtersPartition.newIncome.slice(income_from_index, income_to_index);
            $scope.incomeFrom = $scope.allIncomes[income_from_index];
            $scope.incomeTo = $scope.allIncomes[income_to_index];
            $scope.$apply();
        };

        function allEthnicitiesSelected (ethnicity) {	
            if(ethnicity) return ethnicity.length === 0 || ethnicity.length === filtersPartition.races.length;	
        }

        $scope.iconToLabelMap = {};
        filtersPartition.genders.forEach(g => { $scope.iconToLabelMap[g["icon"]] = _.capitalize(g["audienceLabel"]) });

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.demographics))
        };

        function convertIncomeToIndex (income) {
            return _.map(filtersPartition.newIncome, 'value').findIndex((value) => _.isEqual(value, income.value));
        }
        
        function removeEmptyFilters (demographics) {
            let results = {};
            if ((demographics.gender || []).length > 0) results.gender = demographics.gender;
            if ((demographics.age || []).length > 0 && (demographics.age || []).length < filtersPartition.age.length) results.age = demographics.age;
            if ((demographics.income || []).length < $scope.allIncomes.length - 1) results.income = demographics.income;
            if (demographics.geo !== ALL_GEOS) results.geo = demographics.geo;
            if (demographics.ethnicity && !allEthnicitiesSelected(demographics.ethnicity)) results.ethnicity = demographics.ethnicity;
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters($scope.demographics), {type: "linearTvDemographics", operand: this.demographics.operand}));
        }
    }
}]);
