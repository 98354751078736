import React from 'react';
import PropTypes from 'prop-types';
import './AudienceExplorerSecondaryBar.scss';
import Tooltip from '../../../../common/Tooltip/Tooltip';
import AppSecondaryBar from '../../../../common/AppNavigation/AppSecondaryBar/AppSecondaryBar';
import TabSelector from '../../../../common/TabSelector/TabSelector';

const AudienceExplorerSecondaryBar = ({
  isExportVisible,
  onExportClick,
  tabs,
  selectedTab,
  onTabSelect,
  onRefineAudienceClick,
}) => (
  <div className="audience-explorer-secondary-bar-component">
    <AppSecondaryBar onExportClick={onExportClick} isExportVisible={isExportVisible}>
      <React.Fragment>
        <Tooltip content="Refine Audience" options={{ placement: 'bottom-start' }}>
          <i className="refine-audience icon-chevron-left" onClick={onRefineAudienceClick}></i>
        </Tooltip>
        <TabSelector tabs={tabs} selectedTab={selectedTab} onSelect={onTabSelect}></TabSelector>
      </React.Fragment>
    </AppSecondaryBar>
  </div>
);

AudienceExplorerSecondaryBar.propTypes = {
  isExportVisible: PropTypes.bool,
  onExportClick: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  selectedTab: PropTypes.object.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  onRefineAudienceClick: PropTypes.func.isRequired,
};

export default AudienceExplorerSecondaryBar;
