import React from 'react';
import PropTypes from 'prop-types';
import './TopBar.scss';
import ClickableIcon from '../../ClickableIcon/ClickableIcon';
import Icon from '../../Icon/Icon';
import Separator from '../../Separator/Separator';
import classNames from 'classnames';

// "Powered by ..." commented but we keep the logic due to Kaban board task "Change Hamburger Menu and Global Header"
const TopBar = ({ appLabel, centerElement, rightElement, isHamburgerVisible, onHamburgerClick }) => (
  <div className={classNames('top-bar-component', { 'has-dsp-permissions': isHamburgerVisible })}>
    <div className="left-elements">
      {isHamburgerVisible && <ClickableIcon className="hamburger-icon" iconId="hamburger" onClick={onHamburgerClick} />}
      <div className="logos">
        <Icon className="amobee-logo-icon" iconId="amobee-logo"></Icon>
        <span className="amobee-app-label">{appLabel}</span>
        {/*{isHamburgerVisible && <span className="amobee-app-label-secondary">Powered by Brand Intelligence</span>}*/}
      </div>
    </div>

    <div className="center-elements">{centerElement}</div>

    <div className="right-elements">
      <Separator />
      {rightElement}
    </div>
  </div>
);

TopBar.propTypes = {
  appLabel: PropTypes.string.isRequired,
  centerElement: PropTypes.element,
  rightElement: PropTypes.element,
  onHamburgerClick: PropTypes.func.isRequired,
  isHamburgerVisible: PropTypes.bool.isRequired,
};

export default TopBar;
