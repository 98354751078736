import AbiManagementApi from '../api/AbiManagementApi';
import AudienceProfilerApi from '../api/AudienceProfilerApi';
import { executeHttpRequest } from '../infra/HttpClient';

export function getLinkedinSegmentDemographicsDistribution(audience, userId) {
  return executeHttpRequest(AbiManagementApi.getLinkedinSegmentDemographicsDistribution(audience, userId), {
    displayRedBannerOnError: false,
  });
}

export function getSegmentIds(audience) {
  return executeHttpRequest(AudienceProfilerApi.getSegmentIds(audience));
}

export function createAudienceTargetTaxonomy(segmentId, channel, marketId, advertiserId) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetTaxonomy(segmentId, channel, marketId, advertiserId));
}

export function createAudienceTargetUserList(segmentId, channel, ids) {
  return executeHttpRequest(AbiManagementApi.createAudienceTargetUserList(segmentId, channel, ids));
}

export function getAudienceTaxonomyCategory(segmentId) {
  return executeHttpRequest(AbiManagementApi.getAudienceTaxonomyCategory(segmentId));
}

export function createAmplifiedAudience(
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  amplifyThreshold,
  target,
  ssoAccessToken,
  userListQuery,
  geo,
  isFirstParty
) {
  return executeHttpRequest(
    AbiManagementApi.createAmplifiedAudience(
      segmentId,
      segmentName,
      channel,
      market,
      advertiser,
      amplifyThreshold,
      target,
      ssoAccessToken,
      userListQuery,
      geo,
      isFirstParty
    )
  );
}

export function createDeterministicAudience(
  segmentId,
  segmentName,
  channel,
  market,
  advertiser,
  ssoAccessToken,
  userListQuery
) {
  return executeHttpRequest(
    AbiManagementApi.createDeterministicAudience(
      segmentId,
      segmentName,
      channel,
      market,
      advertiser,
      ssoAccessToken,
      userListQuery
    )
  );
}

export function createAlwaysOnAudience(
  segmentId,
  segmentName,
  dataContractId,
  dataContractText,
  categoryId,
  amplifyThreshold,
  target,
  ssoAccessToken,
  userListQuery,
  geo
) {
  return executeHttpRequest(
    AbiManagementApi.createAlwaysOnAudience(
      segmentId,
      segmentName,
      dataContractId,
      dataContractText,
      categoryId,
      amplifyThreshold,
      target,
      ssoAccessToken,
      userListQuery,
      geo
    )
  );
}

export function createUserListForDeterministicAudience(
  channel,
  segmentId,
  market,
  advertiser,
  categoryId,
  dataContractId,
  dataProviderId,
  dspSegmentId,
  userListQuery,
  isDynamic,
  ssoAccessToken,
  audienceName
) {
  return executeHttpRequest(
    AbiManagementApi.createUserListForDeterministicAudience(
      channel,
      segmentId,
      market,
      advertiser,
      categoryId,
      dataContractId,
      dataProviderId,
      dspSegmentId,
      userListQuery,
      isDynamic,
      ssoAccessToken,
      audienceName
    )
  );
}

export function getAllGeos() {
  return executeHttpRequest(AbiManagementApi.getAllGeos());
}
