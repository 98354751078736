import React, { useCallback, useState } from 'react';
import './AudienceTarget.scss';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import SubmitButton from '../../../../common/SubmitButton/SubmitButton';
import Menu from '../../../../common/Menu/Menu';
import AudienceTargetModal from '../Modals/AudienceTargetModal/AudienceTargetModal';
import Spinner from 'react/components/common/Spinner/Spinner';
import * as Mixpanel from '../../../../../infra/mixpanel/Mixpanel';

const AudienceTarget = ({
  marketsAndAdvertisersPromise,
  audienceId,
  audienceName,
  audienceSegment,
  isActivateEnabled,
  isActivateAudienceEnabled,
  isActivateAudienceVisible,
  isActivateTargetEnabled,
  activateAudienceDisabledText,
  channel,
  getSegmentIds,
  createAmplifiedAudience,
  createAlwaysOnAudience,
  notificator,
  updateAudienceDeterministicActivated,
  updateAudienceAmplifiedActivated,
  updateAudienceAlwaysOnActivated,
  dspService,
  isAudienceDeterministicActivated,
  isAudienceAmplifiedActivated,
  isAudienceAlwaysOnActivated,
  activatedAmplifiedThreshold,
  activatedAlwaysOnThreshold,
  activatedMarket,
  activatedAdvertiser,
  activatedDataContractId,
  activatedDataContractText,
  activatedCategoryId,
  ssoService,
  getAmplifiedEstimatedReachGoal,
  createDeterministicAudience,
  createUserListForDeterministicAudience,
  handleActivatingAudience,
  handleActivatingTarget,
  widgetName,
  hasAmplificationModeSelector,
  getDataContract,
  getTaxonomyCategory,
}) => {
  const [isOpenAudienceTargetModal, setIsOpenAudienceTargetModal] = useState(false);
  const [isTargetModalProcessing, setIsTargetModalProcessing] = useState(false);
  const [isActivationMenuOpen, setIsActivationMenuOpen] = useState(false);
  const [audienceData, setAudienceData] = useState({ audienceId, audienceName });
  const [showSpinner, setShowSpinner] = useState(false);
  const beginingErrorMsg = "Something went wrong... We couldn't";
  const tryAgainMsg = 'Please try again later.';
  const successMsg = (segmentType, time) =>
    `<p>Your ${segmentType} segment was successfully activated and will be available for targeting within ${time}.</p><p>Visit the segments list table to see your segment.</p>`;

  const audienceTargetModalCustomSubmitHandler = useCallback(
    (selectedMarket, selectedAdvertiser, isDeterministic, isAmplified, amplifiedReachGoal, isDynamic) => {
      onTargetAudienceCustomSubmit(
        selectedMarket,
        selectedAdvertiser,
        isDeterministic,
        isAmplified,
        amplifiedReachGoal,
        isDynamic
      );
    },
    [isOpenAudienceTargetModal]
  );
  const audienceTargetModalAlwaysOnSubmitHandler = useCallback(
    (dataContractId, dataContractText, categoryId, amplifiedReachGoal) => {
      onTargetAudienceAlwaysOnSubmit(dataContractId, dataContractText, categoryId, amplifiedReachGoal);
    },
    [isOpenAudienceTargetModal]
  );

  const audienceTargetModalCancelHandler = useCallback(() => setIsOpenAudienceTargetModal(false), [
    isOpenAudienceTargetModal,
  ]);

  const onTargetAudienceCustomSubmit = useCallback(
    async (selectedMarket, selectedAdvertiser, isDeterministic, isAmplified, amplifiedReachGoal, isDynamic) => {
      setIsTargetModalProcessing(true);
      const { accessToken } = await ssoService.getAccessToken();
      await Promise.all([
        isDeterministic && targetUserListAudience(selectedMarket, selectedAdvertiser, accessToken, isDynamic),
        isAmplified && amplifyCustomAudience(selectedMarket, selectedAdvertiser, amplifiedReachGoal, accessToken),
      ]);
      setIsOpenAudienceTargetModal(false);
      setIsTargetModalProcessing(false);
    },
    [audienceData, audienceSegment, channel, notificator]
  );

  const onTargetAudienceAlwaysOnSubmit = useCallback(
    async (dataContractId, dataContractText, categoryId, amplifiedReachGoal) => {
      setIsTargetModalProcessing(true);
      const { accessToken } = await ssoService.getAccessToken();
      await amplifyAlwaysOnAudience(dataContractId, dataContractText, categoryId, amplifiedReachGoal, accessToken);
      setIsOpenAudienceTargetModal(false);
      setIsTargetModalProcessing(false);
    },
    [audienceData, audienceSegment, notificator]
  );

  const amplifyCustomAudience = useCallback(
    async (selectedMarket, selectedAdvertiser, amplifiedReachGoal, ssoAccessToken) => {
      try {
        await createAmplifiedAudience(
          audienceSegment,
          audienceData.audienceId,
          audienceData.audienceName,
          channel,
          selectedMarket,
          selectedAdvertiser,
          amplifiedReachGoal.value,
          ssoAccessToken,
          channel === 'smart_tv' || channel === 'gracenote'
        );
        notificator.success({ body: successMsg('amplified', '72 hours') });
        updateAudienceAmplifiedActivated(selectedMarket, selectedAdvertiser, amplifiedReachGoal.value);
      } catch (error) {
        notificator.error({
          body: `${beginingErrorMsg} amplify the audience "${audienceData.audienceName}". ${tryAgainMsg}`,
        });
      }
    },
    [audienceData, audienceSegment, channel, notificator, ssoService]
  );

  const amplifyAlwaysOnAudience = useCallback(
    async (dataContractId, dataContractText, categoryId, alwaysOnReachGoal, ssoAccessToken) => {
      try {
        await createAlwaysOnAudience(
          audienceSegment,
          audienceData.audienceId,
          audienceData.audienceName,
          channel,
          dataContractId,
          dataContractText,
          categoryId,
          alwaysOnReachGoal.value,
          ssoAccessToken
        );
        notificator.success({ body: successMsg('always on', '72 hours') });
        updateAudienceAlwaysOnActivated(dataContractId, dataContractText, categoryId, alwaysOnReachGoal.value);
      } catch (error) {
        notificator.error({
          body: `${beginingErrorMsg} amplify the audience "${audienceData.audienceName}". ${tryAgainMsg}`,
        });
      }
    },
    [audienceData, audienceSegment, channel, notificator, ssoService]
  );

  const targetUserListAudience = useCallback(
    async (selectedMarket, selectedAdvertiser, ssoAccessToken, isDynamic) => {
      try {
        const createDeterministicRes = await createDeterministicAudience(
          audienceData.audienceId,
          audienceData.audienceName,
          channel,
          selectedMarket,
          selectedAdvertiser,
          ssoAccessToken,
          channel === 'smart_tv' || channel === 'gracenote'
        );
        if (!createDeterministicRes) throw new Error('No response from createDeterministicAudience');
        const { categoryId, dataContractId, dataProviderId, dspSegmentId } = createDeterministicRes;
        notificator.success({ body: successMsg('deterministic', '72 hours') });
        createUserListForDeterministicAudience(
          channel,
          audienceSegment,
          audienceData.audienceId,
          selectedMarket,
          selectedAdvertiser,
          categoryId,
          dataContractId,
          dataProviderId,
          dspSegmentId,
          isDynamic,
          ssoAccessToken,
          audienceData.audienceName
        );
        updateAudienceDeterministicActivated(selectedMarket, selectedAdvertiser);
      } catch (error) {
        notificator.error({
          body: `${beginingErrorMsg} create the audience "${audienceData.audienceName}" in DMP. ${tryAgainMsg}`,
        });
      }
    },
    [audienceData, audienceSegment, channel, notificator]
  );

  const mixpanelTrackAudienceTargetClick = (channel) => {
    // Mixpanel block. Dynamically get the app location from location hash for correct Mixpanel track info variety
    const hashString = location && location.hash ? location.hash.split('/')[1] : '';
    const appLocation = startCase(hashString).split(' ').join('-') || 'Audience';
    Mixpanel.track(`${appLocation} - Activate Audience Button Clicked`, {
      'Audience Name': audienceData.audienceName,
      appName: 'Audience',
      channel,
      widget: widgetName || hashString,
    });
  };

  const isAudienceActive = () =>
    channel === 'articles'
      ? isAudienceAmplifiedActivated
      : isAudienceDeterministicActivated || isAudienceAmplifiedActivated;

  const activateAudience = async () => {
    setShowSpinner(!!audienceName);
    if (!isAudienceActive()) {
      const { canceled, audienceId, audienceName } = await handleActivatingAudience();
      if (canceled) {
        setShowSpinner(false);
        return;
      }
      setAudienceData({ audienceId, audienceName });
    }

    mixpanelTrackAudienceTargetClick(channel);
    setIsOpenAudienceTargetModal(true);
    setShowSpinner(false);
  };

  const activateTarget = async () => {
    setShowSpinner(!!audienceName);
    if (!isAudienceActive()) {
      const { canceled } = await handleActivatingAudience();
      if (canceled) {
        setShowSpinner(false);
        return;
      }
    }
    setShowSpinner(false);
    handleActivatingTarget();
  };

  const getAudienceTargetClickable = () => {
    const buttonClass = 'activate-button';
    const isDisabled = !isActivateEnabled || (!isActivateAudienceEnabled && !isActivateTargetEnabled);
    const commonProps = {
      className: buttonClass,
      label: 'Activate',
      isDisabled,
      onClick: () => setIsActivationMenuOpen(true),
    };

    return (
      <SubmitButton {...commonProps}>
        <Menu
          attachToParent={true}
          isMenuOpen={isActivationMenuOpen}
          closeMenu={() => setIsActivationMenuOpen(false)}
          className="activation-menu"
          menuActions={[
            {
              label: 'Audience Targeting',
              disabled: !isActivateAudienceEnabled,
              hide: !isActivateAudienceVisible,
              disabledText: activateAudienceDisabledText,
              onActionClick: () => {
                setIsActivationMenuOpen(false);
                activateAudience();
              },
            },
            {
              label: 'Contextual Targeting',
              disabled: !isActivateTargetEnabled,
              onActionClick: () => {
                setIsActivationMenuOpen(false);
                activateTarget();
              },
            },
          ]}
        />
      </SubmitButton>
    );
  };

  return (
    <React.Fragment>
      <div className="audience-target-component">
        {getAudienceTargetClickable()}
        <div className="spinner-wrap" style={{ pointerEvents: showSpinner ? 'auto' : 'none' }}>
          <Spinner show={showSpinner} />
        </div>
      </div>

      {isOpenAudienceTargetModal && (
        <AudienceTargetModal
          isOpen={isOpenAudienceTargetModal}
          onSubmitCustom={audienceTargetModalCustomSubmitHandler}
          onSubmitAlwaysOn={audienceTargetModalAlwaysOnSubmitHandler}
          onCancel={audienceTargetModalCancelHandler}
          marketsAndAdvertisersPromise={marketsAndAdvertisersPromise}
          audienceName={audienceData.audienceName}
          isProcessing={isTargetModalProcessing}
          getSegmentIds={getSegmentIds}
          audienceSegment={audienceSegment}
          channel={channel}
          dspService={dspService}
          isAudienceDeterministicActivated={isAudienceDeterministicActivated}
          isAudienceAmplifiedActivated={isAudienceAmplifiedActivated}
          isAudienceAlwaysOnActivated={isAudienceAlwaysOnActivated}
          activatedAmplifiedThreshold={activatedAmplifiedThreshold}
          activatedAlwaysOnThreshold={activatedAlwaysOnThreshold}
          activatedMarket={activatedMarket}
          activatedAdvertiser={activatedAdvertiser}
          activatedDataContractId={activatedDataContractId}
          activatedDataContractText={activatedDataContractText}
          activatedCategoryId={activatedCategoryId}
          ssoService={ssoService}
          getAmplifiedEstimatedReachGoal={getAmplifiedEstimatedReachGoal}
          widgetName={widgetName}
          hasAmplificationModeSelector={hasAmplificationModeSelector}
          getDataContract={getDataContract}
          getTaxonomyCategory={getTaxonomyCategory}
        />
      )}
    </React.Fragment>
  );
};

AudienceTarget.propTypes = {
  marketsAndAdvertisersPromise: PropTypes.object,
  audienceId: PropTypes.string,
  audienceName: PropTypes.string,
  audienceSegment: PropTypes.array.isRequired,
  isActivateEnabled: PropTypes.bool.isRequired,
  isActivateAudienceEnabled: PropTypes.bool.isRequired,
  isActivateTargetEnabled: PropTypes.bool.isRequired,
  isActivateAudienceVisible: PropTypes.bool.isRequired,
  activateAudienceDisabledText: PropTypes.string,
  channel: PropTypes.string.isRequired,
  getSegmentIds: PropTypes.func.isRequired,
  createAmplifiedAudience: PropTypes.func.isRequired,
  createAlwaysOnAudience: PropTypes.func.isRequired,
  createDeterministicAudience: PropTypes.func.isRequired,
  createUserListForDeterministicAudience: PropTypes.func.isRequired,
  notificator: PropTypes.object.isRequired,
  updateAudienceDeterministicActivated: PropTypes.func.isRequired,
  updateAudienceAmplifiedActivated: PropTypes.func.isRequired,
  updateAudienceAlwaysOnActivated: PropTypes.func.isRequired,
  dspService: PropTypes.object.isRequired,
  isAudienceDeterministicActivated: PropTypes.bool,
  isAudienceAmplifiedActivated: PropTypes.bool,
  isAudienceAlwaysOnActivated: PropTypes.bool,
  activatedAmplifiedThreshold: PropTypes.number,
  activatedAlwaysOnThreshold: PropTypes.number,
  activatedMarket: PropTypes.object,
  activatedAdvertiser: PropTypes.object,
  activatedDataContractId: PropTypes.number,
  activatedDataContractText: PropTypes.string,
  activatedCategoryId: PropTypes.number,
  ssoService: PropTypes.object.isRequired,
  getAmplifiedEstimatedReachGoal: PropTypes.func.isRequired,
  handleActivatingAudience: PropTypes.func.isRequired,
  handleActivatingTarget: PropTypes.func.isRequired,
  widgetName: PropTypes.string,
  hasAmplificationModeSelector: PropTypes.bool,
  getDataContract: PropTypes.func,
  getTaxonomyCategory: PropTypes.func,
};

export default AudienceTarget;
