function BubblesController($rootScope) {
    $rootScope.helpPage = '3_Discovery_Screen';
}

BubblesController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "bubbles",
    url: "/bubbles",
    template: require("./bubbles.discovery.html"),
    display: "Discovery",
    data: {
      permissions: ['trending interests']
    },
    controller: BubblesController,
    context: {}
};
