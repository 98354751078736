import TvApi from '../api/TvApi';
import { executeHttpRequest } from '../infra/HttpClient';
import { cacheAsyncMethod } from '../infra/cache/CacheUtils';

export function getGenres() {
  return executeHttpRequest(TvApi.getGenres());
}

export function getNetworks(channel) {
  return executeHttpRequest(TvApi.getNetworks(channel));
}

export function getShows(channel) {
  return executeHttpRequest(TvApi.getShows(channel));
}

export function getCommercials(channel) {
  return executeHttpRequest(TvApi.getCommercials(channel));
}

export function getNetworkIdToNameMap(channel) {
  return executeHttpRequest(TvApi.getNetworkIdToNameMap(channel));
}

export function getShowsMetadataByids(ids, channel) {
  return executeHttpRequest(TvApi.getShowsMetadataByids(ids, channel));
}

export function getNielsenNetworksMap() {
  return executeHttpRequest(TvApi.getNielsenNetworksMap());
}

export async function getNetworksShows(networkIds, channel) {
  const networksShows = await executeHttpRequest(TvApi.getNetworksShows(networkIds, channel));
  return new Set(networksShows);
}

// Cache

export const getCachedGenres = cacheAsyncMethod('TvService.getGenres', getGenres);

export const getCachedNetworks = cacheAsyncMethod('TvService.getNetworks', getNetworks, {
  cacheSingleEntryForMethod: false,
});

export const getCachedShows = cacheAsyncMethod('TvService.getShows', getShows, {
  cacheSingleEntryForMethod: false,
});

export const getCachedCommercials = cacheAsyncMethod('TvService.getCommercials', getCommercials, {
  cacheSingleEntryForMethod: false,
});

export const getCachedShowsMetadataByids = cacheAsyncMethod('TvService.getShowsMetadataByids', getShowsMetadataByids);

export const getCachedNetworksShows = cacheAsyncMethod('TvService.getNetworksShows', getNetworksShows, {
  cacheSingleEntryForMethod: false,
});
