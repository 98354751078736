import getLogoFallback from '../../react/utils/LogoUtils';
import articleTmpl from './article-brick.drv.html';
import videoTmpl from './video-brick.drv.html';
import tweetTmpl from './tweet-brick.drv.html';
import facebookTmpl from './facebook-brick.drv.html';
import pinterestTmpl from './pinterest-brick.drv.html'
import 'jquery.dotdotdot';

const BRICK_TYPE_TO_TMPL_MAP = {
    article: articleTmpl,
    video: videoTmpl,
    tweet: tweetTmpl,
    facebook: facebookTmpl,
    sg_telco: articleTmpl,
    sg_telco_unified: articleTmpl,
    au_telco: articleTmpl,
    pinterest: pinterestTmpl
};

module.exports = angular.module(__filename, [
    require('common/broken-img-fallback.drv').name
]).directive('amBrick', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
        restrict: 'EA',
        link: function (scope, element, attr) {
            var newScope = scope.$new(true);
            var brickData = angular.fromJson(attr.brickData);
            if (brickData['image_height']) {
                brickData['image_height'] = Math.round(brickData['image_height'] / brickData['image_width'] * 263);
            }

            if (brickData['post_image']) {
                brickData['post_image']['height'] = Math.round(brickData['post_image']['height'] / brickData['post_image']['width'] * 263);
            }

            brickData.image_fallback = getLogoFallback(brickData.url) || '/images/common/no-available-image.svg';

            newScope.brickData = brickData;
            newScope.deleteBrick = scope.deleteBrick(attr.id);
            newScope.trackBrick = scope.trackBrick;
            newScope.capitalize = _.capitalize;
            newScope.min = _.min;
            newScope.brickRollover = scope.brickRollover;
            var template = BRICK_TYPE_TO_TMPL_MAP[attr.type];

            element.html($compile(template)(newScope));
            $timeout(function () {
                element.find('.dotdotdot').each(function () {
                    $(this).dotdotdot({after: '.read-more'})
                });
                element.find('.dotdotdot-publish-date').each(function () {
                    $(this).dotdotdot({after: '.publish-date'})
                });
            }, 0);
        }
    }
}]).filter('fromNow', function () {
    return function (date) {
        // replacing spaces with unicode of &nbsp;
        return moment(date).fromNow().replace(/ /g, '\u00A0');
    }
});
