module.exports = angular.module(__filename, []).service('filtersPartition', [function () {
    return {
        genders: [
            {label: 'male', summary: 'male', value: 'audience_s12', icon: 'icon-male', audienceLabel: 'male'},
            {label: 'female', summary: 'female', value: 'audience_s11', icon: 'icon-Female', audienceLabel: 'female'},
            {label: 'all', summary: null, value: 'audience_placeholder-1', icon: 'icon-male_female', audienceLabel: 'both'}
        ],
        linkedinBothGender: {label: "Both", value: "both" },
        age: [
            {label: "13-17", summary: "13-17", value: 'audience_s1'},
            {label: "18-24", summary: "18-24", value: 'audience_s2'},
            {label: "25-34", summary: "25-34", value: 'audience_s4'},
            {label: "35-44", summary: "35-44", value: 'audience_s5'},
            {label: "45-54", summary: "45-54", value: 'audience_s6'},
            {label: "55-64", summary: "55-64", value: 'audience_s7'},
            {label: "65+", summary: "65+", value: 'audience_s8'}
        ],
        children: [
            {label: 'Yes', summary: 'with children', value: 'audience_s10'},
            {label: 'No', summary: 'no children', value: 'audience_s9'},
            {label: 'Both', summary: null, value: 'audience_placeholder-2'}
        ],
        income: [
            {label: '0-15K', summary: '0-15k', value: 'audience_s18'},
            {label: '15-25K', summary: '15-25k', value: 'audience_s20'},
            {label: '25-40K', summary: '25-40k', value: 'audience_s21'},
            {label: '40-60K', summary: '40-60k', value: 'audience_s22'},
            {label: '60-75K', summary: '60-75k', value: 'audience_s23'},
            {label: '75-99K', summary: '75-100k', value: 'audience_s24'},
            {label: '100K+', summary: '100k+', value: 'audience_s19'}
        ],
        newIncome: [
            {label: '0-25K', summary: '0-25k', value: ['0-15k', '15-25k']},
            {label: '25-50K', summary: '25-50k', value: ['25-35k', '35-50k']},
            {label: '50-75K', summary: '50-75k', value: '50-75k'},
            {label: '75-100K', summary: '75-100k', value: '75-100k'},
            {label: '100-150K', summary: '100-150k', value: '100-150k'},
            {label: '150-200K', summary: '150-200k', value: '150-200k'},
            {label: '200K+', summary: '200k+', value: '200k+'}
        ],
        streamsChannels: [
            //all below single, seperator, exclude_from_all commented out TBD ready to enable when streams bi-lite support will be done
            {"label": "Web", "value": 'articles', 'single': 'true',"permission": "web channel"},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel', 'single': 'true'},
            {"label": "Video", "value": 'videos', 'single': 'true',"permission": "video channel"},
            {
                "label": "Social", "value": 'dummy_parent',"permission": "social channel",
                "children": [
                    {"label": "Twitter", "value": 'tweets', 'single': 'true',"permission": "social channel"},
                    {"label": "Facebook", "value": 'facebook', 'single': 'true', "permission": "social channel"},
                ]
            },
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel', "exclude_from_all": 'true', 'single': 'true'}
        ],
        insightsChannels: [
            {"label": "All", "value": 'articles',"permission": 'web channel'},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "Video", "value": 'videos',"permission": 'video channel'},
            {"label": "Social", "value": 'tweets',"permission": 'social channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel'}
        ],
        geoInsightsChannels: [
            {"label": "All", "value": 'articles', anyPermission: ['geos.US', 'insights geo au map']},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel'}
        ],
        insightsAssociationsChannels: [
            {"label": "All", "value": 'articles',"permission": 'web channel'},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "Social", "value": 'tweets',"permission": 'social channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel'}
        ],
        insightsTimingChannels: [
            {"label": "All", "value": 'articles',"permission": 'web channel'},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "Social", "value": 'tweets',"permission": 'social channel'}
        ],
        insightsReferralsChannels: [
            {"label": "All", "value": 'articles',"permission": 'web channel'},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel'}
        ],
        alertChannels: [
            {"label": "All", "value": 'articles', "permission": 'web channel', "first": true},
            {"label": "Video", "value": 'videos', "permission": 'video channel'},
            {"label": "Social", "value": 'tweets', "last": true}
        ],
        alertsV2Channels: [
            {"label": "All", "value": 'articles'},
            {"label": "Social", "value": 'tweets'}
        ],
        audienceChannels: [
            {"label": "Web", "value": 'articles',"permission": 'web channel'},
            {"label": "SG Telco Desktop", "value": 'snbb', debugOnly: true, "permission": 'sg telco channel'},
            {"label": "Web - SG Telco", "value": 'data_spark', "permission": 'audience sg telco channel'},
            {"label": "LinkedIn", "value": 'linkedin', "permission": 'audience linkedin channel'},
            {"label": "Linear TV", "value": 'linear_tv', "permission": 'audience linear tv channel'},
            {"label": "Smart TV", "value": 'gracenote', "permission": 'audience smart tv channel'},
            {"label": "Smart TV Inscape", "value": 'smart_tv', "permission": 'audience smart tv inscape channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'audience au telco channel'}
        ],
        bubblesChannel: [
            {"label": "Web", "value": 'articles',"permission": 'web channel'},
            {"label": "Web - SG Telco", "value": 'sg_telco', "permission": 'sg telco channel'},
            {"label": "Twitter", "value": 'tweets',"permission": 'social channel'},
            {"label": "Facebook", "value": 'facebook',"permission": 'social channel'},
            {"label": "AU Telco", "value": 'au_telco', "permission": 'au telco channel'}
        ],
        mediaTypes: {
          articles: [
            {"label": "Premium", "value": 'premium'},
            {"label": "Blog", "value": 'blog'},
            {"label": "Forum", "value": 'forum'},
            {"label": "Review", "value": 'review'},
            {"label": "Other web", "value": 'site'}
          ],
          sg_telco: [
            {"label": "Premium", "value": 'premium'},
            {"label": "Blog", "value": 'blog'},
            {"label": "Forum", "value": 'forum'},
            {"label": "Review", "value": 'review'},
            {"label": "Other web", "value": 'site'}
          ],
          au_telco: [
            {"label": "Premium", "value": 'premium'},
            {"label": "Blog", "value": 'blog'},
            {"label": "Forum", "value": 'forum'},
            {"label": "Review", "value": 'review'},
            {"label": "Other web", "value": 'site'}
          ],
          tweets: [
            {"label": "Post", "value": 'post'}
          ],
          facebook: [
            {"label": "Post", "value": 'post'},
            {"label": "Video", "value": 'video'},
            {"label": "Image", "value": 'image'},
            {"label": "Article", "value": 'article'}
          ],
          videos: [
            {"label": "Video", "value": 'video'}
          ]
        },
         language: [
            {"label": "English", "value": 'en'},
            {"label": "Spanish", "value": 'es'},
            {"label": "French", "value": 'fr'},
            {"label": "German", "value": 'de'},
            {"label": "Indonesian", "value": 'id'}
        ],
        behavioralSegment: [
            {"label": "Alcohol Consumers",                       "value": 'alcohol_consumers',        'debugOnly':false},
            {"label": "Auto Intenders",                          "value": 'automotive',               'debugOnly':false},
            {"label": "Baby Product Intenders",                  "value": 'baby_product_intenders',   'debugOnly':false},
            {"label": "Beauty Product Buyers",                   "value": 'beauty_product_buyers',    'debugOnly':false},
            {"label": "Breakfast Cereals Shoppers",              "value": 'cereal',                   'debugOnly':false},
            {"label": "Business Decision Makers",                "value": 'business_decision_makers', 'debugOnly':false},
            {"label": "Busy Moms",                               "value": 'busy_moms',                'debugOnly':false},
            {"label": "Celebrity Fans",                          "value": 'celebrity_fans',           'debugOnly':false},
            {"label": "College Students",                        "value": "college_students",         'debugOnly':false},
            {"label": "Cooking Enthusiasts",                     "value": "recipe_cooking",           'debugOnly':false},
            {"label": "Financial Investors",                     "value": 'financial_investors',      'debugOnly':false},
            {"label": "Fitness Enthusiasts",                     "value": 'fitness',                  'debugOnly':false},
            {"label": "Gamers",                                  "value": 'gamers',                   'debugOnly':false},
            {"label": "IT Decision Makers",                      "value": 'it_managers',              'debugOnly':false},
            {"label": "Job Seekers",                             "value": 'job_seekers',              'debugOnly':false},
            {"label": "LGBT",                                    "value": 'lgbt',                     'debugOnly':false},
            {"label": "Luxury Consumers",                        "value": 'luxury_shoppers',          'debugOnly':false},
            {"label": "Movers",                                  "value": 'movers',                   'debugOnly':false},
            {"label": "Movie Lovers",                            "value": 'movie_lovers',             'debugOnly':false},
            {"label": "Music Lovers",                            "value": 'music_lovers',             'debugOnly':false},
            {"label": "New Home Buyers",                         "value": 'new_home_buyers' ,         'debugOnly':false},
            {"label": "New Parents",                             "value": 'pregnancy',                'debugOnly':false},
            {"label": "Newlywed",                                "value": 'newlyweds',                'debugOnly':false},
            {"label": "Outdoor Enthusiasts",                     "value": 'outdoorsmen',              'debugOnly':false},
            {"label": "Pet Lovers",                              "value": 'pet_lovers',               'debugOnly':false},
            {"label": "Physicians",                              "value": 'physicians',               'debugOnly':false},
            {"label": "Political Junkies",                       "value": 'political_junkies',        'debugOnly':false},
            {"label": "Prospective College Students",            "value": 'prospective_students',     'debugOnly':false},
            {"label": "Sports Fans",                             "value": 'sports',                   'debugOnly':false},
            {"label": "Tech Enthusiasts",                        "value": 'techies',                  'debugOnly':false},
            {"label": "Travelers",                               "value": 'travel',                   'debugOnly':false},
            {"label": "Vegan & Vegetarian",                      "value": 'vegan',                    'debugOnly':false},
            {"label": "Weight Conscious",                        "value": 'fat_women',                'debugOnly':false},
            {"label": "Budget Travelers",                        "value": 'budget_travelers',         'debugOnly':true},
            {"label": "DIYers",                                  "value": 'do_it_yourself',           'debugOnly':true},
            {"label": "Finance",                                 "value": 'finance',                  'debugOnly':true},
            {"label": "Fishing",                                 "value": 'outdoor_fishing',          'debugOnly':true},
            {"label": "Foodies",                                 "value": 'foodies',                  'debugOnly':true},
            {"label": "Forex",                                   "value": 'forex',                    'debugOnly':true},
            {"label": "Green Living",                            "value": 'eco_friendly',             'debugOnly':true},
            {"label": "Hunting",                                 "value": 'outdoor_guns',             'debugOnly':true},
            {"label": "Hunting Enthusiasts",                     "value": 'outdoor',                  'debugOnly':true},
            {"label": "Lenovo",                                  "value": 'lenovo',                   'debugOnly':true},
            {"label": "Luxury Travelers",                        "value": 'luxury_travelers',         'debugOnly':true},
            {"label": "Microsoft Surface",                       "value": 'microsoft_surface' ,       'debugOnly':true},
            {"label": "Mini Car",                                "value": 'mini_car',                 'debugOnly':true},
            {"label": "NBA Fans",                                "value": 'sports_nba',               'debugOnly':true},
            {"label": "NFL Fans",                                "value": 'sports_nfl',               'debugOnly':true},
            {"label": "Olympics Followers",                      "value": 'olympics_fans',            'debugOnly':true},
            {"label": "SG Opponents",                            "value": 'sg_opp',                   'debugOnly':true},
            {"label": "SG Supporters",                           "value": 'sg_gov',                   'debugOnly':true},
            {"label": "Soccer Fans",                             "value": 'sports_soccer',            'debugOnly':true},
            {"label": "Wrestling Fans",                          "value": 'sports_wrestling',         'debugOnly':true},
            {"label": "Turn Hack1",                              "value": 'turn_hack1',               'debugOnly':true},
            {"label": "Turn Hack2",                              "value": 'turn_hack2',               'debugOnly':true},
            {"label": "Turn Hack3",                              "value": 'turn_hack3',               'debugOnly':true},
            {"label": "Turn Hack4",                              "value": 'turn_hack4',               'debugOnly':true},
            {"label": "Turn Hack5",                              "value": 'turn_hack5',               'debugOnly':true}
        ],
        auTelcoBehavioralSegment: [
            {"label": "Alcohol Consumers - AU",                 "value": 'alcohol_consumers_au',        'debugOnly': true},
            {"label": "Auto Intenders - AU",                    "value": 'auto_intenders_au',           'debugOnly': true},
            {"label": "Baby Product Intenders - AU",            "value": 'baby_product_intenders_au',   'debugOnly': true},
            {"label": "Beauty Product Buyers - AU",             "value": 'beauty_product_buyers_au',    'debugOnly': true},
            {"label": "Breakfast Cereals Shoppers - AU",        "value": 'cereal_au',                   'debugOnly': true},
            {"label": "Business Decision Makers - AU",          "value": 'business_decision_makers_au', 'debugOnly': true},
            {"label": "Busy Moms - AU",                         "value": 'busy_moms_au',                'debugOnly': true},
            {"label": "Celebrity Fans - AU",                    "value": 'celebrity_fans_au',           'debugOnly': true},
            {"label": "College Students - AU",                  "value": "college_students_au",         'debugOnly': true},
            {"label": "Cooking Enthusiasts - AU",               "value": "cooking_enthusiasts_au",      'debugOnly': true},
            {"label": "Financial Investors - AU",               "value": 'financial_investors_au',      'debugOnly': true},
            {"label": "Fitness Enthusiasts - AU",               "value": 'fitness_enthusiasts_au',      'debugOnly': true},
            {"label": "Gamers - AU",                            "value": 'gamers_au',                   'debugOnly': true},
            {"label": "IT Decision Makers - AU",                "value": 'it_decision_making_au',       'debugOnly': true},
            {"label": "Job Seekers - AU",                       "value": 'job_seekers_au',              'debugOnly': true},
            {"label": "LGBT - AU",                              "value": 'lgbt_au',                     'debugOnly': true},
            {"label": "Luxury Consumers - AU",                  "value": 'luxury_consumers_au',         'debugOnly': true},
            {"label": "Movers - AU",                            "value": 'movers_au',                   'debugOnly': true},
            {"label": "Movie Lovers - AU",                      "value": 'movie_lovers_au',             'debugOnly': true},
            {"label": "Music Lovers - AU",                      "value": 'music_lovers_au',             'debugOnly': true},
            {"label": "New Home Buyers - AU",                   "value": 'new_home_buyers_au' ,         'debugOnly': true},
            {"label": "New Parents - AU",                       "value": 'new_parents_au',              'debugOnly': true},
            {"label": "Newlywed - AU",                          "value": 'newlyweds_au',                'debugOnly': true},
            {"label": "Outdoor Enthusiasts - AU",               "value": 'outdoors_enthusiasts_au',     'debugOnly': true},
            {"label": "Pet Lovers - AU",                        "value": 'pet_lovers_au',               'debugOnly': true},
            {"label": "Physicians - AU",                        "value": 'physicians_au',               'debugOnly': true},
            {"label": "Political Junkies - AU",                 "value": 'political_junkies_au',        'debugOnly': true},
            {"label": "Sports Fans - AU",                       "value": 'sports_fans_au',              'debugOnly': true},
            {"label": "Tech Enthusiasts - AU",                  "value": 'tech_enthusiasts_au',         'debugOnly': true},
            {"label": "Travelers - AU",                         "value": 'travelers_au',                'debugOnly': true},
            {"label": "Vegan & Vegetarian - AU",                "value": 'vegan_au',                    'debugOnly': true},
            {"label": "Weight Conscious - AU",                  "value": 'weight_conscious_au',         'debugOnly': true},
        ],
        sgTelcoBehavioralSegment: [
            {"label": "Baby Product Intenders - SG",            "value": 'baby_product_intenders_sg',         'debugOnly': true},
            {"label": "Business Decision Makers - SG",          "value": 'business_decision_makers_sg',       'debugOnly': true},
            {"label": "Busy Moms - SG",                         "value": 'busy_moms_sg',                      'debugOnly': true},
            {"label": "College Students - SG",                  "value": "college_students_sg",               'debugOnly': true},
            {"label": "Cooking Enthusiasts - SG",               "value": "cooking_enthusiasts_sg",            'debugOnly': true},
            {"label": "Financial Investors - SG",               "value": 'financial_investors_sg',            'debugOnly': true},
            {"label": "Luxury Consumers - SG",                  "value": 'luxury_consumers_sg',               'debugOnly': true},
            {"label": "New Home Buyers - SG",                   "value": 'new_home_buyers_sg' ,               'debugOnly': true},
            {"label": "Political Junkies - SG",                 "value": 'political_junkies_sg',              'debugOnly': true},
            {"label": "Tech Enthusiasts - SG",                  "value": 'tech_enthusiasts_sg',               'debugOnly': true},
            {"label": "Travelers - SG",                         "value": 'travelers_sg',                      'debugOnly': true},
        ],
        articlesBehavioralSegment: [
            {"label": "Cord Cutters - Cord Cutting Intenders",  "value": "cord_cutters_content",              'debugOnly':false},
            {"label": "Cord Cutters - Free Services Streamers", "value": "cord_cutters2_low_cost",            'debugOnly':false},
            {"label": "Cord Cutters - Paid Subscription",       "value": "cord_cutters_subscribers_modified", 'debugOnly':false},
            {"label": "Cord Cutters - Watch Online",            "value": "cord_cutters_torrents",             'debugOnly':false},
        ],
        inscapeBehavioralSegment: [
            {"label": "Cord Cutters - OTT Consumers",            "value": "cord_cutters_ins_ott2",            'debugOnly':false},
            {"label": "Cord Cutters - Streaming Apps Consumers", "value": "cord_cutters_ins_apps2",           'debugOnly':false},
        ],
        ethnicity: [
            {label: 'African American', summary: 'African American', value: 'black'},
            {label: 'Hispanic', summary: 'Hispanic', value: 'hispanic'}
        ],
        ethnicityWithAll: [
            {label: 'All Ethnicities', summary: '', value: ''},
            {label: 'African American', summary: 'African American', value: 'black'},
            {label: 'Hispanic', summary: 'Hispanic', value: 'hispanic'}
        ],
        sgEthnicityWithAll: [
            {label: 'All Ethnicities', summary: 'All Ethnicities', value: 'all'},
            {label: 'Chinese', summary: 'Chinese', value: 'chinese'},
            {label: 'Malay', summary: 'Malay', value: 'malay'},
            {label: 'Indian', summary: 'Indian', value: 'indian'},
            {label: 'Other', summary: 'Other ethnicities', value: 'other'}
        ],
        sgEthnicity: [
            {label: 'Chinese', summary: 'Chinese', value: 'chinese'},
            {label: 'Malay', summary: 'Malay', value: 'malay'},
            {label: 'Indian', summary: 'Indian', value: 'indian'},
            {label: 'Other', summary: 'Other ethnicities', value: 'other'}
        ],
        sgNationality: [
            {label: 'Singapore', summary: 'Singapore', value: 'nationality-SGP'},
            {label: 'India', summary: 'India', value: 'nationality-IND'},
            {label: 'Malaysia', summary: 'Malaysia', value: 'nationality-MYS'},
            {label: 'China', summary: 'China', value: 'nationality-CHN'},
            {label: 'Other', summary: 'Other nationalities', value: 'nationality-other'}
        ],
        races: [
            {label: 'African American', summary: 'African American', value: 'black'},
            {label: 'Asian American', summary: 'Asian American', value: 'asian'},
            {label: 'Caucasian', summary: 'Caucasian', value: 'white'},
            {label: 'Hispanic', summary: 'Hispanic', value: 'hispanic'}
        ],
        racesWithAll: [
            {label: 'All Ethnicities', summary: 'All Ethnicities', value: 'all'},
            {label: 'African American', summary: 'African American', value: 'black'},
            {label: 'Asian American', summary: 'Asian American', value: 'asian'},
            {label: 'Caucasian', summary: 'Caucasian', value: 'white'},
            {label: 'Hispanic', summary: 'Hispanic', value: 'hispanic'}
        ],
        queryLogic: [
            {label: 'Any', summary: 'Any', value: 'or'},
            {label: 'All', summary: 'All', value: 'and'}
        ],
        logicOperand: [
            {label: 'Require', value: 'and'},
            {label: 'Prefer', value: 'or'},
            {label: 'Exclude', value: 'not'}
        ],
        testSegment: [
            {"label": "Keywords", "value": 'keywords'},
            {"label": "Searches", "value": 'searches'},
            {"label": "URL Keywords", "value": 'uw'},
            {"label": "Frequent Keywords", "value": 'frequent-keywords'}
        ],
        levelOfIntent: [
            {"label": "Awareness", "value": 'keywords'},
            {"label": "Consideration", "value": 'frequent-keywords'},
            {"label": "In Market", "value": 'searches'}
        ],
        sgWebSource: [
            {"label": "Web", "value": 'web'},
            {"label": "SG Telco", "value": 'telco'}
        ]
    }
}]);
