import 'angular-ui-router';
import { isTimeframeHidden, showDatePicker } from './timeframe/timeframe-utils';

const timeFrameFilter = require("./timeframe/timeframe"),
    contextModule = require("infra/context/context");

module.exports = angular.module(__filename, [
    timeFrameFilter.name,
    contextModule.name,
    'ui.router',
    require("data/discovery-service").name
]).directive("appCommonElements", ["context", "$state", '$rootScope', 'abiPermissions', 'TIMES',
    function (context, $state, $rootScope, abiPermissions, TIMES) {

        return {
            restrict: "E",
            template: require("./app-common-elements.html"),
            scope: {
                onExport: '='
            },
            link: function (scope) {
                scope.context = context;
                scope.$state = $state;

                scope.times = [];
                scope.minDate = null;
                scope.subtractDay = false;

                scope.onToggleFilterMenu = $rootScope.toggleFilterBar;

                scope.hasActiveFilter = function () {
                    const state = $state.current;
                    const display = state.display.toLowerCase();
                    return (['dashboard', 'discovery', 'insights'].includes(display) || state.name.startsWith('warroom')) && $rootScope.hasActiveFilter(state.name);
                };

                scope.hasPermission = abiPermissions.hasPermission;

                scope.hideFiltersMenu = function () {
                    const name = $state.current.name;

                    if (  name.includes('warroom.groups')
                        ||name.includes('warroom.trending')) return true;

                    if (!name.startsWith('audience-')) return false;
                    const hasAudienceChannelPermissions = abiPermissions.hasPermission('audience au telco channel') || abiPermissions.hasPermission('audience linear tv channel') || abiPermissions.hasPermission('audience linkedin channel') || abiPermissions.hasPermission('audience sg telco channel');
                    return !hasAudienceChannelPermissions || name !== 'audience-builder';
                };


                scope.hideExport = function () {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                           || (state.name.startsWith('settings.') && state.name !== 'settings.users' && state.name !== 'settings.targets')
                           || state.name.indexOf('warroom.groups') !== -1
                           || state.name.indexOf('warroom.realtime') !== -1
                           || state.name === 'audience-builder'
                           || state.name === 'alerts-v2'
                           || !scope.hasPermission(['export']);
                };

                scope.hideTimeframe = function () {
                    return isTimeframeHidden($state.current.name, $state.current.display);
                };

                scope.showDatePicker = function () {
                    return showDatePicker($state.current.name, $state.current.display, scope.hasPermission);
                };

                scope.getTimeframeMold = function () {
                    const state = $state.current;
                    if (state.name.indexOf('warroom') !== -1)
                        return context.current._warroom_timeframe_mold;
                    if (state.display.toLowerCase() === 'discovery')
                        return context.current._discovery_timeframe_mold;
                    if (state.display.toLowerCase() === 'insights')
                        return context.current._insights_timeframe_mold;
                    return null;
                };

                scope.$on('timeframe-vars-update', function(event, times, minDate, subtractDay, fullWeeksDetection, noMaxTimeframeNotification, maxSpan) {
                    if (times) {
                        times = TIMES.getTimes(times);
                        if (!_.isEqual(times, scope.times)) {
                            scope.times = times;
                        }
                    }

                    scope.minDate = minDate;
                    scope.subtractDay = !!subtractDay;
                    scope.fullWeeksDetection = !!fullWeeksDetection;
                    scope.noMaxTimeframeNotification = !!noMaxTimeframeNotification;
                    scope.maxSpan = maxSpan;
                });
            }
        }
    }
]);
