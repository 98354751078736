import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { difference, flatMap, groupBy, intersection, isEmpty, join, keys, map } from 'lodash';
import './CommercialsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import V2Dropdown from '../../../../../common/Dropdown/V2Dropdown/V2Dropdown';
import WindowEventListener from '../../../../../common/WindowEventListener/WindowEventListener';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import moment from 'moment';
import { isSmartTvChannel } from '../../../../../../../data/audience-segment-builder-helper';

const MOMENT_DATE_FORMAT = 'MM-DD-YY';

const CommercialsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  commercialsInput,
  commercialsMetadataPromise,
  channel,
  isTimeframeVisible,
}) => {
  /* eslint-disable camelcase*/
  const CHANNEL_TO_DATASOURCE_MAP = {
    smart_tv: 'smartTvCommercials',
    linear_tv: 'linearTvCommercials',
    gracenote: 'gracenoteCommercials',
  };
  /* eslint-enable camelcase */
  const SEGMENT_TYPE = CHANNEL_TO_DATASOURCE_MAP[channel];
  const WITH_BRAND_PARENT = channel !== 'smart_tv';
  const RESPONSE_ATTRIBUTES = WITH_BRAND_PARENT
    ? ['brand_parents', 'brands', 'creatives', 'brand_parent_to_brands', 'brand_to_creatives', 'brand_to_brand_parents']
    : ['brands', 'creatives', 'brand_to_creatives'];

  // mappings between levels (parent<->brand->creative)
  const [brandParentToBrands, setBrandParentToBrands] = useState({});
  const [brandToCreatives, setBrandToCreatives] = useState({});
  const [brandToBrandParents, setBrandToBrandParents] = useState({});

  // all available values
  const [allBrands, setAllBrands] = useState([]);
  const [allCreatives, setAllCreatives] = useState([]);

  // values currently in dropdown
  const [brandParentValues, setBrandParentValues] = useState([]);
  const [brandValues, setBrandValues] = useState([]);
  const [creativeValues, setCreativeValues] = useState([]);

  // values selected in dropdown
  const [selectedBrandParents, setSelectedBrandParents] = useState(commercialsInput.brandParents || []);
  const [selectedBrands, setSelectedBrands] = useState(commercialsInput.brands || []);
  const [selectedCreatives, setSelectedCreatives] = useState(commercialsInput.creatives || []);

  const [showSpinner, setShowSpinner] = useState(true);
  const [modalMaxHeight, setModalMaxHeight] = useState('491px');
  const [selectedStartDate, setSelectedStartDate] = useState(commercialsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(commercialsInput.endDate);

  const setModalMaxHeights = () => {
    setModalMaxHeight(window.innerHeight > 900 || isSmartTvChannel(channel) ? '491px' : '412px');
  };

  useEffect(() => {
    setModalMaxHeights();
  });

  const fetchCommercialsMetadata = async () => {
    const res = await commercialsMetadataPromise;
    const missingProperties = difference(RESPONSE_ATTRIBUTES, keys(res));
    if (missingProperties.length)
      throw new Error(`The following properites are missing: ${join(missingProperties, ', ')}`);

    setBrandParentToBrands(res.brand_parent_to_brands);
    setBrandToCreatives(res.brand_to_creatives);
    setBrandToBrandParents(res.brand_to_brand_parents);

    setAllBrands(res.brands);
    setAllCreatives(res.creatives);

    setBrandParentValues(res.brand_parents);
    setBrandValues(res.brands);
    setCreativeValues(res.creatives);

    if (
      (WITH_BRAND_PARENT && selectedBrandParents.length === 0 && selectedBrands.length === 0) ||
      (!WITH_BRAND_PARENT && selectedBrands.length === 0)
    )
      return setShowSpinner(false);

    const parentValues = map(selectedBrandParents, 'value');
    const selectedCreativeValues = new Set(
      WITH_BRAND_PARENT
        ? flatMap(selectedBrands, (brand) => {
            let curentBrandBrandParentIds = isEmpty(parentValues)
              ? res.brand_to_brand_parents[brand.value]
              : intersection(parentValues, res.brand_to_brand_parents[brand.value]);
            return flatMap(
              curentBrandBrandParentIds,
              (brandParentId) => res.brand_to_creatives[`${brandParentId}_${brand.value}`]
            );
          })
        : flatMap(selectedBrands, (brand) => res.brand_to_creatives[brand.value])
    );
    const creatives = res.creatives.filter((creative) => selectedCreativeValues.has(creative.value));
    const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({
      label,
      value: map(values, 'value'),
    }));
    setCreativeValues(mergedCreatives);

    if (!WITH_BRAND_PARENT || isEmpty(selectedBrandParents)) return setShowSpinner(false);

    const selectedBrandsValues = new Set(
      flatMap(selectedBrandParents, (parent) => res.brand_parent_to_brands[parent.value])
    );
    const brands = res.brands.filter((brand) => selectedBrandsValues.has(brand.value));
    setBrandValues(brands);
    return setShowSpinner(false);
  };

  useEffect(() => {
    fetchCommercialsMetadata();
  }, [commercialsMetadataPromise]);

  const handleSubmit = useCallback(() => {
    const segment = {
      ...commercialsInput,
      type: SEGMENT_TYPE,
      channel,
      ...(WITH_BRAND_PARENT && { brandParents: selectedBrandParents }),
      brands: selectedBrands,
      creatives: selectedCreatives,
      ...(selectedStartDate && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && { endDate: dateToString(selectedEndDate) }),
    };

    onSubmit(segment);
  }, [
    selectedCreatives,
    selectedBrands,
    selectedBrandParents,
    brandParentValues,
    brandValues,
    creativeValues,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handleCancel = () => {
    onCancel(SEGMENT_TYPE);
  };

  const onParentsSelect = useCallback(
    (parents) => {
      const selectedBrandsValues = new Set(flatMap(parents, (parent) => brandParentToBrands[parent.value]));
      const selectedBrands = allBrands.filter((brand) => selectedBrandsValues.has(brand.value));
      setSelectedBrandParents(parents);
      setBrandValues(selectedBrands.length ? selectedBrands : allBrands);
      onBrandsSelect(selectedBrands, undefined, parents);
    },
    [
      allBrands,
      allCreatives,
      creativeValues,
      selectedCreatives,
      brandParentToBrands,
      brandToCreatives,
      brandToBrandParents,
    ]
  );

  const onBrandsSelect = useCallback(
    (brands, oldSelectedBrands, parents) => {
      const creativesSet = createCreativesSet(brands, parents);
      const creatives = allCreatives.filter((creative) => creativesSet.has(creative.value));
      const mergedCreatives = map(groupBy(creatives, 'label'), (values, label) => ({
        label,
        value: map(values, 'value'),
      }));
      setSelectedBrands(brands);
      setCreativeValues(mergedCreatives);
      setSelectedCreatives(mergedCreatives);
    },
    [allCreatives, creativeValues, selectedCreatives, brandToCreatives, brandToBrandParents]
  );

  const createCreativesSet = useCallback(
    (brands, parents) => {
      const parentValues = parents && map(parents, 'value');
      const selectedCreativeValueMemory = new Set();
      brands.forEach((brand) => {
        if (WITH_BRAND_PARENT) {
          const currentBrandBrandParentIds = parents
            ? intersection(parentValues, brandToBrandParents[brand.value])
            : brandToBrandParents[brand.value];
          return currentBrandBrandParentIds.forEach((brandParentId) =>
            (brandToCreatives[`${brandParentId}_${brand.value}`] || []).forEach((creativeValue) =>
              selectedCreativeValueMemory.add(creativeValue)
            )
          );
        }

        return brandToCreatives[brand.value].forEach((creativeValue) => selectedCreativeValueMemory.add(creativeValue));
      });
      return selectedCreativeValueMemory;
    },
    [brandToCreatives, brandToBrandParents]
  );

  const parentsSummaryTextBuilder = (selectedValues, values) => {
    if (!selectedValues.length || selectedValues.length === values.length) return 'Select parent brands';
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return 'Select';
    if (selectedValues.length === 1) return selectedValues[0].label;
    return selectedValues.map((value) => value.label).join(', ');
  };

  const brandsSummaryTextBuilder = (selectedValues, values) => {
    if (selectedValues.length && selectedValues.length === values.length) return 'All brands';
    if (!selectedValues.length || (selectedValues.length === 1 && isEmpty(selectedValues[0]))) return 'Select brands';
    if (selectedValues.length === 1) return selectedValues[0].label;
    return selectedValues.map((value) => value.label).join(', ');
  };

  const creativesSummaryTextBuilder = (selectedValues, values) => {
    if (!selectedValues.length || selectedValues.length === values.length) return 'All creatives';
    if (selectedValues.length === 1 && isEmpty(selectedValues[0])) return 'Select';
    if (selectedValues.length === 1) return selectedValues[0].label;
    return selectedValues.map((value) => value.label).join(', ');
  };

  function dateToString(date) {
    return moment(new Date(date)).format(MOMENT_DATE_FORMAT);
  }

  const maxNumberOfSelectedBrands = WITH_BRAND_PARENT ? 50 : 7;
  const reachMaxSelectedBrandsMsg = `Sorry, this field is limited to ${maxNumberOfSelectedBrands} brands`;
  return (
    <div className="commercials-modal-component">
      <WindowEventListener events="resize" eventHandlerFunction={setModalMaxHeights} />
      <ModalWithConfirmationButtons
        width="800px"
        maxHeightBeforeScroll={modalMaxHeight}
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        showSpinner={showSpinner}
        isSubmitDisabled={isEmpty(selectedCreatives)}
        isDisabledEnterKeyPress={isEmpty(selectedCreatives)}
        isLightTheme={true}
        isYAutoScrollEnabled={false}
      >
        <div className="commercials-modal-content">
          {WITH_BRAND_PARENT && (
            <div className="modal-row">
              <div className="row-label">Parent brand:</div>
              <V2Dropdown
                selectedValues={selectedBrandParents}
                isMulti={true}
                isSearchable={true}
                summaryTextBuilder={parentsSummaryTextBuilder}
                onSelect={onParentsSelect}
                values={brandParentValues}
                maxNumberOfSelectedOptions={7}
                reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 parent brands"
              />
            </div>
          )}
          <div className="modal-row">
            <div className="row-label">Brand:</div>
            <V2Dropdown
              selectedValues={selectedBrands}
              isMulti={true}
              isSearchable={true}
              summaryTextBuilder={brandsSummaryTextBuilder}
              onSelect={onBrandsSelect}
              showSelectAllOptions={!isEmpty(selectedBrandParents)}
              values={brandValues}
              isOpen={isEmpty(selectedBrandParents) ? false : undefined}
              maxNumberOfSelectedOptions={maxNumberOfSelectedBrands}
              reachMaxSelectedOptionsMsg={reachMaxSelectedBrandsMsg}
            />
          </div>
          <div className="modal-row">
            <div className="row-label">Creative:</div>
            <V2Dropdown
              selectedValues={selectedCreatives}
              isMulti={true}
              isSearchable={true}
              isDisabled={isEmpty(selectedBrands)}
              summaryTextBuilder={creativesSummaryTextBuilder}
              onSelect={setSelectedCreatives}
              showSelectAllOptions={true}
              values={creativeValues}
              isOpen={
                isEmpty(selectedBrands) || (WITH_BRAND_PARENT && isEmpty(selectedBrandParents)) ? false : undefined
              }
            />
          </div>
          {isTimeframeVisible && isSmartTvChannel(channel) && (
            <div className="modal-row">
              <div className="row-label">Timeframe:</div>
              <TvCustomTimeframe
                startDateString={selectedStartDate}
                endDateString={selectedEndDate}
                selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
                selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
              />
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

CommercialsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  commercialsMetadataPromise: PropTypes.object,
  commercialsInput: PropTypes.object,
  channel: PropTypes.string,
  isTimeframeVisible: PropTypes.bool,
};

CommercialsModal.defaultProps = {
  commercialsInput: {},
  channel: '',
  isTimeframeVisible: true,
};

export default CommercialsModal;
