import {react2angular} from 'react2angular';
import common from 'infra/utils/common';
import {validateUserAuthentication} from '../../data/audience-linkedin-helper';
import LifestyleModal from 'react/components/apps/audience/AudienceBuilder/Modals/LifestyleModal/LifestyleModal';
import FirstPartyModal from 'react/components/apps/audience/AudienceBuilder/Modals/FirstPartyModal/FirstPartyModal';
import CommercialsModal from 'react/components/apps/audience/AudienceBuilder/Modals/CommercialsModal/CommercialsModal';
import TvShowsModal from 'react/components/apps/audience/AudienceBuilder/Modals/TvShowsModal/TvShowsModal';
import {checkNameSensitivity, checkSensitivity} from 'react/utils/sensitivityUtils';
import ReviewSensitivityModal
    from 'react/components/apps/audience/AudienceBuilder/Modals/ReviewSensitivityModal/ReviewSensitivityModal';
import AudienceNameModal
    from 'react/components/apps/audience/AudienceBuilder/Modals/AudienceNameModal/AudienceNameModal';
import AudiencePreviewWidget
    from 'react/components/apps/audience/AudienceBuilder/AudiencePreviewWidget/AudiencePreviewWidget';
import SegmentsImageToggleButtonSelector
    from 'react/components/apps/audience/AudienceBuilder/SegmentsImageToggleButtonSelector/SegmentsImageToggleButtonSelector.js';
import AudienceTarget from 'react/components/apps/audience/AudienceBuilder/AudienceTarget/AudienceTarget';
import AudienceSecondaryBar
    from 'react/components/apps/audience/AudienceBuilder/AudienceSecondaryBar/AudienceSecondaryBar';
import CloneAudience from 'react/components/apps/audience/AudienceBuilder/CloneAudience/CloneAudience';
import add_tooltip from 'common/tooltip/tooltip.js';
import {
    getSegmentValuesSummary,
    hasActivateAudiencePermission,
    isActivateAudienceEnabled,
    SEGMENT_MAP,
    LOGICAL_OPERANDS
} from '../../data/audience-segment-builder-helper';
import {
    createAudienceTargetTaxonomy,
    createAudienceTargetUserList,
    createAmplifiedAudience,
    createAlwaysOnAudience,
    createDeterministicAudience,
    createUserListForDeterministicAudience,
    getDemographicsDataForPreviewBySegment,
    getFirstPartyDataByProgram,
    getLinkedinMetaData,
    getSegmentIds,
    getSegmentInterestsData,
    getSegmentParams,
    getTvCommercialsMetaData,
    getTvGenresMetaData,
    getTvNetworksMetaData,
    getTvShowsMetaData,
    getDataContract,
    getTaxonomyCategory,
} from '../../react/services/AudienceInsightsService';
import {QUERY_NAMES} from '../../react/services/AudienceServiceHelper';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';

const audienceBuilderModule = angular.module(__filename, [
    require("./modals/demographics-modal").name,
    require("./modals/interests-modal").name,
    require("./modals/websites-modal").name,
    require("./modals/linkedin/linkedin-demographics-modal").name,
    require("./modals/linkedin/linkedin-industries-modal").name,
    require("./modals/linkedin/linkedin-companies-modal").name,
    require("./modals/linkedin/linkedin-jobs-modal").name,
    require("./modals/linear-tv/linear-tv-demographics-modal").name,
    require("./modals/smart-tv/smart-tv-demographics-modal").name,
    require("./modals/load-audience/load-audience-modal").name,
    require("../../widgets/audience-preview-widget/audience-preview-widget").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
    require("data/audience-mgmt").name,
    require("./modals/apps-modal").name
]);

audienceBuilderModule
    .component('reactAudiencePreviewWidget', react2angular(AudiencePreviewWidget, [
        'activatedAdvertiser',
        'activatedAmplifiedThreshold',
        'activatedAlwaysOnThreshold',
        'activateAudienceDisabledText',
        'activatedMarket',
        'activatedDataContractId',
        'activatedDataContractText',
        'activatedCategoryId',
        'audienceDistributionService',
        'audienceId',
        'audienceName',
        'audienceSegment',
        'channel',
        'createAmplifiedAudience',
        'createAlwaysOnAudience',
        'createAudienceTargetTaxonomy',
        'createAudienceTargetUserList',
        'createDeterministicAudience',
        'createUserListForDeterministicAudience',
        'dspService',
        'getAmplifiedEstimatedReachGoal',
        'getDataContract',
        'getSegmentIds',
        'getTaxonomyCategory',
        'handleActivatingAudience',
        'handleActivatingTarget',
        'hasAmplificationModeSelector',
        'isActivateAudienceEnabled',
        'isActivateAudienceVisible',
        'isActivateEnabled',
        'isActivateTargetEnabled',
        'isActivateVisible',
        'isAudienceAmplifiedActivated',
        'isAudienceAlwaysOnActivated',
        'isAudienceDeterministicActivated',
        'isRequestCancelledService',
        'isTargetModalProcessing',
        'marketsAndAdvertisersPromise',
        'notificator',
        'onAudienceDataLoaded',
        'onExploreAudience',
        'onTargetAudienceSubmit',
        'ssoService',
        'trackTooNarrow',
        'updateAudienceAmplifiedActivated',
        'updateAudienceAlwaysOnActivated',
        'updateAudienceDeterministicActivated',
        'userId',
    ]))
    .component('lifestyleModal', react2angular(LifestyleModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'lifestyleInput', 'lifestyles']))
    .component('firstPartyModal', react2angular(FirstPartyModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'firstPartyInput', 'firstPartyPromise']))
    .component('segmentsImageToggleButtonSelector', react2angular(SegmentsImageToggleButtonSelector, ['segmentsData', 'selectedSegment', 'onToggle']))
    .component('commercialsModal', react2angular(CommercialsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'commercialsInput', 'commercialsMetadataPromise', 'channel', 'isTimeframeVisible']))
    .component('tvShowsModal', react2angular(TvShowsModal, ['isOpen', 'modalTitle', 'onSubmit', 'onCancel', 'tvShowsInput', 'channel', 'tvNetworksMetadataPromise', 'tvGenresMetadataPromise', 'tvShowsMetadataPromise', 'channel', 'isTimeframeVisible']))
    .component('reviewSensitivityModal', react2angular(ReviewSensitivityModal, ['isOpen', 'data', 'modalTitle', 'keepPhrasesText', 'removePhrasesText', 'onSubmit', 'onCancel', 'segmentMap']))
    .component('audienceNameModal', react2angular(AudienceNameModal, ['isOpen', 'origName', 'origErrorMessage', 'checkName', 'onSubmit', 'onCancel']))
    .component('audienceTarget', react2angular(AudienceTarget, ['marketsAndAdvertisersPromise', 'audienceId', 'audienceName',
        'audienceSegment', 'isActivateEnabled', 'isActivateAudienceEnabled', 'isActivateAudienceVisible', 'activateAudienceDisabledText',
        'isActivateTargetEnabled', 'isActivateVisible', 'channel', 'getSegmentIds', 'createAudienceTargetTaxonomy',
        'createAudienceTargetUserList', 'createAmplifiedAudience', 'createAlwaysOnAudience', 'notificator',
        'updateAudienceDeterministicActivated', 'dspService', 'isAudienceDeterministicActivated', 'activatedAmplifiedThreshold',
        'activatedAlwaysOnThreshold', 'isAudienceAmplifiedActivated', 'isAudienceAlwaysOnActivated', 'activatedMarket',
        'activatedAdvertiser', 'activatedDataContractId', 'activatedDataContractText', 'activatedCategoryId',
        'updateAudienceAmplifiedActivated', 'updateAudienceAlwaysOnActivated', 'ssoService', 'getAmplifiedEstimatedReachGoal',
        'createDeterministicAudience', 'createUserListForDeterministicAudience', 'handleActivatingAudience', 'handleActivatingTarget',
        'widgetName', 'hasAmplificationModeSelector', 'getDataContract', 'getTaxonomyCategory']))
    .component('audienceSecondaryBar', react2angular(AudienceSecondaryBar, ['hasNoSegments', 'disableLoadAudience', 'onNewAudienceClick', 'onLoadAudienceClick',
        'onSaveAudienceClick', 'isDropdownVisible', 'audienceChannels', 'selectedChannel', 'onChannelChange', 'isAudienceActivated', 'updateIsCloneAudienceOpen',
        'isCloneAudienceVisible']))
    .component('cloneAudience', react2angular(CloneAudience, ['createClonedAudience', 'updateIsCloneAudienceOpen', 'isOpen', 'audienceId', 'audienceName', 'audienceSegment']));

audienceBuilderModule.stateConfig = [{
    name: "audience-builder",
    url: "/audience-builder",
    params: {tokenStatus: null, channel: null},
    template: require("./audience-builder.html"),
    display: "Audience Discovery",
    data: {
        permissions: ['audience']
    },
    context: {
        audience_segment: "GeneralMold"
    },
    controller: audienceBuilderController
}];

audienceBuilderController.$inject = ['$scope', 'context', 'audienceMgmt', 'filtersPartition', 'demographicsModal',
    'interestsModal', 'websitesModal', 'loadAudienceModal', 'notificator', 'confirmAction',
    'linkedinDemographicsModal', 'linkedinJobsModal', 'linkedinCompaniesModal',
    'linkedinIndustriesModal', 'abiPermissions', 'linearTvDemographicsModal', 'smartTvDemographicsModal',
    '$stateParams', '$state', 'errorMgmt', '$window', '$rootScope', 'appsModal', 'dspService', 'ssoService', '$location',
    'audienceTableStructure', 'TargetsCommon', '$timeout'];

const regularSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "1st party", label: "1st Party", image: "./images/pages/audience-builder/images/1st_party_img.jpg", permission: 'first party segments'}
];

const sgSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"}
];

const linearTvSegmentTypes = [
    {value: "linearTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png", isDisabled: true, tagLabel: 'Coming soon'},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "linearTvCommercials", label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"}
];

const smartTvSegmentTypes = [
    {value: "smartTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "smartTvCommercials", label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"}
];

const gracenoteSegmentTypes = [
    {value: "smartTvDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png"},
    {value: "tvShows", label: "TV Shows", image: "./images/pages/audience-builder/images/tv_shows_img.jpg", permission: 'tv'},
    {value: "gracenoteCommercials", label: "Commercials", image: "./images/pages/audience-builder/images/commercials_img.png"}
];

const auTelcoSegmentTypes = [
    {value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png"},
    {value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png"},
    {value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png", debugOnly: true},
    {value: "apps", label: "Apps", image: "./images/pages/audience-builder/images/apps_img.png", isDisabled: true, tagLabel: 'Coming soon', debugOnly: true}
];

const linkedinSegmentTypes = [
    {value: "linkedinDemographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png"},
    {value: "linkedinIndustries", label: "Industries", image: "./images/pages/audience-builder/images/industries_img.jpg"},
    {value: "linkedinCompanies", label: "Companies", image: "./images/pages/audience-builder/images/companies_img.jpg"},
    {value: "linkedinJobs", label: "Jobs", image: "./images/pages/audience-builder/images/jobs_img.jpg"}
];

const GRACENOTE_EXCLUDED_LIFESTYLES = ['college_students', 'it_managers', 'job_seekers', 'luxury_shoppers', 'movers', 'movie_lovers', 'prospective_students', 'techies', 'travel'];

const channelToSegmentTypes = {
    linkedin: linkedinSegmentTypes,
    data_spark: sgSegmentTypes,
    snbb: sgSegmentTypes,
    au_telco: auTelcoSegmentTypes,
    articles: regularSegmentTypes,
    linear_tv: linearTvSegmentTypes,
    smart_tv: smartTvSegmentTypes,
    gracenote: gracenoteSegmentTypes
};

const audienceActivationPermissions = {
    smart_tv: "audience activation - channel: smart tv",
    web: "audience activation - channel: web"
};

function audienceBuilderController($scope, context, audienceMgmt, filtersPartition, demographicsModal,
    interestsModal, websitesModal, loadAudienceModal, notificator, confirmAction,
    linkedinDemographicsModal, linkedinJobsModal, linkedinCompaniesModal,
    linkedinIndustriesModal, abiPermissions, linearTvDemographicsModal, smartTvDemographicsModal,
    $stateParams, $state, errorMgmt, $window, $rootScope, appsModal, dspService, ssoService, $location,
    audienceTableStructure, TargetsCommon, $timeout) {

    let current_channel;
    let linkedinMetaDataPromise, tvGenresMetaDataPromise;
    let originalAudienceName = '';
    const DEMOGRAPHICS = ['demographics', 'linkedinDemographics', 'linearTvDemographicsModal', 'smartTvDemographicsModal'];
    const debugUser = $scope.$root.user.userType === 'debug';
    const userId = $scope.$root.user.id;
    const comingSoonLifestyle = $scope.$root.user.email.toLowerCase() === 'christian.sullivan@optus.com.au';
    const SEGMENT_MODAL_MAP = {
        demographics: demographicsModal, interests: interestsModal, websites: websitesModal,
        linkedinDemographics: linkedinDemographicsModal, linkedinJobs: linkedinJobsModal, linkedinCompanies: linkedinCompaniesModal,
        linkedinIndustries: linkedinIndustriesModal, linearTvDemographics: linearTvDemographicsModal,
        smartTvDemographics: smartTvDemographicsModal, apps: appsModal
    };
    const tvChannelToTvShowsMetaDataPromise = {}; // { articles: tvShowsMetaDataPromise, linear_tv: linearTvShowsMetaDataPromise, smart_tv: smartTvShowsMetaDataPromise, gracenote: gracenoteShowsMetaDataPromise}
    const tvChannelToTvNetworksMetaDataPromise = {}; // { articles: tvNetworksMetaDataPromise, linear_tv: linearTvNetworksMetaDataPromise, smart_tv: smartTvNetworksMetaDataPromise, gracenote: gracenoteNetworksMetaDataPromise}
    const CHANNEL_TO_LIFESTYLE_NAME_MAP = { articles: 'articlesBehavioralSegment',
                                            au_telco: 'auTelcoBehavioralSegment',
                                            data_spark: 'sgTelcoBehavioralSegment',
                                            smart_tv: 'inscapeBehavioralSegment' };
    const UPSELL_PAGE_REDIRECT_CHANNEL = 'gracenote';
    $scope.SEGMENT_MAP = SEGMENT_MAP;
    $scope.logicalOperands = LOGICAL_OPERANDS;
    validateChannelContext();
    validateFirstPartySegmentContext();
    loadFromContext();
    changeSegmentTypes(current_channel);
    disableEnableLinkedinSegments();

    MixpanelAudience.trackPageView('audience-builder', context.current.audience_app.current_channel.value);

    $scope.getSegmentIds = getSegmentIds;
    $scope.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = createAudienceTargetUserList;
    $scope.createAmplifiedAudience = createAmplifiedAudience;
    $scope.createAlwaysOnAudience = createAlwaysOnAudience;
    $scope.createDeterministicAudience = createDeterministicAudience;
    $scope.createUserListForDeterministicAudience = createUserListForDeterministicAudience;
    $scope.getDataContract = getDataContract;
    $scope.getTaxonomyCategory = getTaxonomyCategory;
    $scope.dspService = dspService;
    $scope.ssoService = ssoService;
    $scope.notificator = notificator;
    $scope.filtersPartition = filtersPartition;
    $scope.context = context;
    $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
    $scope.audience = $scope.audience || {};
    $scope.isCloneAudienceOpen = false;
    $scope.isTimeframeVisible = abiPermissions.hasPermission('tva custom timeframe');
    $scope.reactSegmentModals = {
        lifestyle: {
            isOpen: false,
            newTitle: "Add a lifestyle segment",
            editTitle: "Modify segment's lifestyle",
            lifestyles: getLifestyles((context.current.audience_app || {}).current_channel || {}, debugUser)
        },
        '1st party': {
            isOpen: false,
            newTitle: "Add a 1st party segment",
            editTitle: "Modify segment's 1st party"
        },
        'linearTvCommercials': {
            isOpen: false,
            newTitle: "Add a segment based on Linear TV commercial viewership",
            editTitle: "Modify segment's TV commercial viewership"
        },
        'smartTvCommercials': {
            isOpen: false,
            newTitle: "Add a segment based on Smart TV Inscape commercial viewership",
            editTitle: "Modify segment's TV commercial viewership"
        },
        'gracenoteCommercials': {
            isOpen: false,
            newTitle: "Add a segment based on Smart TV commercial viewership",
            editTitle: "Modify segment's TV commercial viewership"
        },
        tvShows: {
            isOpen: false,
            newTitle: getNewTvShowsTitle(current_channel),
            editTitle: "Modify segment's TV viewership"
        }
    };
    $scope.isActivateVisible = false;
    $scope.isActivateAudienceEnabled = false;
    $scope.isActivateAudienceVisible = false;
    $scope.activateAudienceDisabledText = '';
    $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);

    $scope.isActivateTargetEnabled = ()=>{
        return abiPermissions.hasPermission('activate targets') && current_channel !== 'linkedin';
    };

    $scope.handleActivatingTarget = ()=>{
        const segment = $scope.audience.segment;
        const channel = current_channel;
        TargetsCommon.openAudienceContextualTarget({
            segment,
            channel,
            phrasesPromise: ()=>getSegmentInterestsData($scope.audience.segment, QUERY_NAMES.phrases, current_channel),
            query: getSegmentParams(segment, QUERY_NAMES[$scope.tab], channel),
            audience_name: context.current.audience_app[current_channel].audience_name,
            $scope,
            program_id: context.current.p_id
        });
    };

    $scope.modalCancelHandler = (type) => {
        $scope.segmentType = null;
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);
        updatedReactSegmentModals[type].isOpen = false;
        updatedReactSegmentModals.input = {};
        $scope.reactSegmentModals = updatedReactSegmentModals;
        $scope.$digest();
    };

    $scope.modalSubmitHandler = (values) => {
        $scope.segmentType = null;
        const segmentType = _.get(values, 'type');
        if (!segmentType) return;
        const segmentIndex = values.index;
        const updatedAudienceSegment = _.cloneDeep($scope.audience.segment);
        const updatedReactSegmentModals = _.cloneDeep($scope.reactSegmentModals);

        if (_.isNumber(segmentIndex) && segmentIndex >= 0) {
            const segmentKeyDifferences = _.difference(Object.keys(updatedAudienceSegment[segmentIndex]), Object.keys(values));
            const updatedSegment = {..._.omit(updatedAudienceSegment[segmentIndex], segmentKeyDifferences), ...values};
            updatedAudienceSegment.splice(segmentIndex, 1, updatedSegment);
            $scope.audience.segment = updatedAudienceSegment;
            refreshContext();
        } else {
            const operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
            const newSegment = {operand, ...values};
            updatedAudienceSegment.push(newSegment);
            $scope.audience.segment = updatedAudienceSegment;
            if (current_channel === 'linkedin') disableEnableLinkedinSegments(segmentType);
            refreshContext();
        }

        updatedReactSegmentModals[segmentType].isOpen = false;
        $scope.reactSegmentModals = updatedReactSegmentModals;
        $scope.$digest();
    };

    function getLifestyles(currentChannel, debugUser) {
        let lifestyles = filtersPartition.behavioralSegment.filter((segment) => segment.debugOnly ? debugUser : true);
        if (currentChannel.value === 'gracenote') {
            lifestyles = lifestyles.filter((segment) => !GRACENOTE_EXCLUDED_LIFESTYLES.includes(segment.value));
        }

        const channelLifestylesName = CHANNEL_TO_LIFESTYLE_NAME_MAP[currentChannel.value];

        if (channelLifestylesName) {
            const channelLifestyles = filtersPartition[channelLifestylesName].filter((segment) => segment.debugOnly ? debugUser : true);;
            return _.orderBy([...channelLifestyles, ...lifestyles], 'label', 'asc');
        }

        return lifestyles;
    }

    function getNewTvShowsTitle(currentChannel) {
        return "Add a segment based on " + getTvType(currentChannel) + " TV viewership";
    }

    function updateModalLifestyles(channel) {
        if (!_.has($scope.reactSegmentModals, 'lifestyle.lifestyles')) return;
        $scope.reactSegmentModals.lifestyle.lifestyles = getLifestyles(channel, debugUser);
    }

    function updateModalTvShows(channel) {
        $scope.reactSegmentModals.tvShows.newTitle = getNewTvShowsTitle(channel);
    }

    function validateChannelContext() {
        $scope.audienceChannelsFilter = _.filter($scope.$root.audienceChannelsFilter, channel => (debugUser || !channel.debugOnly));
        if (!context.current.audience_app || !_.includes(_.map($scope.audienceChannelsFilter, 'value'), (context.current.audience_app.current_channel || {}).value)
            || (context.current.audience_app.current_channel.permission != null && !abiPermissions.hasPermission(context.current.audience_app.current_channel.permission))) {
            context.current.audience_app = {current_channel: common.getAvailableContext($scope.audienceChannelsFilter, abiPermissions)};
        }
    }

    function loadFromContext() {
        current_channel = context.current.audience_app.current_channel.value;
        if (!context.current.audience_app[current_channel]) context.current.audience_app[current_channel] = {};
        let channel_audience = context.current.audience_app[current_channel];

        $scope.audience = {
            id: channel_audience.audience_id || '',
            name: channel_audience.audience_name || '',
            segment: channel_audience.audience_segment || [],
            activation: channel_audience.audience_activation,
        };
        $scope.advancedSegmentation = channel_audience.audience_advancedSegmentation || false;
        $scope.isAudienceDeterministicActivated = channel_audience.is_audience_deterministic_activated || false;
        $scope.isAudienceAmplifiedActivated = channel_audience.is_audience_amplified_activated || false;
        $scope.isAudienceAlwaysOnActivated = channel_audience.is_audience_always_on_activated || false;
        $scope.activatedAmplifiedThreshold = channel_audience.activated_amplified_threshold || null;
        $scope.activatedAlwaysOnThreshold = channel_audience.activated_always_on_threshold || null;
        $scope.activatedMarket = channel_audience.activated_market;
        $scope.activatedAdvertiser = channel_audience.activated_advertiser;
        $scope.activatedDataContractId = channel_audience.activated_data_contract_id;
        $scope.activatedDataContractText = channel_audience.activated_data_contract_text;
        $scope.activatedCategoryId = channel_audience.activated_category_id;
        if (_.isEmpty(channel_audience.audience_logicalOperand)) {
            $scope.logicalOperand = $scope.logicalOperands[0];
        } else {
            $scope.logicalOperand = _.find($scope.logicalOperands, {value: channel_audience.audience_logicalOperand.value});
        }
        if (!$scope.advancedSegmentation) _.each($scope.audience.segment, (s) => s.operand = $scope.logicalOperand);
    }

    $scope.onLogicalOperandChange = function (selectedOperand) {
        $scope.logicalOperand = selectedOperand;
        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
        _.each(newAudienceSegment, (segment) => segment.operand = selectedOperand);
        $scope.audience.segment = newAudienceSegment;
        refreshContext();
    };

    $scope.onToggleAdvancedSegmentation = function (on) {
        MixpanelAudience.trackAdvancedSegmentation(context.current.audience_app.current_channel.value);
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        if (!on) {
            var advancedOperands = _($scope.audience.segment).map('operand.value').uniq().value();
            if (advancedOperands.length === 1) {
                var operand = _.find($scope.logicalOperands, {value: advancedOperands[0] || 'and'});
                $scope.logicalOperand = operand ? operand : $scope.logicalOperand;
            }
            let newAudienceSegment = _.cloneDeep($scope.audience.segment);
            _.each(newAudienceSegment, (segment) => segment.operand = $scope.logicalOperand);
            $scope.audience.segment = newAudienceSegment;
            refreshContext();
        }
        $scope.advancedSegmentation = on;
    };

    $scope.loadAudience = function () {
        getConfirmation(true, function () {
            loadAudienceModal.showModal(current_channel, $scope.isActivateAudienceVisible).then(function (modal) {
                modal.close.then(function (value) {
                    // When the user uses browse back btn, there is no "segments" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "value" and segments" attribute.
                    if (value && !_.isEmpty(value.segments)) {
                        MixpanelAudience.trackLoadAudience(value.name, context.current.audience_app.current_channel.value);
                        $scope.audience = {
                            id: value.id,
                            name: value.name,
                            segment: _.cloneDeep(value.segments),
                            activation: _.cloneDeep(value.activation),
                        };
                        originalAudienceName = value.name;
                        $scope.advancedSegmentation = value.advancedSegmentation;
                        $scope.isAudienceDeterministicActivated = !!value.activation?.deterministic;
                        $scope.isAudienceAmplifiedActivated = !!value.activation?.amplified;
                        $scope.isAudienceAlwaysOnActivated = !!value.activation?.always_on;
                        $scope.activatedAmplifiedThreshold = value.activation?.amplified?.threshold;
                        $scope.activatedAlwaysOnThreshold = value.activation?.always_on?.threshold;
                        $scope.activatedMarket = value.activation?.market;
                        $scope.activatedAdvertiser = value.activation?.advertiser;
                        $scope.activatedDataContractId = value.activation?.data_contract_id;
                        $scope.activatedDataContractText = value.activation?.data_contract_text;
                        $scope.activatedCategoryId = value.activation?.category_id;
                        $scope.logicalOperand = !$scope.advancedSegmentation && value.segments[0].operand ? value.segments[0].operand : $scope.logicalOperands[0];
                        notificator.success({body: `"${$scope.audience.name}" audience loaded successfully`});
                        clearNameErrors();
                        refreshContext();
                    }

                    getAudiencesForCurrentChannel(true);
                    if (current_channel === "linkedin") disableEnableLinkedinSegments();
                });
            });
        });
    };

    $scope.onChannelFilterChange = (channel) => {
        context.current.audience_app.current_channel = channel;
    };

    function getConfirmation(load, callback) {
        if (!$scope.audienceDirty() || $scope.isAudienceActivated()) {
            callback();
        } else {
            var confirm = 'Continue without saving';
            var cancel = 'Cancel';
            var title = 'Audience unsaved';
            var msg = `Are you sure you want to ${load ? 'load' : 'create'} a new audience
                       without saving your changes to this audience?`;

            confirmAction.getConfirmation(msg, confirm, cancel, title, 'audience-confirmation', true).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation === true) {
                        callback();
                    }
                });
            });
        }
    }

    $scope.audienceDirty = function () {
        const current_audience = _.find(programAudiences, {id: $scope.audience.id}) || {segments: [], name: '', advancedSegmentation: false};
        return angular.toJson(current_audience.segments) !== angular.toJson($scope.audience.segment)
            || current_audience.name !== $scope.audience.name
            || current_audience.advancedSegmentation !== $scope.advancedSegmentation;
    };

    $scope.resetAudience = function () {
        getConfirmation(false, function () {
            MixpanelAudience.trackNewAudience({ channel: current_channel });
            $scope.audience = {name: '', segment: []};
            originalAudienceName = '';
            $scope.advancedSegmentation = false;
            $scope.isAudienceDeterministicActivated = false;
            $scope.isAudienceAmplifiedActivated = false;
            $scope.isAudienceAlwaysOnActivated = false;
            $scope.activatedAmplifiedThreshold = null;
            $scope.activatedAlwaysOnThreshold = null;
            $scope.activatedMarket = null;
            $scope.activatedAdvertiser = null;
            $scope.activatedDataContractId = null;
            $scope.activatedDataContractText = null;
            $scope.activatedCategoryId = null;
            $scope.logicalOperand = $scope.logicalOperands[0];
            const audienceApp = context.current.audience_app[current_channel];
            audienceApp.audience_id = null;
            refreshContext();
            clearNameErrors();
            if (current_channel === "linkedin") disableEnableLinkedinSegments();
        });
    };

    $scope.deleteSegment = function (index) {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
        let deletedSegment = newAudienceSegment.splice(index, 1)[0];
        $scope.audience.segment = newAudienceSegment;
        disableEnableLinkedinSegments(deletedSegment.type);
        refreshContext();
    };

    $scope.editSegment = function (index) {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        const type = $scope.audience.segment[index].type;
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];

        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].editTitle;
            $scope.reactSegmentModals[type].input = {...$scope.audience.segment[index], index};
            return;
        }

        const disableGeo = DEMOGRAPHICS.includes(type) && _.filter($scope.audience.segment, (seg) => DEMOGRAPHICS.includes(seg.type)).length > 1;

        modal.showModal($scope.audience.segment[index], false, disableGeo, current_channel,
            {
                linkedinMetaDataPromise, abiPermissions, debugUser
            }).then(function (modal) {
                modal.close.then(function (value) {
                    $scope.segmentType = null;
                    // When the user uses browse back btn, there is no "type" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "type" attribute.
                    if (value && value["type"]) {
                        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
                        newAudienceSegment[index] = value;
                        $scope.audience.segment = newAudienceSegment;
                        refreshContext();
                    }
                });
            })
    };

    $scope.newSegment = function (type) {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        $scope.segmentType = type;
        const modal = SEGMENT_MODAL_MAP[type];

        MixpanelAudience.trackSegment(type, context.current.audience_app.current_channel.value);
        if (!modal) {
            $scope.reactSegmentModals[type].isOpen = true;
            $scope.reactSegmentModals[type].modalTitle = $scope.reactSegmentModals[type].newTitle;
            $scope.reactSegmentModals[type].input = {};
            return;
        }

        const prevDefinedGeo = _.filter($scope.audience.segment, (seg) => DEMOGRAPHICS.includes(seg.type))[0] || {};
        const disableGeo = DEMOGRAPHICS.includes(type) && Object.keys(prevDefinedGeo).length > 0;
        const initialDefinedProperties = disableGeo ? {geo: prevDefinedGeo.geo, ethnicity: prevDefinedGeo.ethnicity, income: prevDefinedGeo.income} : null;

        modal.showModal(initialDefinedProperties, true, disableGeo, current_channel,
            {
                linkedinMetaDataPromise, abiPermissions, debugUser
            }).then(function (modal) {
                modal.close.then(function (value) {
                    $scope.segmentType = null;
                    if (value && value["type"]) {
                        value.operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
                        let newAudienceSegment = _.cloneDeep($scope.audience.segment);
                        newAudienceSegment.push(value);
                        $scope.audience.segment = newAudienceSegment;
                        refreshContext();
                        disableEnableLinkedinSegments(value["type"]);
                    }
                });
            });
    };

    async function isInvalidName() {
        const {audience: {id, name}} = $scope;
        $scope.name_missing = !name;
        if ($scope.name_missing) return true;

        $scope.name_exist_in_program = await isNameExists(id, name);
        return $scope.name_exist_in_program;
    }

    async function isNameExists(id, name) {
        const audiences = await getAudiencesForCurrentChannel();
        return !!audiences.find((a) => a.id !== id && a.name.toLowerCase() === name.toLowerCase());
    }

    async function isSensitiveName() {
        const {audience: {name}} = $scope;
        const {passed, text} = await checkNameSensitivity(name);
        $scope.name_is_insensitive = !passed;
        $scope.sensitiveTitleText = text;
        return $scope.name_is_insensitive;
    }

    function handleAudienceSensitivity(audience, props) {
        return new Promise(async (resolve, reject) => {
            try {
                const invalidName = await isSensitiveName();
                if (invalidName) return resolve({ canceled: true });

                if (!$scope.isActivateAudienceEnabled) return resolve({canceled: false, isDirty: false});

                const {
                    reviewSensitivityHandler,
                    sensitivityData,
                } = await checkSensitivity(audience, (newSegments) => {
                    if (newSegments) audience.segments = newSegments;

                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ isDirty: !_.isEmpty(newSegments) });
                });

                if (_.isEmpty(sensitivityData)) return resolve({ isDirty: false });

                const cancelHandler = () => {
                    $scope.reviewSensitivity = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                };
                $scope.reviewSensitivity = {
                    isOpen: true,
                    data: sensitivityData,
                    onCancel: cancelHandler,
                    onSubmit: reviewSensitivityHandler,
                    ...props,
                };
                $scope.$apply();
            } catch (e) {
                reject(e);
            }
        });
    }

    let saveInProcess = false;
    async function saveAudience(props) {
        if (saveInProcess) return { canceled: true };

        saveInProcess = true;
        try {
            const invalidName = await isInvalidName();
            if (invalidName) return { canceled: true };

            const audience = {
                id: $scope.audience.id,
                name: $scope.audience.name,
                segments: _.cloneDeep($scope.audience.segment), // not sure why the audience has "segment" and not "segments"
                advancedSegmentation: $scope.advancedSegmentation,
                channel: current_channel,
            };
            const {canceled, isDirty} = ['smart_tv', 'gracenote', 'articles'].includes(current_channel)
             ? await handleAudienceSensitivity(audience, props)
             : {canceled: false, isDirty: false};
            if (canceled) return { canceled: true };

            // skip saving of audience if there are no segments to save (because removing the sensitive ones cleared everything)
            $scope.audience.segment = audience.segments;
            if (_.isEmpty(audience.segments)) return { canceled: true };

            // skip saving of audience if it is not changed
            if (!isDirty && !$scope.audienceDirty()) return { canceled: false };

            const res = await (
              $scope.audience.id ? audienceMgmt.update($scope.audience.id, {data: audience}) : audienceMgmt.create({data: audience})
            );
            const rename = originalAudienceName && res.name !== originalAudienceName;
            const msg = rename ? `The audience "${originalAudienceName}" was renamed to "${audience.name}" and saved successfully`
                            : `The audience "${audience.name}" was saved successfully`;
            notificator.success({body: msg});
            await finalizeSave(res);
            return { canceled: false };
        } catch (e) {
            const msg = 'Failed saving audience';
            console.error(msg, e);
            notificator.error({ body: msg });
            return { canceled: true };
        } finally {
            saveInProcess = false;
        }
    }

    function trackSaveAudience(segments) {
        const inputSegments = segments.map(
          ({required = [], included = []}) => [
              ...required.map(sr => sr.text),
              ...included.map(si => si.text),
          ]
        );
        const trackProps = {
            channel: current_channel,
            program: context.program.name,
            segment: (context.current.audience_app || {})[current_channel],
            "input segments": inputSegments
        };
        MixpanelAudience.trackSaveAudience(trackProps);
    }

    function finalizeSave(audience) {
        $scope.audience.id = audience.id;
        $scope.audience.name = audience.name;
        trackSaveAudience(audience.segments);
        refreshContext();
        clearNameErrors();
        return getAudiencesForCurrentChannel(true);
    }

    $scope.saveAudience = () => {
        return saveAudience({
            modalTitle: 'Review before saving',
            keepPhrasesText: 'Save Audience',
            removePhrasesText: 'Remove & Save',
        });
    };

    async function checkDemographicsData(segments, options) {
        const { status, error } = await getDemographicsDataForPreviewBySegment(segments, options);
        if (status === 'ok') return true;

        const {reason} = error;
        switch (reason) {
            case 'selectionTooNarrow':
                notificator.error('Your audience is too narrow and cannot be activated. Please refine your audience.');
                break;
            case 'selectionTooWide':
                notificator.error('Your audience is too wide and cannot be activated. Please refine your audience.');
                break;
            case 'no_results':
            case 'too_many_entities':
            default:
                // do nothing
        }

        return false;
    }

    async function checkName(id, name) {
        if (!name) return { isValid: false };

        const nameExists = await isNameExists(id, name);
        if (nameExists) return {
            error: `The name "${name}" already exists. Please choose a different name.`,
            isValid: false,
        };

        const {passed, text} = await checkNameSensitivity(name);
        if (!passed) return { error: text, isValid: false };

        return { isValid: true };
    }

    function getAudienceName(id, origName) {
        return new Promise(async (resolve) => {
            const { isValid, error } = await checkName(id, origName);
            if (isValid) {
                return resolve({ name: origName });
            }

            $scope.audienceNameProps = {
                isOpen: true,
                origName,
                origErrorMessage: error,
                checkName: (name) => checkName(id, name),
                onCancel() {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ canceled: true });
                },
                onSubmit(name) {
                    $scope.audienceNameProps = { isOpen: false };
                    $scope.$apply();
                    resolve({ name });
                },
            };
        });
    }

    $scope.handleActivatingAudience = async () => {
        const {audience: {id, name: origName}} = $scope;
        const {name, canceled: nameCanceled} = await getAudienceName(id, origName);
        if (nameCanceled) return { canceled: true };

        $scope.audience.name = name;
        const { canceled: saveCanceled } = await saveAudience({
            modalTitle: 'Review before activating',
            keepPhrasesText: 'Activate Audience',
            removePhrasesText: 'Remove & Activate',
        });
        if (saveCanceled) return { canceled: true };

        const isBidstream = current_channel === 'articles';
        const demographicsDataOk = await checkDemographicsData($scope.audience.segment, {
            channel: current_channel,
            userId,
            isBidstream,
            minimalSize: isBidstream ? 2000 : 1500,
            filterBidstreamDomains: isBidstream,
        });
        if (!demographicsDataOk) return { canceled: true };

        return {
            audienceId: $scope.audience.id,
            audienceName: $scope.audience.name,
        };
    };

    $scope.clearNameErrors = clearNameErrors;

    function clearNameErrors() {
        $scope.name_exist_in_program = false;
        $scope.name_missing = false;
        $scope.name_is_insensitive = false;
        $scope.sensitiveTitleText = '';
        $scope.cloned_audience_name_auto_generated = false;
    }

    let programAudiences = [];

    function getAudiencesForCurrentChannel(force = false) {
        const audienceApp = context.current.audience_app[current_channel];

        // the best way to check if a value is a promise
        // https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise/38339199#38339199
        if (force || Promise.resolve(audienceApp.getProgramAudiencePromise) !== audienceApp.getProgramAudiencePromise) {
            audienceApp.getProgramAudiencePromise = getProgramAudience(current_channel);
        }

        return audienceApp.getProgramAudiencePromise;
    }

    async function getProgramAudience(channel) {
        const audienceApp = context.current.audience_app[channel];
        programAudiences = await audienceMgmt.list();
        const currentAudience = _.find(programAudiences, {name: $scope.audience.name}) || {};
        $scope.audience.id = currentAudience.id;
        originalAudienceName = currentAudience.name;
        $scope.disableLoadAudience = programAudiences.length === 0;
        // map audience ids to names, for later display
        audienceApp.audience_ids = programAudiences.reduce((obj, aud) => {
            obj[aud.id] = aud.name;
            return obj;
        }, {});
        audienceApp.audience_id = currentAudience.id;
        await updateAudienceAppForChannel(channel, $scope.audience);
        return programAudiences;
    }

    async function updateAudienceAppForChannel(channel, audience) {
        context.current.audience_app[channel] = {
            ...context.current.audience_app[channel],
            audience_targetDisabled: !$scope.hasAudienceData,
            is_activate_target_enabled: $scope.isActivateTargetEnabled(),
            is_audience_deterministic_activated: $scope.isAudienceDeterministicActivated,
            is_audience_amplified_activated: $scope.isAudienceAmplifiedActivated,
            is_audience_always_on_activated: $scope.isAudienceAlwaysOnActivated,
            activated_amplified_threshold: $scope.activatedAmplifiedThreshold,
            activated_always_on_threshold: $scope.activatedAlwaysOnThreshold,
            activated_market: $scope.activatedMarket,
            activated_advertiser: $scope.activatedAdvertiser,
            activated_data_contract_id: $scope.activatedDataContractId,
            activated_data_contract_text: $scope.activatedDataContractText,
            activated_category_id: $scope.activatedCategoryId,
            is_audience_dirty: $scope.audienceDirty(),
            finalizeSave: finalizeSave,
        };
        $scope.isActivateAudienceEnabled = false;
        $scope.activateAudienceDisabledText = '';
        const {isEnabled, isVisible, disabledText} = await isActivateAudienceEnabled(abiPermissions, channel, audience, userId);
        $scope.isActivateAudienceEnabled = isEnabled;
        $scope.isActivateAudienceVisible = isVisible;
        $scope.activateAudienceDisabledText = disabledText;
        $scope.isActivateVisible = isVisible || $scope.isActivateTargetEnabled();
        $scope.$digest();
    }

    getAudiencesForCurrentChannel(true);
    var unbindContextListener = context.onChange(function (newVal, oldVal) {
        if (oldVal && newVal.p_id === oldVal.p_id) return;
        clearNameErrors();
        loadFromContext();
        getAudiencesForCurrentChannel();
    });

    $scope.$on('$destroy', unbindContextListener);
    $scope.refreshContext = refreshContext;

    async function refreshContext(channel = current_channel) {
        const channelAudience = context.current.audience_app[channel];
        channelAudience.audience_segment = _.cloneDeep($scope.audience.segment);
        channelAudience.audience_activation = _.cloneDeep($scope.audience.activation);
        channelAudience.audience_name = $scope.audience.name;
        channelAudience.audience_id = $scope.audience.id;
        channelAudience.audience_advancedSegmentation = $scope.advancedSegmentation;
        channelAudience.audience_logicalOperand = _.cloneDeep($scope.logicalOperand);
        delete channelAudience.audience_interestsToTest;
        await updateAudienceAppForChannel(channel, $scope.audience);
    }

    var nameInput = angular.element(document.querySelector('.segment-name'));
    var inputFont = nameInput.css('font');

    $scope.rename = function (onBlur) {
        if (saveInProcess) return;
        if (!onBlur) document.querySelector('.segment-name').blur();
        if ($scope.audience.name === originalAudienceName || $scope.audience.segment.length === 0) return;
        $scope.saveAudience();
    };

    $scope.calcAsteriskLeft = function () {
        return 38 + common.getTextWidth($scope.audience.name, inputFont) * 1.4;
    };

    function getTooltipOffsets(index, textLength) {
        let elemWidth = $(".audience-builder").width();
        let limit = ($rootScope.filterMenuOpen ? 800 : 1035);
        let offset = _.max([(elemWidth - limit), 0]);
        return {x: (offset / 10) * index - textLength, y: elemWidth > limit + 200 ? -20 : -50};
    }

    $scope.getSegmentValuesSummary = function (segment) {
        return getSegmentValuesSummary(segment, filtersPartition);
    };

    $scope.onLogicOperandChange = function (segment, value, index) {
        if ($scope.isAudienceActivated()) return $scope.updateIsCloneAudienceOpen(true);
        const clonedAudienceSegment = _.cloneDeep($scope.audience.segment);
        // Because the operand is changed mutably by the am-input, the prevProps and currentProps are the same, thus
        // widget update is not triggered
        clonedAudienceSegment[index].immutableOperand = value;
        $scope.audience.segment = clonedAudienceSegment;
        refreshContext();
    };

    // When you switch to a new program on audience_builder page.
    $scope.$watch('context.program', function () {
        if (context.current.audience_app) return;
        context.current.audience_app = {current_channel: $scope.audienceChannelsFilter.find(channel => channel.value === current_channel)};
        loadFromContext();
        getAudiencesForCurrentChannel();
    });

    $scope.$watch('context.current.audience_app.current_channel', channelChanged);
    $scope.$watch('context.current.program', channelChanged);
    $($window).on("resize", recreateTooltips);
    let isRedirected = false;

    function channelChanged(newChannel = {}, oldChannel = {}) {
        if (_.isEmpty(newChannel) && _.isEmpty(oldChannel)) return;
        setDisabledFirstPartyModalValue(true, newChannel.value);
        $scope.channelAuthorized = false;
        const channelParam = $stateParams.channel || $location.search().channel;

        if (channelParam) {
            const nextChannel = (channelParam === 'smart_tv') ? 'gracenote' : channelParam;
            $stateParams.channel = null;
            if ($location.search().hasOwnProperty('channel')) $location.search('channel', null);
            isRedirected = true;
            context.current.audience_app[current_channel].getProgramAudiencePromise = undefined;
            return validateChannelFromFilters(nextChannel);
        }

        $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
        $scope.channelFilterSideMenuSelectedChannel = $scope.audienceChannelsFilter.find((channel) => channel.value === context.current.audience_app.current_channel.value);
        channelAuthorizationCheck(newChannel).then((authorizationStatus) => {
            if (!authorizationStatus) return;
            if (!authorizationStatus.authorized) return handleChannelNotAuthorized(newChannel, oldChannel, authorizationStatus.error);
            $scope.channelAuthorized = true;
            if (newChannel.value === 'articles') handleArticlesChannel(newChannel);
            if (newChannel.value === 'linear_tv') handleLinearTvChannel(newChannel);
            if (newChannel.value === 'smart_tv') handleInscapeChannel(newChannel);
            if (newChannel.value === 'gracenote') handleGracenoteChannel(newChannel);
            if (newChannel.value === "linkedin") linkedinMetaDataPromise = getLinkedinMetaData(context.current.u_id);
            if (newChannel.value === oldChannel.value) return;
            changeSegmentTypes(newChannel.value);
            updateModalLifestyles(newChannel);
            updateModalTvShows((newChannel || {}).value);
            isRedirected ? isRedirected = false : refreshContext(oldChannel.value);
            loadFromContext();
            getAudiencesForCurrentChannel();
        })
    }

    function setDisabledFirstPartyModalValue(value, channel) {
        $scope.noFirstPartySegments = value;
        setDisabledSegmentTypes(channel);
    }

    function removeNoSegmentsTooltips() {
        $(".qtip.no-segments").remove();
    }

    function channelAuthorizationCheck(channel) {
        let tokenStatus = $stateParams.tokenStatus;
        $stateParams.tokenStatus = null;
        if (channel.value === 'linkedin' || (channel.value === 'linkedin' && tokenStatus)) return validateUserAuthentication(context.current.u_id, tokenStatus, errorMgmt);
        return Promise.resolve({authorized: true});
    }

    function handleChannelNotAuthorized(newChannel, oldChannel, error) {
        console.info(`could not switch to ${newChannel.value} channel: ${error}`);
        context.current.audience_app.current_channel = $scope.audienceChannelsFilter.find(channel => channel.value === 'articles');
        $scope.channelFilterSideMenuSelectedChannel = context.current.audience_app.current_channel;
    }

    function disableEnableLinkedinSegments(type) {
        if (current_channel === "linkedin" && type) {
            let relevantSegmentType = linkedinSegmentTypes.find(segmentType => segmentType.value == type);
            Object.assign(relevantSegmentType, {isDisabled: !relevantSegmentType.isDisabled});
        } else {
            let segments = ((context.current.audience_app.linkedin || []).audience_segment || []).map(segment => segment.type);
            linkedinSegmentTypes.forEach(function (linkedinSegmentType) {
                Object.assign(linkedinSegmentType, {isDisabled: segments.includes(linkedinSegmentType.value)});
            });
        }
        changeSegmentTypes(current_channel);
    }

    function setDisabledSegmentTypes(channel) {
        $scope.segmentTypes = $scope.segmentTypes.map((segment) => {
            if (segment.value === '1st party') {
                return $scope.noFirstPartySegments ? {
                        ...segment,
                        isDisabled: true,
                        tooltip: 'No segments applied for this program'
                    }
                    : _.omit(segment, ['isDisabled', 'tooltip']);
            }

            if (comingSoonLifestyle && channel === 'articles' && segment.value === 'lifestyle') return {...segment, isDisabled: true, tagLabel: 'Coming soon'};

            if (['smart_tv', 'gracenote'].includes(channel)
                && ['interests', 'websites'].includes(segment.value)
                && abiPermissions.hasPermission('smart tva lite')) {
                return {
                    ...segment,
                    isDisabled: true,
                    tooltip: 'Contact your Amobee Account Manager to learn more about advanced insights-to-activation.',
                };
            }

            return segment;
        });
        recreateTooltips();
    }

    function recreateTooltips() {
        removeNoSegmentsTooltips();
        _.each($scope.segmentTypes, function (segment, i) {
            if (segment.tooltip) {
                let offsets = getTooltipOffsets(i, segment.tooltip.length);
                let element = $("toggle-button." + _.snakeCase(segment.value));
                let options = {
                    prerender: true,
                    position: {
                        at: 'center',
                        adjust: {
                            x: offsets.x,
                            y: offsets.y
                        }
                    },
                    style: {classes: "no-segments common-tooltip-info"}
                };
                add_tooltip(element, 'info', options);
            }
        });
    }

    function debugOnlySegmentsOmitKeys(segments, debugUser) {
        return _.map(segments, (segment) => (
            debugUser && segment.debugOnly ? _.omit(segment, ['isDisabled', 'tagLabel']) : segment
        ));
    }

    function changeSegmentTypes(channel) {
        $scope.segmentTypes = channelToSegmentTypes[channel];
        $scope.segmentTypes = $scope.segmentTypes.filter((segmentType) =>
            segmentType.debugOnly ? (debugUser || segmentType.tagLabel) :
                (!segmentType.permission || abiPermissions.hasPermission(segmentType.permission))
        );
        $scope.segmentTypes = debugOnlySegmentsOmitKeys($scope.segmentTypes, debugUser);
        setDisabledSegmentTypes(channel);
    }

    function validateFirstPartySegmentContext() {
        if (abiPermissions.hasPermission('first party segments') || !_.has(context.current.audience_app, 'articles.audience_segment')) return;
        context.current.audience_app.articles.audience_segment = _.reject(context.current.audience_app.articles.audience_segment, ['type', '1st party']);
    }

    function handleArticlesChannel (newChannel) {
        if (abiPermissions.hasPermission('first party segments')) {
            $scope.reactSegmentModals['1st party'].firstPartyPromise =
                getFirstPartyDataByProgram(
                    context.program, (data) => setDisabledFirstPartyModalValue(_.isEmpty(data), newChannel.value));
        }

        startTvMetadataPromises(newChannel);
    }

    function handleLinearTvChannel (newChannel) {
        $scope.reactSegmentModals.linearTvCommercials.commercialsMetadataPromise = getTvCommercialsMetaData(newChannel.value);
        startTvMetadataPromises(newChannel);
    }

    function handleInscapeChannel (newChannel) {
        $scope.reactSegmentModals.smartTvCommercials.commercialsMetadataPromise = getTvCommercialsMetaData(newChannel.value);
        startTvMetadataPromises(newChannel);
    }

    function handleGracenoteChannel (newChannel) {
        $scope.reactSegmentModals.gracenoteCommercials.commercialsMetadataPromise = getTvCommercialsMetaData(newChannel.value);
        startTvMetadataPromises(newChannel);
    }

    function startTvMetadataPromises (newChannel) {
        $scope.reactSegmentModals.tvShows.tvNetworksMetadataPromise = getTvNetworksMetaData(newChannel.value);
        $scope.reactSegmentModals.tvShows.tvGenresMetadataPromise = getTvGenresMetaData();
        $scope.reactSegmentModals.tvShows.tvShowsMetadataPromise = getTvShowsMetaData(newChannel.value);
        if ($scope.hasActivateAudiencePermission(newChannel.value)) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');
    }

    function getTvType(channel) {
        if (channel === 'linear_tv') return 'Linear';
        if (channel === 'smart_tv') return 'Smart';
        return 'online';
    }

    function validateChannelFromFilters(nextChannel) {
        if (!nextChannel) return;
        const channelFromFilter = $scope.audienceChannelsFilter.find(channel => channel.value === nextChannel);
        if (!channelFromFilter && nextChannel === UPSELL_PAGE_REDIRECT_CHANNEL) return redirectToTVAUpsellPage();
        if (channelFromFilter && nextChannel) {
            context.current.audience_app.current_channel = channelFromFilter;
            $scope.channelFilterSideMenuSelectedChannel = channelFromFilter;
        }
        return $scope.channelAuthorized = true;
    }

    function redirectToTVAUpsellPage() {
        sessionStorage['login_message'] = 'To gain access to TVA audience activation,</br>contact your account manager today.</br></br></br>';
        return $window.location.replace('/login_message');
    }

    $scope.hasActivateAudiencePermission = function (channel) {
        return hasActivateAudiencePermission(abiPermissions, channel);
    };

    $scope.updateAudienceAmplifiedActivated = function (activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold) {
        common.updateAudienceAmplifiedActivated(activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold,
                                                context, $scope, current_channel);
        $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
        updateAudienceAppForChannel(current_channel, $scope.audience);
    };

    $scope.updateAudienceAlwaysOnActivated = function (activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                       activatedAlwaysOnThreshold) {
        $timeout(() => {
            common.updateAudienceAlwaysOnActivated(activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                   activatedAlwaysOnThreshold, context, $scope, current_channel);
            $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
            updateAudienceAppForChannel(current_channel, $scope.audience);
        });
    };

    $scope.updateAudienceDeterministicActivated = function (activatedMarket, activatedAdvertiser) {
        common.updateAudienceDeterministicActivated(activatedMarket, activatedAdvertiser, context, $scope, current_channel);
        $scope.audience.activation = context.current.audience_app[current_channel].audience_activation;
        updateAudienceAppForChannel(current_channel, $scope.audience);
    };

    $scope.isAudienceActivated = () => ($scope.isAudienceDeterministicActivated || $scope.isAudienceAmplifiedActivated);

    $scope.updateIsCloneAudienceOpen = (isOpen) => $scope.isCloneAudienceOpen = isOpen;

    $scope.onLogicOperandSummaryClick = () => $scope.updateIsCloneAudienceOpen(true);

    $scope.createClonedAudience = () => {
        $scope.audience = {...$scope.audience, name: `${$scope.audience.name}_copy`, activation: undefined};
        delete $scope.audience.id;
        originalAudienceName = '';
        $scope.isAudienceDeterministicActivated = false;
        $scope.isAudienceAmplifiedActivated = false;
        $scope.isAudienceAlwaysOnActivated = false;
        $scope.activatedAmplifiedThreshold = null;
        $scope.activatedAlwaysOnThreshold = null;
        $scope.activatedMarket = null;
        $scope.activatedAdvertiser = null;
        $scope.activatedDataContractId = null;
        $scope.activatedDataContractText = null;
        $scope.activatedCategoryId = null;
        refreshContext();
        $scope.cloned_audience_name_auto_generated = true;
        $scope.updateIsCloneAudienceOpen(false);
    };

    $scope.getAmplifiedEstimatedReachGoal = (bidstreamUsers, amplifiedReachGoalValue) => (new Promise((resolve) => setTimeout(() => resolve(bidstreamUsers * (amplifiedReachGoalValue / 100) + _.random(0, 99) * 1000), 2000)));

    $scope.updateHasAudienceData = async function (hasData) {
        $scope.hasAudienceData = hasData;
        await updateAudienceAppForChannel(current_channel, $scope.audience);
    };

    $scope.hideChannelDropdown = function () {
        const name = $state.current.name;
        if (!name.startsWith('audience-')) return false;
        if (name !== 'audience-builder') return true;

        return !abiPermissions.hasPermission('audience au telco channel') &&
            !abiPermissions.hasPermission('audience linear tv channel') &&
            !abiPermissions.hasPermission('audience linkedin channel') &&
            !abiPermissions.hasPermission('audience sg telco channel');
    };

    $scope.isSegmentWithDate = function(index) {
        const segment = $scope.audience.segment[index];
        return segment && segment.hasOwnProperty('startDate') && segment.hasOwnProperty('endDate');
    };
}

audienceBuilderModule.filter("trusted_html", ['$sce', function ($sce) {
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    }
}]);

export default audienceBuilderModule;
