import moment from 'moment';
import _ from 'lodash'; // Required for test coverage purpose
import {getDemographicsDataForPreviewBySegment} from "../react/services/AudienceInsightsService";

const md5 = require('js-md5');
const KEY_NAME_MAP = {"geo": "countries", "age": "ages", "gender": "genders", "children": "children", "income": "new-incomes", "ethnicity": "new-races"};
const DEMOGRAPHICS_FIELDS = ['age', 'geo', 'income', 'ethnicity', 'gender', 'children'];
const TV_DEMOGRAPHICS_FIELDS = ['age', 'geo', 'ethnicity', 'gender', 'income'];
const TV_SHOWS_FIELDS = ['networks', 'genres', 'tv'];
const NEW_QUERY_TV_SHOWS_FIELDS = ['genres'];
const SG_TELCO_CHANNELS = ['snbb', 'data_spark'];
const LEVEL_OF_INTENT_MAP = {"searches": 'iw', "frequent-keywords": 'xw', "keywords": 'aw'};
const COMMERCIALS_DESC_MAP = {'creatives': 'ad-ids', 'channel': 'table-name'};
const TV_SHOWS_FILTER_MAPPING_MAP = {tv: 'show-ids', networks: 'pnws'};
const TV_CHANNELS = ['linear_tv', 'smart_tv', 'gracenote'];
const AND_DELIMITER = ' <ii>and</ii> ', OR_DELIMITER = ' <ii>or</ii> ', NOT_DELIMITER = ' <ii>without</ii> ';
const SEGMENT_MAP = {
    lifestyle: {label: "Lifestyles", icon: "icon-lifestyle", inlineSummaryFunction: getLifestyleInlineValueSummary},
    demographics: {label: "Demographics", icon: "icon-profile", inlineSummaryFunction: getDemographicsInlineValueSummary},
    interests: {label: "Interests", icon: "icon-interests", inlineSummaryFunction: getInterestsAndWebsitesInlineValueSummary},
    websites: {label: "Websites", icon: "icon-tv-monitor", inlineSummaryFunction: getInterestsAndWebsitesInlineValueSummary},
    tvShows: {label: "TV Shows", icon: "icon-tv-shows", inlineSummaryFunction: getTvShowsInlineSummary},
    '1st party': {label: "1st Party", icon: "icon-1st-party"},
    linkedinDemographics: {label: "Demographics", icon: "icon-profile"},
    linkedinIndustries: {label: "Industries", icon: "icon-industries"},
    linkedinCompanies: {label: "Companies", icon: "icon-companies"},
    linkedinJobs: {label: "Jobs", icon: "icon-jobs"},
    linearTvDemographics: {label: "Demographics", icon: "icon-profile", inlineSummaryFunction: getDemographicsInlineValueSummary},
    smartTvDemographics: {label: "Demographics", icon: "icon-profile", inlineSummaryFunction: getDemographicsInlineValueSummary},
    apps: {label: "Apps", icon: "icon-industries"},
    smartTvCommercials: {label: "Commercials", icon: "icon-commercials", inlineSummaryFunction: getCommercialsInlineValueSummary},
    linearTvCommercials: {label: "Commercials", icon: "icon-commercials", inlineSummaryFunction: getCommercialsInlineValueSummary},
    gracenoteCommercials: {label: "Commercials", icon: "icon-commercials", inlineSummaryFunction: getCommercialsInlineValueSummary},
};
const AND_LOGICAL_OPERAND = {value: 'and', label: 'ALL of the attributes below'};
const OR_LOGICAL_OPERAND = {value: 'or', label: 'ANY of the attributes below'};
const LOGICAL_OPERANDS = [AND_LOGICAL_OPERAND, OR_LOGICAL_OPERAND];


module.exports = {
    convertAudienceSegmentToLogicStatement,
    convertAudienceSegmentToFilterMapping,
    geoByChannel,
    getSegmentValuesSummary,
    hasActivateAudiencePermission,
    isActivateAudienceEnabled,
    isSmartTvChannel,
    convertTvShowsToNewLogicStatement: convertTvShowsToNewLogicStatement,
    SEGMENT_MAP,
    LOGICAL_OPERANDS,
    AND_LOGICAL_OPERAND,
    TV_CHANNELS,
};

function getStatementOperand(statement) {
    if (!Array.isArray(statement)) return 'none';
    return statement[0];
}

function isAndStatement(statement) {
    return getStatementOperand(statement) == 'and';
}

function isNotStatement(statement) {
    return getStatementOperand(statement) == 'not';
}

function isNoneStatement(statement) {
    return getStatementOperand(statement) == 'none';
}

function optimizeStatement(statement) {
    // [and, filter1, [and, filter2, filter3]] => [and, filter1, filter2, filter3]
    if (isNoneStatement(statement)) return statement;

    if (isAndStatement(statement)) {
        if (statement.length == 2 && !isNoneStatement(statement[1])) return statement[1];
        var optimizedLogicStatement = ["and"]
        statement.slice(1).forEach(function (s) {
            if (isAndStatement(s)) {
                optimizedLogicStatement = optimizedLogicStatement.concat(s.slice(1))
            }
            else {
                optimizedLogicStatement.push(optimizeStatement(s));
            }
        });

        return optimizedLogicStatement;
    }

    for (var i = 0; i < statement.length; i++) {
        statement[i] = optimizeStatement(statement[i])
    }

    return statement;
}

function convertAppsSegmentToLogicStatement(apps) {
    const filterType = 'app';
    let filters = _.pick(apps, ['required', 'included', 'excluded']);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'id'));
    filters = _.mapValues(filters, (filterVal) => (
        _.map(filterVal, val => ({[filterType]: val.toLowerCase()}))
    ));

    const logicStatement = [];
    if (filters["required"]) logicStatement.concat(filters["required"]);
    if (filters["included"]) logicStatement.push(["or"].concat(filters["included"]));
    if (filters["excluded"]) logicStatement.push(["not", ["or"].concat(filters["excluded"])]);

    return logicStatement.length == 1 ? logicStatement[0] : ["and"].concat(logicStatement);
}

function convertInterestsAndWebsitesSegmentToLogicStatement(interests) {
    var filterType = interests.type == "interests" ? interests.levelOfIntent.value : "domains";
    var filters = _.pick(interests, ['required', 'included', 'excluded']);
    filters = _.mapValues(filters, function (filterVal, filterType) {
        return _.map(filterVal, 'id')
    });
    filters = _.mapValues(filters, function (filterVal) {
        return _.map(filterVal, val => ({[filterType]: (filterType == 'domains') ? val.toLowerCase() : val}))
    });
    var logicStatement = [];
    if (filters["required"]) logicStatement = logicStatement.concat(filters["required"]);
    if (filters["included"]) logicStatement.push(["or"].concat(filters["included"]));
    if (filters["excluded"]) logicStatement.push(["not", ["or"].concat(filters["excluded"])]);

    return logicStatement.length == 1 ? logicStatement[0] : ["and"].concat(logicStatement);
}

function convertInterestsToLogicStatement(interests) {
    const levelOfIntentKey = LEVEL_OF_INTENT_MAP[interests.levelOfIntent.value];
    const values = convertInterestsAndWebsitesSegmentToLogicStatement(_.extend({}, interests, {levelOfIntent: {value: levelOfIntentKey}}));
    return values;
}

function convertTvShowsToLogicStatement(tvShows, channel) {
    let filters = _.pick(tvShows, TV_SHOWS_FIELDS);

    filters = _.mapKeys(filters, (filterVal, filterType) => {
        if (filterType === 'networks') return channel === 'articles' ? 'networks' : 'pnws';
        return filterType;
    });

    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'value'));

    filters = _.map(filters, function (filterVal, filterType) {
        let values = _.map(filterVal, v => ({[filterType]: v}));
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });
    return filters.length == 1 ? filters[0] : ["and"].concat(filters);
}

function convertTvShowsToNewLogicStatement(tvShows, channel) {
    let filters = _.pick(tvShows, NEW_QUERY_TV_SHOWS_FIELDS);
    filters = _.mapValues(filters, (filterVal, filterType) => _.map(filterVal, 'value'));
    filters = _.map(filters, function (filterVal, filterType) {
        let values = _.map(filterVal, v => ({[filterType]: v}));
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });

    let filterMapping = _.pick(tvShows, Object.keys(TV_SHOWS_FILTER_MAPPING_MAP));
    filterMapping = _.mapKeys(filterMapping, (filterVal, filterType) => TV_SHOWS_FILTER_MAPPING_MAP[filterType])
    filterMapping = _.mapValues(filterMapping, (filterVal, filterType) => _.map(filterVal, 'value'));

    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, tvShows);
    if (filters.length > 0 && !_.isEmpty(filterMapping)) return ['and', filters[0], {'place-holder': convertTvShowsSegmentToMd5(channel, filterMapping)}];
    if (filters.length > 0) return filters[0];
    if (!_.isEmpty(filterMapping)) return {'place-holder': convertTvShowsSegmentToMd5(channel, filterMapping)};
}

function convertCommercialsSegmentToLogicStatement(commercials, channel) {
    let filters = _.pick(commercials, Object.keys(COMMERCIALS_DESC_MAP));

    filters = _.mapKeys(filters, (filterVal, filterType) => COMMERCIALS_DESC_MAP[filterType]);

    filters = _.mapValues(filters, (filterVal, filterType) => {
        if (filterType === 'ad-ids') return [...new Set(_.flatMap(filterVal, 'value'))]
        return filterVal;
    });

    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filters, commercials);
    if (Object.keys(filters).length === 0) return {};
    return {'place-holder': convertCommercialsSegmentToMd5(channel, filters)};
}

function replaceValues(values, oldValue, newValues) {
    if (!_.includes(values, oldValue)) return;
    values.splice(values.indexOf(oldValue), 1);
    _.each(newValues, function (newVal) {
        values.push(newVal);
    });
}

function rebuildIncome(income) {
    let newIncome = [];

    _.each(income, (val) => {
        _.isArray(val) ? _.each(val, vv => newIncome.push(vv)) : newIncome.push(val)
    });
    return newIncome
}

function convertDemographicsSegmentToLogicStatement(demographics, channel, allPermittedGeos, isBidStream, isDeterministic) {
    var filters = _.pick(demographics, DEMOGRAPHICS_FIELDS);

    filters = _.mapValues(filters, function (filterVal, filterType) {
        if (_.includes(['age', 'gender'], filterType)) return _.map(filterVal, 'summary').map(f => f == "13-17" ? "12-17" : f);
        if (_.includes(['children'], filterType)) return _.map(filterVal, children => children.label.toLowerCase());
        if (_.includes(['ethnicity', 'income'], filterType)) return _.map(filterVal, 'value');
        if (_.includes(['geo'], filterType)) return geoByChannel(filterVal, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    replaceValues(filters.income, "0-25k", ["0-15k", "15-25k"]);
    replaceValues(filters.income, "25-50k", ["25-35k", "35-50k"]);
    if (filters.income) {
        // Flatten income params - to accommodate server api
        filters.income = rebuildIncome(filters.income)
    }

    filters = _.mapKeys(filters, function (val, key) {
        if (key == "ethnicity") return SG_TELCO_CHANNELS.includes(channel) ? "sg-races" : "new-races";
        return KEY_NAME_MAP[key] || key;
    });
    filters = _.map(filters, function (filterVal, filterType) {
        var values = _.map(filterVal, function (v) {
            return {[filterType]: v}
        });
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });
    return filters.length == 1 ? filters[0] : ["and"].concat(filters);
}

function convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic) {
    if (segment.type == "demographics" || segment.type == "linearTvDemographics" || segment.type == "smartTvDemographics") return convertDemographicsSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    if (segment.type == "interests") return convertInterestsToLogicStatement(segment);
    if (segment.type == "websites") return convertInterestsAndWebsitesSegmentToLogicStatement(segment);
    if (segment.type == "lifestyle") return {intentions: segment.value};
    if (segment.type == "tvShows" && (channel == 'smart_tv' || channel == 'gracenote')) return convertTvShowsToNewLogicStatement(segment, channel);
    if (segment.type == "tvShows") return convertTvShowsToLogicStatement(segment, channel);
    if (segment.type == "1st party") return {fp: segment.value};
    if (segment.type === 'apps') return convertAppsSegmentToLogicStatement(segment);
    if (segment.type === 'smartTvCommercials' || segment.type === 'linearTvCommercials' || segment.type === 'gracenoteCommercials') return convertCommercialsSegmentToLogicStatement(segment, channel);
}

function convertAudienceSegmentToFilterMapping(audienceSegment, channel) {
    const filterMapping = audienceSegment.reduce((filterMapping, segment) => {
        if (['smartTvCommercials', 'linearTvCommercials', 'gracenoteCommercials'].includes(segment.type))
            return {...filterMapping, ...convertCommercialsSegmentToFilterMapping(segment, channel)};

        if (segment.type === 'tvShows' && (channel === 'smart_tv' || channel === 'gracenote') && ['networks', 'tv', 'startDate'].some(k => k in segment))
            return {...filterMapping, ...convertTvShowsSegmentToFilterMapping(segment, channel)};

        return filterMapping;
    }, {});
    return _.isEmpty(filterMapping) ? null : filterMapping;
}

function convertCommercialsSegmentToFilterMapping(commercials, channel) {
    let filters = _.pick(commercials, Object.keys(COMMERCIALS_DESC_MAP));

    filters = _.mapKeys(filters, (filterVal, filterType) => COMMERCIALS_DESC_MAP[filterType]);

    const filterMapping = _.mapValues(filters, (filterVal, filterType) => {
        if (filterType === 'ad-ids') return [...new Set(_.flatMap(filterVal, 'value'))];
        if (filterType === 'table-name' && channel === 'smart_tv') return 'inscape_ads';
        if (filterType === 'table-name' && channel === 'linear_tv') return 'nielsen_ads';
        if (filterType === 'table-name' && channel === 'gracenote') return 'gracenote_ads';
        return filterVal;
    });
    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, commercials);

    return {[convertCommercialsSegmentToMd5(channel, filterMapping)]: filterMapping};
}

function convertTvShowsSegmentToFilterMapping(tvShows, channel) {
    let filters = _.pick(tvShows, Object.keys(TV_SHOWS_FILTER_MAPPING_MAP));

    filters = _.mapKeys(filters, (filterVal, filterType) => TV_SHOWS_FILTER_MAPPING_MAP[filterType]);

    const filterMapping = _.mapValues(filters, (filterVal, filterType) => {
        if (['show-ids', 'pnws', 'genres'].includes(filterType)) return _.map(filterVal, 'value');
        return filterVal;
    });
    if (channel === 'smart_tv') filterMapping['table-name'] = 'inscape_media';
    if (channel === 'linear_tv') filterMapping['table-name'] = 'nielsen_media';
    if (channel === 'gracenote') filterMapping['table-name'] = 'gracenote_media';
    if (isSmartTvChannel(channel)) addDateRangeToFilterMapping(filterMapping, tvShows);

    return {[convertTvShowsSegmentToMd5(channel, filterMapping)]: filterMapping};
}

function hasSegmentTimeframe(segment) {
    return segment.startDate && segment.endDate;
}

function hasFilterMappingTimeframe(filterMapping) {
    return filterMapping['start-time'] && filterMapping['end-time'];
}

function getTimeframePlaceholder(filters) {
    return `_${filters['start-time']}-${filters['end-time']}`;
}

function addDateRangeToFilterMapping(filterMapping, segment) {
    if (!hasSegmentTimeframe(segment)) return;

    const formatDate = (dateString) => moment(new Date(dateString)).format('YYYY-MM-DD')
    filterMapping['start-time'] = formatDate(segment.startDate);
    filterMapping['end-time'] = formatDate(segment.endDate);
}

function convertCommercialsSegmentToMd5(channel, commercials) {
    let commercialsStr = `commercials_${channel}_${_.join(commercials['ad-ids'].sort(), '_')}`;
    if (hasFilterMappingTimeframe(commercials)) commercialsStr += getTimeframePlaceholder(commercials);
    return md5(commercialsStr);
}

function convertTvShowsSegmentToMd5(channel, tvShows) {
    let tvShowsStr = `tv_${channel}_${_.join((tvShows['show-ids'] || []).sort(), '_')}_${_.join((tvShows['pnws'] || []).sort(), '_')}_${_.join((tvShows['genres'] || []).sort(), '_')}`;
    if (hasFilterMappingTimeframe(tvShows)) tvShowsStr += getTimeframePlaceholder(tvShows);
    return md5(tvShowsStr);
}

function convertAudienceSegmentToLogicStatement(audienceSegment, channel, allPermittedGeos, isTv = null, isBidStreamChannel = null, isDeterministic = null) {
    audienceSegment = _.cloneDeep(audienceSegment);
    _.each(audienceSegment, function (segment) {
        segment.operand = segment.operand || {value: "and"}
    });

    // When user "exclude" demographics with country, delete all geos from all segments and add an "require" segment with this geo
    var geo = _.get(_.filter(audienceSegment, function (segment) {
        return segment.type == "demographics" || segment.type == "linearTvDemographics" || segment.type == "smartTvDemographics";
    })[0], 'geo');

    _.each(audienceSegment, (segment) => delete segment.geo);
    _.remove(audienceSegment, (segment) => (segment.type == "demographics" || segment.type == "smartTvDemographics" || segment.type == "linearTvDemographics") && _.isEmpty(_.pick(segment, DEMOGRAPHICS_FIELDS)));
    audienceSegment.push({geo: geo, type: "demographics", operand: {value: "and"}});

    var segmentByOperand = _.groupBy(audienceSegment, 'operand.value');
    const isBidStream = isBidStreamChannel || _.some(audienceSegment, {type: '1st party'});

    let andStatements = _.map(segmentByOperand["and"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    let orStatements = _.map(segmentByOperand["or"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });
    let notStatements = _.map(segmentByOperand["not"], function (segment) {
        return convertSegmentToLogicStatement(segment, channel, allPermittedGeos, isBidStream, isDeterministic);
    });

    if (orStatements.length) orStatements = [["or"].concat(orStatements)];
    if (notStatements.length) notStatements = [["not"].concat(notStatements.length == 1 ? notStatements : [["or"].concat(notStatements)])];

    var logicalStatement = ["and"].concat(andStatements).concat(orStatements).concat(notStatements);
    if ((isTv || _.some(audienceSegment, {type: 'tvShows'})) && !TV_CHANNELS.includes(channel)) logicalStatement.push({"any-tv": "yes"});

    return optimizeStatement(logicalStatement);
}

function geoByChannel(geo, channel, allPermittedGeos = null, isBidStream = null, isDeterministic = null) {
    if (channel === 'data_spark') return ["sg:data_spark_https"];
    if (SG_TELCO_CHANNELS.includes(channel)) return [`sg:${channel}`];
    if (channel === 'linear_tv') return ["us:vid"];
    if (channel === 'smart_tv' && isDeterministic) return ["us:bid_stream_inscape", "us:inscape"];
    if (channel === 'gracenote' && isDeterministic) return ["us:gracenote", "us:bid_stream_gracenote_nofilter"];
    if (channel === 'smart_tv') return ["us:bid_stream_inscape"];
    if (channel === 'gracenote') return ["us:bid_stream_gracenote"];
    if (channel === 'au_telco') return ["au:optus"];

    const geoCode = geo && geo[0].cc.toLowerCase();
    let channelAddition = isBidStream ? ':bid_stream' : '';

    if (!geoCode) return _.map(allPermittedGeos, (value, key) => value.cc.toLowerCase() + channelAddition);
    return [geoCode + channelAddition];
}

function parseToNumber(str) {
    return _.toNumber(_.replace(str, /\D/g, ''));
}

function mergeRangesForSummary(ranges) {
    if (_.isEmpty(ranges)) return [];
    const START = 0, END = 1;
    let mergedRanges = [];
    let currentRange = _.split(ranges[START], '-');

    if (currentRange.length === 1) currentRange.push(currentRange[START]);
    for (let i = 1; i < ranges.length; i++) {
        let nextRange = _.split(ranges[i], '-');

        if (nextRange.length === 1) nextRange.push(nextRange[START]);
        if (_.isEqual(parseToNumber(currentRange[END]), parseToNumber(nextRange[START]))
            || _.isEqual(parseToNumber(currentRange[END]) + 1, parseToNumber(nextRange[START]))) {
            currentRange = [currentRange[START], nextRange[END]];
        } else {
            mergedRanges.push(currentRange);
            currentRange = [nextRange[START], nextRange[END]];
        }
    }
    mergedRanges.push(currentRange);

    return _.map(mergedRanges, (range) => _.uniq(range))
}

function getRangeValueSummary(ranges, rangeText) {
    const mergedRanges = mergeRangesForSummary(_.map(ranges, (range) => range.label));
    return "<ii>" + rangeText + " between</ii> " + _.join(_.map(mergedRanges, (range) => _.join(range, '-')), ' and ');
}

function getIncomeValueSummary(income, filtersPartition) {
    var has_first_income = _.isEqual(income[0].value, filtersPartition.newIncome[0].value);
    var has_last_income = _.isEqual(income[income.length - 1].value, filtersPartition.newIncome[filtersPartition.newIncome.length - 1].value);
    var summary = "<ii>Income ";
    if (!has_first_income && !has_last_income) {
        summary += "between</ii> " + income[0].label.split('-')[0] + "-" + income[income.length - 1].label.split('-')[1];
    } else if (has_first_income) {
        summary += "below</ii> " + income[income.length - 1].label.split('-')[1];
    } else {
        summary += "over</ii> " + income[0].label.split('-')[0] + "K";
    }

    summary = summary.replace(/\+k/i, '').replace(/99k/i, '100K');
    return summary;
}

function multipleItemsSummary(items, concatenateSymbol, itemsToDisplay = 1) {
    const arrOfItems = [...items];
    const extra = arrOfItems.length - itemsToDisplay > 0 ? arrOfItems.length - itemsToDisplay : 0;
    const labels = arrOfItems.map((item) => _.isString(item) ? item : item.label)
    let summary = labels.slice(0, itemsToDisplay).join(` <ii>${concatenateSymbol}</ii> `);

    if (extra) {
        const extraItems = labels.slice(itemsToDisplay, labels.length).join(` ${concatenateSymbol} `);
        summary += `<span title="${extraItems}" am-tooltip="top center to bottom center"> <ii>${concatenateSymbol}</ii> ${extra} other${extra === 1 ? '' : 's'} </span>`
    }

    return summary;
}

function getDemographicsValueSummary(demographics, filtersPartition) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.income) summary.push(getIncomeValueSummary(demographics.income, filtersPartition));
    if (demographics.children) summary.push(_.upperFirst(demographics.children[0].summary));
    if (demographics.geo) summary.push(demographics.geo[0].label);
    if (demographics.ethnicity && demographics.ethnicity.length) summary.push(demographics.ethnicity.map(e => e.label).join(OR_DELIMITER));
    return summary.join(', ');
}

function getLinearTvDemographicsValueSummary(demographics, filtersPartition) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.income) summary.push(getIncomeValueSummary(demographics.income, filtersPartition));
    if (demographics.geo) summary.push(demographics.geo.label);
    if (demographics.ethnicity && demographics.ethnicity.length) summary.push(demographics.ethnicity.map(e => e.label).join(OR_DELIMITER));
    return summary.join(', ');
}

function getSmartTvDemographicsValueSummary(demographics, filtersPartition) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.income) summary.push(getIncomeValueSummary(demographics.income, filtersPartition));
    if (demographics.geo) summary.push(demographics.geo.label);
    if (demographics.ethnicity && demographics.ethnicity.length) summary.push(demographics.ethnicity.map(e => e.label).join(OR_DELIMITER));
    return summary.join(', ');
}

function getAudienceInlineSummaryForArray(key, values) {
    return {
        key: key,
        value: _.map(values, (value) => value.label),
        summarizedValue: `(${values.length})`,
        popoverTitle: `${key} (${values.length})`,
        popoverContent: _.join(_.map(values, (value) => `<ul>${value.label}</ul>`), '')
    };
}

function getAudienceInlineSummaryForRange(key, values, labelField = "label") {
    const mergedRanges = mergeRangesForSummary(_.map(values, (range) => range[labelField]));
    let summary = {key: key, value: mergedRanges};
    if (mergedRanges.length > 1) {
        summary.summarizedValue = `(${values.length})`;
        summary.popoverTitle = `${key} (${values.length})`;
        summary.popverContent = _.join(_.map(values, (range) => `<ul>${range[labelField]}</ul>`), '');
    }
    return summary;
}

function getDemographicsInlineValueSummary(demographics) {
    let summary = [];
    if (demographics.gender) summary.push({key: "Gender", value: _.upperFirst(demographics.gender[0].label)});
    if (demographics.age) summary.push(getAudienceInlineSummaryForRange("Age", demographics.age));
    if (demographics.income) summary.push(getAudienceInlineSummaryForRange("Income", demographics.income));
    if (demographics.geo) summary.push({key: "Geo", value: demographics.geo.label});
    if (!_.isEmpty(demographics.ethnicity)) summary.push(getAudienceInlineSummaryForArray("Ethnicity", demographics.ethnicity));

}

function getInterestsAndWebsitesValueSummary(interests) {
    let summary = [];
    if (interests.required) summary = summary.concat(_.map(interests.required, 'text').join(AND_DELIMITER));
    if (interests.included) summary = summary.concat(_.map(interests.included, 'text').join(OR_DELIMITER));
    if (interests.excluded) summary = summary.concat(NOT_DELIMITER + _.map(interests.excluded, 'text').join(OR_DELIMITER));
    if (interests.levelOfIntent) summary = summary.concat("<ii>Level of Intent</ii> " + interests.levelOfIntent.label);
    return summary.join(",&nbsp;&nbsp;&nbsp;");
}

function getInterestsAndWebsitesInlineValueSummary(interests) {
    let summary = [];
    if (interests.levelOfIntent)  summary.push({key: "Level of Intent", value: interests.levelOfIntent.label});
    if (interests.required) summary.push(getAudienceInlineSummaryForArray("AND", interests.required, "text"));
    if (interests.included) summary.push(getAudienceInlineSummaryForArray("OR", interests.required, "text"));
    return summary;
}

function getLinkedinDemographicsValueSummary(demographics) {
    let summary = [];
    if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
    if (demographics.age) summary.push(getRangeValueSummary(demographics.age, "Age"));
    if (demographics.country) summary.push(multipleItemsSummary(demographics.country, 'and'));
    if (demographics.state) summary.push(multipleItemsSummary(demographics.state, 'and'));
    if (demographics.regions) summary.push(multipleItemsSummary(demographics.regions, 'and'));

    return summary.join(', ');
}

function getLinkedinIndustriesValueSummary(industries) {
    var summary = [];
    if (industries.industries) {
        industries.industries.forEach(industry => summary.push(industry.label));
    }
    return summary.join(', ');
}

function getLinkedinCompaniesValueSummary(companies) {
    let summary = [];
    if (companies.sizes) summary.push(getRangeValueSummary(companies.sizes, "Company size"));
    if (companies.names) summary = summary.concat(_.map(companies.names, 'text').join(OR_DELIMITER));
    return summary.join(', ');
}

function getLinkedinJobsValueSummary(jobs) {
    var summary = [];
    if (jobs.seniorities) jobs.seniorities.forEach(seniority => summary.push(seniority.label));
    if (jobs.functions) jobs.functions.forEach(func => summary.push(func.label));
    if (jobs.jobTitles) jobs.jobTitles.forEach(jobTitle => summary.push(jobTitle.label));
    return summary.join(', ');
}

function getTvShowsSummary(tvShows, isAudienceInfoTooltip = false) {
    var summary = [];
    if (tvShows.networks) summary = summary.concat("<ii>Networks</ii> " + multipleItemsSummary(tvShows.networks, 'or', 3));
    if (tvShows.genres) summary = summary.concat("<ii>Genres</ii> " + multipleItemsSummary(tvShows.genres, 'or', 3));
    if (tvShows.tv) summary = summary.concat("<ii>Watched either</ii> " + multipleItemsSummary(tvShows.tv, 'or', 2));
    if (isAudienceInfoTooltip && tvShows.startDate && tvShows.endDate) summary = summary.concat("<ii>Timeframe</ii> " + tvShows.startDate + " - " + tvShows.endDate);
    return summary.join(', ');
}

function getTvShowsInlineSummary(tvShows) {
    let summary = [];
    if (tvShow.networks) summary.push(getAudienceInlineSummaryForArray("Networks", tvShows.networks));
    if (tvShow.genres) summary.push(getAudienceInlineSummaryForArray("Genres", tvShows.networks));
    if (tvShow.tv) summary.push(getAudienceInlineSummaryForArray("TV Shows", tvShows.networks));
    summary.push({
        key: "Custom Time Frame",
        value: `${tvShows.startDate}-${tvShows.endDate}`
    });
    return summary;
}

function getCommercialsValueSummary(commercials, isAudienceInfoTooltip = false) {
    let summary = [];
    if (commercials.brands) summary = summary.concat("<ii>Was exposed to</ii> " + multipleItemsSummary(commercials.brands, 'or', 2));
    if (isAudienceInfoTooltip && commercials.startDate && commercials.endDate) summary = summary.concat("<ii>Timeframe</ii> " + commercials.startDate + " - " + commercials.endDate);
    return summary.join(', ');
}

function getCommercialsInlineValueSummary(commercials) {
    let summary = [];
    let numAllCreatives = 0;
    let popoverContent = '';
    _.each(commercials.parentBrands || [], function (parentBrand, index) {
        if (index === 0) {
            popoverContent += '<span class="popover-type-name">Parent Brands</span><br/><br/>'
        }
        const numBrands = parentBrand.children.length;
        const numCreatives = _.reduce(parentBrand.children, (sum, brand) => sum + brand.children.length, 0);
        numAllCreatives += numCreatives;
        popoverContent += `<span class="popover-name">${parentBrand.label}</span><br/>`;
        popoverContent += `<span class="popover-details">${numBrands} Brands | ${numCreatives} Creatives</span><br/>`;
        if (index === commercials.parentBrands.length - 1) {
            popoverContent += '<br/>';
        }
    });
    _.each(commercials.brands || [], function (brand, index) {
        if (index === 0) {
            popoverContent += '<span class="popover-type-name">Brands</span><br/><br/>'
        }
        numAllCreatives += brand.children.length;
        popoverContent += `<span class="popover-name">${brand.label}</span><br/>`;
        popoverContent += `<span class="popover-details">${brand.children.length} Creatives</span><br/>`;
        if (index === commercials.parentBrands.length - 1) {
            popoverContent += '<br/>';
        }
    });
    _.each(commercials.brands || [], function (creative, index) {
        if (index === 0) {
            popoverContent += '<span class="popover-type-name">Creatives</span><br/><br/>'
        }
        popoverContent += `<span class="popover-name">${creative.label}</span><br/>`;
    });
    numAllCreatives += commercials.brands.length;
    summary.push({
        key: "Creatives",
        value: `(${numAllCreatives})`,
        popoverTitle: `Creatives (${numAllCreatives})`,
        popoverContent: popoverContent
    });
    summary.push({
        key: "Custom Time Frame",
        value: `${commercials.startDate}-${commercials.endDate}`
    });
    return summary;
}

function getLifestyleInlineValueSummary(lifestyle) {
    return {value: lifestyle.label};
}

function getSegmentValuesSummary(segment, filtersPartition, isAudienceInfoTooltip = false) {
    const {type, label} = segment;
    const SUMMARY_FUNC_BY_TYPE = {
        lifestyle: () => label,
        custom_segment: () => label,
        '1st party': () => label,
        demographics: () => getDemographicsValueSummary(segment, filtersPartition),
        interests: () => getInterestsAndWebsitesValueSummary(segment),
        websites: () => getInterestsAndWebsitesValueSummary(segment),
        linkedinDemographics: () => getLinkedinDemographicsValueSummary(segment),
        linkedinIndustries: () => getLinkedinIndustriesValueSummary(segment),
        linkedinCompanies: () => getLinkedinCompaniesValueSummary(segment),
        linkedinJobs: () => getLinkedinJobsValueSummary(segment),
        tvShows: () => getTvShowsSummary(segment, isAudienceInfoTooltip),
        linearTvDemographics: () => getLinearTvDemographicsValueSummary(segment, filtersPartition),
        smartTvDemographics: () => getSmartTvDemographicsValueSummary(segment, filtersPartition),
        apps: () => getInterestsAndWebsitesValueSummary(segment),
        smartTvCommercials: () => getCommercialsValueSummary(segment, isAudienceInfoTooltip),
        linearTvCommercials: () => getCommercialsValueSummary(segment, isAudienceInfoTooltip),
        gracenoteCommercials: () => getCommercialsValueSummary(segment, isAudienceInfoTooltip),
    };
    return SUMMARY_FUNC_BY_TYPE[type]?.();
}

function hasActivateAudiencePermission(abiPermissions, channel) {
    switch (channel) {
        case 'articles':
            return abiPermissions.hasPermission('audience activation - channel: web');
        case 'smart_tv':
        case 'gracenote':
            return abiPermissions.hasPermission('audience activation - channel: smart tv');
        default:
            return false;
    }
}

/**
 * @param abiPermissions - permissions manager
 * @param channel - valid channels can be 'articles'/'smart_tv'/'gracenote'.
 * @param audience
 * @param userId
 * @returns {Promise<{isEnabled: boolean, isVisible: boolean, disabledText: string}|{isEnabled: boolean, isVisible: boolean}>}
 * reasons:
 *   channel - the channel can not be activated
 *   no geo - the segment must contain a geo section
 *   unsupported geo - the geo section must contain supported country codes (currently only US)
 *   active - audience is already active (or fully-active, in case of smart_tv/gracenote)
 *   demographics - the resulting demographics will be too small for the audience to be activated
 */
async function isActivateAudienceEnabled(abiPermissions, channel, audience, userId) {
    const hasPermission = hasActivateAudiencePermission(abiPermissions, channel);
    if (!hasPermission) return {isEnabled: false, isVisible: false};

    const isSupportedChannel = ['articles', 'smart_tv', 'gracenote'].includes(channel);
    if (!isSupportedChannel) return {isEnabled: false, isVisible: false};

    const hasAlwaysOnPermission = abiPermissions.hasPermission('audience activation - always on');
    const canActivateCustom = !audience.activation?.amplified || (channel !== 'articles' && !audience.activation?.deterministic);
    const canActivateAlwaysOn = hasAlwaysOnPermission && !audience.activation?.always_on;
    if (!canActivateCustom && !canActivateAlwaysOn) return {
        isEnabled: false,
        isVisible: true,
        disabledText: 'This segment has been activated. To activate again please clone and activate this audience.'
    };

    // smart_tv and gracenote audiences are always set to US, no need to check demographics
    if (channel === 'articles') {
        const geo = audience.segment.find(({type}) => /demographics/i.test(type))?.geo;
        if (_.isEmpty(geo)) return {
            isEnabled: false,
            isVisible: true,
            disabledText: 'Please select a Geo to enable Audience Targeting.'
        };

        const amplificationGeos = ['US', 'AU', 'SG'];
        const cc = _.isArray(geo) ? geo[0].cc : geo.cc;
        const isSupportedGeo = amplificationGeos.includes(cc);
        if (!isSupportedGeo) return {
            isEnabled: false,
            isVisible: true,
            disabledText: 'We currently do not support Audience Targeting in the selected Geos.'
        };
    }

    const isBidstream = channel === 'articles';
    const minimalSize = channel === 'articles' ? 2000 : 1500;
    const isDemographicsTooNarrow = await checkDemographicsTooNarrow(audience.segment, channel, userId, isBidstream, minimalSize);
    if (isDemographicsTooNarrow) return {isEnabled: false, isVisible: true, disabledText: 'The audience you have selected is too narrow for Audience Targeting. Please expand your audience criteria.'};

    return {isEnabled: true, isVisible: true};
}

async function checkDemographicsTooNarrow(segment, channel, userId, isBidstream, minimalSize) {
    const {status} = await getDemographicsDataForPreviewBySegment(segment, {
        channel,
        userId,
        isBidstream,
        minimalSize,
        filterBidstreamDomains: isBidstream,
    });
    return status !== 'ok';
}

function isSmartTvChannel(channel) {
    return ['smart_tv', 'gracenote'].includes(channel);
}
