module.exports = angular.module(__filename, [
    require('data/settings/program-service').name,
    require('data/settings/program-boolean-logic-service').name,
    require('data/settings/program-sources-service').name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
    require('common/modals/define-interest/define-interest').name,
    require('./program-interests').name,
    require('./program-sources').name
]).controller('programSettingsController',
    ['$scope', '$timeout', '$location', '$state' ,  'program', 'close', 'keywords', 'keywordSuggester', 'programService', 'notificator', 'confirmAction', 'initialView', 'abiPermissions',
        function ($scope, $timeout, $location, $state , program, close, keywords, keywordSuggester, programService, notificator, confirmAction, initialView, abiPermissions) {

            $scope.isEditFromDashboard = $location.$$path.includes("dashboard") && program.id;
            $scope.isEditFromStreams = $location.$$path.includes("streams");
            $scope.manageInterests = !program;
            var user = $scope.$root.user;
            var context = $scope.$root.context;
            
            $scope.program = program;
            $scope.isMainInterest  = isMyInterests(program);
            $scope.isMySegments  = isMySegments(program);
            $scope.isNewProgram = !$scope.program.id;
            $scope.suggestionsInx = 0;
            $scope.editSourceOpened = false;
            $scope.refineInterestOpened = false;
            $scope.hasDashboardPermission = abiPermissions.hasPermission('dashboard');
            $scope.notificator = notificator;
            $scope.confirmAction = confirmAction;
            $scope.close = close;
            $scope.suggest = suggestPhrases;
            $scope.hideSuggestions = hideSuggestions;
            $scope.update = update;
            $scope.onEnter = onEnter;

            $scope.emptyProgram = $scope.isEditFromDashboard && !$scope.program.brand_name;
            $scope.title = $scope.emptyProgram ? 'Create a program dashboard' : 'Program Settings';

            $scope.changeView = function(view) {
                $scope.selectedView = view;
            };

            $scope.changeView(initialView || 'dashboard interests');

            // close modal on navigate back
            var closeListener = $scope.$root.$on("$stateChangeStart", function () {
                close({reload: false});
            });
            $scope.$on('$destroy', closeListener);

            $scope.addSource = function () {
                $scope.$broadcast('add-new-source');
            };

            function onEnter(event) {
                event.preventDefault();
                event.currentTarget.blur();
            }

            function suggestPhrases(text, index, event) {
                if (_.isEmpty(text)) return $scope.suggestions = null;

                $scope.suggestionsInx = index;
                keywordSuggester.getSuggestions(text).then(function (suggestions) {
                    $scope.suggestions = _.map(suggestions, 'text');
                });
            }

            function hideSuggestions() {
                $timeout(function () {
                    $scope.suggestions = null;
                }, 500);
            }

            function createProgram(program) {
                program.creator_id = user.id;
                program.account_id = user.account.id;
                programService.create(program).then(function (data) {
                    user.programs.push(data);
                    notificator.success({body: data.name + ' was saved successfully'});
                    return close({reload: true});
                });
            }

            function update(program) {
                program.competitors = _.reject(program.competitors, _.isEmpty);
                var language = context.current._language_mold.getLanguage($state, context).value;
                var validation = keywords.validation([program.brand_name].concat(program.competitors), language);
                validation.then(validatePhrases).then(function() {
                    if (program.id == undefined) return createProgram(program);
                    if (program["shared?"]) {
                        var confirmationMsg = "This program is shared by others, do you wish to proceed?";
                        confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                            modal.close.then(function (save) {
                                if (save) updateProgram(program);
                            });
                        });
                    } else {
                        updateProgram(program);
                    }
                }).catch(function(rejectReason) { notificator.error({body: rejectReason})})
            }

            function validatePhrases(phrasesObj) {
                var invalid = [];
                _.each(phrasesObj, function(x,y){if (x === null) invalid.push(y)});
                if (invalid.length > 0) throw "\"" + invalid.join("\", \"") + '\" not supported'
            }

            function updateProgram(program) {
                var saveSuccessfullyMsg = $scope.isEditFromDashboard ? " was updated successfully" : " was updated successfully and set as the current program";
                programService.update(program.id, program).then(function (data) {
                    // update program name for side bar (program select filter)
                    var index = _.findIndex(user.programs, {id: program.id});
                    user.programs[index] = data;
                    context.changeContext(data);

                    notificator.success({body: data.name + saveSuccessfullyMsg});
                    close({reload: true});
                });
            }

            function isMyInterests(program) {
                return program && /interests/.test(program.name) && program.name != 'guest interests';
            }

            function isMySegments(program) {
                return program && /segments$/.test(program.name);
            }
        }
    ]
).directive('programNameValidChars', function() {
    return {
      // element must have ng-model attribute
      // or $validators does not work
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.programNameValidChars = function(modelValue, viewValue) {
            // validate viewValue with your custom logic
            const areAllCharsValid = /^([a-zA-Z0-9 @.,'/é|:_+*!#&()-])*$/.test(viewValue)
            return areAllCharsValid;
        };
      } 
    }
})

