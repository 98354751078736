import { checkLinkedinUserAccessTokenStatus } from '../react/services/AudienceInsightsService';
import config from 'infra/config';
const AUTHORIZATION_CODE_API = 'http://www.linkedin.com/oauth/v2/authorization';
// In development: make sure the redirect uri is equal to the redirect uri sent to linkedin from abi-mgmt
const REDIRECT_URI = `${config.USER_MGMT_API.startsWith("http") ? '' : window.location.protocol}${config.USER_MGMT_API}/3rd_party/linkedin/generate_user_access_token`;
const LINKEDIN_PERMISSIONS = ['r_emailaddress', 'r_liteprofile', 'rw_audience_engagement', 'w_member_social'];
const CLIENT_ID = '86ww21fnqm3qf0'

export const validateUserAuthentication = (userId, tokenStatus, errorMgmt) => {
    if (tokenStatus) return getTokenStatusFromUrl(tokenStatus, errorMgmt);
    return checkLinkedinUserAccessTokenStatus(userId).then((authorizationStatus) => {
        if (authorizationStatus.authorized) return {authorized: true};
        redirectToAutherizationPage(authorizationStatus.jwt);
    })
}

const getTokenStatusFromUrl = (tokenStatus, errorMgmt) => {
    if (tokenStatus === 'valid') return Promise.resolve({authorized: true});
    if (tokenStatus === 'access-token-generation-faild') errorMgmt.customError("Sorry, there was an error generating your LinkedIn access token. Our engineers are working quickly to resolve the issue.");
    return Promise.resolve({authorized: false, error: tokenStatus});
}

const redirectToAutherizationPage = (jwt) => {
    window.location.href = `${AUTHORIZATION_CODE_API}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${jwt}&scope=${LINKEDIN_PERMISSIONS.join('%20')}`;
}

export default { validateUserAuthentication }
