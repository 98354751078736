module.exports = angular.module(__filename, []).service('loadAudienceModal', ['ModalService', 'audienceMgmt', function (ModalService, audienceMgmt) {
    this.showModal = showModal;

    function showModal(channel, isAudienceTargetSupported) {
        return ModalService.showModal({
            template: require('./load-audience-modal.html'),
            inputs: { channel, isAudienceTargetSupported },
            controller: ['$scope', 'close', '$timeout', 'confirmAction', 'channel', 'isAudienceTargetSupported', LoadAudienceCtrl]
        });
    }

    function LoadAudienceCtrl($scope, close, $timeout, confirmAction, channel, isAudienceTargetSupported) {
        $scope.close = close;
        $scope.channel = channel;
        $scope.isAudienceTargetSupported = isAudienceTargetSupported;

        let activationFields = [
            {
                id: 'date_amplified',
                title: 'date amplified',
                flex: '1-5',
                template: 'partials/audience-date-amplified.partial.html'
            },
            {
                id: 'date_always_on',
                title: 'date always on created',
                flex: '1-5',
                template: 'partials/audience-date-always-on.partial.html'
            }
        ];

        if (channel !== 'articles') activationFields = [
            {
                id: 'date_activated',
                title: 'date activated',
                flex: '1-5',
                template: 'partials/audience-date-activated.partial.html'
            },
            ...activationFields,
        ];

        function populate() {
            audienceMgmt.list($scope.channel).then(function (data) {
                $scope.programAudiences = data;
                $scope.query.show(data);
            });
        }

        function onRowClicked(row) {
            close(row);
        }

        $scope.query = {
            dataArray: [],
            columns: [
                {
                    id: 'name',
                    title: 'Audience Name',
                    template: 'partials/audience-name.partial.html'
                },
                {
                    id: 'created_by',
                    title: 'created by',
                    flex: '1-5'
                },
                {
                    id: 'created_at',
                    title: 'date added',
                    flex: '1-5',
                    template: 'partials/audience-date.partial.html'
                },
                ...isAudienceTargetSupported ? activationFields : [],
                {
                    id: 'actions',
                    title: '',
                    flex: '0-5',
                    template: 'partials/audience-actions.partial.html',
                    sort: false
                }
            ],
            clickRowHandler: onRowClicked,
            fixedSize: true,
            order: 'created_at',
            reverse: true
        };

        $timeout(populate);

        $scope.deleteAudience = function (audience) {
            var confirmationMsg = `Are you sure you want to delete the audience ${audience.name}?
                                   It will affect all users who share the program.`;
            confirmAction.getConfirmation(confirmationMsg, 'Delete', 'Cancel', 'Delete audience', 'audience-confirmation', true).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) audienceMgmt.delete(audience.id, audience.is_audience_activated).then(populate);
                });
            });
        };
    }
}]);
