import { isFinite, sum } from 'lodash';

export function formatNumberToUnits(number, decimalPlaces = 0) {
  if (!isFinite(number)) return null;

  const units = ['K', 'M', 'B'];
  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (Math.abs(number) >= decimal) {
      return (number / decimal).toFixed(decimalPlaces) + units[i];
    }
  }

  return number.toFixed(0);
}

export function roundPercents(percents) {
  if (!sum(percents)) return percents;
  const sortedByDecimal = [];
  for (let i = 0; i < percents.length; i++) {
    const percentWithIndex = {};
    percentWithIndex[percents[i]] = i;
    sortedByDecimal.push(percentWithIndex);
  }
  sortedByDecimal.sort(function (a, b) {
    const aDecimal = Object.keys(a)[0];
    const bDecimal = Object.keys(b)[0];
    return aDecimal - Math.floor(aDecimal) > bDecimal - Math.floor(bDecimal) ? -1 : 1;
  });
  const indexOfPercentsSortedByDecimal = sortedByDecimal.map((d) => d[Object.keys(d)[0]]);
  percents = percents.map((n) => Math.floor(n));
  const missingTo100 = 100 - percents.reduce((a, b) => a + b);
  for (let j = 0; j < missingTo100; j++) {
    percents[indexOfPercentsSortedByDecimal[j]] += 1;
  }
  return percents;
}
