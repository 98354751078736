import common from 'infra/utils/common';
import { react2angular } from 'react2angular';
import GenderDistribution from 'react/components/common/GenderDistribution/GenderDistribution';
import {
    getSegmentIds,
    createAudienceTargetTaxonomy,
    createAudienceTargetUserList,
    createAmplifiedAudience,
    createAlwaysOnAudience,
    createDeterministicAudience,
    createUserListForDeterministicAudience,
    getFullDemographicsDataWithGenderAgeBySegment,
    getDataContract,
    getTaxonomyCategory,
} from '../../react/services/AudienceInsightsService';
import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import { isActivateAudienceEnabled } from "../../data/audience-segment-builder-helper";

const BaseWidget = require("../base_widget");

AudienceDemographicsWidgetController.$inject = ['context', 'dspService', 'notificator',
                                                'abiPermissions', '$timeout', 'ssoService'];

function AudienceDemographicsWidgetController(context, dspService, notificator, abiPermissions,
                                              $timeout, ssoService) {

    const SG_CHANNELS = ['snbb', 'data_spark'];
    const $scope = this.$scope;
    const dataTypesToggleValues = [
        {
            value: 'distribution',
            label: 'Consumption',
            tooltip: 'How your audience is distributed between demographic segments'
        },
        {
            value: 'skew',
            label: 'Skew',
            tooltip: 'The extent to which the demographic segment is over-indexed within the audience'
        }
    ];
    const MIN_AUDIENCE_SIZE = 1000;
    $scope.ethnicityBarChartOptions = {labelFont: '13px Roboto', highlightedLabelFont: '13px Roboto'}; // todo remove after responsive-bar-chart colliding labels problem is fixed

    this.channel = context.current.audience_app.current_channel.value;
    this.segment = context.current.audience_app[this.channel].audience_segment;

    $scope.context = context;
    $scope.channel = this.channel;
    $scope.audienceSegment = this.segment;
    const audienceAppForChannel = context.current.audience_app[this.channel];
    $scope.audienceId = audienceAppForChannel.audience_id;
    $scope.audienceName = audienceAppForChannel.audience_name;
    $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled;
    $scope.isActivateAudienceEnabled = false;
    $scope.activateAudienceDisabledText = '';
    $scope.isActivateAudienceVisible = false;
    $scope.isActivateTargetEnabled = audienceAppForChannel.is_activate_target_enabled;
    $scope.isAudienceDeterministicActivated = audienceAppForChannel.is_audience_deterministic_activated;
    $scope.isAudienceAmplifiedActivated = audienceAppForChannel.is_audience_amplified_activated;
    $scope.isAudienceAlwaysOnActivated = audienceAppForChannel.is_audience_always_on_activated;
    $scope.activatedAmplifiedThreshold = audienceAppForChannel.activated_amplified_threshold;
    $scope.activatedAlwaysOnThreshold = audienceAppForChannel.activated_always_on_threshold;
    $scope.activatedMarket = audienceAppForChannel.activated_market;
    $scope.activatedAdvertiser = audienceAppForChannel.activated_advertiser;
    $scope.activatedDataContractId = audienceAppForChannel.activated_data_contract_id;
    $scope.activatedDataContractText = audienceAppForChannel.activated_data_contract_text;
    $scope.activatedCategoryId = audienceAppForChannel.activated_category_id;
    $scope.getSegmentIds = getSegmentIds;
    $scope.createAudienceTargetTaxonomy = createAudienceTargetTaxonomy;
    $scope.createAudienceTargetUserList = createAudienceTargetUserList;
    $scope.createAmplifiedAudience = createAmplifiedAudience;
    $scope.createAlwaysOnAudience = createAlwaysOnAudience;
    $scope.createDeterministicAudience = createDeterministicAudience;
    $scope.createUserListForDeterministicAudience = createUserListForDeterministicAudience;
    $scope.dspService = dspService;
    $scope.notificator = notificator;
    $scope.ssoService = ssoService;
    $scope.hasAmplificationModeSelector = common.hasAmplificationModeSelector(context, abiPermissions);
    $scope.getDataContract = getDataContract;
    $scope.getTaxonomyCategory = getTaxonomyCategory;
    updateAudienceActivationUi();

    let segmentDemographics = this.segment.find((segment) => segment.type === "demographics");
    $scope.geo = segmentDemographics && segmentDemographics.geo && segmentDemographics.geo[0];
    $scope.isUSA = this.channel === 'smart_tv' || this.channel === 'linear_tv' || this.channel === 'gracenote' || $scope.geo && $scope.geo.cc === 'US';
    $scope.isSgChannel = SG_CHANNELS.includes(this.channel);

    $scope.dataTypesToggleValues = dataTypesToggleValues;
    $scope.selectedDataType = $scope.selectedDataType || 'skew';
    MixpanelAudience.trackDemographicsInterestsView($scope.selectedDataType, 'demographics', $scope.channel);

    $scope.dataTypesChange = (value) => {
        MixpanelAudience.trackViewChange(value, 'Audience Demographics widget', '', $scope.channel);
        $timeout(() => $scope.selectedDataType = value);
        MixpanelAudience.trackDemographicsInterestsView(value, 'demographics', $scope.channel);
    };

    $scope.data = {distribution: {}, skew: {}};

    async function updateAudienceActivationUi() {
        const channel = $scope.channel;
        const channelAudience = context.current.audience_app[channel];
        const tempAudience = {
            id: channelAudience.audience_id,
            name: channelAudience.audience_name,
            segment: channelAudience.audience_segment,
            activation: channelAudience.audience_activation,
        };
        $scope.isActivateAudienceEnabled = false;
        $scope.activateAudienceDisabledText = '';
        const {isEnabled, isVisible, disabledText} = await isActivateAudienceEnabled(abiPermissions, channel, tempAudience, $scope.$root.user.id);
        $scope.isActivateAudienceEnabled = isEnabled;
        if ($scope.isActivateAudienceEnabled) $scope.marketsAndAdvertisersPromise = dspService.getAmplificationMarketContext('value');

        $scope.isActivateAudienceVisible = isVisible;
        $scope.activateAudienceDisabledText = disabledText;
        $scope.isActivateEnabled = !audienceAppForChannel.audience_targetDisabled || isVisible;
        $scope.$digest();
    }

    $scope.updateAudienceDeterministicActivated = function (activatedMarket, activatedAdvertiser) {
        common.updateAudienceDeterministicActivated(activatedMarket, activatedAdvertiser, context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.updateAudienceAmplifiedActivated = function (activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold) {
        common.updateAudienceAmplifiedActivated(activatedMarket, activatedAdvertiser, activatedAmplifiedThreshold,
                                                context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.updateAudienceAlwaysOnActivated = function (activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                                       activatedAlwaysOnThreshold) {
        common.updateAudienceAlwaysOnActivated(activatedDataContractId, activatedDataContractText, activatedCategoryId,
                                               activatedAlwaysOnThreshold, context, $scope, $scope.channel);
        updateAudienceActivationUi();
    };

    $scope.shouldShowEthnicityBox = function() {
        return $scope.isUSA || ($scope.isSgChannel && abiPermissions.hasPermission("sg telco ethnicity"));
    };

    $scope.getAmplifiedEstimatedReachGoal = (bidstreamUsers, amplifiedReachGoalValue) => (new Promise((resolve) => setTimeout(() => resolve(bidstreamUsers * (amplifiedReachGoalValue / 100) + _.random(0, 99) * 1000), 2000)));

    const audienceDemographicsDataPromise = getFullDemographicsDataWithGenderAgeBySegment(this.segment, {channel: this.channel})
        .then((data) => {
                if(!data) return;
                switch(data.status) {
                    case 'ok':
                        // insert skew tooltips
                        _.each(data.skew.gender, (o, gender) => {
                            o.tooltip = skewTooltip(`${_.capitalize(gender)}s`, o.value)
                        });
                        data.skew.genderAge.forEach((o) => {
                            o.male.tooltip = skewTooltip(`Males of age ${o.label}`, o.male.value);
                            o.female.tooltip = skewTooltip(`Females of age ${o.label}`, o.female.value);
                        });
                        data.skew.ethnicity.forEach((o) => {
                            let ethnicity_label = o.label.charAt(o.label.length - 1) === 's' ? o.label : `${o.label}s`;
                            o.tooltip = skewTooltip(ethnicity_label, o.value);
                        });
                        data.skew.income.forEach((o) => {
                            o.tooltip = skewTooltip(`Residents of households with an income of ${o.label} USD`, o.value);
                        });

                        $scope.data = data;
                        if ($scope.data.audienceSize.population < MIN_AUDIENCE_SIZE) $scope.data.audienceSize.population = MIN_AUDIENCE_SIZE;
                        $scope.$apply();
                        break;
                    case 'error':
                        switch(data.error.reason) {
                            case "selectionTooWide":
                                $scope.too_wide = true;
                                break;
                            case "selectionTooNarrow":
                                $scope.too_narrow = true;
                                break;
                        }
                        break;
                }
            });

    $scope.isAudienceDemographicsLoading = true;
    audienceDemographicsDataPromise.then(() => { $scope.isAudienceDemographicsLoading = false });
    $scope.loadingPromise = audienceDemographicsDataPromise;

    function skewTooltip(topicPlural, value) {
        switch(value) {
            case 1:
                return `${topicPlural} are as likely to be in the target audience as the average consumer`;
            case 0:
                return `${topicPlural} are unlikely to be in the target audience`;
            default:
                const [multiplier, comparePhrase] = value > 1 ? [value, 'more'] : [1 / value, 'less'];
                return `${topicPlural} are ×${multiplier.toFixed(1)} times ${comparePhrase} likely to be in the target audience than the average consumer`;
        }
    }
}

module.exports = require("angular").module(__filename, [
    require('common/segment-size.drv/segment-size.drv').name,
    require('common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv').name,
    require('common/age-gender-chart.drv/age-gender-chart.drv').name,
    require('common/am-donut-chart.drv/am-donut-chart.drv').name,
])
    .directive("audienceDemographicsWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-demographics-widget.html"),
        scope: {
            handleActivatingAudience: '&',
            handleActivatingTarget: '&'
        },
        controller: AudienceDemographicsWidgetController,
    })])
    .component('genderDistribution', react2angular(GenderDistribution, ['femaleValue', 'femaleDisplayValue', 'femaleTooltip', 'maleValue', 'maleDisplayValue', 'maleTooltip']));