import Cookies from 'js-cookie';
import { setCommonHeader } from '../infra/HttpClient';
import { getSilentReAuthenticationService } from './AngularExportedMiddleware';

export async function refreshJwtToken(request) {
  const response = await getSilentReAuthenticationService()(request.url);
  const { refresh_token: refreshToken, token: jwtToken } = response.data;
  setCommonHeader('Authorization', jwtToken);
  if (refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  }
  Cookies.set('Authorization', jwtToken, { expires: 1 });
  Cookies.set('Authorization_help', jwtToken, { expires: 1 });
  return response;
}
