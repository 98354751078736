var BaseWidget = require("../base_widget"),
    Template = require("./referrals-widget.html"),
    c = require("infra/utils/common"),
    TopChart = require("common/charts/top-chart"),
    ex = require("infra/utils/export");

var topChartConfiguration = {
    source: {
        topBarGroup: {
            topBar: {
                labelSuffix: '%'
            },
            iconLabels: {
                Facebook: 'images/images/insights-referrals/facebook.svg',
                Direct: 'images/images/insights-referrals/direct.svg',
                Search: 'images/images/insights-referrals/search.svg',
                Pinterest: 'images/images/insights-referrals/pinterest.svg',
                Reddit: 'images/images/insights-referrals/reddit.svg',
                Twitter: 'images/images/insights-referrals/twitter.svg',
                'Other websites': 'images/images/insights-referrals/other.svg',
                'Organic reading': 'images/images/insights-referrals/organic.svg',
                Instagram: 'images/images/insights-referrals/instagram.svg'
            }
        }
    },
    publisher: {
        topBarGroup: {
            topBar: {
                labelSuffix: ''
            },
            clickableLabel: true
        }
    }
};

var MAX_DISPLAY_RESULTS = 100;

ReferralsController.$inject = ['$scope', '$rootScope', '$timeout', 'referralsService', 'util', 'insightsExportService',
                               'examplesDataModel', 'consumptionTrendService', 'abiPermissions', 'baseInsightsService',
                               'TIMES', 'filtersPartition', 'notificator', '$location'];
function ReferralsController($scope, $rootScope, $timeout, referralsService, util, insightsExportService,
                             examplesDataModel, consumptionTrendService, permissions, baseInsightsService, TIMES,
                             filtersPartition, notificator, $location) {
    var sourcesMapping = {
      publisher:{
        name: 'Publishers',
        exportValuesFormat: 'number'
      },
      source: {
        name: 'Sources',
        exportValuesFormat: 'percent2'
      },
      media: {
        name: 'Media Types',
        exportValuesFormat: 'percent2'
      }
    };
    var channelDescription = {
      publisher: {
        title: 'Web Consumption by Publisher',
        description: 'Content about the seed interests is mostly consumed from the following publishers:'
      },
      source: {
        title: 'Web Consumption by Referring Source',
        description: 'Content consumption about the seed interests is referred from the following sources:'
      },
      media: {
        title: 'Web Consumption by Media type',
        description: 'Content about the seed interests is mostly consumed in the following media types:'
      }
    };

    var self = this;
    this.permissions = permissions;
    this.baseInsightsService = baseInsightsService;
    this.TIMES = TIMES;
    this.filtersPartition = filtersPartition;
    this.notificator = notificator;
    this.min_date = this.TIMES.INSIGHTS_OFFSET;

    $scope.sourceTypes = [
      {value: 'publisher', label: 'Publisher'},
      {value: 'source', label: 'Source', plural: 'phrases', singular: 'phrase'}
    ];

    this.determineTimeframeVars = function() {
        if ($location.path() != "/insights/channels") { //Don't set timeframe vars if we're stepping out of widget
            return;
        }
        let times = ["1M", "3M", "6M", "1Y"];
        self.min_date = this.TIMES.INSIGHTS_OFFSET;
        if ($scope.webConsumptionSource === 'source' && $scope.insightsChannel.value == 'sg_telco') {
            _.pull(times, "1Y");
        }
        $scope.$root.$broadcast('timeframe-vars-update', times, self.min_date, false);
    };

    this.performUpdate = function() {
        self.parameters.timeframe = $scope.timeframe;
        return $scope.dataPromise = self.service.get(self.parameters).then(function(response) {
          $scope.channelDescription = channelDescription[$scope.webConsumptionSource];
          $scope.noTopData = false;
          $scope.$emit('insightsInsufficientData', response.insufficientSeeds);
            $timeout(function() {
                $scope.noTopData = !c.isArray(response.topData);
                response.topData.forEach(function(obj) {
                    obj.label = _.capitalize(obj.label);
                });
                self.latestData = _.take(response.topData, MAX_DISPLAY_RESULTS);
                self.topChart = new TopChart('normal', null,
                  '#referrals-bar-chart', topChartConfiguration[$scope.webConsumptionSource]);
                self.topChart.draw(self.latestData, 'normal', 'relative');
            }, 0);
        }, function(error) {
            if (error != "No Terms") {
                $scope.$emit('insightsError', error);
            }
        });
    };

    this.onResize = function() {
      $timeout(function() {
        self.topChart && self.topChart.draw(self.latestData, 'normal', 'relative');
      }, 0);
    };

    this.getExportReport = function(format) {
        var channelInfo = sourcesMapping[$scope.webConsumptionSource];
        var channel = channelInfo.name;
        var data = self.service.getCachedData(self.parameters);
        var title = [format(channel.substring(0, channel.length - 1), 'bold')];
        var columns = [{
            width: 35
        }];
        _.each(self.util.getValidTerms($scope, self.parameters), function(term) {
            title.push(format(term.text, 'bold'));
            columns.push({
                width: 35
            });
        });

        var table = [title];
        _.each(data.topData, function(refData) {
            var tableLine = [refData.label];
            _.each(refData.values, function(value) {
                tableLine.push(format(value.value, channelInfo.exportValuesFormat));
            });

            table.push(tableLine);
        });
        return {
            name: channel,
            columns: columns,
            table: table
        };
    };

    this.getExportExamples = function(format) {
        var report = {
            name: 'Content drivers',
            columns: [],
            table: []
        };
        report.table = [
            [format('Date', 'bold'), format('Seed', 'bold'), format('URL', 'bold')]
        ];
        report.columns = [{
            width: 18
        }, {
            width: 25
        }, {
            width: 100
        }];
        var result = consumptionTrendService.getExamples($scope.trends, format);
        report.table = report.table.concat(result);
        return report;
    };

    $scope.changeSource = function() {
        self.parameters.webSource = $scope.$root.webConsumptionSource = $scope.webConsumptionSource;
        self.determineTimeframeVars();
        return self.performUpdate();
    };

    this.getExamples = function() {
      let params = _.clone(self.parameters);
      params.examples_only = true;
      $scope.examplesData.promise = consumptionTrendService.get(params, null);
      $scope.examplesData.terms = $scope.trends.filter(function (t) {
        return ['mention', 'hashTag', 'post'].indexOf(t.type) === -1;
      });
      angular.copy($scope.examplesData.terms, $scope.examplesData.filters);

      var termClasses = {};
      _.each($scope.examplesData.terms, function(trend) {
        termClasses[trend.text] = termClasses[trend.id] = trend.class;
      });
      _.each($scope.examplesData.filters, function(trend) {
          trend.show = true;
      });
      return $scope.examplesData.promise.then(function(data) {
          self.$scope.noData = false;
          self.chartData = data;
          self.$scope.trendAverages = data.averages;
          $scope.examplesData.icon = data.icon;
          $scope.examplesData.examples.consumption = data.examples;
          _.each($scope.examplesData.examples.consumption, function(ex, i) {
              ex.class = termClasses[ex.keyword];
          });
          $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption, function(example) {
              return termClasses[example.keyword]
          });

          angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
          self.$scope.inProcess = false;
      }, function(error) {
          if (error != "No Terms") {
              $scope.$emit('insightsError', error);
          }
      });
    };

    this.validateContextChannel = function() {
        if($scope.insightsChannel == null){
            $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
        }

        if (!$scope.insightsChannel.hasOwnProperty('value')) {
            $rootScope.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
        }
        else{
            if ($scope.insightsChannel.permission != null && !this.permissions.hasPermission($scope.insightsChannel.permission)){
                $rootScope.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
            }
        }

        if(c.isNotBelongToPermissionGroup($scope.$root.insightsReferralsChannelsFilter, $scope.insightsChannel.value)){
            $rootScope.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
        }
    };

    function initWidgetParameters() {
        self.service = referralsService;
        self.util = util;
        self.insightsExportService = insightsExportService;
        self.parameters = {};
        $scope.noTopData = true;

        $scope.webConsumptionSource = $scope.$root.webConsumptionSource = "publisher";
        $scope.channelDescription = channelDescription[$scope.webConsumptionSource];
        $scope.examplesData = examplesDataModel;
        self.topChart = null;
        $scope.examplesData.visible = true;
        $scope.examplesData.alphabetized = false;
        $scope.examplesData.filter_type = 'trend';
    }

    initWidgetParameters();
}

ReferralsController.prototype._doUpdate = function(values, changedVals) {
    var $scope = this.$scope;
    this.validateContextChannel();
    c.validateNonPhrases($scope.terms, null, this.notificator);
    this.parameters = this.util.buildInsightsParameters(_.pick($scope, ['topics', 'terms', 'active', 'geo', 'timeframe', 'audience', 'sgTelcoAudience','insightsChannel', 'sub_geos','userFirstPartyAudience']), false, true);
    this.parameters.webSource = $scope.webConsumptionSource;
    $scope.trends = this.parameters.trends;
    this.insightsExportService.clear();
    var export_parameters = [];
    if (this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        export_parameters =  _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        export_parameters = _.clone(this.parameters);
    }
    if(this.parameters.channel == 'articles' && this.permissions.hasPermission('first party segments') && !_.isEmpty($scope.userFirstPartyAudience)) {
        this.insightsExportService.addSummaryFields(ex.FIRST_PARTY_SEGMENTS_SUMMARY_FIELD);
        export_parameters.first_party_audience = _.map($scope.userFirstPartyAudience, 'summary');
    } else {
        delete this.parameters.user_first_party_audience;
    }
    this.insightsExportService.setParams(export_parameters);
    this.insightsExportService.setReportFunctions([]);
    this.insightsExportService.addReportFunction(this.getExportReport);
    this.insightsExportService.setExampleFunction(this.getExportExamples);
    c.logUpdate('Referrals', this.parameters);
    this.determineTimeframeVars();
    this.parameters.timeframe = $scope.timeframe;
    this.getExamples();
    return this.performUpdate();
};

module.exports = angular.module(__filename, [
    require('data/insights/referrals-service').name
]).directive("referralsWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: Template,
        scope: {
            timeframe: "=",
            active: "=",
            terms: "=",
            geo: "=",
            topics: "=",
            audience: '=',
            sgTelcoAudience: '=',
            userFirstPartyAudience: '=',
            cacheBaster: "=",
            insightsChannel: '=',
            sub_geos: "=subGeos"
        },
        controller: ReferralsController
    });
}]);
