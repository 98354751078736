import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omitBy } from 'lodash';
import './TvShowsModal.scss';
import ModalWithConfirmationButtons from '../../../../../common/Modal/ModalWithConfirmationButtons/ModalWithConfirmationButtons';
import V2Dropdown from '../../../../../common/Dropdown/V2Dropdown/V2Dropdown';
import TvCustomTimeframe from '../../TvCustomTimeframe/TvCustomTimeframe';
import moment from 'moment';
import { isSmartTvChannel } from '../../../../../../../data/audience-segment-builder-helper';

const MOMENT_DATE_FORMAT = 'MM-DD-YY';

const TvShowsModal = ({
  isOpen,
  modalTitle,
  onSubmit,
  onCancel,
  tvShowsInput,
  tvNetworksMetadataPromise,
  tvGenresMetadataPromise,
  tvShowsMetadataPromise,
  channel,
  isTimeframeVisible,
}) => {
  const [selectedNetworks, setSelectedNetworks] = useState(tvShowsInput.networks || []);
  const [selectedGenres, setSelectedGenres] = useState(tvShowsInput.genres || []);
  const [selectedShows, setSelectedShows] = useState(tvShowsInput.tv || []);
  const [selectedStartDate, setSelectedStartDate] = useState(tvShowsInput.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(tvShowsInput.endDate);

  const isAllValuesSelected = (selectedValues, values) =>
    !selectedValues.length || selectedValues.length === values.length;

  const onNetworksSelect = (networks) => {
    setSelectedNetworks(networks);
  };

  const onGenresSelect = (genres) => {
    setSelectedGenres(genres);
  };

  const onShowsSelect = (shows) => {
    setSelectedShows(shows);
  };

  const handleSubmit = useCallback(() => {
    const segment = {
      ...tvShowsInput,
      type: 'tvShows',
      networks: selectedNetworks,
      genres: selectedGenres,
      tv: selectedShows,
      ...(selectedStartDate && { startDate: dateToString(selectedStartDate) }),
      ...(selectedEndDate && { endDate: dateToString(selectedEndDate) }),
    };
    const removeEmptyTvFilters = omitBy(
      segment,
      (val, key) => ['networks', 'genres', 'tv'].includes(key) && isEmpty(val)
    );
    onSubmit(removeEmptyTvFilters);
  }, [tvShowsInput, selectedNetworks, selectedGenres, selectedShows, selectedStartDate, selectedEndDate]);

  const handleCancel = () => {
    onCancel('tvShows');
  };

  const networksSummaryTextBuilder = (selectedValues, values) => {
    if (isAllValuesSelected(selectedValues, values)) return 'All networks';
    return summaryTextBuilder(selectedValues);
  };

  const genresSummaryTextBuilder = (selectedValues, values) => {
    if (isAllValuesSelected(selectedValues, values)) return 'All genres';
    return summaryTextBuilder(selectedValues);
  };

  const showsSummaryTextBuilder = (selectedValues, values) => {
    if (isAllValuesSelected(selectedValues, values)) return 'All shows';
    return summaryTextBuilder(selectedValues);
  };

  const summaryTextBuilder = (selectedValues) => {
    if (selectedValues.length === 1) return selectedValues[0]['label'];
    return selectedValues.map((value) => value.label).join(', ');
  };

  const isSubmitDisabled = () => isEmpty(selectedNetworks) && isEmpty(selectedGenres) && isEmpty(selectedShows);

  function dateToString(date) {
    return moment(new Date(date)).format(MOMENT_DATE_FORMAT);
  }

  return (
    <div className="tv-shows-modal-component">
      <ModalWithConfirmationButtons
        width="592px"
        modalTitle={modalTitle}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onXClick={handleCancel}
        isSubmitDisabled={isSubmitDisabled()}
        isDisabledEnterKeyPress={isSubmitDisabled()}
        isLightTheme={true}
        isYAutoScrollEnabled={false}
      >
        <div className="tv-shows-modal-content">
          <div className="modal-row">
            <div className="row-label">Networks:</div>
            <V2Dropdown
              promise={tvNetworksMetadataPromise}
              selectedValues={selectedNetworks}
              isMulti={true}
              isSearchable={true}
              summaryTextBuilder={networksSummaryTextBuilder}
              onSelect={onNetworksSelect}
              maxNumberOfSelectedOptions={7}
              reachMaxSelectedOptionsMsg="Sorry, this field is limited to 7 networks"
            ></V2Dropdown>
          </div>
          <div className="modal-row">
            <div className="row-label">Genres:</div>
            <V2Dropdown
              promise={tvGenresMetadataPromise}
              selectedValues={selectedGenres}
              isMulti={true}
              isSearchable={true}
              summaryTextBuilder={genresSummaryTextBuilder}
              onSelect={onGenresSelect}
            ></V2Dropdown>
          </div>
          <div className="modal-row">
            <div className="row-label">Watched either:</div>
            <V2Dropdown
              promise={tvShowsMetadataPromise}
              selectedValues={selectedShows}
              isMulti={true}
              isSearchable={true}
              summaryTextBuilder={showsSummaryTextBuilder}
              onSelect={onShowsSelect}
              maxNumberOfSelectedOptions={20}
              reachMaxSelectedOptionsMsg="Sorry, this field is limited to 20 shows"
            ></V2Dropdown>
          </div>
          {isTimeframeVisible && isSmartTvChannel(channel) && (
            <div className="modal-row">
              <div className="row-label">Timeframe:</div>
              <TvCustomTimeframe
                startDateString={selectedStartDate}
                endDateString={selectedEndDate}
                selectedStartDateCB={(selectedDate) => setSelectedStartDate(dateToString(selectedDate))}
                selectedEndDateCB={(selectedDate) => setSelectedEndDate(dateToString(selectedDate))}
              ></TvCustomTimeframe>
            </div>
          )}
        </div>
      </ModalWithConfirmationButtons>
    </div>
  );
};

TvShowsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tvShowsInput: PropTypes.object,
  tvNetworksMetadataPromise: PropTypes.object,
  tvGenresMetadataPromise: PropTypes.object,
  tvShowsMetadataPromise: PropTypes.object,
  channel: PropTypes.string,
  isTimeframeVisible: PropTypes.bool,
};

TvShowsModal.defaultProps = {
  tvShowsInput: {},
  channel: '',
  isTimeframeVisible: true,
};

export default TvShowsModal;
