import { clone, extend, map, isEqual } from 'lodash';
import { getContext, getStateCurrent } from '../../middleware/AngularExportedMiddleware';
import * as MixpanelCommon from './MixpanelCommon';

const appName = 'Insights';
const associationsMeasure = { sov: 'SOV', relative: 'Strength', absolute: 'Index' };

let context = getContext();
let snapshot = context ? context.current : {};
let associations = [];
let widgetName = MixpanelCommon.extractWidget(getStateCurrent());

export const extractTimeframe = (snapshot) =>
  MixpanelCommon.buildTimeframe(snapshot.insights_timeframe || [], 'YYYY-MM-DD');

export const insights = () => {
  context = getContext();
  snapshot = context ? context.current : {};

  const allTerms = MixpanelCommon.buildTerms(snapshot.terms);
  const result = {
    appName,
    widget: widgetName,
    terms: allTerms.terms.concat(allTerms.bl_terms),
    refinedTerms: allTerms.bl_terms.length > 0,
    timeframe: extractTimeframe(snapshot),
    topics: map(snapshot.topics, 'name'),
    geo: map(snapshot.geo, 'label'),
    channels: snapshot.insightsChannels ? snapshot.insightsChannels.label : '',
  };
  extend(result, MixpanelCommon.buildProgram(context));
  return result;
};

let lastSearch = clone(insights());
let lastTerms = lastSearch.terms;

export const trackPageView = (widget) => {
  MixpanelCommon.trackPageView(appName, widget);
  widgetName = widget;
};

export const trackExport = () => MixpanelCommon.trackExport(appName, widgetName);

export const trackInsightsSearch = () => {
  const props = clone(insights());

  if (isEqual(props, lastSearch)) return;

  lastSearch = clone(props);
  props.fullSearch = clone(insights());
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
  MixpanelCommon.sendToMixpanel('Insights - Search', props);

  if (isEqual(props.terms, lastTerms)) return;

  MixpanelCommon.trackSearch('Insights', widgetName, props.terms, props.refinedTerms, snapshot.insightsChannels.label);
  lastTerms = props.terms;
};

export const trackInsightsSearchChange = () => {
  const props = clone(insights());
  props.fullSearch = clone(insights());
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
  MixpanelCommon.sendToMixpanel('Insights - Search', props);
};

export const trackAssociations = (newAssociations) => {
  associations = map(newAssociations, 'text');
  const associationMode = associationsMeasure[snapshot.measure];
  const props = {
    appName,
    'Tested Associations': associations,
    'Association Mode': associationMode,
    Search: insights(),
    widget: 'associations',
  };
  MixpanelCommon.sendToMixpanel('Overview - Insights Generation', props);
  MixpanelCommon.sendToMixpanel('Insights - Tested Associations', props);
};
