import React from 'react';
import PropTypes from 'prop-types';
import './AppCommonElements.scss';
import ClickableIcon from '../../ClickableIcon/ClickableIcon';
import Separator from '../../Separator/Separator';
import V2Dropdown from '../../Dropdown/V2Dropdown/V2Dropdown';

const AppCommonElements = ({
  onCalendarClick,
  onExportClick,
  onFilterClick,
  isCalendarVisible,
  isExportVisible,
  isFilterVisible,
  isDropdownVisible,
  dropdownValues,
  selectedDropdownValue,
  onDropdownChange,
}) => (
  <div className="app-common-elements-component">
    {/* calendar */}
    {isExportVisible && (
      <React.Fragment>
        <Separator />
        <ClickableIcon className="common-icon" iconId="download" onClick={onExportClick} tooltip="Export" />
      </React.Fragment>
    )}
    {isFilterVisible && (
      <React.Fragment>
        <Separator />
        <ClickableIcon className="common-icon" iconId="filter" onClick={onFilterClick} />
      </React.Fragment>
    )}
    {isDropdownVisible && (
      <V2Dropdown
        values={dropdownValues}
        selectedValue={selectedDropdownValue}
        onSelect={onDropdownChange}
        summaryWidth="200px"
        menuPosition="absolute"
        shouldCloseOnSelect={true}
        shouldCloseOnClickOutside={true}
      />
    )}
  </div>
);

AppCommonElements.propTypes = {
  onCalendarClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  isCalendarVisible: PropTypes.bool,
  isExportVisible: PropTypes.bool,
  isFilterVisible: PropTypes.bool,
  isDropdownVisible: PropTypes.bool,
  dropdownValues: PropTypes.array,
  selectedDropdownValue: PropTypes.object,
  onDropdownChange: PropTypes.func,
};

AppCommonElements.defaultProps = {
  isCalendarVisible: false,
  isExportVisible: false,
  isFilterVisible: false,
  isDropdownVisible: false,
};

export default AppCommonElements;
