import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

const BaseWidget = require("../base_widget"),
    c = require("infra/utils/common");

ConsumptionChannelController.$inject = ['$scope', 'consumptionChannelService', 'util', '$timeout',
                                        'insightsExportService', 'TIMES', '$location','abiPermissions'];

function ConsumptionChannelController($scope, consumptionChannelService, util, $timeout, insightsExportService,
                                      TIMES, $location, abiPermissions) {
    const self = this;
    const BAR_MARGIN = 20;
    this.TIMES = TIMES;
    this.permissions = abiPermissions;
    this.$location = $location;
    MixpanelInsights.trackPageView('channels');
    //filter based on fixed result returned from server
    this.barChartFilter = [{name : "web channel", value : 0},{name : "web channel",value : 1}, {name : "social channel", value : 2},{name : "video channel", value : 3}];
    this.performUpdate = function() {
        return self.service.get(self.parameters).then(function(response) {
            $scope.noData = false;
            $scope.inProcess = false;
            $timeout(function() {
                self.setChannelData(response);
            });
        }, function(error) {
            self.clear(true);
            if (error != "No Terms") {
              $scope.$emit('insightsError', error);
            }
        });
    };

    this.getFilteredData = function(data){
        var result = [];
        _.each(this.barChartFilter, function(filter){
            if(abiPermissions.hasPermission(filter.name)){
                result.push(data[filter.value])
            }
        });
        return result;
    }

    this.setChannelData = function(channelData) {
        var cd = this.getFilteredData(channelData);
        const maxValue = _(cd).map('max').max();
        $scope.factor = 100 / ((maxValue > 0) ? maxValue : 100);
        _.each(cd, function(channel) {
            _.each(channel.values, function(trend) {
                trend.original = trend.value;
                trend.value *= $scope.factor / 100;
            });
        });

        $scope.barChartBreakEven = 100 / maxValue;
        $scope.channelData = cd;
    };

    this.clear = function(update) {
        $scope.noData = true;
        $scope.inProcess = false;
        $scope.channelData = [];
        insightsExportService.clear();
    };

    this.getExportReport = function(format) {
        const firstLine = [format('Channel', 'bold')];
        const columns = [{width: 35}];
        _.each($scope.trends, function(term) {
            firstLine.push(format(term.text, 'bold'));
            columns.push({width: 35});
        });
        const table = [firstLine];
        const data = self.service.getLatestData();
        _.each(data, function(channel) {
            const tableLine = [channel.text];
            _.each(channel.values, (value) => tableLine.push(format(value.original, 'numeric')));
            table.push(tableLine);
        });
        return {name: 'Channels', columns, table};
    };

    function initWidgetParameters() {
        $scope.factor = 1;
        $scope.precision = 2;
        self.service = consumptionChannelService;
        self.util = util;
        self.insightsExportService = insightsExportService;
        self.$scope = $scope;
        self.parameters = {};
        self.timeframe = null;
        self.terms = null;
        self.clear();
        insightsExportService.addReportFunction(self.getExportReport);
    }

    function calcBarsWidth() {
        const barChartEl = angular.element(document.querySelector('am-bar-chart'));
        const barChartWidth = barChartEl[0] ? parseInt(barChartEl.css('width').replace("px", '')) : 0;
        return (barChartWidth + BAR_MARGIN) * $scope.channelData.length;
    }

    initWidgetParameters();

    $scope.calculateAvgLineHeight = function() {
        const barsSliderEl = angular.element(document.querySelector('.channel-sliders'));
        const barAvgEl = angular.element(document.querySelector('.bar-avg'));
        if(barsSliderEl[0] && barAvgEl[0]) { // array containing one DOM element is received unless none is found
            const barsHeight = parseInt(barsSliderEl.css('height').replace("px", ''));
            const avgBottom = parseInt(barAvgEl.css('bottom').replace("px", ''));
            return (barsHeight - avgBottom - 11) * $scope.barChartBreakEven; // 11 is the height of the values above the bar
        } else
            return 0;
    };

    $scope.showButtons = function() {
        const channelFrameEl = angular.element(document.querySelector('.channel-frame'));
        const parentWidth = channelFrameEl[0] ? parseInt(channelFrameEl.css('width').replace("px", '')) : 0;
        return calcBarsWidth() > parentWidth - 90; // -90 for the index block
    }
}

ConsumptionChannelController.prototype._doUpdate = function(values, changedVals) {
    const $scope = this.$scope;
    const $location = this.$location;
    if($scope.insightsChannel == null){
        $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
    }
    if (!$scope.insightsChannel.hasOwnProperty('value')){
        $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
    }else{
        if ($scope.insightsChannel.permission != null && !this.permissions.hasPermission($scope.insightsChannel.permission)){
            $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsReferralsChannelsFilter, this.permissions);
        }
    }
    c.validateNonPhrases($scope.terms, null, this.notificator);
    if ($location.path() == "/insights/channels") { //Don't set timeframe vars if we're stepping out of widget
        let times = ["1M", "3M", "6M", "1Y"];
        $scope.$root.$broadcast('timeframe-vars-update', times, this.TIMES.INSIGHT_OFFSET, false);
    }
    this.parameters = this.util.buildInsightsParameters(_.pick($scope, ['topics', 'terms', 'active', 'geo', 'timeframe', 'userFirstPartyAudience'], false, true));
    $scope.trends = this.parameters.trends;
    $scope.inProcess = true;

    return this.performUpdate();
};

module.exports = angular.module(__filename, [
    require('data/insights/consumption-channel-service').name
])
    .directive("consumptionChannelWidget", [() => BaseWidget({
        restrict: "E",
        template: require('./consumption-channel-widget.html'),
        scope: {
            timeframe: "=",
            active: "=",
            terms: "=",
            geo: "=",
            topics: "=",
            userFirstPartyAudience: '=',
            cacheBaster: "="
        },
        controller: ConsumptionChannelController
    })]);
