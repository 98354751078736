import React from 'react';
import BaseDropdown from '../BaseDropdown/BaseDropdown';
import PropTypes from 'prop-types';
import './V2Dropdown.scss';

const V2Dropdown = (props) => <BaseDropdown {...props} />;

V2Dropdown.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  promise: PropTypes.object,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  shouldCloseOnClickOutside: PropTypes.bool,
  showSelectAllOptions: PropTypes.bool,
  selectAllOptionsText: PropTypes.string,
  showClearAllOptions: PropTypes.bool,
  clearAllOptionsText: PropTypes.string,
  summaryTextAllSelected: PropTypes.string,
  summaryTextBuilder: PropTypes.func,
  onSelect: PropTypes.func,
  virtualizeThreshold: PropTypes.number,
  isVirtualizeDisabled: PropTypes.bool,
  menuMaxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuPosition: PropTypes.string,
  summaryWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clickTwiceToUnselect: PropTypes.bool,
  maxNumberOfSelectedOptions: PropTypes.number,
  reachMaxSelectedOptionsMsg: PropTypes.string,
  tooltip: PropTypes.string,
};

V2Dropdown.defaultProps = {
  className: 'v2-dropdown-component',
  shouldCloseOnClickOutside: true,
  menuMaxHeight: 200,
  summaryIconName: 'chevron',
};

export default V2Dropdown;
