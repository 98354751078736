"use strict";
import 'angular-modal-service';
import * as MixpanelCommon from '../../react/infra/mixpanel/MixpanelCommon'

var keywordSuggesterModule = require('data/keyword_suggester.srv.js'),
    emailService = require('data/email-service'),
    defineInterestModal = require('common/modals/define-interest/define-interest'),
    programBooleanLogicService = require('data/settings/program-boolean-logic-service.js'),
    programSettings = require('pages/programs/program_settings/program-settings'),
    utilModule = require('infra/util.js');

module.exports = angular.module(__filename, [
    'angularModalService',
    keywordSuggesterModule.name,
    utilModule.name,
    emailService.name,
    defineInterestModal.name,
    programBooleanLogicService.name,
    programSettings.name,
]).directive('inputBar',
    ['$timeout', '$window', '$state', 'context', 'keywordSuggester', 'notificator', 'emailService',
     'defineInterestModal', 'programBooleanLogicService', 'ModalService', '$rootScope', 'util', '$document',
     'abiPermissions', 'SIGNS', 'programService',
        function ($timeout, $window, $state, context, keywordSuggester, notificator, emailService, defineInterestModal,
                  pblService, ModalService, $rootScope, util, $document, abiPermissions, SIGNS, programService) {
            var INIT_SUGGESTION_INDEX = 0;
            return {
                restrict: 'EA',
                require: 'ngModel',
                template: require("./input-bar.tmpl.html"),
                scope: {
                    ngModel: '=',
                    onValidate: '&',
                    onComplete: '&',
                    limit: '<',
                    placeholder: '@',
                    placeholderInvalid: '@',
                    programBlAllowed: '&',
                    disableBooleanLogic: '&',
                    disableAutocomplete: '=',
                    disableAddToTracking: '=',
                    limitationExceededMsg: '@',
                    showRefresh: '=',
                    disableMentionsPosts: '@',
                    disablePosts: '&',
                    columns: '<?',
                    useTags: '<?',
                    expandable: '@',
                    customSuggestions: '<?'
                },
                controller: ['$scope', '$element', function ($scope, $element) {
                    var ctrl = $element.controller('ngModel');
                    var self = this;

                    this.initialHeight = $element.height();
                    this.minHeight = $element.height();
                    this.initialMouseY = 0;
                    this.labelElement = $element.children('label');
                    this.placeHolderElement = angular.element($element[0].querySelector('.input'));
                    $scope.columns = angular.isDefined($scope.columns) ? $scope.columns : 2;
                    if ($scope.columns < 1 || $scope.columns > 2) {
                      throw `${$scope.columns} columns are not supported in input-bar`;
                    }
                    $scope.useTags = angular.isDefined($scope.useTags) ? $scope.useTags : true;

                    this.focus = function() {
                      $scope.toInputMode();
                    };

                    this.expand = function (event, isOnDraggingIcon) {
                        if (event.buttons !== 1) {
                            self.initialMouseY = 0;
                            if (isOnDraggingIcon) {
                                event.stopPropagation();
                                return false;
                            }
                            return true;
                        }

                        if (!self.initialMouseY) {
                            if (isOnDraggingIcon) {
                                self.initialMouseY = event.clientY;
                                self.initialHeight = $element.height();
                                self.labelElement.css('cursor', 'row-resize');
                                self.placeHolderElement.css('cursor', 'row-resize');
                                return false;
                            }
                            return true;
                        }
                        var newHeight = self.initialHeight + event.clientY - self.initialMouseY;
                        if (newHeight >= self.minHeight) $element.height(newHeight);
                        event.stopPropagation();
                    };

                    this.stopExpand = function () {
                        if (self.initialMouseY) {
                            self.initialMouseY = 0;
                            self.labelElement.css('cursor', '');
                            self.placeHolderElement.css('cursor', '');
                        }
                    };

                    if ($scope.expandable) {
                        $document.on('mousemove', this.expand);
                        $document.on('mouseup', this.stopExpand);
                        $element.on('dragstart', function () {
                            return false;
                        });
                    }

                    $scope.expand = this.expand;

                    this.removeInvalidTerms = function () {
                        if (ctrl.$viewValue) {
                            ctrl.$viewValue = ctrl.$viewValue.filter(function (tag) {
                                return tag.id;
                            });
                        }
                    };

                    this.editPhrase = function (index, event, termName, newTerm) {
                        var termOriginal = ctrl.$viewValue[index];
                        var isQuickRefine = !_.isEmpty(newTerm);
                        var term;

                        if (termOriginal.type !== 'booleanLogic' && termOriginal.type !== 'programBL') {
                            term = {
                                text: termOriginal.text + SIGNS.EDITED,
                                display: termOriginal.display + SIGNS.EDITED,
                                type: "booleanLogic",
                                class: termOriginal.class,
                                id: -1,
                                required: [angular.copy(termOriginal)]
                            }
                        }

                        var currentTerm = angular.copy(term || termOriginal);
                        currentTerm.display = (term || termOriginal).display;

                        if (isQuickRefine) {
                            angular.forEach(newTerm, function (value, key) {
                                this[key] = (this[key] || []).concat(value);
                                self.executeLimitConstraint(this[key]);
                            }, currentTerm);
                        }

                        var current_terms = angular.copy(ctrl.$viewValue);
                        current_terms.splice(_.findIndex(current_terms, {id: termOriginal.id}), 1);

                        //return term;
                        defineInterestModal.showModal(current_terms, currentTerm, 'Refine interest', isQuickRefine).then(function (modal) {

                            $scope.$parent.$parent.refineInterestOpened = true;
                            modal.close.then(function (interest) {
                               $scope.$parent.$parent.refineInterestOpened = false;
                                if (interest) {
                                    if (interest.deleted) {
                                        ctrl.$viewValue.splice(index, 1);
                                        $scope.reloadProgramBooleanLogics();
                                    } else {
                                        ctrl.$viewValue[index] = interest;
                                    }

                                    ctrl.$setViewValue(_.clone(ctrl.$viewValue));
                                }
                            });
                        });
                    };

                    this.executeLimitConstraint = function (tags) {
                        var c = 0;
                        if (tags.length > $scope.limit) {
                            tags.splice($scope.limit, tags.length - $scope.limit);
                            c = 7;
                            var msg = "Sorry, the interest bar is limited to " + $scope.limit + " seed interests";
                            if ($scope.limitationExceededMsg) msg = $scope.limitationExceededMsg;
                            notificator.notify({body: msg});
                        } else {
                            c = tags.length;
                        }

                        return c;
                    };

                    $element.data("$inputBarController", this);
                }],
                link: function (scope, element, attr, ngModelController) {
                    /********************
                     * Internal variables
                     ********************/
                    var inputBarController = element.data("$inputBarController");
                    var $input = element.find('textarea:nth-child(1)');
                    var joinDelimiter = ', ';
                    var splitDelimiter = ',';
                    var isInputMode = false;
                    var commitInProcess = false;
                    var prevWidth = 0;

                    var Tag = function (text, id, className, invalid) {
                        return {
                            text: text || null,
                            id: id || null,
                            class: className || null,
                            invalid: (invalid != void 0) ? invalid : !id
                        };
                    };

                    var BooleanLogicTag = function (id, text, required, included, excluded) {
                        var tag = {
                            id: id,
                            text: text,
                            class: null,
                            type: 'booleanLogic',
                            invalid: false,
                            required: required,
                            included: included,
                            excluded: excluded
                        };

                        util.ignoreSerialize(tag, 'invalid');
                        util.ignoreSerialize(tag, 'display');
                        return tag;
                    };

                    var onResize = function() {
                      var width = element.width();
                      if (!width || width == prevWidth) return false;
                      prevWidth = width;
                      scope.__width = width;
                      return true;
                    };
                    onResize();

                    /********************
                     * Scope variables
                     ********************/
                    scope.ctrl = ngModelController;
                    scope.EDITED_SIGN = SIGNS.EDITED;
                    scope.POST_SIGN = SIGNS.POST;
                    scope.MENTION_SIGN = SIGNS.MENTION;
                    scope.suggestedType = 'mentions';
                    scope.isSuggestionsOpen = false;
                    scope.placeholder = scope.placeholder || 'Add interest';
                    scope.hasPermission = abiPermissions.hasPermission;

                    scope.hasCustomSuggestions = () => _.isArray(scope.customSuggestions);

                    scope.isCompleteKeywords = () => context.current._language_mold.isCompleteKeywords($state, context);

                    scope.isResolveKeywords = () => context.current._language_mold.isResolveKeywords($state, context);

                    scope.isSuggestionsOpenAllowed = () =>
                        scope.isSuggestionsOpen &&
                        $input.val().length < 1000 &&
                        (scope.hasCustomSuggestions() || scope.isResolveKeywords() ||
                         scope.isCompleteKeywords() || scope.programBlAllowed());

                    scope.clearSuggestions = function() {
                        scope.suggestions = [];
                        scope.suggestionsProgramBL = [];
                    };

                    scope.clearPlaceholder = function (event) {
                        event.currentTarget.placeholder = '';
                    };

                    scope.setPlaceholder = function (event) {
                        ngModelController.$render();
                        event.currentTarget.placeholder = scope.placeholder;
                        $input[0].blur();
                    };

                    scope.showLoader = (index)=>{
                        return index < 10;
                    }

                    scope.clearSuggestions();
                    scope.selectedIndex = INIT_SUGGESTION_INDEX;

                    //events

                    $rootScope.$on('context-updated', function () {
                        util.upgradeTerms(scope.current_program().boolean_logics);
                    });

                    $rootScope.$on('switch-language', function () {
                        scope.clearAll();
                    });

                    angular.element($window).bind('resize', function() {
                      if (onResize() === false) return;
                      scope.$apply();
                    });

                    scope.current_program = function () {
                        return $rootScope.context.program;
                    };

                    scope.termsOrTrends = function () {
                        return $rootScope.context.current.terms.length != 0 || $rootScope.context.current.keyTrends;
                    };

                    scope.noTerms = function () {
                        return $rootScope.context.current.terms.length == 0;
                    };

                    /* internal methods */
                    scope.editPhrase = inputBarController.editPhrase;

                    scope.refreshView = function () {
                        $rootScope.$broadcast("refresh-view", {});
                    };

                    scope.isSocialBL = util.isSocialBooleanLogic;

                    scope.toInputMode = function () {
                        if (isInputMode) return;

                        $timeout(function () {
                            element.click();
                        });

                        isInputMode = true;
                        scope.suggestedType = 'mentions';
                        scope.isSuggestionsOpen = false;
                        element.removeClass("open");
                        scope.clearSuggestions();
                        scope.selectedIndex = INIT_SUGGESTION_INDEX;
                        element.addClass('input-mode').removeClass('tags-mode').focus();
                        ngModelController.$render();
                        element.find('textarea').focus();
                    };

                    scope.toTagMode = function () {
                        isInputMode = false;
                        element.addClass('tags-mode');
                        element.removeClass('input-mode');
                        element.removeClass("open");
                        scope.isSuggestionsOpen = false;
                        $input[0].blur();
                        clearWaiting();
                    };

                    scope.remove = function (index, $event) {

                        trackRemovedPhrases(index);

                        /* remove key word from model itself */
                        $event.preventDefault();
                        $event.stopPropagation();
                        ngModelController.$viewValue.splice(index, 1);
                        /* shallow clone just for angular change detection */
                        ngModelController.$setViewValue(_.clone(ngModelController.$viewValue));
                        ngModelController.$render();
                    };

                    scope.trackPhrase = function (index, $event, tag) {
                        scope.remove(index, $event);
                        var user = $rootScope.user,
                            language = $rootScope.context.current._language_mold.getLanguage($state, $rootScope.context).value;
                        emailService.trackPhrase(user.id, tag, language);
                    };

                    scope.clearAll = function () {
                        trackRemovedPhrases();
                        ngModelController.$setViewValue([]);
                        ngModelController.$render();
                        scope.clearSuggestions();
                    };

                    function trackRemovedPhrases(index) {
                      var removedPhrase = "",
                          eventName = index != undefined? "Overview - Input Bar Phrase Deleted" : "Overview - Input Bar Clear All",
                          viewValue = _.clone(ngModelController.$viewValue);

                        if(index != undefined){
                            removedPhrase = viewValue[index].text;
                            }else{//when clear all pressed
                            if(viewValue){
                                viewValue.forEach(function (phrase) {
                                    removedPhrase += phrase.text + ", ";
                                })
                            }
                        }

                        //dont track reset seeds more then once (when language changes);
                        if(removedPhrase.length > 0){
                            MixpanelCommon.trackInputBar(eventName, removedPhrase);
                        }
                    }

                    var hash = {UP: -1, DOWN: 1};
                    scope.navigationKey = function ($event, $key) {
                        var len = scope.suggestions.length;

                        if (len && !scope.disableMentionsPosts && scope.suggestions[0].text.trim().startsWith("@")) {
                            scope.suggestedType = scope.disablePosts() || scope.suggestedType == 'posts' ? 'mentions' : 'posts';

                            $timeout(function () {
                                $input.val($input[0].value); //caret at end
                            });
                        } else if (len > 0) {
                            var index = scope.selectedIndex,
                                delta = hash[$key];

                            if (delta) {
                                $event.preventDefault();
                                markSelectedSuggest((delta + index + len) % len);
                            }
                        }
                    };

                    scope.imageAllowed = function(){
                        var channels = _.map($rootScope.context.current.channels, 'value');
                        return $state.is('discovery.streams') &&
                               (channels.length == 0 || _.includes(channels, 'tweets')) &&
                               scope.hasPermission(['ditto']);
                    };

                    function scrollTo(index) {
                      var el = element.find(`results result:nth(${index-1})`);
                      var container = element.find("results .results-scrollable");
                      container.scrollTop(0);
                      var pos = el.position().top - container.height() + el.height();
                      container.scrollTop(pos);
                    }

                    function markSelectedSuggest(index) {
                        scrollTo(index);
                        scope.selectedIndex = index;
                        var selected = scope.suggestions[index];
                        var text = selected.text;

                        if (index >= scope.suggestions.length){
                          text = SIGNS.PROGRAM_INTEREST + text;
                        }

                        commitInputTextToViewValue(text);
                        /* for input element view */
                        ngModelController.$render();
                    }

                    scope.defineInterest = function () {
                        var current_terms = angular.copy(ngModelController.$viewValue);
                        defineInterestModal.showModal(current_terms).then(function (modal) {
                            modal.close.then(function (interest) {
                                if (interest) {
                                  let newTag = new BooleanLogicTag(interest.id, interest.text, interest.required, interest.included, interest.excluded);
                                  scope.commitBooleanTag(newTag);
                                }
                            })
                        });

                        clearWaiting();
                    };

                    scope.manageInterests = function () {
                        programService.program($rootScope.context.program.id).then((program) => {
                            ModalService.showModal({
                                template: require('pages/programs/program_settings/program-settings.html'),
                                inputs: {program: program, initialView: 'program interests'},
                                controller: 'programSettingsController'
                            }).then((modal) => {
                                modal.close.then(function () {
                                    scope.reloadProgramBooleanLogics();
                                });
                            });
                        });
                        clearWaiting();
                    };

                    function clearWaiting() {
                        if (commitInProcess || !ngModelController.$viewValue) return;
                        ngModelController.$viewValue = _.reject(ngModelController.$viewValue, 'waiting');
                    }

                    scope.reloadProgramBooleanLogics = function () {
                        pblService.list().then(function () {
                            util.upgradeTerms(scope.current_program().boolean_logics);
                        }).then(updateProgramBooleanLogics);
                    };

                    function updateProgramBooleanLogics() {
                        ngModelController.$viewValue.filter(function (term) {
                            return term.type === 'programBL';
                        }).forEach(function (term) {
                            var pbl = _.find(scope.current_program().boolean_logics, {id: term.id});
                            var index = _.findIndex(ngModelController.$viewValue, {id: term.id});

                            if (pbl) {
                                pbl.class = ngModelController.$viewValue[index].class;
                                ngModelController.$viewValue[index] = pbl;
                            } else {
                                ngModelController.$viewValue.splice(index, 1);
                            }
                        });

                        ngModelController.$setViewValue(_.clone(ngModelController.$viewValue));
                    }

                    scope.buildProgramBooleanTagDescription = function (bl) {
                        var MAX_LENGTH = 115;

                        util.upgradeTerms(bl.required);
                        util.upgradeTerms(bl.included);
                        util.upgradeTerms(bl.excluded);

                        var required = _.map(bl.required, 'display');
                        var res = 'Always about: ' + required.join(', ');

                        if (bl.included && bl.included.length > 0) {
                            var included = _.map(bl.included, 'display');
                            res += ' | Prefer: ' + included.join(', ');
                        }

                        if (bl.excluded && bl.excluded.length > 0) {
                            var excluded = _.map(bl.excluded, 'display');
                            res += ' | Never: ' + excluded.join(', ');
                        }

                        var created_by = ' | Created by: ' + bl.u_name;
                        if (res.length + created_by.length > MAX_LENGTH) {
                            res = res.substr(0, MAX_LENGTH - created_by.length - 3);
                            res += '...';
                        }

                        res += created_by;

                        return res;
                    };

                    /*************
                     * input->view
                     *************/
                    $input.on('input change', function ($event) {
                        var lastTag = ngModelController.$viewValue.slice(-1)[0],
                            lastTagText = lastTag ? lastTag.text || '' : '';

                        scope.selectedIndex = INIT_SUGGESTION_INDEX;
                        scope.currentTag = this.value.split(splitDelimiter).slice(-1)[0];

                        if (($event.type != "change") && (!lastTagText || lastTagText != scope.currentTag)) {
                            if (!scope.disableAutocomplete) updateSuggestions(scope.currentTag);
                        }
                    });

                    function commitInputTextToViewValue(suggestToAdd, booleanLogicTag, finalCommit) {
                        /* convert input to array */
                        if (suggestToAdd) {
                            suggestToAdd = suggestToAdd.trim();
                        }

                        var tagsTextsCandidate = $input.val().split(splitDelimiter).map(function (text) {
                            return util.getTermTextByDisplay(text);
                        });

                        /* clean empty cell from inputs */
                        if (booleanLogicTag) {
                            // handle case when user press space and then 'define an interest',
                            // The _.compact will delete the space, and the previous keyword would deleted
                            var isLastTermEmpty = !(/\S/.test(tagsTextsCandidate[tagsTextsCandidate.length - 1]));
                            tagsTextsCandidate = _.compact(tagsTextsCandidate);
                            if (isLastTermEmpty) tagsTextsCandidate.push("");
                        } else {
                            tagsTextsCandidate = _.compact(tagsTextsCandidate);
                        }

                        /* add suggest */
                        if (suggestToAdd) {
                            tagsTextsCandidate.pop();
                            tagsTextsCandidate.push(suggestToAdd);
                        }

                        if(scope.suggestedType == "posts") {
                            var lastIndex = tagsTextsCandidate.length - 1;
                            tagsTextsCandidate[lastIndex] = SIGNS.POST + tagsTextsCandidate[lastIndex].trim();
                        }

                        /* protect from duplicate tags */
                        var tagsLength = tagsTextsCandidate.length;
                        tagsTextsCandidate = _.uniqBy(tagsTextsCandidate, _.toLower);

                        /* handle when suggestToAdd is a duplicate of existing tag */
                        if (suggestToAdd && !finalCommit && tagsTextsCandidate.length != tagsLength) tagsTextsCandidate.push(scope.currentTag.trim());

                        /* copy $input to $viewValue */
                        var toViewValue = [];
                        tagsTextsCandidate.forEach(function (tagText, i) {

                            var foundIndex = _.findIndex(ngModelController.$viewValue, {text: tagText});

                            if (foundIndex == -1) {
                                if (booleanLogicTag) {
                                    toViewValue[i] = booleanLogicTag;
                                } else if (scope.hasCustomSuggestions()) {
                                    let suggestion = _.find(scope.customSuggestions, (suggestion) => suggestion.text.toLowerCase() == tagText.replace(/^\s+/, '').toLowerCase());
                                    if (suggestion) {
                                      toViewValue.push(new Tag(suggestion.text, suggestion.id, 'term-default', false));
                                    }
                                } else {
                                    toViewValue[i] = new Tag(tagText, null, 'term-waiting', false);
                                    toViewValue[i].waiting = true;
                                }
                            } else {
                                toViewValue[i] = ngModelController.$viewValue[foundIndex];
                            }
                        });

                        ngModelController.$viewValue = toViewValue;
                    }

                    /*************
                     * view->model
                     *************/
                    scope.commit = function commit(suggest, suggestedType) {

                        commitInProcess = true;
                        scope.$parent.$broadcast("commit-in-process", {commitInProcess:true});
                        if (suggestedType) {
                            scope.suggestedType = suggestedType;
                        }

                        commitInputTextToViewValue(suggest, false, true);
                        scope.toTagMode();

                        var newTags = _.reject(ngModelController.$viewValue, 'id');
                        var usedTags = _.filter(ngModelController.$viewValue, 'id');

                        /* validate New Words */
                        var newTagsText = _.map(newTags, 'text').map(util.getTermTextByDisplay);

                        inputBarController.executeLimitConstraint(ngModelController.$viewValue);
                        scope.onValidate({newTagsText: newTagsText, usedClasses : _.map(usedTags, 'class')}).then(onValidateCallback);

                        $timeout(function () {
                            scope.suggestedType = "mentions";
                        });
                    };

                    scope.commitBooleanTag = function (booleanTag) {
                        commitInputTextToViewValue(booleanTag.text, booleanTag, true);
                        scope.toTagMode();

                        scope.onValidate({newTagsText: booleanTag}).then(onValidateCallback);
                    };

                    scope.commitProgramBooleanTag = function (program_bl) {
                        var i = _.findIndex(ngModelController.$viewValue, function (item) {
                            return item.text === program_bl.text;
                        });

                        if (i > -1) return;

                        commitInputTextToViewValue(program_bl.text, program_bl, true);
                        scope.toTagMode();
                        scope.onValidate({newTagsText: program_bl}).then(onValidateCallback);
                    };

                    function onValidateCallback(tagsToAdd) {
                        /** put each returning tag in his place **/

                        tagsToAdd.forEach(function (tag) {
                            var i = _.findIndex(ngModelController.$viewValue, {text: tag.origin});

                            ngModelController.$viewValue[i] = tag;
                        });

                        commitInProcess = false;
                        scope.$parent.$broadcast("commit-in-process", {commitInProcess:false});
                        clearWaiting();

                        ngModelController.$setViewValue(_.uniq(ngModelController.$viewValue, 'text'));
                        if(scope.onComplete){
                            scope.onComplete();
                        }
                    }

                    ngModelController.$parsers.push(function (viewValue) {
                        inputBarController.executeLimitConstraint(viewValue);
                        /* for not separate modelValue from viewValue */
                        return viewValue.filter(function (tag) {
                            return tag.id;
                        });
                    });

                    /************
                     * model->view
                     ************/
                    ngModelController.$formatters.push(function (modelValue) {
                        return modelValue ? _.clone(modelValue) : []; // shallow copy
                    });

                    ngModelController.$render = function () {
                        if (angular.isArray(ngModelController.$viewValue)) {
                            inputBarController.executeLimitConstraint(ngModelController.$viewValue);
                            var value = ngModelController.$viewValue.map(function (tag) {
                                return tag.display || tag.text;
                            }).join(joinDelimiter);

                            if (value != '' && !value.match(/,\s+$/) && scope.limit > 1) {
                                value += joinDelimiter;
                            }
                            $input.val(value);
                        }
                    };

                    function updateSuggestions(text) {
                        if (scope.hasCustomSuggestions()) {
                          let stripped_text = text.replace(/^\s+/, '').toLowerCase();
                          if (stripped_text.length > 0) {
                              let suggestions = _.filter(scope.customSuggestions, (suggestion) => suggestion.text.toLowerCase().indexOf(stripped_text) !== -1);
                              $timeout(function () {
                                  updateSuggestionsCB(text, suggestions);
                              });
                          }
                        } else if (!isCompleteKeywords(text)) {
                            $timeout(function(){
                                updateSuggestionsCB(text, []);
                            });
                        } else {
                            keywordSuggester.getSuggestions(text).then(function (suggestions) {
                                updateSuggestionsCB(text, suggestions);
                            });
                        }
                    }

                    function isCompleteKeywords(text){
                        return false == text.trim().startsWith(scope.MENTION_SIGN) &&
                            $rootScope.context.current._language_mold.isCompleteKeywords($state , $rootScope.context);
                    }

                    function updateSuggestionsCB(text, suggestions) {
                        if (text) {
                            suggestions.unshift({text: text});
                        }

                        scope.suggestions = suggestions.slice(0);

                        if (scope.isProgramBlAllowed() && text) {
                            scope.suggestionsProgramBL = (scope.current_program().boolean_logics || [])
                              .filter(pbl => (pbl.text || "").toLowerCase().includes(text.trim().toLowerCase()))
                              .sort(pbl => pbl.text);
                        } else {
                            scope.suggestionsProgramBL = [];
                        }

                        scope.isSuggestionsOpen = !!text && isInputMode;
                        element.toggleClass("open", scope.isSuggestionsOpen);
                    }

                    scope.isBooleanLogicAllowed = function () {
                        return !scope.disableBooleanLogic() && abiPermissions.hasPermission(['seed refinement']);
                    };

                    scope.isProgramBlAllowed = function () {
                        return scope.isBooleanLogicAllowed() && scope.programBlAllowed();
                    };

                    scope.hasSuggestionsProgramBL = function () {
                        return !_.isEmpty(scope.suggestionsProgramBL);
                    };

                    scope.showEditButton = function (tag) {
                        return scope.isBooleanLogicAllowed() && !tag.invalid && !tag.waiting;
                    };

                    /* init */
                    scope.toTagMode();
                    if (scope.isProgramBlAllowed()) {
                        scope.$on("$stateChangeStart", scope.reloadProgramBooleanLogics);
                        $timeout(scope.reloadProgramBooleanLogics, 500);
                    }
                }
            }
        }
    ]
);
