import { react2angular } from 'react2angular';
import GaugeBar from 'react/components/common/GaugeBar/GaugeBar';

module.exports = require("angular").module(__filename, [])
    .component('gaugeBar', react2angular(GaugeBar, ['lowLabel', 'highLabel', 'percentage', 'numOfTicks']))
    .directive('segmentSize', [() => ({
        restrict: 'E',
        template: require('./segment-size.drv.html'),
        scope: {
            "segmentSize": "=",
            "population": "="
        },
        link: function($scope) {
            $scope.$watch("population", function() {
                $scope.percents = Math.min($scope.segmentSize, 100);
            })
        }
    })]);
