"use strict";
 
var demographics = require('widgets/audience-demographics-widget/audience-demographics-widget').name;

dynamicTrendsEditCtrl.$inject = ['$scope', '$state', 'util', 'filtersPartition', '$timeout', 'parentScope', 'geoService', 'targetInfo', 'TargetsCommon', 'dynamicTrendsCtrl', 'DATE_FORMAT', 'context', 'close'];
function dynamicTrendsEditCtrl($scope, $state, util, filtersPartition, $timeout, parentScope, geoService, targetInfo, TargetsCommon, dynamicTrendsCtrl, DATE_FORMAT, context , close) {

    $scope.editTargetCtrl = parentScope.editTargetCtrl;
    $scope.query = $.extend(true, {}, parentScope.queries.dynamic);
    $scope.targetInfo = {};
    $scope.currentTarget = {};
    $scope.dynamicTempData = {};
    $scope.currentTimeFrame = {};
    $scope.currentTopics = [];
    $scope.editing = false;

    let format = DATE_FORMAT.DDMMYYHHmm;
    $scope.timeframes = [
        {text: '1H', value: '1 hour', first: true, days: 0},
        {text: '1D', value: '1 day', days: 1},
        {text: '3D', value: '3 days', days: 3},
        {text: '1W', value: '1 week', days: 7},
        {text: '1M', value: '1 mounth', days: 31},
        {text: '3M', value: '3 mounths', last: 'true', days: 92}
    ];

    const SG_CHANNELS = ['snbb', 'data_spark'];
    const ALL_ETHNICITIES = filtersPartition.racesWithAll[0];

    $scope.limitTopicsOpened = false;

    $scope.treeLoaded = true;

    $scope.init = function () {

        initTargetData();

        let checkedTopics = [];

        if (!_.isEmpty($scope.currentTarget.query.topics)){
            checkedTopics = $scope.currentTarget.query.topics.slice(0).map(function(t){return {id: t}});
            saveCurrentTopics(checkedTopics, true);
        }

        $scope.dataTrees = { 
            'topicsSummary': $.extend(true, {} , 
                {children: $scope.$root.topicsFilter.slice(0), 
                 checkedArray: checkedTopics,
                 allSelectedLabel: "All Topics"
                })
        };

        $timeout(function(){
          
            $scope.iconToLabelMap = {};
            filtersPartition.genders.forEach(g => { $scope.iconToLabelMap[g["icon"]] = _.capitalize(g["audienceLabel"]) });

            $scope.dataTrees.topicsSummary.show();

            $scope.dataTrees.topicsSummary.saveChecked = function(checkedArray){
                saveCurrentTopics(checkedArray);
            }

            $scope.dynamicSeeds = $scope.editTargetCtrl.dynamicTrendsEditHelper.getSeedsData(util);
            $scope.dynamicKeyTrends = getUpdatedKeyTrends() == 1 ? 'On' : 'Off';

            //timeframe:
            /*
            if($scope.currentTarget.query.timeframe && $scope.currentTarget.query.timeframe != "custom"){
                $scope.currentTimeFrame = $scope.currentTarget.query.timeframe;
            }
            */
        });
    };

    $scope.setTimeFrame = function (timeFrame) {
       $scope.currentTimeFrame = timeFrame;
       $scope.validate();
    };

    $scope.validate = function (source) {

        //seeds dont have source when CB is called
        if(_.isEmpty($scope.dynamicSeeds)){
            $scope.editing = false;
            return
        }

        switch(source) {
            //needed when clear all or delete last seed and entering another
            case 'undefined':
            case  undefined :{
                $scope.editing = !_.isEmpty($scope.dynamicSeeds);
                break;
            }
            case 'keyTrends':{
                $scope.editing = $scope.dynamicKeyTrends != getUpdatedKeyTrends();
                break;
            }
        }
    };

    $scope.getButtonCss = (option, currentVal) => util.getButtonCss(option, currentVal, $scope);

    $scope.resetData = function () {
       $scope.currentTopics = [];
    };

    $scope.close = function () {
        $scope.editTargetCtrl.dynamicTrendsEditOpened = false;
        close({});
    };

    $scope.save = function () {

        if(!_.isEmpty($scope.currentTimeFrame)){
            $scope.dynamicTempData.currentTarget.query.timeframe =  $scope.currentTimeFrame;
        }else{
            $scope.dynamicTempData.currentTarget.query.timeframe = $scope.currentTarget.query.timeframe;
            $scope.dynamicTempData.currentTarget.query.timeframe_start = $scope.currentTarget.query.timeframe_start;
            $scope.dynamicTempData.currentTarget.query.timeframe_end = $scope.currentTarget.query.timeframe_end;
        }

        $scope.dynamicTempData.currentTarget.query.topics =  $scope.currentTopics;
        
       let params = $scope.currentTarget;
            params.action = 'editDynamicTrends';
            params.customSeeds = $scope.dynamicSeeds;

        let queryAdditions = TargetsCommon.TargetData.targetToServer({target: params}).query;
        $scope.dynamicTempData.currentTarget.query.phrases_bl = queryAdditions.phrases_bl;
        $scope.dynamicTempData.currentTarget.query.kwd_ids = queryAdditions.kwd_ids;
        $scope.dynamicTempData.currentTarget.query.phrases = queryAdditions.phrases;
        $scope.dynamicTempData.currentTarget.query.key_trends = $scope.dynamicKeyTrends == "On"? 1 : 0 ;

        parentScope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData = $scope.dynamicTempData;
        dynamicTrendsCtrl.refreshView({dataTrees : $scope.dataTrees}, {"seeds": queryAdditions.phrases});

        $scope.close();

    };

    $scope.toggleTopicsOpened = function(close){
        if(close){
          $scope.limitTopicsOpened = false
        }else{
          $scope.limitTopicsOpened = !$scope.limitTopicsOpened;
        }
    }

    $scope.updateKeyTrends = function(){
        $scope.dynamicKeyTrends = $scope.dynamicKeyTrends == 'On' ? 'Off' : 'On';
        $scope.validate("keyTrends");
    }

    function getUpdatedKeyTrends(){
        return $scope.editTargetCtrl.dynamicTrendsEditHelper.getUpdatedQuery().key_trends;
    }


    function saveCurrentTopics(checkedArray, init){
        let topics = checkedArray.map(function(c){return c.id;});
        
        $scope.currentTopics = topics;

        if(!init){
          $scope.validate();
        }
    }

    function initTargetData(){
         if ($scope.editTargetCtrl.isDynamicTempDataEmpty()){
            $scope.targetInfo = targetInfo;
            $scope.currentTarget = parentScope.currentTarget;
         }else{
            $scope.targetInfo = $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.targetInfo;
            $scope.currentTarget = $scope.editTargetCtrl.dynamicTrendsEditHelper.dynamicTempData.currentTarget;
         }

         $scope.dynamicTempData.targetInfo = $.extend(true, {}, $scope.targetInfo);
         $scope.dynamicTempData.currentTarget = $.extend(true, {}, $scope.currentTarget);
    }

    $scope.init();
}

module.exports = dynamicTrendsEditCtrl;
 
