module.exports = angular.module(__filename, [])
    .service('alertsService', ['mgmtServiceFactory', 'abiPermissions', function (mgmtServiceFactory, abiPermissions) {
        var service = mgmtServiceFactory.createService('alert');
        var sentimentPer = (per) => per ? "" : "Coming soon";

        var alertsTypes = [
            {image: "images/images/alerts/interest_consumption.jpg", value: 'consumption', label: 'Interests consumption'},
            {
                image: "images/images/alerts/sentiment.jpg",
                value: 'sentiment',
                label: 'Sentiment',
                disabled: !abiPermissions.hasPermission('sentiment alert'),
                tagLabel: sentimentPer(abiPermissions.hasPermission('sentiment alert')),
                additionalProps: ['sentiment', 'change']
            },
            {image: "images/images/alerts/audience.jpg", value: 'tba', label: 'Audience', disabled: true, tagLabel: "Coming soon"},
            {image: "images/images/alerts/connect_yours.jpg", value: 'tba2', label: 'Connect your data', disabled: true, tagLabel: "Coming soon"}
        ];

        var sentimentDetection = [
            {label: 'Net Sentiment', id: "net"},
            {label: 'Positive Sentiment', id: "pos"},
            {label: 'Negative Sentiment', id: "neg"}
        ];

        var alertChangeDirection = [
            {label: 'Decreases', value: "dec"},
            {label: 'Increases', value: "inc"}
        ];

        return {
            thinList: thinList,
            list: service.list,
            create: service.create,
            update: service.update,
            delete: service.delete,
            getSettings: getSettings,
            editSettings: editSettings,
            report: report,
            types: alertsTypes,
            sentimentDetection: sentimentDetection,
            alertChangeDirection: alertChangeDirection
        };

        function getSettings() {
            return service.customRequest('GET', '/settings')
        }

        function thinList() {
            return service.customRequest('GET', '/thin')
        }

        function editSettings(params) {
            return service.customRequest('POST', '/settings', params);
        }

        function report(alertId) {
            return service.customRequest('GET', '/' + alertId + '/report');
            // return service.customRequest('GET', '/{{ALERT ID HERE}}/report')
        }
    }]
);
