
import config from 'infra/config';

var warVars = require("pages/warroom/warroom-vars")
   ,sparklineDir = require("gadgets/sparkline")
   ,sparklineJQ =  require("gadgets/jquery-sparkline")
   ,associations = require("pages/warroom/warroom-associations")
   ,datus = require("pages/warroom-realtime/warroom-data-layer")
   ,graphs = require("pages/warroom-realtime/warroom-graphs")
   ,tweets = require("pages/warroom-realtime/warroom-tweets")
   ,dateTime = require("pages/warroom-realtime/warroom-date-time");


var refreshBoxesTimer = null, 
    resizeGraphsTimer = null,
    queries = {}, groupIds = [];

queries.groups = {
    title: 'groups',
    dataArray: [],
    columns: [{id: 'name', title: 'group', flex:2, template: 'partials/warroom-group.partial.html'},
            {id: 'total', title: 'count', template: 'partials/warroom-count.partial.html'},
            {id: 'velocity', title: 'velocity', templateHead: 'partials/warroom-lockable.partial.html', template: 'partials/warroom-velocity.partial.html'},
            {id: 'sentiment', title: 'sentiment', template: 'partials/warroom-sentiment.partial.html'},
            {id: 'phrase', title: 'social mentions', template: 'partials/warroom-mentions-sparkline.partial.html', sort:false}
            ],

    maxMin: true,
    fixedSize: true,
    order: 'order',
    reverse: true,
    excel: true
  };

queries.associations = {
  title: '',
  dataArray: [],
  columns: [
          {id: 'text', title: 'association', flex:3, template: 'partials/warroom-associations.partial.html'},
          {id: 'count', title: 'count'}
          ],

  maxMin: true,
  fixedSize: true,
  order: 'count',
  reverse: true,
  excel: true
};


var DEFAULT_MINUTES = 15;


var realtimeModule = angular.module(__filename,[sparklineDir.name])

.filter('unique', function() {

 return function(collection, keyname) {
    var output = [],
        keys = [];

    angular.forEach(collection, function(item) {
        var key = item[keyname];
        if(keys.indexOf(key) === -1) {
            keys.push(key);
            output.push(item);
        }
    });

    return output;
 };

})

.filter('range', function() {
  return function(input, total) {
    total = parseInt(total);
    for (var i=0; i<total; i++){
      input.push(i);
    }
    return input;
  };
});


realtimeModule.stateConfig = {
  name:'realtime',
  url:'/realtime',
  needPermission: false,
  display: 'Main',
  displayTab: 'War Room',
  template: require('pages/warroom-realtime/warroom-realtime.html'),
  controller: RealtimeCtrl,

  data: {
    permissions: ['war room']
  },

  onExit: function(){
    $("am-timeframe-filter am-date-picker").removeClass("selected");
    datus.stopGroups();
    groupIds = [];
    clearInterval(refreshBoxesTimer);
    clearTimeout(resizeGraphsTimer);

    $("am-timeframe-filter am-date-picker").removeClass("selected");

    if(graphs.sentimentChart){
      graphs.sentimentChart.destroy();
      graphs.sentimentChart = null;
    }
  }
};

module.exports = realtimeModule;



RealtimeCtrl.$inject = ["$scope", "$rootScope", "$state", '$timeout', 'Excel', '$http', '$q', 'context', 'notificator', 'util', 'CHART'];

function RealtimeCtrl($scope, $rootScope, $state, $timeout, Excel, $http, $q, context, notificator, util, CHART){
  $rootScope.helpPage = '6_War_Room';
  datus.$http = $http;
  datus.$q = $q;
  associations.$http = $http;
  tweets.$http = $http;
  $scope.CHART = CHART;

  $scope.queries=queries;
  $scope.query=$scope.queries.groups;

  $scope.loaded = false;
  $scope.tempDataArray = [];

  $scope.timezoneOrig = -1*(new Date()).getTimezoneOffset()*60*1000; //your timezone (ms)

  $scope.isRealtimePage = true;
  $scope.firstLoad = true;
  $scope.firstResize = true;
  $scope.resizeTimer = null;

  $scope.removedPhrase = null;
  $scope.removedGroup = null;

  $scope.timeModes = [
    {value: 'realtime', label: 'Real Time'},
    {value: 'custom', label:'Custom'}
  ];

  $scope.allowedTimeframes = [
    {value: 5, label: '5 minutes'},
    {value: 15, label: '15 minutes'},
    {value: 30, label: '30 minutes'},
    {value: 60, label: '1 hour'},
    {value: 120, label: '2 hours'},
    {value: 180, label: '3 hours'},
    {value: 360, label: '6 hours'},
    {value: 540, label: '9 hours'},
    {value: 720, label: '12 hours'}
  ];

  $scope.allowedTimezones = _.map(_.range(24), function(i) {
    var value = (i - 12) * 60 * 60 * 1000;
    var label = i - 12 === 0 ? '' :  (i - 12 < 0 ? (i - 12) : ('+' + (i - 12).toString()));
    return {label: 'GMT ' + label, value: value};
  });
 


  var mins = parseInt(localStorage["warroom_minutes"]);
  if(!mins){ //dont save 3+ hours
    mins=DEFAULT_MINUTES;
  }

  $scope.time = {
    minutes:  mins,
    mode:     localStorage["warroom_mode"] || 'realtime',
    realtime: localStorage["warroom_realtime"] != "false",
    from:     null,
    to:       null,
    timezone : localStorage["warroom_timezone"] ? 1*localStorage["warroom_timezone"] : $scope.timezoneOrig,
    refreshBoxesFreq: 60 * 1000,
    loading: true,
  };

  $scope.getTimeModeObj = ()=>{
    return $scope.timeModes.filter((obj)=>obj.value == $scope.time.mode)[0];
  };

  $scope.getTimeframeObj = ()=>{
    return $scope.allowedTimeframes.filter((obj)=>obj.value == $scope.time.minutes)[0];
  };

  $scope.getTimezoneObj = ()=>{
    return $scope.allowedTimezones.filter((obj)=>obj.value == $scope.time.timezone)[0];
  };

  $scope.selected = {
    entity:               null,
    phraseOrGroup:        null,
    group:                null,
    association:          null,
    phrase:               null,
    tweets:               null,
    tweetsSentiment:      localStorage["warroom_tweets_sentiment"] || 'all',
    date:                 null,
    date_end:             null
  };

  $scope.init = function(){
    if(groupIds.length){
      $scope.initGroups();
    }else{
      $http.get(config.USER_MGMT_API + '/user-groups/user:'+$scope.$root.user.id+'|brand:'+context.program.id+'?just-ids=1').success(function(ids){
        groupIds = ids;
        $scope.initGroups();
      });
    }
  }

  $scope.initGroups = function(){

    datus.stopGroups();
    $scope.setRefreshBoxes();

    if($scope.query){
      $scope.query.dataArray = [];
    }

    if($scope.time.mode == "realtime"){
      $scope.getGroups({clicked:true});

    }else{ //custom

      $scope.setTimeMode($scope.getTimeModeObj('custom'), true);
      $scope.query = $scope.queries.groups;
    }
  }

  //events
  $scope.$on('update', function() {
    if(!$scope.firstLoad){
      groupIds = [];
      $scope.time.loading = true;
      $scope.init();
    }
  });

  $scope.$on('geo', function() {
    $scope.time.loading = true;
    $scope.init();
  });

  $scope.$on('resize', function() {$scope.resize();});

  $scope.getGroups = function(params) {

      params = params || {};

      if(Object.keys(params).length == 0 || !params.keep){ //reset selected
        $scope.selected.group = null;
        $scope.selected.phrase = null;
        $scope.selected.phraseOrGroup = null;
        $scope.selected.association = null;
        $scope.selected.tweets = null;
      }

      $scope.query = $scope.queries.groups;
      $scope.query.loading = true;
      $scope.time.loading = true;

      if($scope.time.realtime==false){ //get groups 1 by 1
        $scope.query.dataArray.length = [];
        if(groupIds.length==0){
          $scope.gotoGroups();
          return;
        }
      }

      //keep fetching groups from ws
      datus.getGroups($scope.time, groupIds).then(

          function() {
            $scope.query.dataArray = []; //unknown state
            showGroups(params);
          },
          function() {
            $scope.groupsError();
            return;
          },
          function(data) { //notify => groups exist

            if(data.error){
              $scope.groupsError();
              return;
            }

            $.each($scope.query.dataArray, function(i, group){
              $scope.openedGroups[group.id] = group.more;
            });

            var i, j, group, groupNew, len = data.length;

            if($scope.time.realtime==false){ //get groups 1 by 1

              if(data.length){
                $scope.query.dataArray.push(data[0]);
                if($scope.query.dataArray.length < groupIds.length){
                  return;
                }
              }

            }else if($scope.query.dataArray.length == len){ //get all data

              //update only changed items
              for(i=0;i<len;i++){

                group = $scope.query.dataArray[i];
                groupNew = _.filter(data, {id: group.id});
                if(groupNew.length){
                  groupNew = groupNew[0];
                }else{ //group not found
                  $scope.query.dataArray = data;
                  break;
                }

                angular.copy(groupNew.phrases, group.phrases);
                angular.copy(groupNew.stats, group.stats);

                for(j in group){
                  if(j != "phrases" && j != "stats" && j != "$$hashKey" && j!= "order" &&
                    !angular.equals(group[j] , groupNew[j])){
                    group[j] = groupNew[j];
                  }
                }
              }

            }else{
              $scope.query.dataArray = data;
            }

            $.each($scope.query.dataArray, function(i, group){
              group.more = $scope.openedGroups[group.id];
            });

            if(len){
              showGroups(params);
            }else{
              $scope.gotoGroups();
            }
          }
      );
    };

    $scope.gotoGroups = function(){
      $scope.$parent.selectTab('warroom.groups');
    }

    $scope.groupsError = function(){
      $scope.error({body: 'error while fetching groups.'});
      $scope.query.loading = false;
      $scope.time.loading = false;
    }

    function showGroups(params){
      _.each($scope.query.dataArray, function (group) {
          _.each(group.phrases, function (phrase) {
              phrase.display = util.getTermDisplay(phrase);
          });
      });
      $scope.query.show($scope.query.dataArray);
      $scope.time.loading = false;

      var foundSelectedGroup = false;

      $.each($scope.query.dataArray, function(i, group){ //find currently selected group
          if(localStorage['warroom_group_id']==group.id){
            $scope.selected.group = group;
            foundSelectedGroup = true;

            var isPhrase = false;
            if(localStorage['warroom_phrase_id']){ //phrase

              $.each(group.phrases, function(i, phrase){
                if(localStorage['warroom_phrase_id']==phrase.id){
                  isPhrase = true;
                  $scope.selected.phraseOrGroup = phrase;
                  $scope.selected.entity = phrase;
                }
              });
            }

            if(false == isPhrase){
              //group
              localStorage['warroom_phrase_id'] = '';
              $scope.selected.phraseOrGroup = group;
              $scope.selected.entity = group;
            }
          }
      });

      if($scope.selected.group == null || foundSelectedGroup == false){ //if not found, select default group
        $scope.query.viewArray = _.sortBy( $scope.query.viewArray, 'order' ).reverse();

        $scope.selected.group = $scope.query.viewArray[0];
        localStorage['warroom_group_id'] = $scope.selected.group.id;
        localStorage['warroom_phrase_id'] = '';
        var stats = $scope.selected.group.stats;
        $scope.selected.date = stats && stats.length ? stats[stats.length-1].ts : '';
        params.clicked = true;
      }

      params.clicked = params.clicked || $scope.firstLoad;

      if($scope.selected.phraseOrGroup == null || foundSelectedGroup == false){
        $scope.selected.phraseOrGroup = $scope.selected.group;
      }

      $scope.refreshEntity({entity: $scope.selected.phraseOrGroup, clicked: params.clicked});
      $scope.resize();
      params.clicked=false;
    }


    //time functions
    $scope.setTimeMode = function(modeObj, showGroups) {

      $scope.time.mode = modeObj.value;

      localStorage["warroom_mode"] = modeObj.value;
      if('custom'===modeObj.value){
        $scope.realtimeOff();
        $scope.setFromTo(showGroups);
        $timeout(function(){$("am-timeframe-filter am-date-picker").addClass("selected");},0);
      }else{
        $scope.time.minutes = parseInt(localStorage["warroom_minutes"]) || DEFAULT_MINUTES;
        $scope.realtimeOn();
      }
    };

    $scope.realtimeOn = function(){

      //default minutes
      $scope.time.minutes = $scope.time.minutes || DEFAULT_MINUTES;

      var minutes = $scope.allowedTimeframes.map(function(t) { return t.time; });

      if(minutes.indexOf($scope.time.minutes)==-1){
        $scope.time.minutes = DEFAULT_MINUTES;
      }

      $scope.time.realtime = true;
      localStorage["warroom_realtime"] = true;
      $scope.time.mode = 'realtime',
      localStorage["warroom_mode"] = $scope.time.mode;
      $scope.setTimeframe($scope.getTimeframeObj());
    };

    $scope.realtimeOff = function(date, date_end){

      if(date){
        $scope.selected.date = date;
        $scope.selected.date_end = date_end;
      }else if($scope.query && $scope.query.dataArray.length){
        $scope.selected.date = $scope.query.dataArray[0].stats[$scope.query.dataArray[0].stats.length-2].ts;
        $scope.selected.date_end = $scope.query.dataArray[0].stats[$scope.query.dataArray[0].stats.length-1].ts;
      }else{
        $scope.selected.date = null;
        $scope.selected.date_end = null;
      }

      $scope.time.realtime = false;
      localStorage["warroom_realtime"] = false;
      datus.stopGroups();
    };

    $scope.realtimeOnOff = (value)=>{
      $scope.time.realtime = value;
      $timeout(()=>{
          $scope.$apply();
          if(value){
            $scope.realtimeOn();
          }else{
            $scope.realtimeOff();
          }
        });
    }

    $scope.setTimeframe = function(obj) {
      $scope.time.minutes = obj.value;
      localStorage["warroom_minutes"] = $scope.time.minutes;
      $scope.getGroups({clicked:true, keep:true});
    };

    $scope.setFromTo = function(showGroups){
      $scope.time.realtime = false;
      localStorage["warroom_realtime"] = false;
      localStorage["warroom_mode"] = "custom";
      dateTime.setFromTo($scope, showGroups);
    };

    $scope.setOrder = function($event){
      //freeze sort by order
      $scope.query.sort.order='order';
      datus.saveOrder($scope.query.dataArray);
      $event.stopPropagation();
    };


    $scope.setRefreshBoxes = function(){
      clearInterval(refreshBoxesTimer);

      refreshBoxesTimer = setInterval(function(){
        $scope.refreshBoxes();
      }, $scope.time.refreshBoxesFreq);

    };

    $scope.refreshBoxes = function(){
      //refresh associations and tweets every minute
      if($scope.time.realtime===true && $scope.selected.group){
        associations.get($scope.selected.phraseOrGroup, $scope);
        tweets.get($scope);
      }
    };


    $scope.clickEntity = function(params){
      params.clicked=true;
      $scope.refreshEntity(params);
    };


    $scope.refreshEntity = function(params){
      //click a group, phrase, or co-occurence

      $scope.selected.tweets = null;
      $("#graph_date").html(params.date ? $scope.timeDisplay(params.date, "day") : "");

      if(params.clicked){
        $scope.setRefreshBoxes();
      }

      if(params.entity.type === "group"){
        if(params.date){
          $scope.realtimeOff(params.date, params.date_end);
        }else{
          $scope.selected.date = null;
          $scope.selected.date_end = null;
        }

        $scope.selected.group = params.entity;
        $scope.selected.phrase= null;
        $scope.selected.phraseOrGroup = params.entity;
        $scope.selected.entity = params.entity;

        warVars.selectedGroupId = params.entity.id;

        graphs.get($scope);
        if(params.clicked){
          localStorage['warroom_group_id'] = params.entity.id;
          localStorage['warroom_phrase_id'] = '';
          $scope.selected.association = null;
          associations.get(params.entity, $scope);
          tweets.get($scope);
        }

      }if(params.entity.type === "phrase"){

        if(params.date){
          $scope.realtimeOff(params.date, params.date_end);
        }else{
          $scope.selected.date = null;
          $scope.selected.date_end = null;
        }

        $scope.selected.phrase = params.entity;
        $scope.selected.group = params.parent || $scope.selected.group;
        $scope.selected.phrase.ids = $scope.selected.phrase.ids;
        $scope.selected.entity = params.entity;
        $scope.selected.phraseOrGroup = params.entity;

        graphs.get($scope);
        if(params.clicked){
          localStorage['warroom_group_id'] = $scope.selected.group.id;
          localStorage['warroom_phrase_id'] = params.entity.id;
          $scope.selected.association = null;
          associations.get(params.entity, $scope);
          tweets.get($scope);
        }

      }else if(params.entity.type === "association"){

        $scope.selected.association = params.entity;
        $scope.selected.association.ids = getPhraseIds($scope.selected.association, $scope.selected.group);
        $scope.selected.entity = params.entity;

        tweets.get($scope);
      }

    };

    $scope.resize = function(maxed, $event){
      if(maxed){
        $scope.maxed = $scope.maxed != maxed ? maxed : null;
      }
      //responsive resize
      $timeout(function(){
        $scope.size = warVars.getSize();

        $("#groups, #associations, #main_graph, #tweets_examples").hide();

        $scope.resizeGroups();
        $scope.resizeAssosications();
        $scope.resizeGraphs();
        $scope.resizeTweets();

        if($scope.firstLoad){
          $timeout(function(){
            $scope.firstLoad = false;
          },50);
        }

        if($scope.firstResize){
          $timeout(function(){
            $scope.firstResize = false;
          },1000);
        }
   
      },0);
    };

    $scope.resizeGroups = function(){

      if(!$scope.maxed){

        $("#realtime #groups").show();
        $("#realtime #groups .quick-table-wrap").css("width",($scope.size.wid-53)*0.67);
        warVars.height($("#realtime #groups .tbody"), ($scope.size.hi-248)/2);

      }else if($scope.maxed=='groups'){

        $("#realtime #groups").show();

       $("#realtime #groups .quick-table-wrap").css("width",$scope.size.wid-49);
        warVars.height($("#realtime #groups .tbody"), $scope.size.hi-199);
      }

    };

    $scope.resizeAssosications = function(){

      if(!$scope.maxed){

        $("#realtime #associations").show();

        warVars.width($("#realtime #associations .quick-table-wrap"), ($scope.size.wid-73)*0.33);
        warVars.height($("#realtime #associations .tbody"), ($scope.size.hi-304)/2);

        $("#realtime #associations .quick-table").css("border-bottom-width", 0);

      }else if($scope.maxed=='associations'){

        $("#realtime #associations").show();

        $("#realtime #associations .quick-table-wrap").css("width",$scope.size.wid-54);
        warVars.height($("#realtime #associations .tbody"), ($scope.size.hi-199));

        $("#realtime #associations .quick-table").css("border-bottom-width", 1);
      }

    };

    $scope.resizeGraphs = function(){

      if(!$scope.maxed){

        $("#realtime #main_graph").show();

        var graphWidth = ($scope.size.wid-51)*0.67,
            graphsHeight = ($scope.size.hi-172)/2;

        warVars.width($("#main_graph"), graphWidth);
        warVars.height($("#main_graph"), graphsHeight);
        
        warVars.width($("#sentiment_graph_wrap"), graphWidth-214);
        warVars.height($("#sentiment_graph_wrap, #summary_wrap"), graphsHeight-52);
        
      }else if($scope.maxed=='main_graph'){

        $("#realtime #main_graph").show();

        var graphWidth = $scope.size.wid-49 ,
            graphsHeight = $scope.size.hi-170;

        warVars.width($("#main_graph"), graphWidth);
        warVars.height($("#main_graph"), graphsHeight);

        warVars.width($("#sentiment_graph_wrap"), graphWidth-260);
        warVars.height($("#sentiment_graph_wrap, #summary_wrap"), graphsHeight-56);
      }

      var sparksSize = $("#main_graph #max_graph").width();
      warVars.width($("#main_graph_title_name"), graphWidth-300-sparksSize);

      if(!$scope.firstResize){
        
        clearTimeout(resizeGraphsTimer);
        
        resizeGraphsTimer = setTimeout(function(){
          graphs.get($scope, "resizing");
        },50); 
      }

    };


    $scope.resizeTweets = function(){

      if(!$scope.maxed){

        $("#realtime #tweets_examples").show();

        warVars.width($("#realtime #tweets_examples"), ($scope.size.wid-73)*0.33);
        warVars.height($("#realtime #tweets_examples"), ($scope.size.hi-94)/2);
        warVars.height($("#realtime #tweets_examples_tweets"), ($scope.size.hi-228)/2);

      }else if($scope.maxed=='tweets_examples'){

        $("#realtime #tweets_examples").show();

        warVars.width($("#realtime #tweets_examples"), ($scope.size.wid-51));
        warVars.height($("#realtime #tweets_examples"), ($scope.size.hi-165));
        warVars.height($("#realtime #tweets_examples_tweets"), ($scope.size.hi-240));

      }

      $("#realtime #tweets_examples .box_title_text_part").css("max-width", $scope.size.wid/3-137);
    };

    $scope.maxTweets = function(max){
      $scope.selected.tweetsMaxed = max;
      $scope.resizeAssosications();
      $scope.resizeTweets();
    }

    $scope.clickSparkline = function(entity, x){
        var point = entity.stats[x];
        if(point){
          if (entity.stats.length > x + 1) {
            var date_end = entity.stats[x + 1].ts;
          } else {
            var date_end = new Date(new Date(point.ts).getTime() + (new Date(point.ts) - new Date(entity.stats[x - 1].ts))).toISOString();
          }
          $scope.selected.date = point.ts;
          $scope.clickEntity({entity:entity, date:point.ts, date_end: date_end});
        }
    };

    $scope.clickMax = function(type){

      var pos = $("#"+type+"_max").attr("point_index");
      if(!pos){
        return;
      }

      pos = pos*1;

      var stats = $scope.selected.phraseOrGroup.stats;
      var ts = stats[pos].ts;
      if (stats.length > pos + 1) {
        var date_end = stats[pos + 1].ts;
      } else {
        var date_end = new Date(new Date(ts).getTime() + (new Date(ts) - new Date(stats[pos - 1].ts))).toISOString();
      }

      $scope.selected.date = ts;
      $scope.selected.date_end = date_end;

      $scope.selected.tweetsSentiment = type;
      localStorage["warroom_tweets_sentiment"] = $scope.selected.tweetsSentiment;
      $scope.clickEntity({entity:$scope.selected.phraseOrGroup, date: ts, date_end: date_end});
    };

    function getPhraseIds(phrase, parent){
      //if you chose "apple" (mac), search also for "apple" (fruit)

      var ids = [], text = phrase.text;
      $.each(parent.phrases, function(i, p){
        if(p.text === text){
          ids.push(p.id);
        }
      });

      return ids;
    }

    $scope.addAssociation = function(ass){
      associations.add(ass, $scope, context);
    };

    $scope.createExcelWorkbookByID = function () {

        var id = window.exportId;
        delete window.exportId;
        return $scope.queries[id].export();
    };


    $scope.getSummarySheet = function(workbook){

        var enc = warVars.encodeCSV;
        var gmt = $scope.time.timezone/(1000*3600);
        if(gmt > 0){
           gmt = "+"+gmt;
        }

        var timeframe1,timeframe2;

        if($scope.time.mode === 'realtime'){
          var stats = $scope.query.dataArray[0].stats,
              date = stats[stats.length-1].ts;
          timeframe1 = ["Time" , enc($scope.timeDisplay( date, "export"))];
          timeframe2 = ["Timeframe" , $scope.time.minutes+' minutes'];

        }else{

          timeframe1 = ["Custom From", enc($scope.timeDisplay($scope.time.from , "export"))];
          timeframe2 = ["To", enc($scope.timeDisplay($scope.time.from , "export"))];
        }

        var summary = [
          ['Program', context.program.name],
          ['Geo', JSON.parse(localStorage["warroom_geo"]).label],
          ['Time Zone', "GMT "+gmt],
          timeframe1,
          timeframe2
        ];

        var summarySheet = workbook.createWorksheet({name: 'summary'});
        summarySheet.setColumns([{width: 20}, {width: 20}]);
        summarySheet.setData(summary);
        workbook.addWorksheet(summarySheet);
    }


    $scope.getGraphSheet = function(workbook, entity) {

      var arr = [], enc = warVars.encodeCSV;

      if(entity){ //1 graph for single group/phrase

        var desc = {
          'Time' : {key: 'tsdisplay', width: 16},
          'Count' : {key: 'total', width: 16},
          'Positive' : {key: 'pos', width: 16},
          'Neutral' : {key: 'neu', width: 16},
          'Negative' : {key: 'neg', width: 16}
        };

        var viewArray = $.extend(true, [], entity.stats);

        viewArray.forEach(function(stat){
          stat.tsdisplay = $scope.timeDisplay(stat.ts, 'export');
          arr.push(stat);
        });

        Excel.addTableSheet(workbook, arr, desc, {name: (entity.name || entity.display)+' trend graphs'});

      }else{ //graphs for all groups+phrases

        var desc = {
          'Group' : {key: 'name', width: 16},
          'Phrase' : {key: 'phrase', width: 16},
          'Time' : {key: 'tsdisplay', width: 16},
          'Count' : {key: 'total', width: 16},
          'Positive' : {key: 'pos', width: 16},
          'Neutral' : {key: 'neu', width: 16},
          'Negative' : {key: 'neg', width: 16}
        };

        var viewArray = $.extend(true, [], $scope.query.viewArray);

        viewArray.forEach(function(group){

          group.phrases.forEach(function(p) {

            p.stats.forEach(function(stat){
              stat.name = group.name;
              stat.phrase = p.phrase = enc(p.display);
              stat.tsdisplay = $scope.timeDisplay(stat.ts, 'export')
              arr.push(stat);
            });
          });

        });

        Excel.addTableSheet(workbook, arr, desc, {name: 'trend graphs'});
      }
    }


    $scope.queries.groups.export = function() {

      var workbook = Excel.builder.createWorkbook();
      workbook.fileName = 'groups.xlsx';

      $scope.getSummarySheet(workbook);

      var desc = {
          'Group' : {key: 'name', width: 16},
          'Phrase' : {key: 'phrase', width: 16},
          'Count' : {key: 'total', width: 16},
          'Velocity' : {key: 'velocity', width: 16},
          'Positive' : {key: 'pos', width: 16},
          'Neutral' : {key: 'neu', width: 16},
          'Negative' : {key: 'neg', width: 16},
          'Always about' : {key: 'required', width: 16},
          'Prefer content' : {key: 'included', width: 16},
          'Never about' : {key: 'excluded', width: 16}
          };

      var arr = [], viewArray = $.extend(true, [], $scope.query.viewArray),
          enc = warVars.encodeCSV;

      viewArray.forEach(function(group){
        group.name = enc(group.name);
        group.phrase = ' ';
        arr.push(group);

        group.phrases.forEach(function(p) {

          p.name = group.name;
          p.phrase = enc(p.display);
          refineArray(p.required);
          refineArray(p.included);
          refineArray(p.excluded);
          arr.push(p);
        });

      });

      function refineArray(arr){
        if(!arr || !arr.length){
          return '';
        }

        var str = arr.reduce(function(a,b) {
          return a+"¸"+b.display;
        },'');
        return str.substring(1);
      }

      Excel.addTableSheet(workbook, arr, desc, {name: 'groups'});

      $scope.getGraphSheet(workbook);

      return workbook;
    };


    $scope.graphExport = function() {

      var workbook = Excel.builder.createWorkbook();
      var entity = $scope.selected.phraseOrGroup;
      workbook.fileName = (entity.name || entity.display)+' social.xlsx';

      $scope.getSummarySheet(workbook);
      $scope.getGraphSheet(workbook, entity);

      return workbook;
    };


    $scope.queries.associations.export = function(){

      var workbook = Excel.builder.createWorkbook(),
          title = warVars.encodeCSV($scope.queries.associations.title);
      workbook.fileName = title+" associations.xlsx";

      $scope.getSummarySheet(workbook);

      var desc = {
        'Association' : {key: 'ass', width: 16},
        'Count' : {key: 'count', width: 16}
      };

      var arr = [];
      $scope.queries.associations.viewArray.forEach(function(ass){
        arr.push({
          ass: (ass.isHashtag ? '#'+ass.text : ass.text),
          count: ass.count
        });
      });

      Excel.addTableSheet(workbook, arr, desc, {name: 'associations'});

      return workbook;
    };

    $scope.tweetsExport = function(){
      return tweets.export($scope, Excel);
    };

    $scope.changeSentiment = function(sen){
      $scope.selected.tweetsSentiment = sen;
      localStorage["warroom_tweets_sentiment"] = $scope.selected.tweetsSentiment;
      tweets.get($scope);
      $timeout(function() {$scope.$apply();},0);
      $scope.setRefreshBoxes();
    };

    $scope.removePhrase = function(group, phrase){
      $scope.removedPhrase = phrase;
      $scope.removedGroup = group;
    }

    $scope.removePhraseCB = function(){

      var phrases = $scope.removedGroup.phrases.filter(function(phrase) {
        return phrase.id != $scope.removedPhrase.id;
      });

      phrases = $scope.phrasesToBackend(phrases);

      var savedGroup = {
        name: $scope.removedGroup.name,
        phrases: phrases,
        tag: "brand:" + context.program.id
      };

      $http({
        url: config.USER_MGMT_API+"/phrase-groups/"+ $scope.removedGroup.id,
        method: "put",
        contentType: "application/json",
        data: angular.toJson(savedGroup)

      }).success(function() {
        try{
          $scope.removedPhrase = null;
          $scope.initGroups();
        }catch(er){}

      }).error(function(e){
            console.log(e);
      });

    }


    $scope.setTimezone = function(obj){
      $scope.time.timezone = obj.value;

      localStorage["warroom_timezone"] = $scope.time.timezone;

      if($scope.time.mode == 'realtime'){ //realtime => change display

        $scope.time.loading  = false;
        tweets.setDisplayDate($scope, tweets.date);
        graphs.get($scope);

      }else{ //custom => resend query
        localStorage["warroom_mode"] = "custom";
        dateTime.setFromTo($scope, true);
      }
    };

    $scope.timeDisplay = function(ts, format){

      //2015-01-19T13:30 => 19 Jan 13:30

      if(!ts || ts.indexOf(":") === -1){
        return '- - - -';
      }

      var time = Date.parse(ts) + 1*$scope.time.timezone; 

      var momentFormat;
      if(format == 'export'){
        momentFormat = 'DD/MM/YYYY  HH:mm:ss';
      }else if(format == 'year'){
        momentFormat = 'MMM D, YYYY HH:mm';
      }else if(format == 'day'){
        momentFormat = 'M/D HH:mm';
      }else if(format == 'hour'){
        momentFormat = 'HH:mm';
      }else{
        momentFormat = 'HH:mm a';
      }        

      return moment.utc(time).format(momentFormat);
    }

    $scope.error = function(e){
      notificator.error(e);
    }

    $scope.msg = function(e){
      notificator.notify(e);
    }


    $scope.getPercent = function(num, total) {
      if(total===0){
        return 0;
      }else{
        return Math.round(100*(num / total));
      }
    };

    $scope.senTitle = function(sen){
      if(sen==="pos"){
        return "positive";
      }else if(sen==="neg"){
        return "negative";
      }else{
        return "neutral";
      }
    };


    $scope.limitStats = function(stats){
      //returns stats of size <=60

      if(stats.length <= 60){
        return stats;
      }

      var i, len=stats.length, limitedStats = [],
          step = Math.round(len / 60);

      for(i = 0 ; i < len; i += step){
        limitedStats.push(stats[i]);
      }

      return limitedStats;
    };

    if(false==$scope.$parent.selected.includes("warroom.realtime")){
      $scope.$parent.selectTab("warroom.realtime");
    }

    $scope.init();
}
