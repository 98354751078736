import axios from 'axios';
import { refreshJwtToken } from '../middleware/AuthenticationMiddleware';
import {
  redirectToLoginPage,
  openNewVersionModal,
  displayErrorBannerIfNeeded,
} from '../middleware/AngularExportedMiddleware';
import { isEmpty, difference } from 'lodash';
import * as Mixpanel from './mixpanel/Mixpanel';

const DEFAULT_OPTIONS = { shouldRefreshTokenOn401: true, returnOnlyData: true, displayRedBannerOnError: true };

export function executeHttpRequest(request, options = DEFAULT_OPTIONS) {
  validate(request, options);
  options = { ...DEFAULT_OPTIONS, ...options };
  const cancelToken = axios.CancelToken.source();
  const axiosRequest = transformRequestToAxiosFormat(request, cancelToken);

  const promise = axios(axiosRequest)
    .then((response) => {
      if (response.status === 205) {
        openNewVersionModal();
        return Promise.reject(response);
      }

      return options.returnOnlyData ? response.data : response;
    })
    .catch((error) => {
      if (axios.isCancel(error) || error.status === 205) return Promise.reject(error); // request has been canceled or rejected due to new version
      if (error.response && error.response.status == 401) {
        Mixpanel.track('401 Unauthorized', { requested: request.url });
        if (!options.shouldRefreshTokenOn401) {
          //deleting the refresh_token in order for the SSO flow to continue to next step in the /login
          localStorage.removeItem('refresh_token');
          redirectToLoginPage();
          return Promise.reject(); // to be on the safe side: should be already redirected to login page
        }
        return refreshJwtToken(request.url).then(() =>
          executeHttpRequest(request, { ...options, shouldRefreshTokenOn401: false })
        );
      }
      // In case of timeout there is no response and no status code
      if (options.displayRedBannerOnError) displayErrorBannerIfNeeded(error.response || error);
      throw error;
    });

  promise.cancel = cancelToken.cancel;
  return promise;
}

export function setCommonHeader(headerName, headerValue) {
  axios.defaults.headers.common[headerName] = headerValue;
}

function validate(request, options) {
  if (!request) throw Error('executeHttpRequest: missing request argument');
  if (!request.url) throw Error("executeHttpRequest: missing request's url");
  if (request.method && !['get', 'post', 'delete', 'put'].includes(request.method))
    throw Error(`executeHttpRequest: unknown request method ${request.method}`);
  if (request.payload && (request.method === 'get' || request.method === 'delete'))
    throw Error(`executeHttpRequest: payload is not allowed for method ${request.method}`);
  const unknownRequestKeys = difference(Object.keys(request), ['url', 'method', 'params', 'payload', 'headers']);
  if (!isEmpty(unknownRequestKeys))
    throw Error(`executeHttpRequest: unknown request keys: ${unknownRequestKeys.join(', ')}`);
  const unknownOptionsKeys = difference(Object.keys(options), Object.keys(DEFAULT_OPTIONS));
  if (!isEmpty(unknownOptionsKeys))
    throw Error(`executeHttpRequest: unknown options keys: ${unknownOptionsKeys.join(', ')}`);
}

function transformRequestToAxiosFormat(request, cancelToken) {
  // Support for url params that may be included to the request
  let params;
  if (request.params) {
    params = request.params;
  }

  if (request.payload) {
    const { payload: data, ...rest } = request;
    request = { data, ...rest };
  }
  if (!request.method) {
    request = { ...request, method: request.data ? 'post' : 'get' };
  }
  request = { ...request, cancelToken: cancelToken.token };
  if (params) {
    request = { ...request, params };
  }
  return request;
}
