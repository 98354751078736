import React from 'react';
import PropTypes from 'prop-types';
import { DatepickerButton } from '@amobee/component-library';
import classNames from 'classnames';
import './DateRangePicker.scss';

const DATE_FNS_FORMAT = 'MM/dd/yy';

const DateRangePicker = ({
  isLightTheme,
  isHighlighted,
  startDate,
  endDate,
  minDate,
  maxDate,
  onStartDaySelected,
  onEndDaySelected,
}) => {
  const adjustStartDate = () => (startDate < minDate ? minDate : startDate);
  const adjustEndDate = () => (endDate > maxDate ? maxDate : endDate);
  const convertDateToSeconds = (date) => date.getTime() / 1000;
  const onDaySelected = (dateObj, onDaySelectedCB) => {
    //The object emitted from onDaySelected is in seconds, convert it to ms and then to date
    const convertToDate = new Date(dateObj.date * 1000);
    onDaySelectedCB(convertToDate);
  };

  return (
    <div className={classNames('date-range-picker-component', isLightTheme ? 'light' : 'dark')}>
      <DatepickerButton
        className={classNames('date-picker-button', { highlight: isHighlighted })}
        dateFormatString={DATE_FNS_FORMAT}
        initialDate={convertDateToSeconds(adjustStartDate())}
        month={adjustStartDate()}
        minDate={minDate}
        maxDate={adjustEndDate()}
        onDaySelected={(dateObj) => onDaySelected(dateObj, onStartDaySelected)}
      />
      <span className={classNames('range', { highlight: isHighlighted })}>-</span>
      <DatepickerButton
        className={classNames('date-picker-button', { highlight: isHighlighted })}
        dateFormatString={DATE_FNS_FORMAT}
        initialDate={convertDateToSeconds(adjustEndDate())}
        month={adjustEndDate()}
        minDate={adjustStartDate()}
        maxDate={maxDate}
        onDaySelected={(dateObj) => onDaySelected(dateObj, onEndDaySelected)}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  isLightTheme: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onStartDaySelected: PropTypes.func.isRequired,
  onEndDaySelected: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {
  isLightTheme: false,
  isHighlighted: false,
  maxDate: new Date(),
};

export default DateRangePicker;
