'use strict';

module.exports = angular.module(__filename, [])
    .service('tvExportService', ['Excel', 'segmentInterestExportService',
        function(Excel, segmentInterestExportService) {
            function createSummaryWorksheet(workbook, formatter, segment, demographicsData, channel) {
                const worksheet = workbook.createWorksheet({name: 'Summary'});
                worksheet.setData(segmentInterestExportService.getSegmentSummaryData(segment, demographicsData, channel, formatter));
                worksheet.setColumns([{width: 25}, {width: 25}, {width: 18}, {width: 18}, {width: 25}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }


            function createTvShowsWorksheet(workbook, interests, formatter, title, filteredByNetworks = []) {
                const worksheet = workbook.createWorksheet({name: title});

                const titleLine = [
                    formatter(title.slice(0, title.lastIndexOf('s')), 'bold'),
                    formatter('Consumption In Audience', 'bold'),
                    formatter('Audience Portion In Consumption', 'bold'),
                    formatter('Audience Skew', 'bold'),
                    formatter('Significance Score', 'bold')
                ];

                if (title === 'TV Shows') titleLine.push(formatter('Genre', 'bold'), formatter('Origin Network', 'bold'));

                let data = interests.map(interest => {
                    const arr = [
                        formatter(interest.title, 'normal'),
                        formatter(interest['interest-portion'] / 100, 'percent'),
                        formatter(interest['segment-portion'] / 100, 'percent'),
                        formatter(interest['uniqueness-index'], 'numeric'),
                        formatter(interest['score'], 'numeric')
                    ];
                    if (title === 'TV Shows') arr.push(formatter(interest['genre'], 'normal'), formatter(interest['network'], 'normal'));
                    return arr;
                });

                let filteredByNetworksFormatted = [formatter('Filtered By Networks', 'bold')].concat(filteredByNetworks.map(network => formatter(network.label, 'normal')));
                data.unshift(titleLine);
                worksheet.setData(_.isEmpty(filteredByNetworks) ? data : Excel.insertDataAtColumn(data, filteredByNetworksFormatted, titleLine.length + 2));
                worksheet.setColumns([{width: 20}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 15}, {width: 20}, {width: 12}, {width: 20}]);
                workbook.addWorksheet(worksheet);
            }

            const fileNamer = segmentInterestExportService.momentBasedFileNamerGen('TV Shows');

            function exportToExcel(data, demographicsData, segment, withNetworks, channel, filteredByNetworks) {
                const workbook = Excel.builder.createWorkbook();
                const formatter = Excel.formater(workbook);

                createSummaryWorksheet(workbook, formatter, segment, demographicsData, channel);
                createTvShowsWorksheet(workbook, data.tv, formatter, 'TV Shows', filteredByNetworks);
                createTvShowsWorksheet(workbook, data.genres, formatter, 'Genres', filteredByNetworks);
                if (withNetworks) createTvShowsWorksheet(workbook, data.networks, formatter, 'Networks');

                workbook.fileName = fileNamer();
                return workbook;
            }

            function downloadExcel(excel) {
                return segmentInterestExportService.excelDownloader(excel, fileNamer);
            }

            return {exportToExcel, downloadExcel};
        }
    ]);
