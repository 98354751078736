module.exports = {
  supportedChannels: {
    'articles': [
      { id: '840' },
      { id: '036', permission: 'insights geo au map' }
    ],
    'sg_telco': [
      { id: '702' }
    ],
    'au_telco': [
      { id: '036' }
    ]
  },
  scalingOptionsByGeo: {
    '840': [
      {
        id: 1,
        label: 'State',
        value: 'state'
      },
      {
        id: 2,
        label: 'DMA',
        value: 'dma'
      }
    ],
    '702': [
      {
        id: 1,
        label: 'Regions',
        value: 'regions'
      }, {
        id: 2,
        label: 'Planning areas',
        value: 'planning'
      }, {
        id: 3,
        label: 'Subzones',
        value: 'subzone'
      }, 
      {
        id: 4,
        label: 'Electoral',
        value: 'electoral',
        permission: 'SG Telco Electoral Filter'
      }
    ],
    '036': [
      {
        id: 1,
        label: 'State',
        value: 'state'
      }, {
        id: 2,
        label: 'SA4',
        value: 'sa4'
      }, {
        id: 3,
        label: 'SA3',
        value: 'sa3'
      }
    ]
  },
  measureOptions: [
    {
      label: "Home",
      value: "home"
    }, {
      label: "Work",
      value: "work"
    }
  ],
  visualizationModesOptions: [
    {
      title: "Map",
      value: "map",
      icon_v2: 'globe',
      height: "16px",
      width: "16px"
    }, {
      title: "Chart",
      value: 'chart',
      icon_v2: 'bar-chart',
      height: "16px",
      width: "16px"
    }
  ],
  formulaOptions: [
    {
      label: "Consumption",
      value: "consumption",
      tooltipText: {all: 'Geo consumption score represents how often a seed interest consumed in relation to a certain region, compared to the other displayed regions. The displayed region with the highest consumption is given a score of 100.'}
    }, {
      label: "Skew",
      value: "skew",
      tooltipText: {all: 'The extent to which the seed interest is over-indexed within a certain region compared to the entire country.',
                    subGeos: 'The extent to which the seed interest is over-indexed within a certain region compared to all of the selected regions'}
    }, {
      label: "Distribution",
      value: "distribution",
      tooltipText: {all: 'The % of a region out of the total interest consumption',
                    subGeos: 'The % of a region out of the total interest consumption in all of the selected regions'}
    }
  ],
  topChartConfiguration: {
    skew: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2
        }
      }
    },
    distribution: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2,
          labelSuffix: '%'
        }
      }
    },
    consumption: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2
        }
      }
    }
  }
};
