'use strict';
const SEGMENTATION_TO_LABEL_MAP = {and: "Require", or: "Prefer", not: "Exclude"};
const SEARCH_TYPE_TO_MAIN_COLUMN_NAME = {td: 'Website', gsw: 'Search Term', xw: 'Phrase'};
const SEARCH_TYPE_TO_MAIN_SHEET_NAME = {td: 'Websites', gsw: 'Searches', xw: 'Phrases'};
const SEGMENT_TYPE_TO_TITLE_ROW = {
    tvShows: 'tv shows',
    linkedinDemographics: 'demographics',
    linkedinIndustries: 'industries',
    linkedinCompanies: 'companies',
    linkedinJobs: 'jobs',
    linearTvDemographics: 'demographics',
    smartTvDemographics: 'demographics',
    smartTvCommercials: 'commercials',
    linearTvCommercials: 'commercials',
    gracenoteCommercials: 'commercials',
};

module.exports = angular.module(__filename, [])
    .service('segmentInterestExportService', ['Excel', 'util', 'abiPermissions',
        function (Excel, util, abiPermissions) {
            const COMMON_COLUMN_WIDTH = 25;
            const BLANK_LINE = [];
            const options = {
                base64: false,
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            };

            function adjustExcelValue(val, field, defaultVal) {
                if (_.isEmpty(val)) return defaultVal;
                if (Array.isArray(val)) return val.map(v => v[field]).join(', ');
                return field ? val[field] : val
            }

            const segmentDefsRowsUtil = {
                demographics: segment => _.compact([
                    ['Gender', _.capitalize(adjustExcelValue(segment.gender, 'label', 'All'))],
                    ['Age', adjustExcelValue(segment.age, 'label', 'All')],
                    ['Children', adjustExcelValue(segment.children, 'summary', 'Both')],
                    ['Geo', adjustExcelValue(segment.geo, 'label', 'All')],
                    segment.hasOwnProperty('ethnicity') ? ['Ethnicity', adjustExcelValue(segment.ethnicity, 'label', 'All')] : null,
                    ['Income', adjustExcelValue(segment.income, 'label', 'All')]
                ]),
                interests: segment => _.compact([
                    ['Interested in all the following', adjustExcelValue(segment.required, 'text', 'None')],
                    ['Interested in either', adjustExcelValue(segment.included, 'text', 'None')],
                    ['Never interested in', adjustExcelValue(segment.excluded, 'text', 'None')],
                    ['Level of Intent', adjustExcelValue(segment.levelOfIntent, 'label', 'None')],
                    segment.hasOwnProperty('ethnicity') ? ['Ethnicity', adjustExcelValue(segment.ethnicity, 'label', 'All')] : null
                ]),
                websites: segment => [
                    ['Must have visited', adjustExcelValue(segment.required, 'text', 'None')],
                    ['Visited either', adjustExcelValue(segment.included, 'text', 'None')],
                    ['Have never visited', adjustExcelValue(segment.excluded, 'text', 'None')]
                ],
                lifestyle: segment => [
                    ['lifestyle', segment.label]
                ],
                tvShows: segment => [
                    ['Networks', adjustExcelValue(segment.networks, 'label', 'All')],
                    ['Genres', adjustExcelValue(segment.genres, 'label', 'All')],
                    ['Watched either', adjustExcelValue(segment.tv, 'label', 'All')],
                    ...(segment.startDate && segment.endDate ? [['Timeframe', `${segment.startDate} - ${segment.endDate}`]] : [])
                ],
                '1st party': segment => [
                    ['1st Party', segment.label]
                ],
                linkedinDemographics: segment => [
                    ['Gender', _.capitalize(adjustExcelValue(segment.gender, 'label', 'Both'))],
                    ['Age', adjustExcelValue(segment.age, 'label', 'All')],
                    ['Geo', adjustExcelValue(segment.country, 'label', 'All')],
                    ['State', adjustExcelValue(segment.state, 'label', 'All')],
                    ['Area', adjustExcelValue(segment.regions, 'label', 'All')]
                ],
                linkedinIndustries: segment => [
                    ['Industries', adjustExcelValue(segment.industries, 'label', 'All')]
                ],
                linkedinCompanies: segment => [
                    ['Sizes', adjustExcelValue(segment.sizes, 'label', 'All')]
                ],
                linkedinJobs: segment => [
                    ['Functions', adjustExcelValue(segment.functions, 'label', 'All')],
                    ['Job Titles', adjustExcelValue(segment.jobTitles, 'label', 'All')],
                    ['Seniorities', adjustExcelValue(segment.seniorities, 'label', 'All')]
                ],
                linearTvDemographics: segment => [
                    ['Gender', _.capitalize(adjustExcelValue(segment.gender, 'label', 'All'))],
                    ['Age', adjustExcelValue(segment.age, 'label', 'All')],
                    ['Geo', adjustExcelValue(segment.geo, 'label', 'All')],
                    ['Ethnicity', adjustExcelValue(segment.ethnicity, 'label', 'All')]
                ],
                smartTvDemographics: segment => [
                    ['Gender', _.capitalize(adjustExcelValue(segment.gender, 'label', 'All'))],
                    ['Age', adjustExcelValue(segment.age, 'label', 'All')],
                    ['Geo', adjustExcelValue(segment.geo, 'label', 'All')],
                    ['Ethnicity', adjustExcelValue(segment.ethnicity, 'label', 'All')],
                    ['Income', adjustExcelValue(segment.income, 'label', 'All')]
                ],
                linearTvCommercials: segment => [
                    ['Brand', adjustExcelValue(segment.brands, 'label', 'All')]
                ],
                smartTvCommercials: segment => [
                    ['Brand', adjustExcelValue(segment.brands, 'label', 'All')]
                ],
                gracenoteCommercials: segment => [
                    ['Brand', adjustExcelValue(segment.brands, 'label', 'All')]
                ],

                drawDataTable: function (segment, channel, formatter) {
                    const titleRow = [formatter('Audience Definitions', 'bold')];
                    const addEthnicity = channel !== 'data_spark' || abiPermissions.hasPermission("sg telco ethnicity");
                    return segment.reduce((rows, segment) => {
                        let segment_copy = _.clone(segment);
                        if (addEthnicity) {
                            segment_copy.ethnicity = segment.ethnicity || null; //Assign a null value to present 'All' in the summary
                        } else {
                            delete segment_copy.ethnicity;
                        }
                        const titleRow = [formatter(util.capitalFirst(SEGMENT_TYPE_TO_TITLE_ROW[segment.type] || segment.type), 'bold')];
                        const advancedSegmentation = SEGMENTATION_TO_LABEL_MAP[segment.operand && segment.operand.value || 'and'],
                            advancedSegmentationRow = [formatter('Advanced segmentation:', 'normal'), formatter(advancedSegmentation, 'normal')];
                        const segmentRows = this[segment.type](segment_copy).map(row => row.map(col => formatter(col, 'normal')));
                        return rows.concat([titleRow, ...segmentRows, advancedSegmentationRow, BLANK_LINE]);
                    }, [titleRow]);
                }
            };

            const demographicsRowsUtil = {
                commonProps: ['audienceSize', 'gender', 'genderAge','ethnicity'],
                onlyUSProps: ['income'],

                audienceSize: (data, f) => [
                    [f('Audience Summary', 'bold')],
                    [null, 'Estimated Population', 'Percentage in Geo'],
                    ['Audience Size', f(data.audienceSize.population, 'integer'), f(data.audienceSize.segmentSize / 100, 'two_decimal_percent')]
                ],
                gender: (data, f) => [
                    [f('Gender', 'bold')],
                    [null, 'Distribution', 'Skew'],
                    ['Male', f(data.distribution.gender.male.value, 'int_percent'), f(data.skew.gender.male.value, 'numeric')],
                    ['Female', f(data.distribution.gender.female.value, 'int_percent'), f(data.skew.gender.female.value, 'numeric')],
                ],
                genderAge: (data, f) => [
                    [f('Age', 'bold')],
                    [null, 'Male Distribution', 'Female Distribution', 'Male Skew', 'Female Skew'],
                    ...data.distribution.genderAge.map(ageObj => ageObj.label).map((label, i) => [
                        label,
                        f(data.distribution.genderAge[i].male.value / 100, 'int_percent'),
                        f(data.distribution.genderAge[i].female.value / 100, 'int_percent'),
                        f(data.skew.genderAge[i].male.value, 'numeric'),
                        f(data.skew.genderAge[i].female.value, 'numeric')
                    ])
                ],
                income: (data, f) => [
                    [f('Income ($)', 'bold')],
                    [null, 'Distribution', 'Skew'],
                    ...data.distribution.income.map(incomeObj => incomeObj.label).map((label, i) => [
                        label,
                        f(data.distribution.income[i].value / 100, 'int_percent'),
                        f(data.skew.income[i].value, 'numeric')
                    ])
                ],
                ethnicity: (data, f) => {
                    const orderedDists = _.sortBy(data.distribution.ethnicity, 'label'), orderedSkews = _.sortBy(data.skew.ethnicity, 'label');
                    return [
                        [f('Ethnicity', 'bold')],
                        [null, 'Distribution', 'Skew'],
                        ...orderedDists.map(o => o.label).map((label, i) => [
                            label,
                            f(orderedDists[i].value / 100, 'int_percent'),
                            f(orderedSkews[i].value, 'numeric')
                        ])
                    ];
                },

                drawDataTable: function (demographicsData, segment, channel, formatter) {
                    const demographicsDef = segment.find(s => s.type === 'demographics'),
                        isUSA = channel === 'smart_tv' || demographicsDef && demographicsDef.geo && demographicsDef.geo[0] && demographicsDef.geo[0].cc === 'US';
                    const props = isUSA ? this.commonProps.concat(this.onlyUSProps) : _.clone(this.commonProps);
                    if(channel == 'data_spark' && !abiPermissions.hasPermission("sg telco ethnicity")) {
                        _.pull(props, 'ethnicity');
                    }
                    return props.reduce((rows, prop, i) => {
                        if (i !== 0) rows.push(BLANK_LINE);
                        return rows.concat(this[prop](demographicsData, formatter));
                    }, []);
                }
            };

            function getSegmentSummaryData(segment, demographicsData, channel, formatter) {
                return Excel.insertDataAtColumn(
                    segmentDefsRowsUtil.drawDataTable(segment, channel, formatter), // placed at column 1 // xls columns are not zero index
                    demographicsRowsUtil.drawDataTable(demographicsData, segment, channel, formatter), 4
                );
            }

            function createSummaryWorksheet(workbook, formatter, segment, demographicsData, channel) {
                const worksheet = workbook.createWorksheet({name: 'Summary'});
                worksheet.setData(getSegmentSummaryData(segment, demographicsData, channel, formatter));
                worksheet.setColumns([...new Array(11)].map(() => ({width: COMMON_COLUMN_WIDTH})));
                workbook.addWorksheet(worksheet);
            }

            function createTopicsWorksheet(workbook, formatter, topics) {
                const worksheet = workbook.createWorksheet({name: 'Topics'});

                const titleLine = [
                    formatter('Topic', 'bold'),
                    formatter('Consumption', 'bold'),
                    formatter('Audience Skew', 'bold')
                ];

                const data = topics.map(topic => [
                    topic.phrase,
                    formatter(topic['interest-portion'] / 100, 'percent'),
                    formatter(topic['uniqueness-index'], 'numeric')
                ]);

                data.unshift(titleLine);
                worksheet.setData(data);
                worksheet.setColumns([{width: 30}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }

            function createInterestsWorksheet(workbook, interests, interestType, formatter) {
                const mainSheetName = SEARCH_TYPE_TO_MAIN_SHEET_NAME[interestType] || _.capitalize(interestType);
                const worksheet = workbook.createWorksheet({name: mainSheetName});

                const mainColumnName = SEARCH_TYPE_TO_MAIN_COLUMN_NAME[interestType];
                const titleLine = [
                    formatter(mainColumnName, 'bold'),
                    formatter('Consumption In Audience', 'bold'),
                    formatter('Audience Portion In Consumption', 'bold'),
                    formatter('Audience Skew', 'bold'),
                    formatter('Significance Score', 'bold'),
                    formatter('Topic', 'bold')
                ];

                const data = interests.map(interest => [
                    interest.phrase,
                    formatter(interest['interest-portion'] / 100, 'percent'),
                    formatter(interest['segment-portion'] / 100, 'percent'),
                    formatter(interest['uniqueness-index'], 'numeric'),
                    formatter(interest['score'], 'numeric'),
                    formatter(interest['topic'], 'text')
                ]);
                data.unshift(titleLine);

                worksheet.setData(data);
                worksheet.setColumns([{width: 20}, {width: 18}, {width: 18}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }

            function momentBasedFileNamerGen(baseName, extension = 'xlsx') {
                return () => `${baseName} ${moment().toString()}.${extension}`;
            }

            const fileNamer = momentBasedFileNamerGen('Audience Interest');

            function exportToExcel(topics, websites, phrases, searches, segment, demographicsData, channel) {
                const workbook = Excel.builder.createWorkbook();
                const formatter = Excel.formater(workbook);

                createSummaryWorksheet(workbook, formatter, segment, demographicsData, channel);
                if (topics) createTopicsWorksheet(workbook, formatter, topics);
                if (websites) createInterestsWorksheet(workbook, websites, 'td', formatter);
                if (phrases) createInterestsWorksheet(workbook, phrases, 'xw', formatter);
                if (searches) createInterestsWorksheet(workbook, searches, 'gsw', formatter);

                workbook.fileName = fileNamer();
                return workbook;
            }

            let objectURL;

            function excelDownloader(excel, fileNamer, excelBuilderOptions = options) {
                URL.revokeObjectURL(objectURL);
                const blob = Excel.builder.createFile(excel, excelBuilderOptions);
                objectURL = URL.createObjectURL(blob);
                const linkEl = document.createElement('a');
                linkEl.href = objectURL;
                linkEl.setAttribute('download', excel.fileName || fileNamer());
                linkEl.click();
            }

            function downloadExcel(excel) {
                return excelDownloader(excel, fileNamer);
            }

            return {
                COMMON_COLUMN_WIDTH, segmentDefsRowsUtil,
                getSegmentSummaryData, excelDownloader, momentBasedFileNamerGen,

                exportToExcel, downloadExcel
            }
        }
    ]);
