module.exports = angular.module(__filename, [])
    .service('audienceTableStructure', [function () {
        const tableStructure = {};
        const base = {
            dataArray: [],
            columns: [
                {
                    id: 'phrase',
                    title: 'interest',
                    ellipsis:true,
                    alwaysShown: true
                },
                {
                    id: 'interest-portion',
                    title: 'consumption in audience',
                    template: 'partials/interest-portion.partial.html',
                    tooltip: 'How interested is the audience in the phrase'
                },
                {
                    id: 'segment-portion',
                    title: 'audience portion in consumption',
                    template: 'partials/segment-portion.partial.html',
                    tooltip: 'How unique is this interest to the audience',
                    flex: '1-5'
                },
                {
                    id: 'uniqueness-index',
                    title: 'audience skew',
                    template: 'partials/composition-index.partial.html',
                    tooltip: 'The extent to which the interest is over-indexed within the audience',
                    flex: '0-7'
                },
                {
                    id: 'score',
                    title: 'significance score',
                    template: 'partials/segnificance-score.partial.html',
                    tooltip: 'The extent to which the result is unlikely to be random',
                    flex: '0-7'
                },
                {
                    id: 'topic',
                    title: 'topic',
                    tooltip: 'Topic',
                    ellipsis: true,
                    flex: '0-7'
                }
            ],
            order: 'uniqueness-index',
            reverse: true
        };
        const linkedinBase = {
            dataArray: [],
            columns: [
                {
                    id: 'value',
                    title: 'websites',
                    flex: '0-7',
                    tooltip: 'Domains consumed by the audience',
                    ellipsis: true,
                    alwaysShown: true
                },
                {
                    id: 'engagementPercentage',
                    displayValue: 'engagementPercentageDisplay',
                    title: 'engagements',
                    tooltip: 'Distribution of audience engagement',
                    flex: '0-3'
                },
                {
                    id: 'impressionPercentage',
                    displayValue: 'impressionPercentageDisplay',
                    title: 'impressions',
                    tooltip: "Distribution of appearance in the audience’s feed",
                    flex: '0-3'
                }
            ],
            order: 'engagements',
            reverse: true
        };

        tableStructure.web = _.cloneDeep(base);
        tableStructure.web.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
        tableStructure.web.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';

        tableStructure.domain = _.cloneDeep(base);
        tableStructure.domain.columns[0].title = 'website';
        tableStructure.domain.columns[0].template = 'partials/audience-domain.partial.html';
        tableStructure.domain.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience visits the website';
        tableStructure.domain.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience visits the website';

        tableStructure.sports = _.cloneDeep(base);
        tableStructure.keywords = _.cloneDeep(base);

        tableStructure.articles = _.cloneDeep(base);
        tableStructure.articles.order = 'uniqueness-index';

        tableStructure.tv = _.cloneDeep(base);
        tableStructure.tv.columns[0] = {id: 'title', title: 'TV Shows', ellipsis: 'true', flex: '0-8'};
        tableStructure.tv.columns[1].tooltip = 'How interested is the audience in the TV show';
        tableStructure.tv.columns[2].tooltip = 'How unique is this TV show to the audience';
        tableStructure.tv.columns[2].flex = '1-2';
        tableStructure.tv.columns[3].tooltip = 'The extent to which the TV show is over-indexed within the audience';
        tableStructure.tv.columns[3].flex = '0-7';
        tableStructure.tv.columns[4].flex = '0-7';
        tableStructure.tv.columns[5] = {id: 'genre', title: 'Genre', flex: '0-3', ellipsis: 'true', tooltip: ''};
        tableStructure.tv.columns[6] = {id: 'network', title: 'Origin Network', flex: '0-4', ellipsis: 'true', tooltip: 'The network on which the TV show was originally aired', search: true};

        tableStructure.genres = _.cloneDeep(base);
        tableStructure.genres.columns[0] = {id: 'title', title: 'Genres', ellipsis: 'true', flex: '0-8'};
        tableStructure.genres.columns[1].tooltip = 'How interested is the audience in the genre';
        tableStructure.genres.columns[2].tooltip = 'How unique is this genre to the audience';
        tableStructure.genres.columns[2].flex = '1-2';
        tableStructure.genres.columns[3].tooltip = 'The extent to which the genre is over-indexed within the audience';
        tableStructure.genres.columns[3].flex = '0-7';
        tableStructure.genres.columns[4].flex = '0-7';
        tableStructure.genres.columns.splice(-1, 1);

        tableStructure.networks = _.cloneDeep(base);
        tableStructure.networks.columns[0] = {id: 'title', title: 'Networks', ellipsis: 'true', flex: '0-8'};
        tableStructure.networks.columns[1].tooltip = 'How interested is the audience in the network';
        tableStructure.networks.columns[2].tooltip = 'How unique is this network to the audience';
        tableStructure.networks.columns[2].flex = '1-2';
        tableStructure.networks.columns[3].tooltip = 'The extent to which the network is over-indexed within the audience';
        tableStructure.networks.columns[3].flex = '0-7';
        tableStructure.networks.columns[4].flex = '0-7';
        tableStructure.networks.columns.splice(-1, 1);

        tableStructure.linkedin = _.cloneDeep(linkedinBase);
        tableStructure.data_spark = _.cloneDeep(base);
        tableStructure.au_telco = _.cloneDeep(base);
        tableStructure.linear_tv = _.cloneDeep(base);
        tableStructure.smart_tv = _.cloneDeep(base);
        tableStructure.gracenote = _.cloneDeep(base);
        tableStructure.snbb = _.cloneDeep(base);

        this.tableStructure = tableStructure;
        this.base = base;
    }]
    );
