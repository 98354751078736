import moment from 'moment';
import { padStart, first, map, pick } from 'lodash';
import * as MixpanelCommon from './MixpanelCommon';
import { getChannelName, getContext, getStateCurrent } from '../../middleware/AngularExportedMiddleware';

const appName = 'Alerts';
const widgetName = MixpanelCommon.extractWidget(getStateCurrent()) || appName;

/* eslint-disable camelcase*/
const ALERT_MAPPINGS = {
  channel: { articles: 'all', videos: 'video', tweets: 'social' },
  sentiment: { net: 'net sentiment', pos: 'positive sentiment', neg: 'negative sentiment' },
  timeframe: { last_month: '1M', last_quarter: '3M', last_6_months: '6M', last_year: '1Y' },
};

export const isUsingCustomParams = (triggers, metadata) =>
  metadata.timeframe !== 'last_month' ||
  triggers.compare_to_previous.selected ||
  !triggers.compare_to_avg.selected ||
  triggers.compare_to_avg.stdev !== 2;

export const buildAlert = (alert) => {
  const context = getContext();
  let triggers = alert.triggers;
  let metadata = alert.metadata;
  let metric, direction, channel;
  if (metadata.type === 'sentiment') {
    metric = ALERT_MAPPINGS.sentiment[metadata.sentiment];
    direction = metadata.change === 'dec' ? 'decreases' : 'increases';
    channel = 'NA';
  } else {
    metric = 'consumption';
    direction = 'NA';
    channel = ALERT_MAPPINGS.channel[metadata.channel];
  }

  return {
    appName,
    widget: appName.toLowerCase(),
    Channel: getChannelName(channel),
    'Alert ID': alert.id,
    'Alert name': alert.name,
    'Alert type': metadata.type,
    'Tracked interests': metadata['phrases[]'],
    'Interests refinement': map(metadata['boolean_logics[]'], (obj) => pick(obj, ['required', 'included', 'excluded'])),
    'Tracked metric': metric,
    'Program name': context ? context.program.name : '',
    'Using custom params?': isUsingCustomParams(triggers, metadata),
    'Compared to average': triggers.compare_to_avg.selected,
    'Compared to previous day': triggers.compare_to_previous.selected,
    'Average #stdev change': triggers.compare_to_avg.stdev || 0,
    'Average % change': triggers.compare_to_avg.percentage || 0,
    'Previous day #stdev change': triggers.compare_to_previous.stdev || 0,
    'Previous day % change': triggers.compare_to_previous.percentage || 0,
    'Tracked direction': direction,
    'Benchmark timeframe': ALERT_MAPPINGS.timeframe[alert.benchmark_timeframe],
    'Tracked geo': !metadata || metadata.geo.length !== 1 ? 'All' : first(metadata.geo).label,
  };
};

export const buildAlertSettings = (settings) => {
  const interval = settings.interval;
  const context = getContext();
  let hour, day;
  switch (interval.type) {
    case 'Weekly':
      day = moment().day(interval.day_of_week).format('dddd');
    // FALL THROUGH
    case 'Daily':
      hour = padStart(interval.hour_of_day, 2, '0') + ':00';
      break;
    // DO NOTHING
    default:
      break;
  }

  return {
    appName,
    widget: appName.toLowerCase(),
    Hour: hour || 'NA',
    Day: day || 'NA',
    'Program name': context ? context.program.name : '',
    'How often': interval.type,
    'Include weekends?': interval.include_weekends,
    'Send alerts to': settings.emails,
  };
};

export const trackAlertsSettings = (settings) =>
  MixpanelCommon.sendToMixpanel('Alerts - Email settings updated', buildAlertSettings(settings));

export const trackAlertCreated = (alert) => MixpanelCommon.sendToMixpanel('Alerts - Alert created', buildAlert(alert));

export const trackAlertUpdated = (alert) => MixpanelCommon.sendToMixpanel('Alerts - Alert updated', buildAlert(alert));

export const trackAlertDeleted = (alert) => MixpanelCommon.sendToMixpanel('Alerts - Alert deleted', buildAlert(alert));

export const trackExport = () => MixpanelCommon.trackExport(appName, widgetName);

export const trackPageView = () => MixpanelCommon.trackPageView(appName, widgetName);
