import { setDisplayErrorBannerIfNeededService } from '../../react/middleware/AngularExportedMiddleware';

module.exports = angular.module(__filename, [])
    .service('userErrorNotifications', ['errorMgmt', '$q', function (errorMgmt, $q) {
        function shouldNotify(rejection) {
            if (rejection.config.url.match('snapshot|suggest|session|disable_notification')) return false;
            if (rejection.config.timeout && rejection.config.timeout.$$state && rejection.config.timeout.$$state.value === 'AMOBEE_CANCEL_OK') return false;
            return true;
        }

        function displayErrorBannerIfNeeded(error) {
            if (shouldNotify(error)) errorMgmt.httpError(error);
        }       

        setDisplayErrorBannerIfNeededService(displayErrorBannerIfNeeded);

        return {
            'responseError': function (rejection) {
                displayErrorBannerIfNeeded(rejection);
                return $q.reject(rejection)
            }
        }
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('userErrorNotifications')
    }]
);
