var angularUtils = require('common/angularUtils');

// creates a dropdown element holding the markets and advertisers the user is allowed to access on the console.

module.exports = angular.module(__filename, [
]).component('marketContext', {
  template: require('./market-context.html'),
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    allMarketsOption: '<',
    allAdvertisersOption: '<',
    selectAdvertisers: '<',
    fixedMarket: '<'
  },
  controller: ['$scope', '$timeout', '$q', 'MARKET_CONTEXT', 'dspService', function($scope, $timeout, $q, MARKET_CONTEXT, dspService) {
    const ACTIVATE_TO_ALLMARKETS = "Activate to all markets";
    const ACTIVATE_TO_ALLADVERTISERS = "Activate to all advertisers";
    var $ctrl = this;
    if (angular.isUndefined($ctrl.allAdvertisersOption) || $ctrl.allAdvertisersOption === null) {
      $ctrl.allAdvertisersOption = true;
    }

    $ctrl.$onInit = function() {
      $ctrl.loading = true;
      $ctrl.loadingAdvertisers = false;
      $ctrl.ngModel.$render = function() {
        $ctrl.showAdvertisers = $ctrl.selectAdvertisers && $ctrl.ngModel.$viewValue['market_id'] && $ctrl.ngModel.$viewValue['market_id'] != MARKET_CONTEXT.ALL_MARKETS_ID;
        $ctrl.market_label =     _.get(_.find($ctrl.markets,     (o) => o.id == $ctrl.ngModel.$viewValue['market_id']), 'label');
        $ctrl.advertiser_label = _.get(_.find($ctrl.advertisers, (o) => o.id == $ctrl.ngModel.$viewValue['advertiser_id']), 'label');
        if (_.isInteger($ctrl.ngModel.$viewValue['advertiser_id']) && $ctrl.advertiser_label === undefined) {
          $ctrl.advertiser_label = 'N/A';
          $ctrl.advertisers = [];
        }
      };
      $ctrl.ngModel.$isEmpty = function(value) {
        return _(value).values().without(undefined).isEmpty();
      };

      $timeout(function() {
        dspService.getMarketContext().then(marketContext => {
          $ctrl.isCS        = marketContext['isCS']; //isCS == is Customer Service, like 'admin' for the console.
          $ctrl.userMarkets = marketContext['markets'];
          $ctrl._addAllMarketsOption();
          $ctrl._loadAdvertisers(marketContext);
        }, error => {
          $ctrl.errors = true;
          $ctrl.loading = false;
        });
      });
    };

    $ctrl.onMarketSelect = function(selected) {
      if (selected == null) { return }
      else if (_.isEmpty(selected)) {
        $ctrl._setNgModel({market_id: undefined, advertiser_id: undefined});
        $ctrl._setUserAdvertisers([]);
        $ctrl.onAdvertiserSelect({});
        $ctrl._buildMarketList();
      } else if (selected.id === MARKET_CONTEXT.ALL_MARKETS_ID) {
        $ctrl._setNgModel({market_id: selected.id, advertiser_id: undefined});
        $ctrl.market_label = selected.label;
        $ctrl.onAdvertiserSelect({});
        $ctrl._buildMarketList();
      } else {
        $ctrl.loadingAdvertisers = true;
        dspService.getAdvertiserContext(selected.id).then(advertiserContext => {
          let advertiser_id = $ctrl.allAdvertisersOption ? MARKET_CONTEXT.ALL_ADVERTISERS_ID : undefined;
          $ctrl._setNgModel({market_id: selected.id, advertiser_id: advertiser_id});
          $ctrl.market_label = selected.label;
          $ctrl._setUserAdvertisers(advertiserContext['advertisers']);
          $ctrl.onAdvertiserSelect({id: advertiser_id});
          $ctrl._buildMarketList();
        }, error => {
          $ctrl.errors = true;
          $ctrl.loadingAdvertisers = false;
        });
      }
    };

    $ctrl._loadAdvertisers = function(marketContext) {
      $ctrl.loadingAdvertisers = true;
      let advertiserPromise;
      let market_id = $ctrl.ngModel.$viewValue.market_id;
      if (marketContext['market_id'] == market_id) {
        advertiserPromise = $q((resolve, reject) => resolve({'advertisers': marketContext['advertisers'], 'market_id': marketContext['market_id']}));
      } else {
        advertiserPromise = dspService.getAdvertiserContext(market_id);
      }
      return advertiserPromise.then(advertiserContext => {
        $ctrl._setUserAdvertisers(advertiserContext['advertisers']);
        $ctrl._buildMarketList();
      }, error => {
        $ctrl.errors = true;
        $ctrl.loadingAdvertisers = false;
      });
    }

    $ctrl.onAdvertiserSelect = function(selected) {
      if (selected == null) { return }
      $ctrl._setNgModel({market_id: $ctrl.ngModel.$viewValue.market_id, advertiser_id: selected.id});
      $ctrl.advertiser_label = selected.label;
    };

    $ctrl.onMarketClear = function(selected) {
      // TODO: am-autocomplete doesn't notify when pressed on 'clear', this hack fixes that issue
      if (!(_.isString(selected) && _.isEmpty(selected))) { return }
      $ctrl.onMarketSelect({});
    };

    $ctrl.onAdvertiserClear = function(selected) {
      // TODO: am-autocomplete doesn't notify when pressed on 'clear', this hack fixes that issue
      if (!(_.isString(selected) && _.isEmpty(selected))) { return }
      $ctrl.onAdvertiserSelect({});
    };

    $ctrl.onMarketErr = function(msg) {
    };

    $ctrl.onAdvertiserErr = function(msg) {
    };

    $ctrl._setNgModel = function({market_id, advertiser_id}) {
      let viewValue = angular.copy($ctrl.ngModel.$viewValue);
      viewValue['market_id'] = market_id;
      viewValue['advertiser_id'] = advertiser_id;
      $ctrl.ngModel.$setViewValue(viewValue);
      $ctrl.ngModel.$validate();
    }

    $ctrl._setUserAdvertisers = function(advertisers) {
      $ctrl.userAdvertisers = advertisers;
      $ctrl._addAllAdvertisersOption();
    }

    $ctrl._addAllMarketsOption = function() {
      //will show the customed first underlined "Activate to all markets" as the top option
      if ($ctrl.allMarketsOption && $ctrl.isCS) {
        const allMarketsStr = {label: ACTIVATE_TO_ALLMARKETS, id: MARKET_CONTEXT.ALL_MARKETS_ID, specialTitle: true};
        $ctrl.userMarkets.unshift(allMarketsStr);
      }
    }

    $ctrl._addAllAdvertisersOption = function() {
      //will show the customed first underlined "Activate to all advertisers" as the top option
      if ($ctrl.allAdvertisersOption && !_.isUndefined($ctrl.ngModel.$viewValue.market_id)) {
        const allAdvertisersStr = {label: ACTIVATE_TO_ALLADVERTISERS, id: MARKET_CONTEXT.ALL_ADVERTISERS_ID, specialTitle: true};
        $ctrl.userAdvertisers.unshift(allAdvertisersStr);
      }
    }

    $ctrl._canChangeMarket = function(current_market_id) {
      let hasPermission = ((current_market_id == undefined) || _.includes(_.map($ctrl.userMarkets, (m) => m.id), current_market_id));
      $scope.$emit('fixedMarket', !hasPermission);
      return hasPermission;
    }

    $ctrl._buildMarketList = function() {
      let marketsPromise;
      let market_id = $ctrl.ngModel.$viewValue.market_id;
      if ($ctrl.fixedMarket || !$ctrl._canChangeMarket(market_id)) {
        marketsPromise = dspService.getMarketFromId(market_id.toString(), {nameKeyInResult: 'label', isAllMarketsSupported: true}).then( function(externalMarket) {
          externalMarket.id = parseInt(externalMarket.id);
          return [externalMarket];
        })
      } else {
        marketsPromise = $q((resolve, reject) => resolve(angular.copy($ctrl.userMarkets)));
      }
      return marketsPromise.then(function(markets) {
        $ctrl.markets = markets;
        $ctrl.advertisers = angular.copy($ctrl.userAdvertisers);

        if ($ctrl.markets.length == 1) {
          angularUtils.ngModelSkipDirty($ctrl.ngModel, $ctrl.onMarketSelect)(_.first($ctrl.markets));
        }
        if ($ctrl.advertisers.length == 1) {
          angularUtils.ngModelSkipDirty($ctrl.ngModel, $ctrl.onAdvertiserSelect)(_.first($ctrl.advertisers));
        }

        $ctrl.loading = false;
        $ctrl.loadingAdvertisers = false;
        $ctrl.ngModel.$render();
      });
    }

  }]
});

