"use strict";

import { getPhrasesIndexInLifestyle } from '../react/services/AudienceInsightsService';
import config from 'infra/config';
import common from 'infra/utils/common';
var date = require("infra/utils/date");
var conceptsService = require("./concepts-service");

module.exports = angular.module(__filename, [
    conceptsService.name,
]).service("Discovery", ["$http", "$rootScope", "cancellableHttp", "conceptsService", "$q", '$state', 'context', 'geoService', 'util', 'topicsTree', 'abiPermissions', 'CHANNEL',
    function ($http, $rootScope, cancellableHttp, conceptsService, $q, $state, context, geoService, util, topicsTree, permissions , CHANNEL) {
        var cache = {};
        var serviceConfig = {
            timeframe: null,
            terms: null,
            topics: null,
            isKeyTrends: null,
            program: null,
            bubblesChannels: {},
            sensitiveContent: false,
            geos: null,
            sub_geos: null,
            audience: null,
            isRisingOnly: null,
            cacheBaster: null
        };
        var excludeSeedPromise = null;
        /******* API ****************/
        return Object.defineProperties({
            getBubbles: getBubbles,
            getConceptsList: getConceptsList,
            removeExcludedConceptFromBubblesCache: removeExcludedConceptFromBubblesCache,
        }, {
            'config': {
                get: function () {
                    return serviceConfig
                },
                set: function (newConfig) {
                    return _.extend(serviceConfig, newConfig);
                }
            },
            'lastUsedConfig': {
                get: function () {
                    return getBubbles.lastRequstPromise.config || {};
                }
            }
        });
        /******* END API ***********/

        /* ------------------------------------- */
        function getBubbles(instanceConfig) {
            /** init **/
            var config = _.extend({}, serviceConfig, instanceConfig);
            /** static vars **/
            var lastRequest = getBubbles.lastRequstPromise || null;
            var termsText = util.getTermsText(config.terms);
            var deferred = $q.defer();

            excludeSeedPromise = conceptsService.list_for_seeds(config.program.id, termsText, config.isKeyTrends);
            excludeSeedPromise.then(function (excludedSeeds) {
                var breakableList = ['timeframe', 'terms', 'topics', 'program', 'geos', 'sub_geos', 'audience',
                                     'bubblesChannels', 'isKeyTrends', 'cacheBaster', 'language',
                                     'user_first_party_audience'];
                config.excludedSeeds = excludedSeeds;
                config.send_first_party_audience = config.bubblesChannels.value == 'articles' && permissions.hasPermission('first party segments');
                config.user_first_party_audience = _.map(config.user_first_party_audience, "value");

                if (lastRequest) {
                    var lastConfig = lastRequest.config;
                    /** check if old param is same as current params excluded `terms` **/
                    var paramsDifferent = !angular.equals(_.pick(lastConfig, breakableList), _.pick(config, breakableList));
                    /** check for new Terms only */
                    var newTerms = !!_.difference(config.terms, lastConfig.terms).length;
                    /** check for new social terms on unsupported channels*/
                    var unSupported = false, socialRegEx = /^#|^@/;

                    var tt = _.difference(config.terms, lastConfig.terms);
                    var supportedChannel = ['Twitter','Facebook'].includes(config.bubblesChannels.label);
                    if (!supportedChannel && newTerms) {
                        if (tt.filter(function(x){return socialRegEx.test(x.text)}).length == tt.length){unSupported = true};
                    }
                }

                if ((!lastRequest || paramsDifferent || newTerms) && !unSupported) {
                    lastRequest = fireNewRequest(config);
                    lastRequest.config = config;
                    /** save last request **/
                    getBubbles.lastRequstPromise = lastRequest;
                }
                return lastRequest.then(function () {
                    if (config.lifestyle) {
                        //_(cache.bubbles).pick(termsText).values().map(seed => _.keys(seed.concepts)).flatten().uniq().value();
                        var phrases = _.uniq(_.flatten(_.values(_.pick(cache.bubbles, termsText)).map(function (seed) {
                            return Object.keys(seed['concepts'])
                        })));
                        if (config.isKeyTrends) {
                            //_(cache.bubbles.trending).values().flatten().map('id').value();
                            phrases = phrases.concat(_.flatten(_.values(cache.bubbles['trending'])).map(function (phrase) {
                                return phrase.id
                            }))
                        }
                        return getPhrasesIndexInLifestyle(config.lifestyle && config.lifestyle.segment, phrases, _.map(config.terms, 'id'))
                    } else {
                        return Promise.resolve(null)
                    }
                }).then(function (audience) {
                    /** return just the terms that requested in the arguments **/
                    if (config.isKeyTrends) {
                        termsText.push('trending');
                    }

                    var data = _.pick(cache.bubbles, termsText);
                    data = config.isRisingOnly ? risingFilter(data) : data;

                    if (config.velocity) {
                        data = config.velocity ? velocityFilter(data, config.velocity.min, config.velocity.max) : data;
                    }

                    if (audience) {
                        var concepts = _.flatten(_.values(data).map(function (seed) {
                            return _.values(seed['concepts'])
                        }));
                        for (var i = 0; i < concepts.length; i++) {
                            concepts[i]['audience'] = audience[concepts[i]['id']] ? audience[concepts[i]['id']] : 0;
                        }

                    }
                    deferred.resolve({bubbles: data, topicsDistribution: cache.topicsDistribution});
                });
            });
            return {
                promise: deferred.promise,
                cancel: function () {
                    deferred.reject('cancelled by user');
                }
            }
        }

        function risingFilter(data) {
            var res = {};
            for (var keyName in data) {
                res[keyName] = {
                    concepts: _.filter(data[keyName].concepts, function (concept) {
                        return concept.score > 0 || concept.isSeed;
                    })
                };
            }
            return res;
        }

        function velocityFilter(data, min_value, max_value) {
            var res = {};
            for (var keyName in data) {
                res[keyName] = {
                    concepts: _.filter(data[keyName].concepts, function (concept) {
                        return (isNotValidValue(min_value) || concept.score >= min_value) && (isNotValidValue(max_value) || concept.score <= max_value);
                    })
                };
            }
            return res;
        }

        function isNotValidValue(val) {
            return (!val && val != 0) || isNaN(val) || val === '';
        }

        function tweakTelcoData(config, timeframe) {
            var channel = config.bubblesChannels.value || '';
            if (!channel.includes("telco")) return config, timeframe;

            timeframe = date.getTimeframe(config.timeframe, false);
            if (channel == "au_telco" || (channel == "sg_telco" && !permissions.hasPermission('sg telco audience filters'))) {
                config.audience = [];
            }

            return config, timeframe;
        }

        function fireNewRequest(requestConfig) {
            var timeframe = date.getTimeframe(requestConfig.timeframe);
            requestConfig, timeframe = tweakTelcoData(requestConfig, timeframe);
            var sources = [];
            if (requestConfig.isCustom){
                var allFbSources = _.filter(requestConfig.program.program_sources, (source) => source.sources[0].type == 'facebook');
                var programSources =  requestConfig.bubblesChannels.length > 0 ? _.intersectionBy(allFbSources, requestConfig.bubblesChannels, 'id') : allFbSources;
                sources = _.flatMap(programSources, (s) => s.sources);
            }

            var params = {
                trending_topics: requestConfig.program.trending_topics,
                audience_mappings: requestConfig.program.program_audience_mappings,
                topics: topicsTree.getIds(requestConfig.topics),
                sensitive_content: requestConfig.sensitiveContent,
                clustering: permissions.hasPermission('story clustering'),
                geos: geoService.geosForChannel(requestConfig.geos, $state, context, 'id'),
                sub_geos: context.current.discoveryAllSubGeosSelected ? [] : geoService.subGeosForChannel(requestConfig.sub_geos, $state, context),
                audience: util.removeFullySelectedAudienceSegments(_.map(requestConfig.audience, 'value')),
                timeframe_start: timeframe.start,
                timeframe_end: timeframe.end,
                language: context.current._language_mold.getLanguage($state, context).value,
                excluded: requestConfig.excludedSeeds,
                key_trends: (requestConfig.isKeyTrends) ? 1 : 0,
                phrases: util.getTerms(requestConfig.terms, false),
                phrases_bl: util.getTerms(requestConfig.terms, true),
                source: requestConfig.bubblesChannels.value,
                program_sources: _.mapValues(_.groupBy(sources, 'channel'), (val) => _.map(val, 'id'))
            };

            if(requestConfig.send_first_party_audience) {
              params.user_first_party_audience = requestConfig.user_first_party_audience;
            }

            const url = `${config.DISCOVERY_API}/bubbles`;
            return cancellableHttp.$http({data: params, url, method: 'POST'}).cancellableThen(function (res) {
                addMetadata(res.data.bubbles, params, requestConfig);
                res.data.topicsDistribution = util.getTopicsDistribution(res.data.topics);
                _.extend(cache, res.data);
                return res.data;
            }, function (err) {
            });
        }

        function addMetadata(data, params, config) {
            for (var keyName in data) {
                var term = _.find(config.terms, {text: keyName});
                _.forEach(data[keyName].concepts, function (concept) {
                    concept['seed'] = keyName;
                    concept['isSeed'] = (keyName.toLowerCase() == concept.word.toLowerCase());
                    concept['term'] = term;
                });
            }
        }

        function getConceptsList(config) {
            return getBubbles(config).promise.then(function (data) {
                var concepts = _(data.bubbles)
                    .mapValues('concepts')
                    .values()
                    .map(function (v) {
                        return _.values(v)
                    })
                    .flatten()
                    .value();

                return concepts;
            });
        }

        function removeExcludedConceptFromBubblesCache(excludedConceptId) {
            const bubblesRes = {};
            for (const keyName in cache.bubbles) {
                bubblesRes[keyName] = { concepts: _.pickBy(cache.bubbles[keyName].concepts, (_, conceptId) => (conceptId !== excludedConceptId)) };
            }
            _.extend(cache.bubbles, bubblesRes);
        }
    }
]);
