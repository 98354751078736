var newsService = require("data/news"),
    BaseWidget = require("../base_widget");

require('news-ticker');

function NewsTickerCtrl(newsService, $timeout, $scope, $window, $interval) {
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.newsService = newsService;
    $scope.toDate = function (dateString) {
        return new Date(dateString)
    };
    $scope.openBrowserTab = function (url) {
        $window.open(url)
    };
}

NewsTickerCtrl.$inject = ["newsService", "$timeout", "$scope", "$window", "$interval"];

// Overrides
NewsTickerCtrl.prototype.onResize = function () {};
NewsTickerCtrl.prototype.startLoader = function () {};
NewsTickerCtrl.prototype.stopLoader = function () {};

NewsTickerCtrl.prototype._doUpdate = function (values) {
    var self = this,
        timeoutId,
        baseInterval = 50 * 1000,
        terms = [self.$scope.brandName] || [];

    this.$scope.$on('$destroy', function () {
        cancelInterval();
        self.getTweetsPromise.cancel();
    });

    updateTicker(initMarquee);
    setNewInterval(1);

    function cancelInterval() {
        self.$interval.cancel(timeoutId);
        self.$interval.cancel(self.timeoutId);
    }

    function initMarquee() {
        self.$timeout(function () {
            $("#marquee").marquee();
        }, 0);
    }

    function updateTicker(callbackFn) {
        self.getTweetsPromise = self.newsService.getTweets(terms, 10);
        self.getTweetsPromise.promise.then(
            function (result) {
                self.$scope.result = (result || []).map(tweet =>
                  // Fix issue where text with emoji is too low.
                  tweet.text.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g)
                  ? _.extend(tweet, { hasEmoji: true })
                  : tweet
                );
                if (callbackFn) callbackFn();
                setNewInterval(1);
            },
            function getTweetsFailed(errorCounter) {
                if (errorCounter != "canceled") setNewInterval(errorCounter);
            }
        );
    }

    function setNewInterval(baseIntervalMultiplier) {
        cancelInterval();

        self.timeoutId = timeoutId = self.$interval(function () {
            updateTicker(initMarquee);
        }, baseInterval * baseIntervalMultiplier);
    }
};

module.exports = angular.module(__filename, [
    newsService.name
]).directive("newsTicker", ["newsService", "$window", function (newsService, $window) {
    return BaseWidget({
        restrict: "E",
        template: require("./news-ticker.html"),
        scope: {
            brandName: "=brandName"
        },
        controller: NewsTickerCtrl
    });
}]);
