/* eslint-disable camelcase */
import config from '../../infra/config';
const HOST = config.USER_MGMT_API;

export default {
  getLinkedinSegmentDemographicsDistribution: (audience, userId) => ({
    url: `${HOST}/linkedin/get_audience_distribution`,
    /* eslint-disable-next-line camelcase */
    payload: { audience, user_id: userId },
  }),

  createAudienceTargetTaxonomy: (segmentId, channel, marketId, advertiserId) => ({
    url: `${HOST}/audience_target/create_taxonomy`,
    payload: { channel, segment_id: segmentId, market_id: marketId, advertiser_id: advertiserId },
  }),

  createAudienceTargetUserList: (segmentId, channel, ids) => ({
    url: `${HOST}/audience_target/create_user_list/${channel}/${segmentId}`,
    payload: { ids },
  }),

  getAudienceTaxonomyCategory: (segmentId) => ({
    url: `${HOST}/audience_target/taxonomy_category/${segmentId}`,
  }),

  createAmplifiedAudience: (
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo,
    isFirstParty
  ) => ({
    url: `${HOST}/dsp/${channel}/amplified_segment`,
    payload: {
      market,
      advertiser,
      target,
      segment_id: segmentId,
      segment_name: segmentName,
      amplify_threshold: amplifyThreshold,
      user_list_query: userListQuery,
      geo,
      is_first_party: isFirstParty,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  createDeterministicAudience: (
    segmentId,
    segmentName,
    channel,
    market,
    advertiser,
    ssoAccessToken,
    userListQuery
  ) => ({
    url: `${HOST}/dsp/${channel}/deterministic_segment`,
    payload: {
      market,
      advertiser,
      segment_id: segmentId,
      segment_name: segmentName,
      user_list_query: userListQuery,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  createAlwaysOnAudience: (
    segmentId,
    segmentName,
    dataContractId,
    dataContractText,
    categoryId,
    amplifyThreshold,
    target,
    ssoAccessToken,
    userListQuery,
    geo
  ) => ({
    url: `${HOST}/dsp/always_on`,
    payload: {
      target,
      segment_id: segmentId,
      segment_name: segmentName,
      data_contract_id: dataContractId,
      data_contract_text: dataContractText,
      category_id: categoryId,
      amplify_threshold: amplifyThreshold,
      user_list_query: userListQuery,
      geo: geo,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),

  createUserListForDeterministicAudience: (
    channel,
    segmentId,
    market,
    advertiser,
    categoryId,
    dataContractId,
    dataProviderId,
    dspSegmentId,
    userListQuery,
    isDynamic,
    ssoAccessToken,
    audienceName
  ) => ({
    url: `${HOST}/dsp/${channel}/user_list`,
    payload: {
      market,
      advertiser,
      segment_id: segmentId,
      category_id: categoryId,
      data_contract_id: dataContractId,
      data_provider_id: dataProviderId,
      dsp_segment_id: dspSegmentId,
      user_list_query: userListQuery,
      is_dynamic: isDynamic,
      audience_name: audienceName,
    },
    headers: { 'SSO-Access-Token': ssoAccessToken },
  }),
  checkLinkedinUserAccessTokenStatus: (params) => ({ url: `${HOST}/linkedin/check_user_access_token_status`, params }),
  getLinkedinMetaData: (params) => ({ url: `${HOST}/linkedin/get_entities_meta_data`, params }),
  getFirstPartyDataByProgram: (programId) => ({ url: `${HOST}/first_party/${programId}` }),
  getFirstPartyDataBySSOAccessToken: (headers) => ({ url: `${HOST}/first_party_ulapi`, headers }),
  getFirstPartyDataByUser: (userId, headers) => ({ url: `${HOST}/first_party/user/${userId}`, headers }),
  getLinkedinTopContentForAudience: (audience, userId) => ({
    /* eslint-disable-next-line camelcase */
    payload: { audience, user_id: userId },
    method: 'post',
    url: `${HOST}/linkedin/get_top_content_for_audience`,
  }),
  getLinkedinCompaniesInfoByNames: (companiesNames, userId) => ({
    /* eslint-disable-next-line camelcase */
    payload: { companies: companiesNames, user_id: userId },
    method: 'post',
    url: `${HOST}/linkedin/get_organizations_info_by_name`,
  }),

  getDataContract: (data_contract_id, headers) => ({ url: `${HOST}/dsp/data_contract/${data_contract_id}`, headers }),
  getTaxonomyCategory: (data_contract_id, category_id, headers) => ({
    url: `${HOST}/dsp/category/${data_contract_id}/${category_id}`,
    headers,
  }),

  getAllGeos: () => ({
    url: `${HOST}/bi_all_supported_countries?disable_notifications=1`,
  }),

  getAbiAuthToken: (accessToken) => ({
    url: `${HOST}/oauth2/token`,
    method: 'post',
    headers: { Authorization: `Bearer ${accessToken}` },
  }),
};
