module.exports = angular.module(__filename, []).service('websitesModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(websites, isNewSegment, disableGeo, channel, params) {
        return ModalService.showModal({
            template: require('./websites-modal.html'),
            inputs: {websites: websites, isNewSegment: isNewSegment, channel: channel, params: params},
            controller: ['$scope', 'close', 'websites', 'TermsMold', 'isNewSegment', 'channel', 'params', WebsitesModalCtrl]
        });
    }

    function WebsitesModalCtrl($scope, close, websites, TermsMold, isNewSegment, channel, params) {
        $scope.isNewSegment = websites == null;
        $scope.websites = _.extend({required: null, included: null, excluded: null}, websites);
        $scope.close = close;
        $scope.termsMold = new TermsMold();
        $scope.websitesLimit = params.debugUser && channel === 'au_telco' ? 150 : 50;

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.websites));
        };

        function removeEmptyFilters(websites) {
            var results = {};
            if ((websites.required || []).length > 0) results.required = websites.required;
            if ((websites.included || []).length > 0) results.included = websites.included;
            if ((websites.excluded || []).length > 0) results.excluded = websites.excluded;
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters(this.websites), {type: "websites", operand: this.websites.operand}));
        }
    }
}]);
