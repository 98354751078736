import moment from 'moment';
import * as MixpanelReact from './Mixpanel';
import { each, isEqual, map, mapKeys, omit } from 'lodash';
import { getChannelName } from '../../middleware/AngularExportedMiddleware';

let lastApp = 'start session';
let lastWidget = 'start session';

export const sendToMixpanel = (eventName, props) => MixpanelReact.track(eventName, props);

export const extractWidget = (state) => {
  const a = state && state.name ? state.name.split('.') : [];
  a.shift();
  return a.join(' ');
};

export const buildTimeframe = (timeframe, format) =>
  timeframe && timeframe[1] && isCustomTimeframe(timeframe[1])
    ? buildCustomTimeframe(timeframe, format)
    : timeframe[0] + ' ' + timeframe[1];

// match insights & real time view custom timeframe: dd_MM_YY_HH_mm
export const isCustomTimeframe = (timeframe) => timeframe.match(/\d{2}_\d{2}_\d{2}_\d{2}_\d{2}/);

export const buildCustomTimeframe = (timeframe, format) => {
  const start = moment(timeframe[0], 'DD/MM/YY HH:mm').format(format);
  const end = moment(timeframe[1], 'DD/MM/YY HH:mm').format(format);
  return start + ' to ' + end;
};

export const buildProgram = (context) => {
  const currentProgram = context ? context.program : {};
  return {
    programName: currentProgram.name,
  };
};

export const booleanLogicTerm = (term) => {
  const result = { required: map(term.required, 'text') };
  if (term.included && term.included.length > 0) result.included = map(term.included, 'text');
  if (term.excluded && term.excluded.length > 0) result.excluded = map(term.excluded, 'text');

  return result;
};

export const buildTerms = (terms) => {
  /* eslint-disable camelcase*/
  const result = {
    terms: [],
    bl_terms: [],
  };

  each(terms, (term) => {
    if (term.type === 'booleanLogic' || term.type === 'programBL') {
      result.bl_terms.push(booleanLogicTerm(term));
    } else {
      result.terms.push(term.text);
    }
  });

  return result;
};

// track page view
export const trackPageView = (currentApp, currentWidget, channel) => {
  if (isEqual(currentApp, lastApp) && isEqual(currentWidget, lastWidget)) return;
  const eventName = 'Overview - Insights Generation';
  const props = {
    appName: currentApp,
    channel: getChannelName(channel),
    widget: currentWidget,
    lastApp,
    lastWidget,
  };
  sendToMixpanel(eventName, props);
  sendToMixpanel('Overview - Page View', props);
  lastApp = currentApp;
  lastWidget = currentWidget;
};

export const trackSearch = (appName, widget, terms, refinedTerms, channel) => {
  const eventName = 'Overview - Insights Generation';
  const props = {
    appName,
    widget,
    terms,
    refinedTerms,
    channel,
  };
  sendToMixpanel(eventName, props);
  sendToMixpanel('Overview - All Searches', props);
};

export const trackExport = (appName, widget, channel) => {
  const eventName = 'Export';
  const props = {
    appName,
    channel: getChannelName(channel),
    widget,
  };
  sendToMixpanel(eventName, props);
};

export const trackContentDrive = () => sendToMixpanel('Overview - Content Driver clicked');

export const trackInputBar = (eventName, seeds) => {
  const props = {
    appName: lastApp,
    widget: lastWidget,
    seeds,
  };
  sendToMixpanel(eventName, props);
};

export const trackSort = (props) => sendToMixpanel('Overview - Phrase table sort', props);

export const trackUrlClick = (url) => {
  const eventName = 'Overview - Content Driver clicked';
  const props = {
    appName: lastApp,
    widget: lastWidget,
    Url: url,
  };
  sendToMixpanel(eventName, props);
};

export const trackHelpCenter = (helpPage, channel) => {
  const eventName = 'Overview - Help Center';
  const props = {
    appName: lastApp,
    channel,
    helpPage,
    widget: lastWidget,
  };
  sendToMixpanel(eventName, props);
};

export const extractSegmentDetails = (activeChannelData) => {
  const audienceAppMixpanelData = {};
  if (activeChannelData && activeChannelData.audience_segment) {
    const omitKeys = ['$$hashKey', 'channel', 'type', 'operand', 'debugOnly', 'index', 'value'];
    const operand = activeChannelData.audience_logicalOperand.value;
    audienceAppMixpanelData[operand] = activeChannelData.audience_segment.map((segment) => {
      const segmentData = { [segment.type]: {} };
      mapKeys(omit(segment, omitKeys), (value, key) => {
        if (value && value.label) {
          // Case  for object with 'label' value
          segmentData[segment.type][key] = value.label;
        } else if (typeof value === 'string') {
          // Case for string value
          segmentData[segment.type][key] = value;
        } else if (value && value.length) {
          // Case for array
          segmentData[segment.type][key] = value.map((v) => v.label || v.origin);
        }
      });
      return segmentData;
    });
  }
  return audienceAppMixpanelData;
};
