var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require('data/settings/user-mgmt').name,
]).controller('editBulkUsersModalsController', ['$scope', 'users', 'close', 'notificator', 'abiPermissions', 'userMgmt', 'mgmtServiceFactory', 'geoService', '$q',
    function ($scope, users, close, notificator, abiPermissions, userInterface, mgmtServiceFactory, geoService, $q) {
        // handle new user
        $scope.loading = true;

        var orgService = mgmtServiceFactory.createService('organization');
        var currentUser = $scope.$root.user
        $scope.users = users;
        $scope.fullModel = {};
        $scope.user_type_id = undefined;
        $scope.permission_ids = undefined;
        $scope.saving = false;

        $scope.init = function() {
          var options_id = users[0].account.id;
          $scope.getOptions(options_id).then(function() {
            $scope.permissions = $scope.formatPermissions(users);
            $scope.countries   = $scope.formatCountries(users);
            $scope.user_types  = $scope.formatUserTypes(users);
          }).then(function () {
            $scope.loading = false;
          });
        }

        $scope.getOptions = function(id) {
          return orgService.customRequest('GET', '/' + id + '/options').then(function (data) {
              $scope.options = filterOptions(data);
          });
        };

        function filterOptions(options) {
            var hasPermission = currentUser.userType.match(/super admin/i);
            return {
                permissions: options.permissions,
                countries: options.countries,
                account: hasPermission ? options.account : [currentUser.account],
                user_types: hasPermission ? options.user_types : filterUserTypes(options.user_types)
            };

            function filterUserTypes(types) {
                return angular.copy(types).filter(function (t) {
                    return [1, 2, 3].indexOf(t.id) != -1;
                });
            }
        }

        function userHasPermissionId(user, pid) {
          return _.includes(_.map(user.permissions, 'id'), pid);
        }

        function userHasCountryId(user, pid) {
          return _.includes(_.map(user.countries, 'id'), pid);
        }

        $scope.formatPermissions = function(users) {
          var gPermissions = angular.copy($scope.options.permissions);
          return gPermissions.map( function(p) {
            var userHasPermissionCounts = _.countBy(users, function(user) { return userHasPermissionId(user, p.id); });
            p.on = userHasPermissionCounts[true] > 0;
            p.indeterminate = userHasPermissionCounts[true] > 0 && userHasPermissionCounts[false] > 0;
            return p;
          });
        }

        $scope.formatCountries = function(users) {
          var gCountries = angular.copy($scope.options.countries);
          return gCountries.map( function(c) {
            var userHasCountryCounts = _.countBy(users, function(user) { return userHasCountryId(user, c.id); });
            c.selected = userHasCountryCounts[true] > 0;
            c.indeterminate = userHasCountryCounts[true] > 0 && userHasCountryCounts[false] > 0;
            return c;
          });
        };

        $scope.formatUserTypes = function(users) {
          var gUserTypes = _.map($scope.options.user_types, (ut) => ({id: ut.id, label: ut.name}));
          return gUserTypes.map( function(ut) {
            var userHasUserTypes = _.countBy(users, function(user) { return (ut.id == user.user_type.id); });
            ut.selected = userHasUserTypes[true] > 0;
            ut.indeterminate = userHasUserTypes[true] > 0 && userHasUserTypes[false] > 0;
            return ut;
          });
        };

        var getNonValidUsers = function(form, users, request) {
          let nonValidUsers= [];
          _.each(users, function(user) {
            let countries = _(user.countries.map((c) => c.id)).union(request.countries.selected).difference(request.countries.unselected).value();
            let permissions = _(user.permissions.map((p) => p.id)).union(request.permissions.selected).difference(request.permissions.unselected).value();
            if (_.isEmpty(permissions) || _.isEmpty(countries)) {
              nonValidUsers.push(user);
            }
          });
          return nonValidUsers;
        }

        $scope.update = function(users) {
          $scope.saving = true;
          let request = angular.copy($scope.fullModel);
          let nonValidUsers = getNonValidUsers($scope.editBulkUserForm, users, request);
          if (!_.isEmpty(nonValidUsers)) {
            let emails = c.arrayToSentence(_.map(nonValidUsers, "email"), {limit: 3, itemType: "user"});
            notificator.error(`Cannot edit users. ${emails} will be left without permissions or countries`);
            close({reload: false});
            return;
          }
          request.user_type = $scope.user_type_id;
          request.users = _.map(users, 'id');
          userInterface.bulkEdit(request).then(function(data) {
            notificator.success({body: 'Users were updated successfully'});
            close({reload: true});
          }, function(errorResult) {
            close({reload: false});
          });
        }

        // close modal on navigate back
        var closeListener = $scope.$root.$on("$stateChangeStart", function () {
            close({reload: false})
        });
        $scope.$on('$destroy', closeListener);

        $scope.close = close;

        $scope.validateUserTypePermissions = function() {
            if ($scope.user_type_id == 1) {
              $scope.$broadcast('selectAllPermissions', false);
              $scope.$broadcast('togglePermission', {permission: 'discovery', value: true});
            };
        };

      $scope.init();
    }
]);

