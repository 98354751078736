import * as MixpanelAudience from '../../react/infra/mixpanel/MixpanelAudience';
import * as MixpanelInsights from '../../react/infra/mixpanel/MixpanelInsights';

var BaseWidget = require("../base_widget"),
    c = require("infra/utils/common");

var CHANNEL_SOCIAL = {"label": "Social", "value": 'tweets'};

var MAXIMUM_3_MONTHS_MESSAGE = "Maximum date span is three months. Time frame was adjusted.";
var MINIMUM_1_WEEK_MESSAGE = "Minimum date span is one week. Time frame was adjusted.";
var MINIMUM_1_MONTH_MESSAGE = "Minimum date span is one month. Time frame was adjusted.";
var EXPORT_NUMERIC_FORMAT = {'skew': 'numeric', 'distribution': 'percent'};

function TimingWidgetController($scope, $timeout, $element, timingService, util, insightsExportService,
                                examplesDataModel, TIMES, $location, abiPermissions) {

    var self = this;
    this.$scope        = $scope;
    this.util          = util;
    this.TIMES         = TIMES;
    this.$location     = $location;
    this.$timeout      = $timeout;
    this.timingService = timingService;
    this.abiPermissions = abiPermissions;
    this.chartData     = [];
    this.charts        = {};
    this.insightsExportService = insightsExportService;
    this.examplesDataModel = examplesDataModel;

    $scope.compareMode = false;
    $scope.measureTypes = [
        {value: "distribution", label: "Distribution", tooltip: "Consumption breakdown by hour/day"},
        {value: 'skew', label: 'Skew',
         tooltip: "The extent to which the seed interest is over-indexed at a certain hour/day compared to the entire week."}
    ];
    $scope.valueMeasure = 'distribution';
    $scope.compareModeAllowed = false;
    MixpanelInsights.trackPageView('timing');
    var title_texts = [''].concat(c.getHeatmapHours()).concat('Total');
    var columns = [{width: 15}].concat(Array(25).fill({width: 10}));
    var columns_hourly = [{width: 15}].concat(Array(24).fill({width: 10}));
    var columns_daily = [{width: 15}].concat(Array(9).fill({width: 10}));
    var days = c.getWeekDays();

    this.onResize = function(){
        $timeout(function() {
            var node = d3.select($element[0]).node();
            $scope.chartWidth = node.offsetWidth - 10;
            $scope.chartHeight = node.offsetHeight;
        });
    };

    function formatOutput(value, format, measure){
        return format(value / 100, EXPORT_NUMERIC_FORMAT[measure]);
    }

    function formatRecordOutput(record, format, measure){
        return formatOutput(_.last(record.vals), format, measure);
    }

    function getTermDisplay(term){
        var term_display = term.hasOwnProperty('display') ? term.display : term.text;
        if(c.isString(term_display) && term_display.endsWith("*")){
            term_display = term_display.replace(/\*$/," (refined)");
        }
        return term_display;
    }

    this.getReportFunctionForSeed = function(term){
        return function(format){
            let measure = self.$scope.valueMeasure;
            var titles = _.map(title_texts, _.partial(format, _, 'bold'));
            var timing_record = self.timingService.getTimingForSeed(term);
            var formatRecordFunction = _.partial(formatRecordOutput, _, format, measure);
            var getDayLine = function(i){
                var values = _.map(_.filter(timing_record.days_hours, (rec) =>
                rec.vals[0][0] == i), formatRecordFunction);
                var average = formatRecordFunction(_.find(timing_record.day_totals, (rec) => rec.vals[0] == i), format);
                return [days[i-1]].concat(values).concat([average]);
            };
            var table = [titles].concat(_.map(_.range(1,8),getDayLine))
                                .concat([['Total'].concat(_.map(timing_record.hour_totals, formatRecordFunction)).concat([''])]);
            return {name: getTermDisplay(term), columns: columns, table: table};
        };
    };

    this.getHourlyReport = function(format){
        let measure = self.$scope.valueMeasure;
        var titles = _.map([''].concat(c.getHeatmapHours()), _.partial(format, _, 'bold'));
        var table = [titles];
        _.each(self.chartData.seeds, function(record){
            var hour_record = [getTermDisplay(record.term)].concat(_.map(record.hour_totals, _.partial(formatRecordOutput, _, format, measure)));
            table.push(hour_record);
        });
        table.push(['Total'].concat(_.map(_.map(self.chartData.average_hours, 'vals'), _.partial(formatOutput, _, format, measure))));
        return {name: 'Hourly', columns: columns_hourly, table: table};
    };

    this.getDailyReport = function(format){
        let measure = self.$scope.valueMeasure;
        var titles = _.map([''].concat(days).concat(['Weekdays','Weekend']), _.partial(format, _, 'bold'));
        var table = [titles];
        var formatOutputFunction = _.partial(formatOutput, _, format, measure);
        _.each(self.chartData.seeds, function(record){
            var day_record = [getTermDisplay(record.term)].concat(_.map(record.day_totals, _.partial(formatRecordOutput, _, format, measure)))
                                                          .concat([formatOutputFunction(record.weekday_total.val), formatOutputFunction(record.weekend_total.val)]);
            table.push(day_record);
        });
        var totals = _.map(self.chartData.average_days, 'vals').concat([self.chartData.average_weekday.val, self.chartData.average_weekend.val]);
        table.push(['Total'].concat(_.map(totals, formatOutputFunction)));
        return {name: 'Daily', columns: columns_daily, table: table};
    };

    this.adjustTimeframe = function(changedTimeframe, oldTimeframe, timeAmountUnits) {
        const format = "DD_MM_YY_HH_mm";
        let adjustedTimeframe = angular.copy(oldTimeframe);

        if ((changedTimeframe === undefined || changedTimeframe[1] === 'month' || oldTimeframe[1].substring(0, 8) === changedTimeframe[1].substring(0, 8))
            && moment(oldTimeframe[0], format).add(timeAmountUnits) <= moment().subtract({'day' : 1})) {
            adjustedTimeframe[1] = moment(oldTimeframe[0], format).add(timeAmountUnits).format(format);
        } else {
            if (moment(oldTimeframe[1], format) > moment().subtract({'day' : 1})){
                adjustedTimeframe[1] = moment(oldTimeframe[1], format).subtract({'day' : 1}).format(format);
            }
            adjustedTimeframe[0] = moment(oldTimeframe[1], format).subtract(timeAmountUnits).format(format);
        }

        return adjustedTimeframe;
    };

    this.validateTimeframe = function (changedVals, channel) {
        let timeframe = $scope.timeframe;
        let notification_message = null;

        if ((timeframe[1] === 'month' && parseInt(timeframe[0]) > 3) || (timeframe[1] === 'year')) {
            $scope.timeframe = [3, 'month'];
            notification_message = MAXIMUM_3_MONTHS_MESSAGE;
        } else if (_.includes(['articles', 'sg_telco'], channel) && (timeframe[1] === 'day')) {
            $scope.timeframe = [1, 'month'];
            notification_message = MINIMUM_1_MONTH_MESSAGE;
        } else if (channel === 'tweets' && (timeframe[1] === 'day') && (timeframe[0] !== 7)) {
            $scope.timeframe = [7, 'day'];
            notification_message = MINIMUM_1_WEEK_MESSAGE;
        } else if (timeframe[1] != 'month' && timeframe[1] != 'day') {
            const date_format = "DD_MM_YY";

            if (moment(timeframe[0], date_format) < moment(timeframe[1], date_format).subtract({'month': 3, 'day': c.calcOffsetDays(timeframe, 3)})){
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'month': 3});
                notification_message = MAXIMUM_3_MONTHS_MESSAGE;
            } else if (_.includes(['articles', 'sg_telco'], channel) && moment(timeframe[0], date_format).add({'month': 1}) > moment(timeframe[1], date_format)) {
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'month': 1});
                notification_message = MINIMUM_1_MONTH_MESSAGE;
            } else if (moment(timeframe[0], date_format).add({'day': 6}) > moment(timeframe[1], date_format)){
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'day': 6});
                notification_message = MINIMUM_1_WEEK_MESSAGE;
            }
        }
        $scope.$root.$broadcast('timeframe-update', $scope.timeframe, true, notification_message);
    };

    this.validateContextChannel = function() {
        if (!$scope.insightsChannel.hasOwnProperty('value')) {
            $scope.$root.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsTimingChannelsFilter, this.abiPermissions);
        }else{
            if ($scope.insightsChannel.permission != null && !this.abiPermissions.hasPermission($scope.insightsChannel.permission)){
                $scope.$root.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsTimingChannelsFilter, this.abiPermissions);
            }
        }

        if(c.isNotBelongToPermissionGroup($scope.$root.insightsTimingChannelsFilter, $scope.insightsChannel.value)){
            $scope.$root.context.current.insightsChannels = $scope.insightsChannel = c.getAvailableContext($scope.$root.insightsTimingChannelsFilter, this.abiPermissions);
        }
    };

    this.performUpdate = () => {
        self.$element.append(self.loaderDiv);
        if(this.handler !== undefined && this.handler.noData === undefined) {
            this.handler.promise.cancel();
        }
        this.handler = self.timingService.get(self.parameters);

        if(this.handler.noData === undefined){
            this.handler.promise.then(data => {
            let heatmap = self.timingService.getHeatmapResult(data, this.handler.termMap,self.parameters.measure)
            drawChart(heatmap);
            }).catch((e)=>{return Promise.reject(e)});
            return this.handler.promise;
        }else{
            delete this.handler;
            return Promise.resolve('No Terms')
        }
    };

    function drawChart(data){
        self.loaderDiv.remove();
        $scope.inProcess = false;
        $scope.noData = false;
        var ca = [];
        self.chartData = data;
        self.chartData.seeds = _.compact(_.map($scope.terms, (term) => _.find(data.seeds, (d) => d.term && (d.term.text == term.text))));
        self.charts = {};
        self.insightsExportService.clear();
        self.insightsExportService.setParams(self.parameters);
        self.insightsExportService.setReportFunctions([]);
        if (c.isArray(self.chartData.seeds)) {
            _.each(self.chartData.seeds, function (d) {
                if (c.is(d.term)) {
                    var key = c.getKey(d.term.id, d.term.text);
                    if (self.charts[key] == null) {
                        self.charts[key] = {
                            key: key,
                            label: d.term.text,
                            display: self.util.getTermDisplay(d.term),
                            data: d.days_hours,
                            day_totals: d.day_totals,
                            hour_totals: d.hour_totals,
                            colors: d.days_hours_colors,
                            day_totals_colors: d.day_totals_colors,
                            hour_totals_colors: d.hour_totals_colors
                        };
                        ca.push(self.charts[key]);
                        self.insightsExportService.addReportFunction(self.getReportFunctionForSeed(d.term));
                    }
                }
            });
        }
        $scope.chartArray = ca;
        $scope.seedMeasureTopLabel = ($scope.valueMeasure === 'skew') ? ['Overall'] : ['Total'];
        if($scope.chartArray.length <= 1){
            $scope.compareModeAllowed = false;
            $scope.compareMode = false;
        } else {
            $scope.hourlyData = _.pick(self.chartData, 'seeds', 'average_hours', 'average_hours_colors');
            $scope.dailyData = _.pick(self.chartData, 'seeds', 'average_days', 'average_days_colors',
                'average_weekday', 'average_weekday_color',
                'average_weekend', 'average_weekend_color');
            var labels = _.map(self.chartData.seeds, (seed) => {
                return self.util.getTermDisplay(seed.term)
            });
            $scope.hourlyData.labels = labels;
            $scope.dailyData.labels = labels;
            self.insightsExportService.addReportFunction(self.getHourlyReport);
            self.insightsExportService.addReportFunction(self.getDailyReport);
            $scope.compareModeAllowed = true;
        }
        self.onResize();
    }

    $scope.updateValueMeasure = (measure) => {
        MixpanelAudience.trackViewChange(measure, 'Timing widget');
        $scope.valueMeasure = measure;
        $scope.inProcess = true;
        $scope.noData = true;
        self.parameters.measure = $scope.valueMeasure;
        self.performUpdate();
    };

    $scope.toggleCompareMode = (value) => $timeout(() => $scope.compareMode = value);

    this.insightsExportService.setTitle('Timing');
}

TimingWidgetController.prototype._doUpdate = function(values, changedVals){
    var self = this;
    var $scope = this.$scope;
    var $location = this.$location;
    self.examplesDataModel.visible = false;
    $scope.inProcess = true;
    $scope.noData = true;
    this.validateContextChannel();
    this.validateTimeframe(changedVals, $scope.insightsChannel.value);
    self.parameters = this.util.buildInsightsParameters($scope);
    self.parameters.measure = $scope.valueMeasure;
    if (self.parameters.channel !== "tweets"){
      c.validateNonPhrases($scope.terms, self.parameters.channel, self.notificator);
    }
    if ($location.path() == "/insights/timing") { //Don't set timeframe vars if we're stepping out of widget
      let times = self.parameters.channel == "tweets" ? ["1W", "1M", "3M"] : ["1M", "3M"];
      $scope.$root.$broadcast('timeframe-vars-update', times, this.TIMES.INSIGHTS_OFFSET, true, false, true);
    }
    return self.performUpdate();
};

TimingWidgetController.$inject = ["$scope", "$timeout", "$element", "timingService", "util", "insightsExportService",
                                  "examplesDataModel", "TIMES", "$location", "abiPermissions"];

module.exports = require("angular").module(__filename, [
    require('data/insights/timing-service').name,
    require('../../common/am-heatmap-chart/heatmap-chart').name,
    require('../../common/am-heatmap-chart/seed-heatmap-chart').name,
    require('../../common/am-heatmap-chart/daily-heatmap-chart').name,
    require('../../common/am-heatmap-chart/hourly-heatmap-chart').name
])
.directive("timingWidget", [function() {
    return BaseWidget({
        restrict: "E",
        template: require('./timing-widget.html'),
        scope: {
            timeframe: "=",
            terms: "=",
            geo: "=",
            sub_geos: "=subGeos",
            topics: "=",
            insightsChannel: '=',
            cacheBaster: '='
        },
        controller: TimingWidgetController
    });
}]);
