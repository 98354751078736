const DEFAULT_OPTIONS = {
    xAxisColor: "rgba(255, 255, 255, 0.3)", barWidth: 20, barColor: "#cacaca", labelColor: "#cacaca", labelFont: "14px",
    valueColor: "#cacaca", valueFont: "14px", highlightedBarColor: "#44b0c7", highlightedLabelColor: "white",
    highlightedLabelFont: "14px", highlightedValueColor: "white", highlightedValueFont: "22px bold"
};

module.exports = require("angular").module(__filename, [])
    .directive('responsiveBarChart', [() => ({
            restrict: 'E',
            template: require('./am-responsive-bar-chart.drv.html'),
            scope: {
                data: "=",
                indexLine: "=",
                options: "=?",
                autoHighlight: "@?"
            },
            link: function($scope, $element) {
                $scope.options = Object.assign({}, DEFAULT_OPTIONS, $scope.options);

                $scope.$watch('data', () => {
                    if($scope.data) {
                        const values = $scope.data.map(o => o.value);

                        $scope.barUnit = 100 / Math.max(...values);
                        $scope.data.forEach(d => { d.barHeight = d.value * $scope.barUnit });

                        if($scope.autoHighlight) { // highlight bars automatically by given keyword 'min'/'max'
                            const max = Math.max(...values), min = Math.min(...values);
                            const compareVal = {min, max}[$scope.autoHighlight];
                            if(max !== min) $scope.data.forEach(element => { if(compareVal === element.value) element.highlight = true });
                        }
                    }
                });
            }
        })]
    );
