var common = require("infra/utils/common");
var add_tooltip = require('../tooltip/tooltip.js');
var ELEMENT_SELECTOR = '.donut-chart-drv';
var DEFAULT_OPTIONS = {
  donutColors: ['#009EBD', '#40B6CE', '#80CFDE','#BFE7EF', 'red', 'grey', 'yellow', 'green', 'pink'],
  labelColor: 'rgba(255,255,255,0.7)', labelFontSize: '14px', labelFontWeight: 'bold', labelFontFamily: 'Roboto',
  highlightedLabelColor: 'white'
};


module.exports = angular.module(__filename, [
 ]).directive('donutChart', ['$window', function($window) {
    return {
        restrict: 'E',
        template: require('./am-donut-chart.drv.html'),
        scope: {
            "options": "=?",
            "data": "="
        },
        link: function ($scope, $element) {
            var options = _.merge({}, DEFAULT_OPTIONS, $scope.options);
            var elemWidth, elemHeight, newElemWidth, newElemHeight;
            [elemWidth, elemHeight] = getElementWidthAndHeight();
            var currElemWidth, currElemHeight;

            angular.element($window).bind('resize', saveNewSizes);
            $scope.$on('$destroy', () => angular.element($window).off('resize', saveNewSizes));

            $scope.$watch('data', onResize);

            function saveNewSizes() {
                [newElemWidth, newElemHeight] = getElementWidthAndHeight();
                if (newElemWidth != elemWidth || newElemHeight != elemHeight){
                    newElemWidth = elemWidth;
                    newElemHeight = elemHeight;
                    onResize();
                }
            }

            function getElementWidthAndHeight(){
                var pos = d3.select(ELEMENT_SELECTOR)[0][0].getBoundingClientRect();
                return [pos.width, pos.height]
            }

            function onResize(){
                if (!_.isEmpty($scope.data)){
                    drawGenderChart($scope.data);
                }
            }

            function drawGenderChart(data) {

                function wrapText(text, width) {
                    text.each(function() {
                        var text = d3.select(this),
                            words = text.text().split(/\s+/).reverse(),
                            word,
                            line = [],
                            lineHeight = 0, // ems
                            y = text.attr("y"),
                            dy = 1,
                            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em"),
                            isPercentElement = false;
                        while (word = words.pop()) {
                            line.push(word);
                            tspan.text(line.join(" "));
                            if (isPercentElement || tspan.node().getComputedTextLength() > width) {
                                line.pop();
                                tspan.text(line.join(" "));
                                line = [word];
                                tspan = text.append("tspan").attr("x", 0).attr("y", y).text(word).attr("dy", dy + lineHeight + "em");
                                lineHeight += 1;
                            }
                            isPercentElement = false;
                        }
                    });
                }

                function changeLegendLabelColor(label, color) {
                    var labelElem = d3.select(legend[0].filter((l) => l.__data__.label == label)[0]);
                    labelElem.select("text").style({"fill": color});
                }

                function changeArcLabelColor(label, color) {
                    var arcLabel = textElement[0].filter((a) => a.__data__.data && a.__data__.data.label == label)[0];
                    d3.select(arcLabel).select("text").style({"fill": color});
                }

                function addLegendInfoIcon(legend){
                    var otherLabel = legend[0].filter((l) => l.__data__.label == "Other");
                    if (_.isEmpty(otherLabel)) return;
                    var pos = d3.select(otherLabel[0]).select("text")[0][0].getBoundingClientRect();
                    var infoIcon = d3.select(otherLabel[0]).append("foreignObject");
                    infoIcon.attr("width", 10)
                        .attr("height", 10)
                        .attr("x", pos.width + legendRectSize + legendSpacing + 4)
                        .attr("y", -1)
                        .append("xhtml:body")
                        .style("font-size", options.labelFontSize)
                        .style("font-weight", options.labelFontWeight)
                        .style("font-family", options.labelFontFamily)
                        .html(`<i class="icon-info" title="${otherLabel[0].__data__.tooltip}"></i>`)
                        .on('mouseover', function(d){
                            d3.select(infoIcon[0][0]).select("i").style("color", "white");
                            changeArcLabelColor("Other", "white");
                        })
                        .on('mouseout', function(d){
                            d3.select(infoIcon[0][0]).select("i").style("color", options.labelColor);
                            changeArcLabelColor("Other", options.labelColor);
                        });
                     add_tooltip(infoIcon.select(".icon-info"), 'info', {style: {classes: 'common-tooltip-info'}, position: { adjust: { x: 4 }}});
                }

                function drawLegend() {

                    var legendRoot = svg.append("svg:g").classed('legend-element', true);

                    var legend = legendRoot 
                        .selectAll('.legend')
                        .data(dataSortedByValue)
                        .enter()
                        .append('g')
                        .attr('class', 'legend')
                        .attr('transform', function(d, i) {
                            var height = legendRectSize + legendSpacing;
                            var vert = i * height;
                            return 'translate(0,' + vert + ')';
                        });

                    legend.append('rect')
                        .attr('width', legendRectSize)
                        .attr('height', legendRectSize)
                        .style('fill', (d) => d.color)
                        .style('stroke', (d) => d.color);

                    legend.append('text')
                        .attr('x', legendRectSize + legendSpacing)
                        .attr('y', legendRectSize)
                        .text((d) => d.label)
                        .style("font-size", options.labelFontSize)
                        .style("font-weight", options.labelFontWeight)
                        .style("font-family", options.labelFontFamily)
                        .style('fill', options.labelColor);
                    
                    legendRoot.attr('transform', function() {
                        var horz = currElemWidth - legendRoot[0][0].getBoundingClientRect().width;
                        return 'translate(' + horz + ',0)';
                    });

                    addLegendInfoIcon(legend);
                    return legend;
                }

                function calcLabelPosition(d) {
                    var arc_pos = arc.centroid(d),
                        arc_pos_x = arc_pos[0],
                        arc_pos_y = arc_pos[1],
                        h = Math.sqrt(arc_pos_x * arc_pos_x + arc_pos_y * arc_pos_y),
                        numOfDigits = d["value"].toString().length,
                        spaceFromDonut = labelr + 8 * numOfDigits;
                        return [arc_pos_x/h * spaceFromDonut, arc_pos_y/h * (labelr + 8)]
                }

                var sum = _.sum(_.map(data, 'value'));
                var percents = common.roundPercents(_.map(data, (d) => d.value * 100 / sum));
                _.each(data, (d,i) => d.percents = percents[i]);
                data = data.filter((d) => d.percents > 0);
                var dataSortedByValue = _.orderBy(data, 'value', 'desc');
                _.each(dataSortedByValue, (d,i) => d.color = options.donutColors[i]);
                var maxValue =  _.maxBy(data, 'value');
                maxValue.highlight = true;

                var legendRectSize = 12;
                var legendSpacing = 6;
                [currElemWidth, currElemHeight] = getElementWidthAndHeight();
                 d3.select(ELEMENT_SELECTOR).select("svg").remove();
                 var svg = d3.select(ELEMENT_SELECTOR).append("svg:svg");
                 var vis = svg.data([data]).append("svg:g");
                 var legend = drawLegend();  
                 var legendPos = svg.select('.legend-element')[0][0].getBoundingClientRect(); 
                 var legendWidth = legendPos.width;

                 var radius = Math.min(currElemWidth - legendWidth + 30, currElemHeight) / 2 - 30,
                    labelr = radius + 5,
                    donut = d3.layout.pie().padAngle(0.025),
                    arc = d3.svg.arc().innerRadius(radius * .88).outerRadius(radius),
                    highlightedArc = d3.svg.arc().innerRadius(radius * .86).outerRadius(radius * 1.02);

                 var arcs = vis.selectAll("g.arc")
                    .data(donut.value((d) => d.value))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + radius + "," + radius + ")");

                 arcs.append("svg:path")
                    .attr("fill", (d, i) => d.data.color)
                    .attr("d", (d) => d.data.highlight ? highlightedArc(d) : arc(d))
                    .attr("startradius", 100)
                    .on('mouseover', (d) => changeLegendLabelColor(d.data.label, "white"))
                    .on('mouseout', (d) => changeLegendLabelColor(d.data.label, options.labelColor));

                 var textElement = arcs.append("svg:g").attr("class", "text-element");
                 textElement.attr("visibility", (d) => d.data.highlight ? "hidden" : "visible")
                    .append("svg:text")
                    .attr("dy", "0.35em")
                    .attr("text-anchor",  "middle")
                    .text((d, i) => d.data.percents + '%')
                    .style("fill", options.labelColor)
                    .style("font-size", options.labelFontSize)
                    .style("font-weight", options.labelFontWeight)
                    .style("font-family", options.labelFontFamily)
                    .attr("transform", function(d) {
                        var [x, y] = calcLabelPosition(d);
                        return "translate(" + x +  ',' + y +  ")";
                    });

                // Larger element for mouse hover
                  
                var hoverLabelsElem = svg.data([data]).append("svg:g").classed('hover-label', true);
                var hoverLabelsArcs = hoverLabelsElem.selectAll("g.arc")
                    .data(donut.value((d) => d.value))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + radius + "," + radius + ")");
                var hoverTextElement = hoverLabelsArcs.append("svg:g").attr("class", "text-element");
                hoverTextElement.attr("visibility", (d) => d.data.highlight ? "hidden" : "visible")
                    .append("svg:text").text((d, i) => d.data.percents + '%')
                    .style("font-size", options.labelFontSize)
                    .style("font-weight", options.labelFontWeight)
                    .style("font-family", options.labelFontFamily)
                    .style("fill", "transparent")
                    .attr("dy", "0.35em")
                    .attr("text-anchor",  "middle")
                    .attr("transform", function(d) {
                        var [x, y] = calcLabelPosition(d);
                        return "translate(" + x +  ',' + y +  ") scale(2.5,2.5)";
                    });

                hoverTextElement.on('mouseover', (d) => changeLegendLabelColor(d.data.label, "white"))
                                .on('mouseout', (d) => changeLegendLabelColor(d.data.label, options.labelColor));
                
                svg.select(".text-element[visibility='hidden']")[0][0].remove();    
                
                var xOffset = (currElemWidth / 2 - radius),
                    yOffset = (currElemHeight - radius * 2) / 2 + 10;

                // Change xOffset if donut collide with legend
                var donutPos = vis[0][0].getBoundingClientRect();
                var svgPos = svg[0][0].getBoundingClientRect();
                if (donutPos.top + yOffset < legendPos.bottom && donutPos.right + xOffset > legendPos.left) {
                    xOffset = legendPos.left - donutPos.right;
                    xOffset = Math.max(xOffset, svgPos.left - donutPos.left)   
                }   

                vis.attr("transform", "translate(" + xOffset+ "," + yOffset + ")");
                hoverLabelsElem.attr("transform", "translate(" + xOffset+ "," + yOffset + ")");    


                // Inside Text
                 var circleForInsideText = svg.data([[{value: 1}]])
                    .append("svg:donutcircle:g")
                    .attr("transform", "translate(" + xOffset+ "," + yOffset + ")");                    

                 var arcForInsideText = circleForInsideText.selectAll("g.arc")
                    .data(donut.value(() => 1))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + (radius + 0) + "," + radius + ")");

                 arcForInsideText.append("svg:path")
                    .attr("d", arc)
                    .attr("visibility", "hidden");

                 arcForInsideText.append("text")
                    .attr("dy", "-.35em")
                    .style("text-anchor", "middle")
                    .attr("fill", options.highlightedLabelColor)
                    .style("font", "22px Roboto")
                    .style("font-weight", "bold")
                    .text(maxValue.percents + '%');


                 arcForInsideText.append("text")
                    .attr("dy", "2em")
                    .style("text-anchor", "middle")
                    .attr("fill", options.highlightedLabelColor)
                    .style("font", "16px Roboto")
                    .text(maxValue.label)
                    .call((d) => wrapText(d, "60"));
            }
        }
    }
}]);
